import { Routes, RouterModule } from '@angular/router';
import { ProductTypeListComponent } from './product-type-list/product-type-list.component';
import { ProductFormComponent } from './product-form/product-form.component';
import { TypeProductFormComponent } from "./product-type-form/product-type-form.component";

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: "",
        component: ProductTypeListComponent
      },
      {
        path: "new",
        component: TypeProductFormComponent
      },
      {
        path: "new/:uuid",
        component: TypeProductFormComponent
      },
      {
        path: "type/:uuidType/new",
        component: ProductFormComponent
      },
      {
        path: "type/:uuidType/new/:uuidProduct",
        component: ProductFormComponent
      }
    ]
  }
];

export const ProductsRoutes = RouterModule.forChild(routes);
