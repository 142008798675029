export enum ApprovalStatusEnum {
    PENDING_APPROVAL='Aprovação pendente',
    APPROVED='Aprovado',
    REPROVED='Reprovado',

}

export enum ApprovalFundingEnum{
EXTERNAL = 'Interno',
    INTERNAL='Externo'
}
