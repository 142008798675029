import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CompaniesFormsComponent } from './companies-forms/companies-forms.component';
import { CompaniesListComponent } from './companies-list/companies-list.component';

const routes: Routes = [
    { path: '', component: CompaniesListComponent, },
    { path: 'new', component: CompaniesFormsComponent, },
    { path: ':id/edit', component: CompaniesFormsComponent, },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CompaniesRoutingModule { }
