<!-- <app-bread-crumb [items]="[{text: 'Contratos Gerados'}]"></app-bread-crumb> -->

<app-page-header page-title="Contratos FGTS" button-text="+ Gerar Novo Contrato" button-link="/dashboard"
  button-class="btn-success ml-10" [noForm]="true"></app-page-header>

<main>
  <div>
    <ng-container>
      <div style="display: flex; flex-direction: row; width: 100%; justify-content: space-between;">
        <div *ngFor="let card of arrayCards; let i = index" class="opacity-80" (click)="applayFilter(card)"
          [ngStyle]="{'width': widthStyle(arrayCards)}">
          <span [class]="'btn mt-4 ' + checkStatusColor(card.type)" style="width: 100% !important;">
            {{checkStatusText(card.type)}}
            <br>
            <p class="font-size-16">
              {{card.totalValue | currency:'BRL'}}
            </p>
            <span class="font-size-16">
              Quant - {{card.quant}}
            </span>
          </span>
        </div>

      </div>
    </ng-container>
  </div>

  <div class="d-flex align-items-end justify-content-between mt-6">
    <div class="search col-md-4 mt-0 mb-5 w-100">
      <label class="form-label fw-bolder text-dark fs-6 mb-0">Pesquisar</label>
      <input class="form-control bg-white mt-1" placeholder="Nome, documento, status…" (keyup)="filterData($event)"
        autocomplete="off" />

      <!-- <div class="row p-0">
        <div class="col-md-12 mt-0 mb-5">
          <app-date-picker [defaultDate]='defaultDate' (emitDate)="emitDate($event)"></app-date-picker>
        </div>
      </div> -->
    </div>
  </div>


  <div>
    <table mat-table matSort [dataSource]="dataSource"
      class="mat-elevation-z1 table table-striped table-hover mb-0">

      <ng-container matColumnDef="fullname">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Colaborador </th>
        <td mat-cell *matCellDef="let element"> {{element.fullname}} </td>
      </ng-container>

      <ng-container matColumnDef="identityGov">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> CPF </th>
        <td mat-cell *matCellDef="let element" class=" item-row-padding-inline item-row-nowrap">
          {{maskerService.formatDocumentPerson(element.cpf)}} </td>
      </ng-container>

      <ng-container matColumnDef="companyName">
        <th mat-header-cell *matHeaderCellDef> Empresa </th>
        <td mat-cell *matCellDef="let element"> {{element.companyName}} </td>
      </ng-container>

      <ng-container matColumnDef="netCapitalAmountLoan">
        <th mat-header-cell *matHeaderCellDef> Solicitado </th>
        <td mat-cell *matCellDef="let element"> {{element.releasedAmount | currency:'BRL'}} </td>
      </ng-container>

      <ng-container matColumnDef="installmentQuantityLoan">
        <th mat-header-cell *matHeaderCellDef> Parcelas </th>
        <td mat-cell *matCellDef="let element"> {{element.period}}x</td>
      </ng-container>

      <ng-container matColumnDef="fvTotalAmountLoanValue">
        <th mat-header-cell *matHeaderCellDef> Valor Total </th>
        <td mat-cell *matCellDef="let element"> {{element.operationAmount | currency:'BRL'}} </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="text-center"> Ações </th>
        <td mat-cell *matCellDef="let element" class="text-center">
          <mat-icon (click)="goToDetailsProposal(element)">visibility</mat-icon>
          <mat-icon class="mx-4" (click)="getContractTermsCCB(element.uuid)">download</mat-icon>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;">
      </tr>

    </table>
    <mat-paginator class="mat-elevation-z1" #paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 20,50,100]">
    </mat-paginator>
  </div>
</main>