import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiService } from 'src/app/core/_base/_auth/api.service';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from "rxjs";
import { SpeViewNScfiProxy } from "../../../proxy/view/spe-view-n-scfi.proxy";
import { SpeNScfiProxy } from "../../../proxy/spe/spe-n-scfi.proxy";
import {CreateKitDto, DeleteKitDto, UpdateKitDto} from './kits.dto';
import { ConfigurationKitDto } from './kits.dto';

@Injectable({
    providedIn: 'root'
})
export class KitsService {

    formKit: FormGroup;

    constructor(
        protected api: ApiService,
        private formBuilder: FormBuilder,
        private speViewNScfiProxy: SpeViewNScfiProxy,
        private speNScfiProxy: SpeNScfiProxy
    ) { }

    public createFormKit(): FormGroup {
        this.formKit = this.formBuilder.group({
            name: new FormControl('', Validators.compose([
                Validators.required,
            ])),
            documents: new FormControl<string[]>([], Validators.compose([Validators.required])),
        })
        return this.formKit;
    }

    public getErrorMessages() {
        return {
            name: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
            ],
            documents: [
                {
                    type: 'required',
                    message: 'Selecione pelo menos um dado obrigatório'
                }
            ]
        }
    }

    public findKitsList(): Observable<ConfigurationKitDto[]> {
        return this.speViewNScfiProxy.findKitsList();
    }

    public findKitByUuid(uuid: string): Observable<CreateKitDto> {
        return this.speViewNScfiProxy.findKitByUuid(uuid);
    }

    public findByUuidWithDocs(uuid: string): Observable<ConfigurationKitDto> {
        return this.speViewNScfiProxy.findByUuidWithDocs(uuid);
    }

    public createKit(kit: CreateKitDto): Observable<CreateKitDto> {
        return this.speNScfiProxy.createKit(kit);
    }

    public updateKit(updateKitDto: UpdateKitDto): Observable<ConfigurationKitDto> {
        return this.speNScfiProxy.updateKit(updateKitDto);
    }

    public deleteKit(deleteKitDto: DeleteKitDto): Observable<ConfigurationKitDto> {
        return this.speNScfiProxy.deleteKit(deleteKitDto);
    }

}
