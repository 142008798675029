import { Component, Input, OnInit } from '@angular/core';
import { ProposalDto } from 'src/app/core/scfi/services/services-proposal/proposal.dto';

@Component({
    selector: 'proposal-installments-details',
    templateUrl: './proposal-installments-details.component.html',
    styleUrls: ['./proposal-installments-details.component.scss']
})
export class ProposalInstallmentsDetailsComponent implements OnInit {
    @Input() proposal?: ProposalDto;

    ngOnInit() { }
}
