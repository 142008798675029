import { BrMaskerModule } from 'br-mask';
import { MaskService } from './masker.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrMaskDirective } from 'br-mask';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  imports: [
    CommonModule,    
    HttpClientModule
  ],
  providers: [
    MaskService,
    BrMaskDirective
  ],
  declarations: [
    
  ]
})
export class MaskerModule { }
