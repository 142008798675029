import { Injectable } from '@angular/core';
import { BrMaskDirective } from 'br-mask';



@Injectable()
export class MaskService {
    constructor(
        private brMasker: BrMaskDirective,
    ) { }

    formatDocumentPerson(document: string) {
        return this.brMasker.writeValuePerson(document);
    };

    formatDocumentCPF(document: string) {
        return this.brMasker.writeCreateValue(document, { mask: '000.000.000-00', len: 14 });
    };

    formatDocumentCNPJ(document: string) {
        return this.brMasker.writeCreateValue(document, { mask: '00.000.000/0000-00', len: 18 });
    };

    formatAccountNumber(value: string) {
        return this.brMasker.writeCreateValue(value, { money: true, decimalCaracter: '-', decimal: 1 });
    };

    formatDate(date: string) {
        return this.brMasker.writeCreateValue(date, { mask: '00/00/0000', len: 10, type: 'num' });
    };

    formatAgencyNumber(agency: string) {
        return this.brMasker.writeCreateValue(agency, { mask: '00000', type: 'num' });
    };

    formatPhone(agency: string) {
        return this.brMasker.writeCreateValue(agency, { phone: true });
    };

    formatCep(postalCode: string) {
        return this.brMasker.writeCreateValue(postalCode, { mask: '00000-000', len: 9, type: 'num' })
    }
}
