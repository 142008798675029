import { NgModule } from '@angular/core';
import {CommonModule, DatePipe, CurrencyPipe} from '@angular/common';
import { ContractsModule } from './contracts/contracts.module';
import { CustomersModule } from './customers/customers.module';
import { DocumentsModule } from './documents/documents.module';
import { FeesModule } from './fees/fees.module';
import { FundingModule } from './funding/funding.module';
import { KitsModule } from './kits/kits.module';
import { ProductsModule } from './products/products.module';
import { ProposalsModule } from './proposals/proposals.module';
import { SimulationModule } from './simulation/simulation.module';
import { TaxesModule } from './taxes/taxes.module';
import { RouterModule } from '@angular/router';
import { routesScfi } from './scfi.routing';
import { AcceptProposalModule } from './accept/accept-proposal.module';
import {ScfiComponent} from "./scfi.component";
import {BrMaskDirective} from "br-mask";
import { MaskService } from 'src/app/shared/services-ultil/masker-service/masker.service';

@NgModule({
  // declarations: [ScfiComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routesScfi),
    AcceptProposalModule,
    ContractsModule,
    CustomersModule,
    DocumentsModule,
    FeesModule,
    FundingModule,
    KitsModule,
    ProductsModule,
    ProposalsModule,
    SimulationModule,
    TaxesModule,
    RouterModule,
    // ScfiComponent
  ],
  providers: [
    CurrencyPipe,
    DatePipe,
    BrMaskDirective,
    MaskService
  ]
})
export class ScfiModule { }
