import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { InstallmentDetailsDto } from 'src/app/core/scfi/services/services-proposal/proposal.dto';
import { TableData } from 'src/app/core/scfi/services/services-table/table.dto';
import { AbstractScfi } from 'src/app/pages/scfi/abstract-scfi';

@Component({
    selector: 'installments-table',
    templateUrl: './installments-table.component.html',
    styleUrls: ['./installments-table.component.scss']
})

export class InstallmentsTableComponent extends AbstractScfi implements OnInit {
    @Input() installments: InstallmentDetailsDto[];
    public installmentsData: InstallmentDetailsDto[] = [];
    public tableData: TableData;

    constructor(
        protected currencyPipe: CurrencyPipe,
        protected datePipe: DatePipe,
    ) {
        super(currencyPipe, datePipe);
    }

    ngOnInit(): void {
        this.installmentsData = this.installments || [];
        this.sumValuesTableInstallment();
        this.tableData = this.generateTableData();
    }

    public sumValuesTableInstallment() {
        let interestInstallment: number = 0;
        let amortInstallment: number = 0;
        let installmentValue: number = 0;

        this.installments?.map((el) => {
            interestInstallment += Number(el.interestInstallment);
            amortInstallment += Number(el.amortInstallment);
            installmentValue += Number(el.installmentValue);
        });

        this.installmentsData = [...this.installmentsData, {
            interestInstallment,
            amortInstallment,
            installmentValue
        }]
    }

    public generateTableData(): TableData {
        return {
            header: ['Período', 'Data de Vencimento', 'Juros (J)', 'Amortização', 'Parcela (P)', 'Saldo Devedor', ''],
            rows: this.installmentsData.map(row => {
                return {
                    fields: [
                        row.numberInstallment?.toString() || "",
                        row.dateInstallment ? this.formatDate(row.dateInstallment.toString()) : "",
                        this.formatValueToMoney(row.interestInstallment),
                        this.formatValueToMoney(row.amortInstallment),
                        this.formatValueToMoney(row.installmentValue),
                        row.amountDue ? this.formatValueToMoney(row.amountDue) : ""
                    ],
                    actions: []
                }
            }),
        }
    }
}
