import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SettleLoansRoutingModule } from './settle-loans-routing.module';
import { SettleLoansListComponent } from './settle-loans-list/settle-loans-list.component';
import { SettleLoansFormsComponent } from './settle-loans-forms/settle-loans-forms.component';


@NgModule({
  declarations: [
    SettleLoansListComponent,
    SettleLoansFormsComponent
  ],
  imports: [
    CommonModule,
    SettleLoansRoutingModule
  ]
})
export class SettleLoansModule { }
