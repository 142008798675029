import { Component, Input, OnInit } from '@angular/core';

import { UserInfoService } from 'src/app/core/_base/_auth/user-info.service';

@Component({
  selector: 'app-cards-infos',
  templateUrl: './cards-infos.component.html',
  styleUrls: ['./cards-infos.component.scss'],
})
export class CardInfoComponent implements OnInit {
  @Input() cardInfo: any[];

  constructor(protected userInfoService: UserInfoService) {}

  ngOnInit(): void {}

  checkCards(title: string) {
    switch (title) {
      case 'Geral':
        return {
          title: 'Geral',
          color: 'bg-dark',
          colorText: 'text-white',
        };

      case 'Faturas pagas':
        return {
          title: 'Faturas pagas',
          color: 'bg-success',
          colorText: 'text-white',
        };

      case 'Preparadas':
        return {
          title: 'Preparadas',
          color: 'bg-primary',
          colorText: 'text-white',
        };

      case 'Qtd. Faturas':
        return {
          title: 'Qtd. Faturas',
          color: 'bg-info',
          colorText: 'text-white',
        };

      case 'Aguardando pgto':
        return {
          title: 'Aguardando pgto',
          color: 'bg-warning',
          colorText: 'text-dark',
        };

      case 'Aguardando apro':
        return {
          title: 'Aguardando apro',
          color: 'bg-secondary',
          colorText: 'text-dark',
        };

      default:
        return {
          color: '',
          title: '',
        };
        break;
    }
  }
}
