import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProposalsRoutes } from './proposals.routing';
import { ProposalFormComponent } from './proposal-form/proposal-form.component';
import { ProposalListComponent } from './proposal-list/proposal-list.component';
import { UiCTableModule } from 'src/app/shared/components/ui-c-table/ui-c-table.module';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { UiCInputModule } from 'src/app/shared/components/ui-c-input/ui-c-input.module';
import { TableFinanceContractDetailModule } from 'src/app/shared/components/table-details-contract/table-finance-contract.module';
import { MatTableModule } from '@angular/material/table';
import { ProposalCustomerDetailsModule } from 'src/app/shared/components/proposal-customer-details/proposal-customer-details.module';
import { ProposalInstallmentsDetailsModule } from 'src/app/shared/components/proposal-installments-details/proposal-installments-details.module';
import { InstallmentsTableModule } from 'src/app/shared/components/installments-table/installments-table.module';
import { ProposalApprovalDetailsModule } from 'src/app/shared/components/proposal-approval-details/proposal-approval-details.module';
import { ApprovalProposalModule } from 'src/app/shared/components/approval-proposal/approval-proposal.component.module';
import { ProposalService } from 'src/app/core/scfi/services/services-proposal/proposal.service';

@NgModule({
  imports: [
    CommonModule,
    ProposalsRoutes,
    UiCTableModule,
    InlineSVGModule,
    UiCInputModule,
    TableFinanceContractDetailModule,
    MatTableModule,
    ProposalCustomerDetailsModule,
    ProposalInstallmentsDetailsModule,
    InstallmentsTableModule,
    ProposalApprovalDetailsModule,
    ApprovalProposalModule
  ],
  declarations: [ProposalFormComponent, ProposalListComponent],
  providers: [
    ProposalService
  ]
})
export class ProposalsModule { }
