import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TaxesInterestRoutingModule } from './taxes-interest-routing.module';
import { TaxesInterestListComponent } from './taxes-interest-list/taxes-interest-list.component';
import { TaxesInterestFormsComponent } from './taxes-interest-forms/taxes-interest-forms.component';


@NgModule({
  declarations: [
    TaxesInterestListComponent,
    TaxesInterestFormsComponent
  ],
  imports: [
    CommonModule,
    TaxesInterestRoutingModule
  ]
})
export class TaxesInterestModule { }
