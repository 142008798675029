<div class="tp-wrapper">
  <div class="tp-box" [@flipState]="flip">
    <div *ngIf="front"
      class="tp-box__side tp-box__front front dashboard-card card hover-elevate-up shadow-sm parent-hover">
      <div class="card-body d-flex align-items">
        <span class="menu-icon">
          <img src="assets/media/icons/duotune/general/gen008.svg" />
        </span>

        <span class="ms-3 text-gray-700 parent-hover-primary fs-6 fw-bold">
          {{title}}
        </span>
      </div>

      <div class="body card-body">
        <ng-container *ngFor="let p of props">
          <div class="row">
            <div class="text-start col-6 text-gray-700 fs-7 fw-bold">
              {{p.key}}:
            </div>

            <ng-container *ngIf="(p.type != undefined && p.type  === 'money')">
              <div class="col-6 text-end fw-semibold">
                {{!p.value ? 0 : p.value | currency:"BRL" }}
              </div>
            </ng-container>

            <ng-container *ngIf="p.type === undefined || p.type === 'none'">
              <div class="col-6 text-end fw-semibold">
                {{!p.value ? 0 : p.value}}
              </div>
            </ng-container>
          </div>
          <hr>
        </ng-container>
      </div>

      <div class="card-footer" *ngIf="customerList">
        <div class="col-12">
          <button *ngIf="customerList?.length != 0" class="w-60 btn btn-primary btn-sm" (click)="frontBack()">
            Detalhes
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="back"
      class="tp-box__side tp-box__back back dashboard-card card hover-elevate-up shadow-sm parent-hover">
      <div class="card-body d-flex align-items">
        <span class="menu-icon">
          <img src="assets/media/icons/duotune/general/gen008.svg" />
        </span>

        <span class="ms-3 text-gray-700 parent-hover-primary fs-6 fw-bold">
          {{title}}
        </span>
      </div>

      <div class="body card-body" *ngIf="back">
        <ng-container *ngFor="let c of customerList">
          <p>
            <b>Nome: </b> {{c.name}} <br>
            <b>Cpf: </b> {{c.document}}
          </p>
          <hr>
        </ng-container>
      </div>


      <div class="card-footer" *ngIf="customerList">
        <div class="row">
          <div class="col-6">
          </div>
          <div class="col-6">
            <button class="w-100 btn btn-danger btn-sm" (click)="frontBack()">
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>