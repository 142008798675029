import {RouterModule, Routes} from '@angular/router';
import {ScfiComponent} from "./scfi.component";
import { Injectable, NgModule } from '@angular/core';

export const routesScfi: Routes = [
  {
    path: "",
    component: ScfiComponent,
  },
  {
    path: "contracts",
    loadChildren: () => import('./contracts/contracts.module').then(m => m.ContractsModule)
  },
  {
    path: "customers",
    loadChildren: () => import('./customers/customers.module').then(m => m.CustomersModule)
  },
  {
    path: "documents",
    loadChildren: () => import('./documents/documents.module').then(m => m.DocumentsModule)
  },
  {
    path: "fees",
    loadChildren: () => import('./fees/fees.module').then(m => m.FeesModule)
  },
  {
    path: "funding",
    loadChildren: () => import('./funding/funding.module').then(m => m.FundingModule)
  },
  {
    path: "kits",
    loadChildren: () => import('./kits/kits.module').then(m => m.KitsModule)
  },
  {
    path: "products",
    loadChildren: () => import('./products/products.module').then(m => m.ProductsModule)
  },
  {
    path: "proposals",
    loadChildren: () => import('./proposals/proposals.module').then(m => m.ProposalsModule)
  },
  {
    path: "simulation",
    loadChildren: () => import('./simulation/simulation.module').then(m => m.SimulationModule)
  },
  {
    path: "taxes",
    loadChildren: () => import('./taxes/taxes.module').then(m => m.TaxesModule)
  },
  {
    path: "accept",
    loadChildren: () => import('./accept/accept-proposal.module').then(m => m.AcceptProposalModule)
  }
];

@Injectable({
  providedIn: 'root'
})
@NgModule({
  imports: [RouterModule.forChild(routesScfi)],
  exports: [RouterModule]
})
export class ScfiRoutingModule { }