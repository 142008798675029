import { Component, OnInit } from '@angular/core';
import { TableData } from 'src/app/core/scfi/services/services-table/table.dto';
import { AbstractScfi } from '../../abstract-scfi';
import { Router } from '@angular/router';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { FundingService } from 'src/app/core/scfi/services/services-funding/funding.service';
import { FundingDto } from 'src/app/core/scfi/services/services-funding/funding.dto';

@Component({
  selector: 'app-funding-list',
  templateUrl: './funding-list.component.html',
  styleUrls: ['./funding-list.component.scss']
})
export class FundingListComponent extends AbstractScfi implements OnInit {
  public tableData: TableData;
  public fundingList: FundingDto[];

  constructor(
    private fundingService: FundingService,
    private router: Router,
    protected currencyPipe: CurrencyPipe,
    protected datePipe: DatePipe
  ) {
    super(currencyPipe, datePipe);
  }

  ngOnInit() {
    this.findFundingList();
  }

  public findFundingList() {
    this.fundingService.findFundingList().subscribe({
      next: (fudingList: FundingDto[]) => {
        this.fundingList = fudingList;
        this.tableData = this.generateTableData();
      },
      error: (error) => {
        this.showErrorModal('Erro ao buscar fundos!');
      }
    });
  }

  public generateTableData(): TableData {
    return {
      header: ['Fundo', 'Taxa Negociada', 'Capital', 'Ações'],
      rows: this.fundingList.map(row => {
        const formattedNegotiatedRate = this.formatValueToPercent(row.negotiatedRate);
        const formattedCapital = this.formatValueToMoney(row.capital);

        return {
          fields: [
            row.name,
            formattedNegotiatedRate,
            formattedCapital
          ],
          actions: [
            { icon: './assets/media/icons/duotune/general/gen055.svg', onClick: () => this.goToCreateOrEditFunding(row.uuid) }
          ]
        }
      }),
    }
  }

  public goToCreateOrEditFunding(uuid?: string) {
    if (uuid) {
      this.router.navigate(['scfi/funding/edit', uuid]);
    } else {
      this.router.navigate(['scfi/funding/create']);
    }
  }
}
