import {  Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-scfi',
  templateUrl: './scfi.component.html',
  standalone: true
})
export class ScfiComponent implements OnInit {
  ngOnInit() {}
}
