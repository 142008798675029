import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ErrorMessagesProposalDto } from 'src/app/core/scfi/services/services-proposal/proposal.dto';

@Component({
    selector: 'proposal-customer-details',
    templateUrl: './proposal-customer-details.component.html',
    styleUrls: ['./proposal-customer-details.component.scss']
})
export class ProposalCustomerDetailsComponent implements OnInit {
    @Input() formProposal: FormGroup;
    @Input() errorMessagesProposal: ErrorMessagesProposalDto;
    @Input() isPF: boolean;

    ngOnInit() { }
}
