<table mat-table matSort [dataSource]="dataSource" multiTemplateDataRows
  class="mat-elevation-z1 table table-striped table-hover mb-0">
  <ng-container matColumnDef="installmentsNumber">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>
      Qtd.
    </th>
    <td mat-cell *matCellDef="let element"> {{element.installmentsNumber}}X </td>
  </ng-container>

  <ng-container matColumnDef="installmentValue">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>
      Valor da parcela
    </th>
    <td mat-cell *matCellDef="let element"> {{element.installmentValue | currency:"BRL" }} </td>
  </ng-container>

  <ng-container matColumnDef="totalAmountProposal">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>
      (M) Valor total da proposta
    </th>
    <td mat-cell *matCellDef="let element"> {{element.totalAmountProposal | currency:"BRL" }} </td>
  </ng-container>

  <ng-container matColumnDef="totalInterest">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>
      Juros totais
    </th>
    <td class="center" mat-cell *matCellDef="let element"> {{element.totalInterest | currency:"BRL" }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr class="center" mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
</table>
