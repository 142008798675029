import { take } from 'rxjs';
import { CompanyServices } from 'src/app/pages/consigned/admin/companies/services-apis/companies.services';
import { UserInfoService } from './../../../core/_base/_auth/user-info.service';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  Output,
  EventEmitter
} from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-select-companies',
  templateUrl: './select-companies.component.html',
  styleUrls: ['./select-companies.component.scss'],
})
export class SelectCompaniesComponent implements OnInit {
  companie: string;
  form: FormGroup;

  companieList: Array<{ uuid: string; company: string }>;

  @Output() emitCompany = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    protected userInfoService: UserInfoService,
    private companyService: CompanyServices,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.loadCompanies();

  }

  changeCompanies(uuidCompanie: string) {
    this.emitCompany.emit(uuidCompanie)
  }

  loadCompanies(){
    this.companyService.getListCompanySelectEmployee()
    .pipe(take(1))
    .subscribe(result => {
      this.companieList = result;
      this.companieList.unshift({
        company: "TODOS",
        uuid: "ALL"
      })

      this.cdr.detectChanges();
    })
  }

  isPermissionAction(name: string) {
    return this.userInfoService.isUserInRole(name);
  }

  getData() {
   
  }
}
