import { RawInsuranceModel } from "src/app/pages/consigned/dashboard/dashboard-model/raw-insurance-model";
import { Observable } from 'rxjs';
import { Injectable } from "@angular/core";
import { ApiService } from "../../_base/_auth/api.service";
import { RawOneModel } from "src/app/pages/consigned/dashboard/dashboard-model/raw-one-model";
import { RawTwoModel } from "src/app/pages/consigned/dashboard/dashboard-model/raw-two-model";

const SPE_VIEW_N_DASHBOARD_REPORT_CONSIGNED = '/spe-view-n-dashboard-reports-consigned';

@Injectable({
  providedIn: 'root'
})
export class SpeViewNDashboardReposrtConsignedProxy {

  private model: any;
  constructor(private api: ApiService) { }

  getRawOne(uuidCompany: string): Observable<RawOneModel> {
    const uri = `${SPE_VIEW_N_DASHBOARD_REPORT_CONSIGNED}/dashboard/rowone/${uuidCompany}`;
    return this.api.get(uri);
  }

  getRawTwo(uuidCompany: string): Observable<RawTwoModel> {
    const uri = `${SPE_VIEW_N_DASHBOARD_REPORT_CONSIGNED}/dashboard/rowtwo/${uuidCompany}`;
    return this.api.get(uri);
  }

  getRawInsurance(uuidCompany: string): Observable<RawInsuranceModel> {
    const uri = `${SPE_VIEW_N_DASHBOARD_REPORT_CONSIGNED}/dashboard/insurance/${uuidCompany}`;
    return this.api.get(uri);
  }
}
