<!-- <app-bread-crumb [items]="[{text: 'Empresas'}]"></app-bread-crumb> -->

<app-page-header page-title="Lista de Empresas Conveniada" button-text="+ Nova Empresa"
  button-link="/admin/companies/new" button-class="btn-success ml-10"></app-page-header>

<div class="container-fluid p-3 my-0">


  <div class="row p-0">
    <div class="col-md-12 mt-0 mb-5">
      <app-date-picker (emitDate)="emitDate($event)"></app-date-picker>
    </div>
  </div>

  <div class="row">
    <div class="search col-md-4 mt-0 mb-5">
      <label class="form-label fw-bolder text-dark fs-6 mb-0">Pesquisar</label>
      <input class="form-control bg-white mt-1" placeholder="Nome, documento, status…" (keyup)="filterData($event)" autocomplete="off" />
    </div>

    <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z1 table table-striped table-hover mb-0">
      <ng-container matColumnDef="id">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> ID </th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Razão Social </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <ng-container matColumnDef="documentGov">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> CNPJ </th>
        <td mat-cell *matCellDef="let element"> {{maskerService.formatDocumentCPF(element.document)}} </td>
      </ng-container>

      <ng-container matColumnDef="dateRegister">
        <th mat-header-cell *matHeaderCellDef> Cadastro </th>
        <td mat-cell *matCellDef="let element"> {{element.dateRegister | date: "dd/MM/yyyy HH:mm"}} </td>
      </ng-container>

      <ng-container matColumnDef="companiesStatus">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element"> {{checkStatus(element.status)}} </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="text-center"> Ações </th>
        <td mat-cell *matCellDef="let element" class="text-center">
          <mat-icon class="mx-4" (click)="viewDetail(element.uuid)">visibility</mat-icon>
          <mat-icon (click)="editCompany(element.uuid)">edit</mat-icon>
          <!-- <mat-icon class="mx-4" (click)="getByuuid(element.uuid)">delete</mat-icon> -->
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
    <mat-paginator class="mat-elevation-z1" #paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 20]">
    </mat-paginator>
  </div>