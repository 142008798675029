<div id="proposal-customer-details">
    <section class="card mb-4 shadow-sm">
        <div class="card-header border-0 d-flex align-items-center justify-content-start">
            <ng-container *ngIf="isPF">
                <span [inlineSVG]="'./assets/media/icons/duotune/communication/com013.svg'"
                    class="svg-icon svg-icon-2tx svg-icon-gray-800 me-4"></span>
            </ng-container>

            <ng-container *ngIf="!isPF">
                <span [inlineSVG]="'./assets/media/icons/duotune/communication/com014.svg'"
                    class="svg-icon svg-icon-2tx svg-icon-gray-800 me-4"></span>
            </ng-container>
            <h3 class="fw-bolder card-title mt-3">Dados para Simulação</h3>
        </div>

        <div class="card-body border-top p-9">

            <ng-container *ngIf="isPF">
                <div class="d-flex disabled">
                    <div class="col-3 px-10">
                        <app-ui-c-input [form]="formProposal" [errorMessages]="errorMessagesProposal"
                            [formName]="'document'" [name]="'CPF:'"></app-ui-c-input>
                    </div>
                    <div class="col-3 px-10">
                        <app-ui-c-input [form]="formProposal" [errorMessages]="errorMessagesProposal"
                            [formName]="'name'" [name]="'Nome:'"></app-ui-c-input>
                    </div>
                    <div class="col-3 px-10">
                        <app-ui-c-input [form]="formProposal" [errorMessages]="errorMessagesProposal"
                            [formName]="'motherName'" [name]="'Nome da Mãe:'"></app-ui-c-input>
                    </div>
                    <div class="col-3 px-10">
                        <app-ui-c-input [form]="formProposal" [errorMessages]="errorMessagesProposal"
                            [formName]="'fatherName'" [name]="'Nome do Pai:'"></app-ui-c-input>
                    </div>
                </div>

                <div class="d-flex disabled mt-10">
                    <div class="col-3 px-10">
                        <app-ui-c-input [form]="formProposal" [errorMessages]="errorMessagesProposal"
                            [formName]="'birthDate'" [name]="'Data de Nascimento:'"></app-ui-c-input>
                    </div>
                    <div class="col-3 px-10">
                        <app-ui-c-input [form]="formProposal" [errorMessages]="errorMessagesProposal"
                            [formName]="'phone'" [name]="'Celular:'"></app-ui-c-input>
                    </div>
                    <div class="col-3 px-10">
                        <app-ui-c-input [form]="formProposal" [errorMessages]="errorMessagesProposal"
                            [formName]="'email'" [name]="'E-mail:'"></app-ui-c-input>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="!isPF">
                <div class="d-flex disabled">
                    <div class="col-3 px-10">
                        <app-ui-c-input [form]="formProposal" [errorMessages]="errorMessagesProposal"
                            [formName]="'document'" [name]="'CNPJ:'"></app-ui-c-input>
                    </div>
                    <div class="col-3 px-10">
                        <app-ui-c-input [form]="formProposal" [errorMessages]="errorMessagesProposal"
                            [formName]="'name'" [name]="'Nome:'"></app-ui-c-input>
                    </div>
                    <div class="col-3 px-10">
                        <app-ui-c-input [form]="formProposal" [errorMessages]="errorMessagesProposal"
                            [formName]="'stateRegistration'" [name]="'Inscrição Estadual:'"></app-ui-c-input>
                    </div>
                    <div class="col-3 px-10">
                        <app-ui-c-input [form]="formProposal" [errorMessages]="errorMessagesProposal"
                            [formName]="'openingDate'" [name]="'Data de Abertura:'"></app-ui-c-input>
                    </div>
                </div>

                <div class="d-flex disabled mt-10">
                    <div class="col-3 px-10">
                        <app-ui-c-input [form]="formProposal" [errorMessages]="errorMessagesProposal"
                            [formName]="'phone'" [name]="'Celular:'"></app-ui-c-input>
                    </div>
                    <div class="col-3 px-10">
                        <app-ui-c-input [form]="formProposal" [errorMessages]="errorMessagesProposal"
                            [formName]="'email'" [name]="'E-mail:'"></app-ui-c-input>
                    </div>
                </div>
            </ng-container>
        </div>
    </section>
</div>