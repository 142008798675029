<div class="">
    <section class="card mb-4 shadow-sm">
        <div class="card-header border-0 d-flex align-items-center justify-content-start">
            <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr086.svg'"
                  class="svg-icon svg-icon-2tx svg-icon-gray-800 me-4"></span>
            <h3 class="fw-bolder card-title mt-3">Aprovação</h3>
        </div>

        <div class="border-top"></div>
        <div class="d-flex justify-content-between">
            <select [(ngModel)]="opcaoSelecionada" class="select form-select form-select-solid text-dark ">
                <option value="seleciona" disabled selected>Selecione uma opção</option>
                <option *ngFor="let opcao of opcoes" [value]="opcao">{{ opcao }}</option>
            </select>

            <select [(ngModel)]="opcaoFundo" class="select form-select form-select-solid text-dark ">
                <option value="seleciona" disabled selected>Selecione o fundo</option>
                <option *ngFor="let fundo of fundos" [value]="fundo">{{ fundo }}</option>
            </select>

            <div class="container-box" style="display: inline-block; padding: 20px;">
                <h4>Por favor, colocar sua consideração:</h4>
                <textarea  rows="4" class="box"></textarea>
                <p> minimo de 20 caracteres </p>
            </div>
        </div>
        <div class="d-flex flex-end">
            <button type="button" class="btn buttonClass btn-success">
                <span>Continuar</span>
            </button>
        </div>

    </section>
</div>
