import { SpeNOpenAccountProxy } from 'src/app/core/proxy/spe/spe-n-open-account.proxy';
import { NgxSpinner, NgxSpinnerService } from 'ngx-spinner';
import { ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { BaseResourceFormComponent } from 'src/app/shared/components/base-resource-form/base-resource-form.component';
import { EmployeeServices } from '../services-apis/employees-services'
import { take, timeout } from 'rxjs';
import { Employee } from '../services-apis/employees-model';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { StorageService } from 'src/app/shared/services-ultil/storage/storage.service';
import { CompanyServices } from '../../companies/services-apis/companies.services';
import { ProposalServices } from '../../../operations/proposals/services-apis/proposals-services';
import { UserInfoService } from 'src/app/core/_base/_auth/user-info.service';


@Component({
  selector: 'app-employees-forms',
  templateUrl: './employees-forms.component.html',
  styleUrls: ['./employees-forms.component.scss']
})
export class EmployeesFormsComponent extends BaseResourceFormComponent<Employee> implements OnInit {

  public errorMessagesEmployees: any = [];
  public uuidEmployee: string;
  public isEdit: boolean = false;
  public isView: any = false;
  public employeeName: string | any;

  public loading: boolean;
  public listUf: { name: string; value: string; }[];
  companyList: any[];
  selectedValue: any;

  displayedColumns = ['proposalDate', 'netCapitalAmountLoan',
    'installmentQuantityLoan', 'installmentValue', 'iProposalInterestMonth', 'fvTotalAmountLoanValue', 'action'];

  public dataSource: any;
  emailUser: string = "";
  cardList: any;
  itemStatusByCard: string = "ALL";
  showListProposals: boolean;
  constructor(
    protected employeeServices: EmployeeServices,
    protected injector: Injector,
    private spinner: NgxSpinnerService,
    private speNOpenAccountProxy: SpeNOpenAccountProxy,
    protected activeRouter: ActivatedRoute,
    private storageService: StorageService,
    private companyService: CompanyServices,
    private cdr: ChangeDetectorRef,
    private proposalService: ProposalServices,
    public userService: UserInfoService
  ) {
    super(injector, new Employee(), employeeServices, Employee.fromJson)
  }

  ngOnDestroy(): void {
    this.storageService.removeData("isView");
  }  

  ngOnInit(): void {
    this.listUf = this.speNOpenAccountProxy.getListUf()
    this.buildResourceForm();

    this.isView = this.storageService.getData("isView");

    this.companyService.getListCompanySelectEmployee()
      .pipe(take(1))
      .subscribe(result => {
        this.companyList = result;        
        this.getNameCompany();
        this.cdr.detectChanges();
      })

    this.activeRouter.paramMap.pipe(take(1))
      .subscribe((res: any) => {
        this.uuidEmployee = res.params['id'];
        if (this.uuidEmployee) {
          this.spinner.show();
          this.isEdit = true;
          this.employeeServices.getEmployee(this.uuidEmployee).subscribe(employee => {
            this.spinner.hide();
            this.employeeName = employee.customerData?.fullName;
            this.employeeServices.sycForm(employee);
            this.getNameCompany();
          })
        }
      });

    let role = this.userService.verifyRole() || '';

    this.userService.getInfoProfile().then((res: any) => {
      this.emailUser = res.email || "";
      this.proposalService.cardsCustomers(role, this.emailUser, this.uuidEmployee)
        .subscribe(result => {      
          this.cardList = result;
          this.dataSource = this.cardList.list;
          this.cdr.detectChanges();
        })
    });
  }

  checkStatus(status: string, isNonStandard?: boolean, isSpecial?: boolean) {
    if (isNonStandard && (status === 'REQUESTED')) {
      return {
        color: 'text-bg-danger text_color_white',
        status: "Fora do Padrão"
      }
    }

    if (isSpecial && (status === 'REQUESTED')) {
      return {
        color: 'text-bg-primary text_color_white',
        status: "Proposta Especial"
      }
    }

    switch (status) {
      case 'SPECIAL_FINANCE_APROVED':
        return {
          color: 'text-bg-success text_color_white',
          status: "Aprovado Financeiro"
        }

      case 'REQUESTED':
        return {
          color: 'text-bg-secondary text_color_black',
          status: "Solicitado"
        }

      case 'MANAGER_IN_ANALYSIS':
        return {
          color: 'text-bg-primary text_color_white',
          status: "Anál. Gestor"
        }

      case 'MANAGER_APPROVED':
        return {

          color: 'text-bg-primary text_color_white',
          status: "Ap. Gestor"
        }

      case 'MANAGER_REPROVED':
        return {
          color: 'text-bg-danger text_color_white',
          status: "Rep. Gestor"
        }

      case 'AUDIT_ANALYSIS':
        return {
          color: 'text-bg-primary text_color_white',
          status: "Anál. Auditor"
        }

      case 'AUDIT_APPROVED':
        return {
          color: 'text-bg-info text_color_white',
          status: "Ap. Auditor"
        }

      case 'AUDIT_REPROVED':
        return {
          color: 'text_color_white text-bg-danger ',
          status: "Rep. Auditor"
        }
      case 'CONTRACT_WORK_SIGNATURE':
        return {
          color: 'text_color_white text-bg-success ',
          status: "Aprovado"
        }

      case 'ALL':
        return {
          color: 'text_color_white text-bg-success ',
          status: "Todos"
        }
      case 'SIGNEDCCB': {
        return {
          color: 'text_color_white text-bg-success ',
          status: "Aguad. depósito"
        }
      }

      default:
        return {
          color: "",
          status: ""
        }
    }
  }

  closeProposalList() {
    this.showListProposals = !this.showListProposals;
  }

  filterListByStatus(status: string) {   
    this.showListProposals  = true;
    this.itemStatusByCard = status;
    if (status == "ALL") {
      this.dataSource.data = this.cardList.list;
      return;
    }

    let result = this.cardList.list.filter((item: any) => {
      if (item.status === status) return item;
    })
    this.dataSource.data = result;
  }

  viewDetails(uuid: string) {
    this.storageService.saveData("isView", "true")
    this.router.navigate([`operations/proposals/${uuid}/edit`]);
  }

  protected buildResourceForm() {
    this.errorMessagesEmployees = this.employeeServices.getFormErrosMessage();
    this.resourceForm = this.employeeServices.createFormEmployee();
  }

  protected editionPageTitle(): string {
    const employeeName = this.resource.id || "";
    return "Editando Colaborador: " + employeeName;
  }

  protected creationPageTitle(): string {
    if (this.isView) {
      return "Detalhes do Colaborador";
    } else if (this.isEdit) return `Editando Colaborador: ${this.employeeName}`
    else {
      return "Cadastro de Novo Colaborador";
    }
  }


  save(): void {
    let document = `${this.resourceForm.get('identityGov')?.value}`;
    if (document.includes('.')) {
      document = this.resourceForm.get('identityGov')?.value.replaceAll('.', '').replace('-', '');
    }
    this.resourceForm.get('identityGov')?.patchValue(document);
    let responsibleIdentityGov = this.resourceForm.get('responsibleIdentityGov')?.value.replaceAll('.', '').replace('-', '');
    this.resourceForm.get('responsibleIdentityGov')?.patchValue(responsibleIdentityGov);
    this.spinner.show();
    this.employeeServices.crateEmployee()
      .pipe(take(1)).subscribe(res => {
        this.spinner.hide();
        if (res) {
          this.alert('Sucesso!', 'Colaborador cadastrado com sucesso!', 'success', 'OK')
            .then(res => {
              if (res.isConfirmed) {
                this.router.navigate(['/admin/employees']);
              }
            })
        } else {
          this.spinner.hide();
          this.alert('Atenção!', 'Erro ao processar! tente novamente!', 'warning', 'OK')
        }
      }, err => {
        this.spinner.hide();
        this.alert('Atenção!', 'Erro ao processar! tente novamente!', 'warning', 'OK')
      });
  }

  updateEmployBrassPress() {
    let documentReplace = "" + this.resourceForm.get('identityGov')?.value;
    if (documentReplace.includes('.')) {
      documentReplace = this.resourceForm.get('identityGov')?.value.replaceAll('.', '').replace('-', '');
    }
    this.resourceForm.get('uuid')?.setValue(this.uuidEmployee);
    this.spinner.show();
    this.employeeServices.getEmployeesBraspress(documentReplace)
      .subscribe(result => {
        if (result.code == '404') {
          this.spinner.hide();
          this.alert('Atenção!', result.description, 'warning', 'OK')
        } else {
          result.id = this.resourceForm.get('id')?.value;          
          this.companyService.getCompanyByCNPJ(result.CNPJ.replaceAll('.', '').replace('-', '').replace('/', '')).subscribe(el=>{
            this.resourceForm.patchValue({
              uuidCompany: el.uuid
            })            
          });
          this.employeeServices.sycFormBrasspress(result)
          this.cdr.detectChanges();
          setTimeout(() => {
            if (this.resourceForm.valid) {
              this.spinner.hide();
              this.update();
            } else {
              this.spinner.hide();
              this.alert('Atenção!', 'Alguns dados precisam ser revisados!', 'warning', 'OK')
            }
          }, 4000)
        }
      }, error => {
        this.spinner.hide();
        this.alert('Atenção!', 'Erro ao processar! tente novamente!', 'warning', 'OK')
      })
  }

  update() {
    this.spinner.show();
    let documentReplace = this.resourceForm.get('identityGov')?.value;
    let responsibleIdentityGov = this.resourceForm.get('responsibleIdentityGov')?.value;

    this.resourceForm.get('identityGov')?.patchValue(documentReplace);
    this.resourceForm.get('responsibleIdentityGov')?.patchValue(responsibleIdentityGov);
    this.resourceForm.get('uuid')?.patchValue(this.uuidEmployee);

    this.employeeServices.editEmployee()
      .pipe(take(1)).subscribe(res => {
        this.spinner.hide();
        if (res) {
          this.alert('Sucesso!', 'Colaborador alterada com sucesso!', 'success', 'OK')
            .then(res => {
              if (res.isConfirmed) {
              }
            })
        } else {
          this.spinner.hide();
          this.alert('Atenção!', 'Erro ao processar! tente novamente!', 'warning', 'OK')
        }
      }, err => {
        this.spinner.hide();
        this.alert('Atenção!', 'Erro ao processar! tente novamente!', 'warning', 'OK')
      });
  }


  onChangePostal(e: any) {
    this.spinner.show();
    this.speNOpenAccountProxy.searchPostalCode(e.target.value).pipe(take(1)).subscribe(res => {
      if (res != null) {
        this.employeeServices.updateAddressResentential(this.resourceForm, res);
      }
      this.spinner.hide();
    })
  }

  onChangePostalComercial(e: any) {
    this.spinner.show();
    if (e.target.value != '') {
      this.speNOpenAccountProxy.searchPostalCode(e.target.value).pipe(take(1)).subscribe(res => {
        if (res != null) {
          this.employeeServices.updateAddressWork(this.resourceForm, res);
        }
        this.spinner.hide();
      })
    } else {
      this.spinner.hide();
    }
  }

  alert(title: any, text: any, icon: any, confirmButtonText: any) {
    return Swal.fire({
      title,
      text,
      icon,
      confirmButtonText
    })
  }

  selectCompany(company: any) {
    let companySelected = this.companyList.find((el: any) => {
      return el.company === company.target.value;
    });

    this.resourceForm.patchValue({
      uuidCompany: companySelected.uuid
    })
  }

  getNameCompany() {
    /* if (!!this.resourceForm.get('uuidCompany')?.value) { */
      this.companyList.find((el: any) => {
        /* return el.uuid === this.resourceForm.get('uuidCompany')?.value; */
        if(el.uuid === this.resourceForm.get('uuidCompany')?.value) {
          this.resourceForm.patchValue({
            uuidCompany: el.uuid,
            companyName: el.company
          })          
          return;
        }
      });
      this.cdr.detectChanges();
    /* } */
  }

  getByDocument(event: any) {
    let document = event.target.value;
    document = document.replaceAll(".", "").replaceAll("-", "");
    this.spinner.show();
    this.employeeServices.getEmployeesBraspress(document)
      .subscribe(result => {
        /* console.log(result) */
        if (result.code == '404') {
          this.spinner.hide();
          this.alert('Atenção!', result.description, 'warning', 'OK')
        } else {
          this.employeeServices.sycFormBrasspress(result)
          this.spinner.hide();
          if (this.resourceForm.valid) {
          } else {
            this.alert('Atenção!', 'Alguns dados precisam ser revisados!', 'warning', 'OK')
          }
        }
      }, error => {
        this.spinner.hide();
      })
  }
}
