import { Routes, RouterModule } from '@angular/router';
import { FundingListComponent } from './funding-list/funding-list.component';
import { FundingFormComponent } from './funding-form/funding-form.component';

const routes: Routes = [
  {
    path: "funding-list",
    component: FundingListComponent
  },
  {
    path: "create",
    component: FundingFormComponent
  },
  {
    path: "edit/:uuid",
    component: FundingFormComponent
  }
];

export const FundingRoutes = RouterModule.forChild(routes);
