import { SettleLoansFormsComponent } from './settle-loans-forms/settle-loans-forms.component';
import { SettleLoansListComponent } from './settle-loans-list/settle-loans-list.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {path: '', component: SettleLoansListComponent,},
  {path: 'new', component: SettleLoansFormsComponent,},
  {path: ':id/edit', component: SettleLoansFormsComponent,},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettleLoansRoutingModule { }
