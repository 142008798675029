import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiService } from 'src/app/core/_base/_auth/api.service';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from "rxjs";
import { SpeNScfiProxy } from "../../../proxy/spe/spe-n-scfi.proxy";
import { ErrorMessagesSimulationRequestDto, SimulationEntryParamsType } from "./dto/simulation.dto";
import { ScfiCalculatorResponseType, ScfiPickSimulationResponseType } from './dto/calculator.dto';
import { PickSimulationEntryParamsType } from './dto/pick-simulation.dto';

@Injectable({
    providedIn: 'root'
})
export class SimulationService {
    formSimulation: FormGroup;

    constructor(
        protected api: ApiService,
        private formBuilder: FormBuilder,
        private speNScfiProxy: SpeNScfiProxy
    ) {
    }

    public createSimulation(): FormGroup {
        this.formSimulation = this.formBuilder.group({
            document: new FormControl('', Validators.compose([
                Validators.required,
            ])),
            name: new FormControl('', Validators.compose([
                Validators.required,
            ])),
            phone: new FormControl('', Validators.compose([
                Validators.required,
            ])),
            email: new FormControl('', Validators.compose([
                Validators.required,
                Validators.email
            ])),
            productType: new FormControl('', Validators.compose([
                Validators.required,
            ])),
            product: new FormControl('', Validators.compose([
                Validators.required,
            ])),
            minValue: new FormControl('', Validators.compose([])),
            maxValue: new FormControl('', Validators.compose([])),
            typeFee: new FormControl('', Validators.compose([])),
            valueTac: new FormControl('', Validators.compose([
                Validators.required,
            ])),
            interestRate: new FormControl('', Validators.compose([
                Validators.required,
            ])),
            quantityInstallments: new FormControl('', Validators.compose([
                Validators.required,
            ])),
            loanAmount: new FormControl('', Validators.compose([
                Validators.required,
            ])),
            gracePeriod: new FormControl('', Validators.compose([
                Validators.required,
            ])),
            isIofIncluded: new FormControl(false, Validators.compose([])),
        })
        return this.formSimulation;
    }

    public getErrorMessages(): ErrorMessagesSimulationRequestDto {
        return {
            document: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
            ],
            name: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
            ],
            phone: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
            ],
            email: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
                {
                    type: 'email',
                    message: 'Informe um e-mail válido'
                }
            ],
            productType: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
            ],
            product: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
            ],
            valueTac: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
            ],
            interestRate: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
            ],
            quantityInstallments: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
            ],
            loanAmount: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
            ],
            gracePeriod: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
            ]
        }
    }

    public simulate(simulationEntryParams: SimulationEntryParamsType): Observable<ScfiCalculatorResponseType> {
        return this.speNScfiProxy.simulate(simulationEntryParams);
    }

    public pickSimulation(pickSimulationEntryParams: PickSimulationEntryParamsType): Observable<ScfiPickSimulationResponseType> {
        return this.speNScfiProxy.pickSimulation(pickSimulationEntryParams);
    }

}
