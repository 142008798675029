<!-- <app-bread-crumb [items]="[{text: 'Contratos Gerados'}]"></app-bread-crumb> -->

<app-page-header page-title="Lista de Contratos Gerados" button-text="+ Gerar Novo Contrato" button-link="/contract/new"
  button-class="btn-success ml-10" [noForm]="true"></app-page-header>

<div class="container-fluid p-3">
  <div class="row">
    <div class="row p-0">
      <div class="col-md-12 mt-0 mb-5">
        <app-date-picker [defaultDate]='defaultDate' (emitDate)="emitDate($event)"></app-date-picker>
      </div>
    </div>

    <div class="row d-flex align-items-end justify-content-between">
      <div class="search col-md-4 mt-0 mb-5">
        <label class="form-label fw-bolder text-dark fs-6 mb-0">Pesquisar</label>
        <input class="form-control bg-white mt-1" placeholder="Nome, documento, status…" (keyup)="filterData($event)"
          autocomplete="off" />
      </div>

      <div *ngIf="isliquidation()" class="d-flex justify-content-end offset-md-4 col-md-4 me-0 mt-0 mb-5">
        <button [disabled]="!(selection.selected.length > 0)" class="btn btn-success float-right"
          (click)="depositContracts()">Depositar contratos</button>
      </div>
    </div>


    <div class="row">
      <table mat-table matSort [dataSource]="dataSource"
        class="mat-elevation-z1 table table-striped table-hover my-1 mb-0">

        <!-- Checkbox Column -->
        <ng-container matColumnDef="select" *ngIf="isliquidation()">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()" [color]="'primary'">
            </mat-checkbox>
          </th>

          <td mat-cell *matCellDef="let row" class="item-row-padding-inline">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
              [disabled]="row?.statusProposal  == 'PAID'" [ngClass]="row?.statusProposal  == 'PAID' ? 'disabled':''"
              [checked]="selection.isSelected(row)" [color]="'primary'">
            </mat-checkbox>
          </td>
        </ng-container>

        <!-- <ng-container matColumnDef="proposalNumber">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Proposta </th>
      <td mat-cell *matCellDef="let element"> {{element.proposalNumber}} </td>
    </ng-container> -->

        <ng-container matColumnDef="uuidContract">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Nr. Contrato </th>
          <td mat-cell *matCellDef="let element"> {{element.proposalName}} </td>
        </ng-container>

        <ng-container matColumnDef="numberCCB">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Número da CCB </th>
          <td mat-cell *matCellDef="let element" class="item-row-padding-inline"> {{element.numberCCB}} </td>
        </ng-container>

        <ng-container matColumnDef="fullName">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Colaborador </th>
          <td mat-cell *matCellDef="let element"> {{element.employee}} </td>
        </ng-container>

        <ng-container matColumnDef="identityGov">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> CPF </th>
          <td mat-cell *matCellDef="let element" class=" item-row-padding-inline item-row-nowrap">
            {{maskerService.formatDocumentPerson(element.document)}} </td>
        </ng-container>

        <ng-container matColumnDef="companyName">
          <th mat-header-cell *matHeaderCellDef> Empresa </th>
          <td mat-cell *matCellDef="let element"> {{element.companyName}} </td>
        </ng-container>

        <!--  <ng-container matColumnDef="salary">
      <th mat-header-cell *matHeaderCellDef> Salário </th>
      <td mat-cell *matCellDef="let element"> {{element.salary | currency:'BRL'}} </td>
    </ng-container> -->

        <!-- <ng-container matColumnDef="installmentInterest">
      <th mat-header-cell *matHeaderCellDef> Taxa de juros </th>
      <td mat-cell *matCellDef="let element"> {{element.installmentInterest }}% </td>
    </ng-container> -->

        <ng-container matColumnDef="netCapitalAmountLoan">
          <th mat-header-cell *matHeaderCellDef> Solicitado </th>
          <td mat-cell *matCellDef="let element"> {{element.loanValue | currency:'BRL'}} </td>
        </ng-container>

        <ng-container matColumnDef="installmentQuantityLoan">
          <th mat-header-cell *matHeaderCellDef> Parcelas </th>
          <td mat-cell *matCellDef="let element"> {{element.installmentQuantity}}x</td>
        </ng-container>

        <ng-container matColumnDef="installmentValue">
          <th mat-header-cell *matHeaderCellDef> Valor Parcela </th>
          <td mat-cell *matCellDef="let element"> {{truncate.truncate(element.installmentValue, 2)}} </td>
        </ng-container>

        <ng-container matColumnDef="fvTotalAmountLoanValue">
          <th mat-header-cell *matHeaderCellDef> Valor Total </th>
          <td mat-cell *matCellDef="let element"> {{element.totalValue | currency:'BRL'}} </td>
        </ng-container>

        <ng-container matColumnDef="dtAssignatureCCB">
          <th mat-header-cell *matHeaderCellDef> Data Ass. CCB</th>
          <td mat-cell *matCellDef="let element"> {{element.dataCCB | date: 'dd/MM/YYYY HH:mm' : 'UTC-3'}}</td>
        </ng-container>

        <ng-container matColumnDef="iProposalInterestMonth">
          <th mat-header-cell *matHeaderCellDef> Situação </th>
          <td mat-cell *matCellDef="let element" class="item-row-padding-inline item-row-nowrap">
            <span *ngIf="(element?.statusProposal  == 'PAID')"
              class="bold p-2 rounded-pill text-bg-success text_color_white">
              Depositado
            </span>

            <span *ngIf="(element?.statusProposal  != 'PAID')"
              class="bold p-2 rounded-pill text-bg-danger text_color_white ">
              Não Depositado
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="statusProposal">
          <th mat-header-cell *matHeaderCellDef> Status </th>
          <td mat-cell *matCellDef="let element">
            <span [class]="'p-2 rounded-pill ' + checkStatus(element.isLate, element.status)"> {{ element.status ?
              element.status : element.installmentQuantity+'/'+element.installmentQuantity }}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef class="text-center"> Ações </th>
          <td mat-cell *matCellDef="let element" class="text-center">
            <mat-icon [routerLink]="[element.uuid, 'edit']">visibility</mat-icon>
            <mat-icon class="mx-4" (click)="getContractTermsCCB(element.uuid)">download</mat-icon>
            <!--     <mat-icon class="mx-4" (click)="getByuuid(element.uuid)">delete</mat-icon> -->
          </td>
        </ng-container>

        <!--  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr> -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
        </tr>

      </table>
      <mat-paginator class="mat-elevation-z1" #paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 20,50,100]">
      </mat-paginator>
    </div>
  </div>