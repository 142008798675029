import { TaxesInterestFormsComponent } from './taxes-interest-forms/taxes-interest-forms.component';
import { TaxesInterestListComponent } from './taxes-interest-list/taxes-interest-list.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {path: '', component: TaxesInterestListComponent,},
  {path: 'new', component: TaxesInterestFormsComponent,},
  {path: ':id/edit', component: TaxesInterestFormsComponent,},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TaxesInterestRoutingModule { }
