import { Base64Service } from 'src/app/shared/services-ultil/base64-service/base64.service';
import { UiCDatePickerModule } from 'src/app/shared/components/date-picker/date-picker.module';
import { PostalCodeService } from 'src/app/shared/services-ultil/postal-code/postal-code.service';
import { MaskerModule } from 'src/app/shared/services-ultil/masker-service/masker-module.module';
import { UiCInputModule } from 'src/app/shared/components/ui-c-input/ui-c-input.module';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EmployeesRoutingModule } from './employees-routing.module';
import { EmployeesListComponent } from './employees-list/employees-list.component';
import { EmployeesFormsComponent } from './employees-forms/employees-forms.component';
import { EmployeesImportsComponent } from './employees-imports/employees-imports.component';
import { InterceptService } from 'src/app/core/_base/_auth/intercept/intercept.service';
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import { SpeNOpenAccountProxy } from 'src/app/core/proxy/spe/spe-n-open-account.proxy';
import { SpeViewNBackofficeConsignedProxy } from 'src/app/core/proxy/view/spe-view-n-backoffice-consigned.proxy';
import { BrMaskDirective } from 'br-mask';
import { ProposalsModule } from '../../operations/proposals/proposals.module';


@NgModule({
  declarations: [
    EmployeesListComponent,
    EmployeesFormsComponent,
    EmployeesImportsComponent
  ],
  imports: [
    CommonModule,
    EmployeesRoutingModule,
    SharedModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatIconModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    UiCInputModule,
    MaskerModule,
    UiCDatePickerModule,    
    ProposalsModule
  ],
  providers:[
    PostalCodeService,
    SpeNOpenAccountProxy,
    SpeViewNBackofficeConsignedProxy,
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
    Base64Service,
    BrMaskDirective
  ]
})
export class EmployeesModule { }
