import { BillsChargeFormsComponent } from './bills-charge-forms/bills-charge-forms.component';
import { BillsChargeListComponent } from './bills-charge-list/bills-charge-list.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', component: BillsChargeListComponent, },
  { path: 'new', component: BillsChargeFormsComponent, },
  { path: ':id/edit', component: BillsChargeFormsComponent, },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BillsChargeRoutingModule { }
