import { SharedModule } from 'src/app/shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BillsChargeRoutingModule } from './bills-charge-routing.module';
import { BillsChargeListComponent } from './bills-charge-list/bills-charge-list.component';
import { BillsChargeFormsComponent } from './bills-charge-forms/bills-charge-forms.component';


@NgModule({
  declarations: [
    BillsChargeListComponent,
    BillsChargeFormsComponent
  ],
  imports: [
    CommonModule,
    BillsChargeRoutingModule,
    SharedModule
  ]
})
export class BillsChargeModule { }
