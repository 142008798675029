import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CurrencyPipe, DatePipe, Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup } from "@angular/forms";
import { environment } from 'src/environments/environment';
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { NgxSpinnerService } from "ngx-spinner";
import { AbstractScfi } from "../../abstract-scfi";
import { TypeCustomerDataService } from "src/app/core/scfi/services/services-customer-data/type-customer-data.service";
import { CustomerService } from "src/app/core/scfi/services/services-customer/customer.service";
import {
  ErrorMessagesDataDto,
  CreateTypeDataCustomerDto,
  TypeCustomerDataDto
} from "src/app/core/scfi/services/services-customer-data/type-customer-data.dto";
import { TypeCustomerDto } from "src/app/core/scfi/services/services-customer/type-customer.dto";

@Component({
  selector: 'app-document-form',
  templateUrl: './document-form.component.html',
  styleUrls: ['./document-form.component.scss']
})
export class DocumentFormComponent extends AbstractScfi implements OnInit {

  errorMessagesData: ErrorMessagesDataDto;
  formData: FormGroup;
  isEditMode: boolean = false;
  isPjClient: boolean = false;
  typePj: TypeCustomerDto;
  typePf: TypeCustomerDto;
  choosenTypeCustomer: string;

  constructor(
      private location: Location,
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private dataService: TypeCustomerDataService,
      private customerService: CustomerService,
      private spinner: NgxSpinnerService,
      private cdr: ChangeDetectorRef,
      protected currencyPipe: CurrencyPipe,
      protected datePipe: DatePipe
  ) {
    super(currencyPipe, datePipe);
  }

  ngOnInit() {
    this.findTypeCustomerList();
  };

  changeClientType(e: MatSlideToggleChange) {
    this.isPjClient = e.checked;
    if (this.isPjClient) {
      this.choosenTypeCustomer = this.typePj.uuid;
    } else {
      this.choosenTypeCustomer = this.typePf.uuid;
    }
  }

  public buildFormData() {
    this.errorMessagesData = this.dataService.getErrorMessages()
    this.formData = this.dataService.createFormDocument()
  }

  public findTypeCustomerList() {
    this.customerService.findTypeCustomer().subscribe({
      next: (typeCustomers: TypeCustomerDto[]) => {
        this.typePj = typeCustomers.find(obj => obj.name === "Pessoa jurídica") ?? {} as TypeCustomerDto;
        this.typePf = typeCustomers.find(obj => obj.name === "Pessoa física") ?? {} as TypeCustomerDto;
        this.choosenTypeCustomer = this.typePf.uuid;
      },
      error: () => {
        this.showErrorModal('Erro ao buscar os tipos de clientes!');
      }
    });
    this.activatedRoute.params.subscribe(params => {
      const uuidData = params['uuidDocument'];
      this.isEditMode = !!uuidData;
      if (uuidData) {
        this.buildFormData();
        this.findDataByUuid(uuidData);
      } else {
        this.buildFormData();
      }
    });
  }

  public findDataByUuid(uuid: string) {
    this.spinner.show();
    this.dataService.findDataByUuid(uuid).subscribe({
      next: (doc: TypeCustomerDataDto) => {
        this.formData.patchValue({
          uuidWhiteLabel: doc.uuidWhiteLabel,
          name: doc.name,
          uuidTypeCustomer: doc.uuidTypeCustomer,
          hasAttachment: doc.hasAttachment,
          typeCustomerDataParent: doc.typeCustomerDataParent
        });
        this.choosenTypeCustomer = doc.typeCustomer.uuid;
        this.isPjClient = doc.typeCustomer.name === 'Pessoa jurídica' ? true : false;
        this.cdr.detectChanges();
        this.spinner.hide();
      },
      error: (error) => {
        this.showErrorModal('Erro ao buscar dado!');
        this.spinner.hide();
      }
    });
  }

  public async saveData() {
    const confirmationMessage = this.isEditMode
    ? 'Tem certeza que deseja alterar o dado?'
    : 'Tem certeza que deseja criar esse dado?';
    
    const confirmed = await this.showConfirmationModal(confirmationMessage);

    this.formData.patchValue({
      uuidWhiteLabel: environment.wlp,
      uuidTypeCustomer: this.choosenTypeCustomer
    });
    if (confirmed) {
      const form: CreateTypeDataCustomerDto = this.formData.getRawValue();

      if (this.isEditMode) {
        this.updateData();
      } else {
        this.createData(form);
      }
    }

  }

  public createData(createTypeCustomer: CreateTypeDataCustomerDto) {
    this.dataService.createData(createTypeCustomer).subscribe({
      next: async () => {
        await this.showSuccessModal('Dado criado com sucesso!');
        this.router.navigate(['/scfi/documents/document-list']);
      },
      error: () => {
        this.showErrorModal('Erro ao criar dado!');
      }
    });
  }

  public updateData() {
    return;
  }

  backToLastPage(): void {
    this.location.back();
  }
}




