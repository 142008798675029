import { Routes, RouterModule } from '@angular/router';
import { DocumentListComponent } from './document-list/document-list.component';
import { DocumentFormComponent } from './document-form/document-form.component';
import {CustomerFormComponent} from "../customers/customer-form/customer-form.component";

const routes: Routes = [
  {
    path: "document-list",
    component: DocumentListComponent
  },
  {
    path: "edit-document/:uuidDocument",
    component: DocumentFormComponent
  },
  {
    path: "new-document",
    component: DocumentFormComponent
  },
];

export const DocumentsRoutes = RouterModule.forChild(routes);
