import { Observable } from 'rxjs';
import { Injectable, Injector } from "@angular/core";
import { SpeNBackofficeConsignedProxy } from "src/app/core/proxy/spe/spe-n-backoffice-consigned";
import { SpeViewNBackofficeConsignedProxy } from 'src/app/core/proxy/view/spe-view-n-backoffice-consigned.proxy';
import { ApiService } from "src/app/core/_base/_auth/api.service";


@Injectable({
    providedIn: 'root'
})

export class InvoiceService {

    constructor(
        protected injector: Injector,
        protected api: ApiService,
        private speViewNBackofficeConsignedProxy: SpeViewNBackofficeConsignedProxy,
        private speNBackofficeConsignedProxy: SpeNBackofficeConsignedProxy
    ) { }



    generetedHeaderInvoice(month: string, uuidCompany: string, emailUser: string): Observable<any> {
        return this.speViewNBackofficeConsignedProxy
            .generetedHeaderInvoice(month, uuidCompany, emailUser)
    }

    findInvoiceDetail(uuidInvoice: string): Observable<any> {
        return this.speViewNBackofficeConsignedProxy
            .findInvoiceDetail(uuidInvoice)
    }

    findInvoiceFileXls(uuidInvoice: string): Observable<any> {
        return this.speViewNBackofficeConsignedProxy
            .findInvoiceFileXls(uuidInvoice)
    }

    approveInvoice(uuidInvoice: string, auditEmail: string): Observable<any> {
        return this.speNBackofficeConsignedProxy
            .approveInvoice(uuidInvoice, auditEmail);
    }

    generetedInvoice(uuidInvoice: string, auditEmail: string): Observable<any> {
        return this.speNBackofficeConsignedProxy
            .generetedInvoice(uuidInvoice, auditEmail);
    }

    deleteInvoice(uuid: string) {
        return this.speNBackofficeConsignedProxy
            .deleteInvoice(uuid);
    }

    depositedInvoice(uuidInvoice: string, auditEmail: string): Observable<any> {
        return this.speNBackofficeConsignedProxy
            .depositedInvoice(uuidInvoice, auditEmail);
    }

    findInvoice(uuidInvoice: string): Observable<any> {
        return this.speViewNBackofficeConsignedProxy
            .findInvoice(uuidInvoice)
    }

    getCompaniesList(startdate: string, endDate: string, uuidCompanie: string): Observable<any> {
        return this.speViewNBackofficeConsignedProxy.findInvoiceDateCompany(startdate, endDate, uuidCompanie);
    }

    getCompaniesAllList(startdate: string, endDate: string, role: string, emailUser: string): Observable<any> {
        return this.speViewNBackofficeConsignedProxy.findInvoiceDate(startdate, endDate, role, emailUser);
    }

}

