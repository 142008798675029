<div id="kit-form-component">

    <!--Título-->
    <h1 class="page-title mb-4"> {{ isNewKit ? 'Cadastro de Kit' : 'Alteração de Kit' }} </h1>

    <!--Botão Voltar-->
    <div id="back-to-last-page-btn-container">
        <button type="button" class="btn btn-warning btn-default px-10 mb-4" id="back-to-last-page-btn" (click)="backToLastPage()">
            <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr074.svg'" class="svg-icon svg-icon-1 me-4"></span>
            <span class="pt-2"> Voltar </span>
        </button>
    </div>

    <!--Formulário Informações Gerais-->
    <section class="card mb-4 shadow-sm">
        <div class="card-header border-0 d-flex align-items-center justify-content-start">
            <span [inlineSVG]="'./assets/media/icons/duotune/general/gen045.svg'"
                class="svg-icon svg-icon-2tx svg-icon-gray-800 me-4"></span>
            <h3 class="fw-bolder card-title mt-3"> Informações Gerais </h3>
        </div>
        <div class="card-body border-top p-9">
            <form>
                <div class="d-flex">
                    <div class="col-3 px-10">
                        <app-ui-c-input [form]="formKit" [errorMessages]="errorMessagesKit" [formName]="'name'" [name]="'Nome:'" 
                        [type]="'text'" [placeholder]="'Digite o nome'"></app-ui-c-input>
                    </div>
                    <div class="col-3 px-10">
                        <form [formGroup]="formKit">
                            <label class="form-label fs-6 fw-bolder text-dark mt-3"> Dados Necessários: </label>
                            <mat-select class="form-select form-select-solid form-select-lg text-dark"
                            formControlName="documents" multiple>
                                <mat-option *ngFor="let data of dataList" [value]="data">{{data}}</mat-option>
                            </mat-select>
                        </form>
                    </div>
                </div>
            </form>
        </div>
    </section>

    <!--Botão Salvar-->
    <div class="d-flex justify-content-end my-8">
        <button type="button" class="btn btn-primary btn-default" [disabled]="formKit.invalid" (click)="saveKit()">
            Salvar
        </button>
    </div>

</div>
