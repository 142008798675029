import { AdminRoutes } from './admin.routing';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BillingInvoicesRoutingModule } from './billing-invoices/billing-invoices-routing.module';
import { CalculationEngineModule } from './calculation-engine/calculation-engine.module';
import { CompaniesRoutingModule } from './companies/companies-routing.module';
import { DefaultRoutingModule } from './default/default-routing.module';
import { EmployeesModule } from './employees/employees.module';
import { InvestimentFundsModule } from './investiment-funds/investiment-funds.module';
import { ReportsModule } from './reports/reports.module';
import { SettleLoansModule } from './settle-loans/settle-loans.module';
import { TaxesInterestModule } from './taxes-interest/taxes-interest.module';
import { UsersModule } from './users/users.module';
import { BillingInvoicesModule } from './billing-invoices/billing-invoices.module';
import { CompaniesModule } from './companies/companies.module';
import { InterceptService } from 'src/app/core/_base/_auth/intercept/intercept.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    BillingInvoicesRoutingModule,
    CalculationEngineModule,
    CompaniesRoutingModule,
    DefaultRoutingModule,
    EmployeesModule,
    InvestimentFundsModule,
    ReportsModule,
    SettleLoansModule,
    TaxesInterestModule,
    UsersModule,
    SharedModule,
    AdminRoutes,
    BillingInvoicesModule,
    CompaniesModule
  ],
  providers: [
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
  ],
})
export class AdminModule { }
