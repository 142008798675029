
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FgtsContractsListComponent } from './fgts-contracts-list/fgts-contracts-list.component';
import { FgtsFormsComponent } from './fgts-contracts/fgts-contracts-forms.component';

const routes: Routes = [
  { path: '', component: FgtsContractsListComponent, },  
  { path: ':id/:contractType/edit', component: FgtsFormsComponent, },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FgtsContractsLoansRoutingModule { }
