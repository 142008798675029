import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ErrorMessagesConfigurationTaxDto, ConfigurationTaxDto, UpdateTaxDto } from 'src/app/core/scfi/services/services-tax/tax.dto';
import { TaxService } from 'src/app/core/scfi/services/services-tax/tax.service';
import { AbstractScfi } from '../../abstract-scfi';
import { Router } from '@angular/router';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-tax-form',
  templateUrl: './tax-form.component.html',
  styleUrls: ['./tax-form.component.scss']
})
export class TaxFormComponent extends AbstractScfi implements OnInit {
  public errorMessagesConfigurationTax: ErrorMessagesConfigurationTaxDto;
  public formTax: FormGroup;
  uuidTax: string;

  constructor(
    private taxService: TaxService,
    private router: Router,
    protected currencyPipe: CurrencyPipe,
    protected datePipe: DatePipe,
    private cdr: ChangeDetectorRef,
    private spinner: NgxSpinnerService
  ) {
    super(currencyPipe, datePipe);
  }

  ngOnInit() {
    this.findTaxes();
    this.buildFormTax();
  }

  public buildFormTax() {
    this.errorMessagesConfigurationTax = this.taxService.getErrorMessages();
    this.formTax = this.taxService.createFormTax();
  }

  public findTaxes() {
    this.spinner.show();
    this.taxService.findTaxes().subscribe({
      next: (taxes: ConfigurationTaxDto[]) => {
        this.uuidTax = taxes[0].uuid;
        this.findTaxByUuid(this.uuidTax);
      },
      error: (error) => {
        this.showErrorModal('Erro ao buscar impostos!');
      }
    })
  }

  public findTaxByUuid(uuid: string) {
    this.taxService.findTaxByUuid(uuid).subscribe({
      next: (tax: ConfigurationTaxDto) => {
        this.formTax.patchValue({
          pfIofIndex: tax.pfIofIndex,
          pfIofDailyIndex: tax.pfIofDailyIndex,
          pfIofMaxDays: tax.pfIofMaxDays,
          pjIofIndex: tax.pjIofIndex,
          pjIofDailyIndex: tax.pjIofDailyIndex,
          pjIofMaxDays: tax.pjIofMaxDays
        });
        this.cdr.detectChanges();
        this.spinner.hide();
      },
      error: (error) => {
        this.showErrorModal('Erro ao buscar imposto!');
        this.spinner.hide();
      }
    })
  }

  public async saveTax() {
    const confirmed = await this.showConfirmationModal("Tem certeza que deseja alterar o imposto?");

    if (confirmed) {
      const formTaxData: ConfigurationTaxDto = this.formTax.value;

      const updateTax: UpdateTaxDto = {
        ...formTaxData,
        uuid: this.uuidTax
      };

      this.spinner.show();
      this.taxService.updateTaxData(updateTax).subscribe({
        next: () => {
          this.findTaxByUuid(this.uuidTax);
          this.showSuccessModal('Imposto alterado com sucesso!');
          this.spinner.hide();
        },
        error: (error) => {
          this.showErrorModal('Erro ao alterar o imposto!');
          this.spinner.hide();
        }
      });
    }
  }
}
