import { Proposal } from 'src/app/pages/consigned/operations/proposals/services-apis/poposals-modal';
import { Injectable } from '@angular/core';
import { ApiService } from '../../_base/_auth/api.service';
import { Observable } from 'rxjs';
import { Employee } from 'src/app/pages/consigned/admin/employees/services-apis/employees-model';


const SPE_VIEW_N_BACKOFFICE_CONSIGNED = '/spe-view-n-backoffice-consigned';

@Injectable({
  providedIn: 'root'
})

export class SpeViewNBackofficeConsignedProxy {

  private model: any;
  constructor(private api: ApiService) {
  }

  /* Employess */
  getEmployeesList(startDate: string, endDate: string, role: string | undefined, email: string): Observable<any> {
    const uri = `${SPE_VIEW_N_BACKOFFICE_CONSIGNED}/v1/employees/${startDate}/${endDate}/${role}/${email}`;
    return this.api.get(uri);
  }

  getEmployeeUuidByEmail(email: string) {
    const uri = `${SPE_VIEW_N_BACKOFFICE_CONSIGNED}/v1/employee/uuid/userIn/email/${email}`;
    return this.api.get(uri);
  }

  getUuidCompanyByEmail(email: string) {
    const uri = `${SPE_VIEW_N_BACKOFFICE_CONSIGNED}/v1/employee/email/${email}/find/company`;
    return this.api.get(uri);
  }

  getAllAtributesEmployee(uuid: string): Observable<Employee> {
    const uri = `${SPE_VIEW_N_BACKOFFICE_CONSIGNED}/v1/employee/allatributes/${uuid}`;
    return this.api.get(uri);
  }

  getEmployeesAllAtributesByDocument(document: string): Observable<any> {
    const uri = `${SPE_VIEW_N_BACKOFFICE_CONSIGNED}/v1/employee/allatributes/document/${document}`;
    return this.api.get(uri);
  }

  /* /v1/employeesBraspress/searchByCpf */

  getEmployeesBraspress(cpf: string): Observable<any> {
    const uri = `${SPE_VIEW_N_BACKOFFICE_CONSIGNED}/v1/employeesBraspress/searchByCpf`;
    return this.api.post(uri, { cpf });
  }


  /* Comanies */
  getCompaniesList(startDate: string, endDate: string): Observable<any> {
    const uri = `${SPE_VIEW_N_BACKOFFICE_CONSIGNED}/v1/company/of-interval/${startDate}/${endDate}`;
    return this.api.get(uri);
  }

  getCompanyByCNPJ(cnpj: string): Observable<any> {
    const uri = `${SPE_VIEW_N_BACKOFFICE_CONSIGNED}/v1/company/company/${cnpj}`;
    return this.api.get(uri);
  }

  getAllAtributesCompany(uuid: string): Observable<any> {
    const uri = `${SPE_VIEW_N_BACKOFFICE_CONSIGNED}/v1/company/all-attributes/${uuid}`;
    return this.api.get(uri);
  }

  findByUuidCompanyUrbanActives(uuid: string): Observable<any> {
    const url = `${SPE_VIEW_N_BACKOFFICE_CONSIGNED}/v1/companiesInPartner/byUuidCompanyUrbanActives/${uuid}`;
    return this.api.get(url);
  }

  getUuidCompany(uuid: string): Observable<any> {
    const uri = `${SPE_VIEW_N_BACKOFFICE_CONSIGNED}/v1/company/of-id/${uuid}`;
    return this.api.get(uri);
  }

  getListCompanySelectEmployee(): Observable<any> {
    const uri = `${SPE_VIEW_N_BACKOFFICE_CONSIGNED}/v1/company/find/combo`;
    return this.api.get(uri);
  }

  /* Contract */
  getContracstList(startDate: string, endDate: string, role: string | undefined, email: string): Observable<any> {
    const uri = `${SPE_VIEW_N_BACKOFFICE_CONSIGNED}/v1/contract/of-date/${startDate}/${endDate}/${role}/${email}`;
    return this.api.get(uri);
  }

  getContractTerms(uuid: string): Observable<Proposal> {
    const uri = `${SPE_VIEW_N_BACKOFFICE_CONSIGNED}/v1/contract/find/contract-terms/${uuid}`;
    return this.api.get(uri);
  }

  getContractTermsCCB(uuid: string): Observable<Proposal> {
    const uri = `${SPE_VIEW_N_BACKOFFICE_CONSIGNED}/v1/proposal/ccb/${uuid}`;
    return this.api.get(uri);
  }

  getAllAtributesContract(uuid: string): Observable<Proposal> {
    const uri = `${SPE_VIEW_N_BACKOFFICE_CONSIGNED}/v1/contract/allatributes/${uuid}`;
    return this.api.get(uri);
  }

  /* proposal */
  getProposalsList(startDate: string, endDate: string, role: string | undefined, email: string): Observable<any> {
    const uri = `${SPE_VIEW_N_BACKOFFICE_CONSIGNED}/v1/proposal/find/date/${startDate}/${endDate}/${role}/${email}`;
    return this.api.get(uri);
  }

  cardFilters(role: string, emailManager: string): Observable<any> {
    const uri = `${SPE_VIEW_N_BACKOFFICE_CONSIGNED}/v1/proposal/cardFilters/${role}/${emailManager}`;
    return this.api.get(uri);
  }

  getAllAtributesProposal(uuid: string): Observable<Proposal> {
    const uri = `${SPE_VIEW_N_BACKOFFICE_CONSIGNED}/v1/proposal/allatributes/${uuid}`;
    return this.api.get(uri);
  }


  cardsCustomers(role: string, emailManager: string, uudiCustomer: string): Observable<any> {
    const uri = `${SPE_VIEW_N_BACKOFFICE_CONSIGNED}/v1/proposal/find/cardsCustomers/${role}/${emailManager}/${uudiCustomer}`;
    return this.api.get(uri);
    /* /v1/proposal/find/cardsCustomers/{role}/{emailManager}/{uudiCustomer} */
  }


  getHistoryApprovation(uuidProposal: string): Observable<any> {
    const uri = `${SPE_VIEW_N_BACKOFFICE_CONSIGNED}/v1/proposal/find/aprovation-history/${uuidProposal}`;
    return this.api.get(uri);
  }

  findByUuidProposaldetailsInsurance(): Observable<any> {
    const uri = `${SPE_VIEW_N_BACKOFFICE_CONSIGNED}/v1/proposal/insurance/installments`;
    return this.api.get(uri);
  }

  findByUuidProposaldetailsInsuranceXls(): Observable<any> {
    const uri = `${SPE_VIEW_N_BACKOFFICE_CONSIGNED}/v1/proposal/download/insurance/installments`;
    return this.api.getBlob(uri);
  }

  /* Invoices */
  findInvoice(uuid: string): Observable<any> {
    const uri = `${SPE_VIEW_N_BACKOFFICE_CONSIGNED}/v1/invoice/of-id/${uuid}`;
    return this.api.get(uri);
  }

  findInvoiceFileXls(uuidInvoice: string): Observable<any> {
    const uri = `${SPE_VIEW_N_BACKOFFICE_CONSIGNED}/v1/invoice/generate/file/header/${uuidInvoice}`;
    return this.api.getBlob(uri);
  }

  generetedHeaderInvoice(month: string, uuidCompany: string, emailUser: string): Observable<any> {
    const uri = `${SPE_VIEW_N_BACKOFFICE_CONSIGNED}/v1/invoice/generate/header/${month}/${uuidCompany}/user/${emailUser}/none`;
    /* const uri = `${SPE_VIEW_N_BACKOFFICE_CONSIGNED}/v1/invoice/generate/header/${month}/${uuidCompany}/user/${emailUser}/none`; */
    return this.api.get(uri);
  }

  findInvoiceDate(startDate: string, endDate: string, userRole: string, userEmail: string): Observable<any> {
    const uri = `${SPE_VIEW_N_BACKOFFICE_CONSIGNED}/v1/invoice/find/date/${startDate}/${endDate}/user/${userRole}/${userEmail}`;
    return this.api.get(uri);
  }

  findInvoiceDateCompany(startDate: string, endDate: string, uuidCompany: string): Observable<any> {
    const uri = `${SPE_VIEW_N_BACKOFFICE_CONSIGNED}/v1/invoice/find/date/${startDate}/${endDate}/company/${uuidCompany}/`;
    return this.api.get(uri);
  }

  findInvoiceDetail(uuidInvoice: string): Observable<any> {
    const uri = `${SPE_VIEW_N_BACKOFFICE_CONSIGNED}/v1/invoice/details/${uuidInvoice}`;
    return this.api.get(uri);
  }

  /* Simulation findAllAtributesByStatusNonStandart */
  getSimulationByid(id: string): Observable<any> {
    const uri = `${SPE_VIEW_N_BACKOFFICE_CONSIGNED}/v1/simulation/complet/${id}`;
    return this.api.get(uri);
  }

  getAllEspecialProposal(): Observable<any> {
    const uri = `${SPE_VIEW_N_BACKOFFICE_CONSIGNED}/v1/proposal/findAllAtributesByStatusNonStandart`;
    return this.api.get(uri);
  }

  getAllEspecialSimulation(): Observable<any> {
    const uri = `${SPE_VIEW_N_BACKOFFICE_CONSIGNED}/v1/all-simulation-special`;
    return this.api.get(uri);
  }


}




