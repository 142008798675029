import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiService } from 'src/app/core/_base/_auth/api.service';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from "rxjs";
import { SpeViewNScfiProxy } from "../../../proxy/view/spe-view-n-scfi.proxy";
import { SpeNScfiProxy } from "../../../proxy/spe/spe-n-scfi.proxy";
import { CreateTypeProductDto, TypeProductDto } from "./type-product.dto";
import { CreateProductDto, ProductDto, UpdateTypeProductDto } from "./products.dto";

@Injectable({
    providedIn: 'root'
})
export class ProductsService {
    formTypeProduct: FormGroup;
    formProduct: FormGroup;

    constructor(
        protected api: ApiService,
        private formBuilder: FormBuilder,
        private speViewNScfiProxy: SpeViewNScfiProxy,
        private speNScfiProxy: SpeNScfiProxy
    ) {
    }

    public createFormTypeProduct(): FormGroup {
        this.formTypeProduct = this.formBuilder.group({
            name: new FormControl('', Validators.compose([
                Validators.required,
            ])),
        })
        return this.formTypeProduct;
    }

    public createFormProduct(): FormGroup {
        this.formProduct = this.formBuilder.group({
            name: new FormControl('', Validators.compose([
                Validators.required,
            ])),
            isPF: new FormControl('', Validators.compose([
                Validators.required,
            ])),
            isPJ: new FormControl('', Validators.compose([
                Validators.required,
            ])),
            minInstallmentPF: new FormControl('', Validators.compose([
                Validators.required,
            ])),
            maxInstallmentPF: new FormControl('', Validators.compose([
                Validators.required,
            ])),
            minValuePF: new FormControl('', Validators.compose([
                Validators.required,
            ])),
            maxValuePF: new FormControl('', Validators.compose([
                Validators.required,
            ])),
            feeNamePF: new FormControl('', Validators.compose([
                Validators.required,
            ])),
            minInterestRatePF: new FormControl('', Validators.compose([
                Validators.required,
            ])),
            interestRatePF: new FormControl('', Validators.compose([
                Validators.required,
            ])),
            typeTacPF: new FormControl('', Validators.compose([
                Validators.required,
            ])),
            minValueTacPF: new FormControl('', Validators.compose([
                Validators.required,
            ])),
            valueTacPF: new FormControl('', Validators.compose([
                Validators.required,
            ])),
            minInstallmentPJ: new FormControl('', Validators.compose([
                Validators.required,
            ])),
            maxInstallmentPJ: new FormControl('', Validators.compose([
                Validators.required,
            ])),
            minValuePJ: new FormControl('', Validators.compose([
                Validators.required,
            ])),
            maxValuePJ: new FormControl('', Validators.compose([
                Validators.required,
            ])),
            feeNamePJ: new FormControl('', Validators.compose([
                Validators.required,
            ])),
            minInterestRatePJ: new FormControl('', Validators.compose([
                Validators.required,
            ])),
            interestRatePJ: new FormControl('', Validators.compose([
                Validators.required,
            ])),
            typeTacPJ: new FormControl('', Validators.compose([
                Validators.required,
            ])),
            minValueTacPJ: new FormControl('', Validators.compose([
                Validators.required,
            ])),
            valueTacPJ: new FormControl('', Validators.compose([
                Validators.required,
            ])),
            kitNamePF: new FormControl('', Validators.compose([
                Validators.required,
            ])),
            kitNamePJ: new FormControl('', Validators.compose([
                Validators.required,
            ])),
            pfIofIndex: new FormControl('', Validators.compose([])),
            pfIofDailyIndex: new FormControl('', Validators.compose([])),
            pfIofMaxDays: new FormControl('', Validators.compose([])),
            pjIofIndex: new FormControl('', Validators.compose([])),
            pjIofDailyIndex: new FormControl('', Validators.compose([])),
            pjIofMaxDays: new FormControl('', Validators.compose([])),
        })
        return this.formProduct;
    }

    public getErrorMessagesTypeProduct() {
        return {
            name: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
            ]
        }
    }

    public getErrorMessagesProduct() {
        return {
            name: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
            ],
            minInstallmentPF: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
            ],
            maxInstallmentPF: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
            ],
            minValuePF: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
            ],
            maxValuePF: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
            ],
            feeNamePF: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
            ],
            minInterestRatePF: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
                {
                    type: 'min',
                    message: 'A taxa deve ser maior do que zero',
                }
            ],
            interestRatePF: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
                {
                    type: 'min',
                    message: 'A taxa deve ser maior do que zero',
                }
            ],
            typeTacPF: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
            ],
            minValueTacPF: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
            ],
            valueTacPF: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
            ],
            minInstallmentPJ: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
            ],
            maxInstallmentPJ: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
            ],
            minValuePJ: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
            ],
            maxValuePJ: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
            ],
            feeNamePJ: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
            ],
            minInterestRatePJ: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
                {
                    type: 'min',
                    message: 'A taxa deve ser maior do que zero',
                }
            ],
            interestRatePJ: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
                {
                    type: 'min',
                    message: 'A taxa deve ser maior do que zero',
                }
            ],
            typeTacPJ: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
            ],
            minValueTacPJ: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
            ],
            valueTacPJ: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
            ],
            kitNamePF: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
            ],
            kitNamePJ: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
            ],
        }
    }

    public createTypeProduct(createTypeProduct: CreateTypeProductDto): Observable<TypeProductDto> {
        return this.speNScfiProxy.createTypeProduct(createTypeProduct);
    }

    public updateProductType(updateProductTypeDto: UpdateTypeProductDto): Observable<TypeProductDto> {
        return this.speNScfiProxy.updateProductType(updateProductTypeDto);
    }

    public deleteProductType(uuid: string): Observable<TypeProductDto[]> {
        return this.speNScfiProxy.deleteProductType(uuid);
    }

    public findProductTypeList(uuidWhitelabel: string): Observable<TypeProductDto[]>  {
        return this.speViewNScfiProxy.findProductTypeList(uuidWhitelabel)
    }

    public findProductTypeWithProductConfiguredList(uuidWhitelabel: string): Observable<TypeProductDto[]>  {
        return this.speViewNScfiProxy.findProductTypeWithProductConfiguredList(uuidWhitelabel);
    }

    public findProductTypeByUuid(uuid: string): Observable<TypeProductDto> {
        return this.speViewNScfiProxy.findTypeProductByUuid(uuid);
    }

    public findProductList(uuidWhitelabel: string, uuidProductType: string): Observable<ProductDto[]> {
        return this.speViewNScfiProxy.findProductList(uuidWhitelabel, uuidProductType)
    }

    public findFullRegisterProductList(uuidWhitelabel: string, uuidProductType: string): Observable<ProductDto[]> {
        return this.speViewNScfiProxy.findFullRegisterProductList(uuidWhitelabel, uuidProductType)
    }

    public findProductByUuid(uuid: string): Observable<ProductDto> {
        return this.speViewNScfiProxy.findProductByUuid(uuid);
    }

    public getProductByUuid(uuid: string): Observable<ProductDto> {
        return this.speViewNScfiProxy.getProductByUuid(uuid);
    }

    public findProductDetailsByUuid(uuid: string): Observable<ProductDto> {
        return this.speViewNScfiProxy.findProductDetailsByUuid(uuid);
    }

    public createProduct(createProduct: CreateProductDto): Observable<ProductDto> {
        return this.speNScfiProxy.createProduct(createProduct);
    }

    public updateProduct(updateProductDto: CreateProductDto): Observable<ProductDto> {
        return this.speNScfiProxy.updateProduct(updateProductDto);
    }

    public deleteProduct(uuid: string): Observable<ProductDto[]> {
        return this.speNScfiProxy.deleteProduct(uuid);
    }

}
