<div id="proposal-form-component">
  <h1 class="page-title mb-4">Detalhes da Proposta</h1>

  <div class="d-flex justify-content-end mb-4">
    <button type="button" class="btn btn-warning" (click)="backToLastPage()">
      <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr074.svg'" class="svg-icon svg-icon-1 me-4"></span>
      <span class="pt-2">Voltar</span>
    </button>
  </div>

  <proposal-customer-details [formProposal]="formProposal" [errorMessagesProposal]="errorMessagesProposal"
    [isPF]="isPF"></proposal-customer-details>

  <section class="card mb-4 shadow-sm">
    <div class="card-header border-0 d-flex align-items-center justify-content-start">
      <span [inlineSVG]="'./assets/media/icons/duotune/files/fil003.svg'"
        class="svg-icon svg-icon-2tx svg-icon-gray-800 me-4"></span>
      <h3 class="fw-bolder card-title mt-3">Detalhes do Contrato - {{ proposal.proposalName }}</h3>
    </div>

    <div class="card-body border-top p-9">
      <detail-contract [price]="price"></detail-contract>
    </div>
  </section>

  <section class="card mb-4 shadow-sm">
    <div class="card-header border-0 d-flex align-items-center justify-content-start">
      <span [inlineSVG]="'./assets/media/icons/duotune/finance/fin010.svg'"
        class="svg-icon svg-icon-2tx svg-icon-gray-800 me-4"></span>
      <h3 class="fw-bolder card-title mt-3">Detalhes Financeiros do Contrato</h3>
    </div>

    <div class="card-body border-top p-9">
      <table-finance-contract-detail [proposal]="proposal"></table-finance-contract-detail>
    </div>
  </section>

  <proposal-installments-details [proposal]="proposal"></proposal-installments-details>

  <installments-table [installments]="proposal.installments"></installments-table>

  <proposal-approval-details [proposal]="proposal"></proposal-approval-details>

  <approval-proposal></approval-proposal>
</div>
