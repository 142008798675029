export class RawOneModel {
  public bucket: { potencialValue: number , nowValue: number, nowIndex: number };
  public paidContractsLastYearCard: { quantity: number | string, totalValue: number, avarage: number };
  public paidContractsMonthCard: { quantity: number | string, totalValue: number, avarage: number };
  public paidContractsLastMonthCard: { quantity: number | string, totalValue: number, avarage: number };
  public invoicesYearCard: { quantity: number | string, value: number };
  public invoicesMonthCard: { quantity: number | string, value: number };
  public lastMonthInvoicesCard: { quantity: number | string, value: number };
  public totalIofCard: { totalIof: number | string };
  public totalInteresCard: { totalInterest: number | string };
}
