import { SpeViewNBackofficeConsignedProxy } from 'src/app/core/proxy/view/spe-view-n-backoffice-consigned.proxy';
import { SpeNBackofficeConsignedProxy } from '../../core/proxy/spe/spe-n-backoffice-consigned';
import { BaseResourceModel } from "../models/base-resource.model";

import { Injector } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import {ApiService} from "../../core/_base/_auth/api.service";


export abstract class BaseResourceService<T extends BaseResourceModel> {

  protected speNBackofficeConsignedProxy: SpeNBackofficeConsignedProxy
  protected speViewNBackofficeConsignedProxy: SpeViewNBackofficeConsignedProxy;
  constructor(

    protected apiPath: string,
    protected injector: Injector,
    protected jsonDataToResourceFn: (jsonData: any) => T,
    protected api: ApiService,
  ) {
    // this.http = injector.get(HttpClient);
    this.speNBackofficeConsignedProxy = new SpeNBackofficeConsignedProxy(api);
    this.speViewNBackofficeConsignedProxy = new SpeViewNBackofficeConsignedProxy(api);
  }

  getAll() {
    const url = this.apiPath + "/v1/all/actives";
    return this.api.get(url);
  }

  // getAllAtributesBtUuid(uuid: string){
  //   return this.speViewNBackofficeConsignedProxy.getAllAtributes(uuid);
  // }

  getById(uuid: string): Observable<T> {
    const url = `${this.apiPath}/v1/${uuid}`;
    return this.api.get(url).pipe(
      map(this.jsonDataToResource.bind(this)),
      catchError(this.handleError)
    )
  }

  create(resource: T){
   
   /*  return this.speNBackofficeConsignedProxy.save(resource).pipe(
      map(this.jsonDataToResource.bind(this)),
      catchError(this.handleError)
    ) */

    return null;

    // const url = this.apiPath + "/new";
    // return
    // return this.api.post(url, resource).pipe(
    //   map(this.jsonDataToResource.bind(this)),
    //   catchError(this.handleError)
    // )
  }

  update(resource: T): Observable<T> {
    const url = `${this.apiPath}/update`;

    return this.api.put(url, resource).pipe(
      map(() => resource),
      catchError(this.handleError)
    )
  }

  delete(id: number): Observable<any> {
    const url = `${this.apiPath}/${id}`;

    return this.api.delete(url).pipe(
      map(() => null),
      catchError(this.handleError)
    )
  }

  // PROTECTED METHODS

  protected jsonDataToResources(jsonData: any[]): T[] {
    const resources: T[] = [];
    jsonData.forEach(
      element => resources.push(this.jsonDataToResourceFn(element))
    );
    return resources;
  }

  protected jsonDataToResource(jsonData: any): T {
    return this.jsonDataToResourceFn(jsonData);
  }

  protected handleError(error: any): Observable<any> {
    console.log("ERRO NA REQUISIÇÃO => ", error);
    return throwError(error);
  }

}
