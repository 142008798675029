import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiService } from 'src/app/core/_base/_auth/api.service';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from "rxjs";
import { SpeViewNScfiProxy } from "../../../proxy/view/spe-view-n-scfi.proxy";
import { CustomerDto, CustomerPfDto, CustomerPjDto } from "../services-customer/customer.dto";
import { ContractTable } from "./contract.dto";

@Injectable({
    providedIn: 'root'
})
export class ContractService {

    formCustomer: FormGroup;

    constructor(
        protected api: ApiService,
        private formBuilder: FormBuilder,
        private speViewNScfiProxy: SpeViewNScfiProxy,
    ) { }

    public findContractList(): Observable<ContractTable[]> {
        return this.speViewNScfiProxy.findContractList();
    }

    public findCustomerByUuid(uuid: string): Observable<CustomerDto> {
        return this.speViewNScfiProxy.findCustomerByUuid(uuid);
    }

    public createFormCustomer(): FormGroup {
        this.formCustomer = this.formBuilder.group({
            name: new FormControl('', Validators.compose([])),
            birthday: new FormControl('', Validators.compose([])),
            document: new FormControl('', Validators.compose([])),
            phone: new FormControl('', Validators.compose([])),
            email: new FormControl('', Validators.compose([])),
            cep: new FormControl('', Validators.compose([])),
            street: new FormControl('', Validators.compose([])),
            number: new FormControl('', Validators.compose([])),
            complement: new FormControl('', Validators.compose([])),
            neighborhood: new FormControl('', Validators.compose([])),
            city: new FormControl('', Validators.compose([])),
            state: new FormControl('', Validators.compose([])),
            bankNumber: new FormControl('', Validators.compose([])),
            nameNumber: new FormControl('', Validators.compose([])),
            agency: new FormControl('', Validators.compose([])),
            numberAccount: new FormControl('', Validators.compose([])),
            typeAccount: new FormControl('', Validators.compose([])),
            score: new FormControl('', Validators.compose([])),
        })
        return this.formCustomer;
    }

}
