<!-- <app-bread-crumb [items]="[{text: 'Colaboradores'}]"></app-bread-crumb> -->

<app-page-header page-title="Lista de Colaboradores" button-text="+ Novo Colaborador" button-link="/admin/employees/new"
  button-class="btn-success ml-10"></app-page-header>

<div class="container-fluid employees p-0">
  <!-- <div class="row"> -->

  <div class="d p-0">
    <div class="col-md-12 mt-0 mb-5">
      <app-date-picker (emitDate)="emitDate($event)"></app-date-picker>
    </div>
  </div>

  <div class="d-flex flex-direction-row">
    <div class="search col-md-4 mt-0 mb-5">
      <label class="form-label fw-bolder text-dark fs-6 mb-0">Pesquisar</label>
      <input class="form-control bg-white mt-1" placeholder="Nome, documento, status…" (keyup)="filterData($event)"
        autocomplete="off" />
    </div>

    <div class="d-flex justify-content-end col-md-8 mt-0 mb-5">
      <div class="mx-3 mt-2 ms-5">
        <input class="input-file" #file type="file" id="upload" accept=".xlsx" (change)="uploadFile($event.target)">
        <button for="upload" (click)="file.click()" class="btn btn-info" type="button">
          Importar <i class="ms-2 fas fa-file icon-xs ml-3"></i>
        </button>        
      </div>
    </div>
  </div>

  <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z1 mb-0 table table-striped table-hover">

    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Colaborador </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <ng-container matColumnDef="identityGov">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Documento </th>
      <td mat-cell *matCellDef="let element"> {{maskerService.formatDocumentCPF(element.document)}} </td>
    </ng-container>

    <ng-container matColumnDef="identity">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Matrícula </th>
      <td mat-cell *matCellDef="let element"> {{element.registration}} </td>
    </ng-container>

    <ng-container matColumnDef="ocuppation">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Cargo </th>
      <td mat-cell *matCellDef="let element"> {{element.sector}} </td>
    </ng-container>

    <ng-container matColumnDef="dateRegister">
      <th mat-header-cell *matHeaderCellDef> Cadastro </th>
      <td mat-cell *matCellDef="let element"> {{element.dateRegister | date: "dd/MM/yyyy HH:mm"}} </td>
    </ng-container>

    <ng-container matColumnDef="customerStatus">
      <th mat-header-cell *matHeaderCellDef> Status </th>
      <td mat-cell *matCellDef="let element">
        <span [class]="'p-2 rounded-pill ' + checkStatus(element.status).color">{{ checkStatus(element.status).status }} </span>
        <!-- <span class="status-employee badge rounded-pill text-bg-primary text-center">{{setStatus(element.status)}}</span> -->
      </td>
    </ng-container>


    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef class="text-center"> Ações </th>
      <td mat-cell *matCellDef="let element" class="text-center">
        <mat-icon class="mx-4" (click)="viewDetails(element.uuid)">visibility</mat-icon>
        <mat-icon [routerLink]="[element.uuid, 'edit']">edit</mat-icon>
        <!-- <mat-icon class="mx-4" (click)="deleteEmployee(element.uuid)">delete</mat-icon> -->
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>
  <h4 class="mat-table-message text-center mt-0 pb-15 font-weight-bold" *ngIf="isEmpty">Nenhum registro
    encontrado</h4>
  <mat-paginator class="mat-elevation-z1"  #paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 20]">
  </mat-paginator>
  <!-- </div> -->
</div>