import { Injectable } from '@angular/core';
import { ApiService } from '../../_base/_auth/api.service';
import { Observable } from 'rxjs';


const SPE_N_ACCOUNTS = '/spe-n-accounts';

@Injectable({providedIn: 'root'})
export class SpeNAccountsProxy {

  constructor(private api: ApiService) {
  }

  changeAccount(uuidUserAccount: string): Observable<any> {
    const uri = `${SPE_N_ACCOUNTS}/v1/access/change`;
    return this.api.put(uri, '', '', [{name: 'X-User-Account-ID', value: uuidUserAccount }]);
  }

}
