import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {UserInfoService} from "src/app/core/_base/_auth/user-info.service";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'token-accept',
  templateUrl: './token-accept.component.html',
  styleUrls: ['./token-accept.component.scss']
})
export class TokenAcceptComponent implements OnInit {

  public formToken: FormGroup;

  constructor(
      private dialogRef: MatDialogRef<TokenAcceptComponent>,
      protected userInfoService: UserInfoService,
  ) {}

  ngOnInit(): void {
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
