<!--begin::Chart widget 8-->
<div class="card card-flush" [ngClass]="cssClass">
  <!--begin::Header-->
  <div class="card-header pt-5">
    <!--begin::Title-->
    <h3 class="card-title align-items-start flex-column">
      <span class="card-label fw-bold text-dark">Performance Overview</span>
      <span class="text-gray-400 mt-1 fw-semibold fs-6">Users from all channels</span>
    </h3>
    <!--end::Title-->

    <!--begin::Toolbar-->
    <div class="card-toolbar">
      <ul class="nav" id="kt_chart_widget_8_tabs">

        <li class="nav-item">
          <a class="nav-link btn btn-sm btn-color-muted btn-active btn-active-light fw-bold px-4 me-1"
            [ngClass]="{'active': tab === 'Week'}" data-bs-toggle="tab" (click)="setTab('Week')"
            id="kt_chart_widget_8_month_toggle">Week</a>
        </li>
        <li class="nav-item">
          <a class="nav-link btn btn-sm btn-color-muted btn-active btn-active-light fw-bold px-4 me-1"
            [ngClass]="{'active': tab === 'Month'}" data-bs-toggle="tab" (click)="setTab('Month')"
            id="kt_chart_widget_8_week_toggle">Month</a>
        </li>

      </ul>
    </div>
    <!--end::Toolbar-->
  </div>
  <!--end::Header-->

  <!--begin::Body-->
  <div class="card-body pt-6">
    <!--begin::Tab content-->
    <div class="tab-content">
      <!--begin::Tab pane-->
      <div class="tab-pane fade" [ngClass]="{'active show': tab === 'Week'}" id="kt_chart_widget_8_week_tab"
        role="tabpanel">
        <!--begin::Statistics-->
        <div class="mb-5">
          <!--begin::Statistics-->
          <div class="d-flex align-items-center mb-2">
            <span class="fs-1 fw-semibold text-gray-400 me-1 mt-n1">$</span>

            <span class="fs-3x fw-bold text-gray-800 me-2 lh-1 ls-n2">18,89</span>

            <span class="badge badge-light-success fs-base">
              <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr066.svg'"
                class="svg-icon svg-icon-5 svg-icon-success ms-n1"></span>
              4,8%
            </span>
          </div>
          <!--end::Statistics-->

          <!--begin::Description-->
          <span class="fs-6 fw-semibold text-gray-400">Avarage cost per interaction</span>
          <!--end::Description-->
        </div>
        <!--end::Statistics-->

        <!--begin::Chart-->
        <div id="kt_chart_widget_8_week_chart" class="ms-n5 min-h-auto" [ngStyle]="{'height': chartHeight}">
          <ng-container *ngIf="hadDelay">
            <apx-chart [series]="chart1Options.series" [chart]="chart1Options.chart"
              [xaxis]="chart1Options.xaxis" [yaxis]="chart1Options.yaxis" [dataLabels]="chart1Options.dataLabels"
              [stroke]="chart1Options.stroke" [legend]="chart1Options.legend" [fill]="chart1Options.fill"
              [states]="chart1Options.states" [tooltip]="chart1Options.tooltip" [colors]="chart1Options.colors"
              [markers]="chart1Options.markers" [plotOptions]="chart1Options.plotOptions"></apx-chart>
          </ng-container>


        </div>
        <!--end::Chart-->

        <!--begin::Items-->
        <div class="d-flex flex-wrap pt-5">
          <!--begin::Item-->
          <div class="d-flex flex-column me-7 me-lg-16 pt-sm-3 pt-6">
            <!--begin::Item-->
            <div class="d-flex align-items-center mb-3 mb-sm-6">
              <!--begin::Bullet-->
              <span class="bullet bullet-dot bg-primary me-2 h-10px w-10px"></span>
              <!--end::Bullet-->

              <!--begin::Label-->
              <span class="fw-bold text-gray-600 fs-6">Social Campaigns</span>
              <!--end::Label-->
            </div>
            <!--ed::Item-->

            <!--begin::Item-->
            <div class="d-flex align-items-center">
              <!--begin::Bullet-->
              <span class="bullet bullet-dot bg-danger me-2 h-10px w-10px"></span>
              <!--end::Bullet-->

              <!--begin::Label-->
              <span class="fw-bold text-<gray-600 fs-6">Google Ads</span>
              <!--end::Label-->
            </div>
            <!--ed::Item-->
          </div>
          <!--ed::Item-->

          <!--begin::Item-->
          <div class="d-flex flex-column me-7 me-lg-16 pt-sm-3 pt-6">
            <!--begin::Item-->
            <div class="d-flex align-items-center mb-3 mb-sm-6">
              <!--begin::Bullet-->
              <span class="bullet bullet-dot bg-success me-2 h-10px w-10px"></span>
              <!--end::Bullet-->

              <!--begin::Label-->
              <span class="fw-bold text-gray-600 fs-6">Email Newsletter</span>
              <!--end::Label-->
            </div>
            <!--ed::Item-->

            <!--begin::Item-->
            <div class="d-flex align-items-center">
              <!--begin::Bullet-->
              <span class="bullet bullet-dot bg-warning me-2 h-10px w-10px"></span>
              <!--end::Bullet-->

              <!--begin::Label-->
              <span class="fw-bold text-gray-600 fs-6">Courses</span>
              <!--end::Label-->
            </div>
            <!--ed::Item-->
          </div>
          <!--ed::Item-->

          <!--begin::Item-->
          <div class="d-flex flex-column pt-sm-3 pt-6">
            <!--begin::Item-->
            <div class="d-flex align-items-center mb-3 mb-sm-6">
              <!--begin::Bullet-->
              <span class="bullet bullet-dot bg-info me-2 h-10px w-10px"></span>
              <!--end::Bullet-->

              <!--begin::Label-->
              <span class="fw-bold text-gray-600 fs-6">TV Campaign</span>
              <!--end::Label-->
            </div>
            <!--ed::Item-->

            <!--begin::Item-->
            <div class="d-flex align-items-center">
              <!--begin::Bullet-->
              <span class="bullet bullet-dot bg-success me-2 h-10px w-10px"></span>
              <!--end::Bullet-->

              <!--begin::Label-->
              <span class="fw-bold text-gray-600 fs-6">Radio</span>
              <!--end::Label-->
            </div>
            <!--ed::Item-->
          </div>
          <!--ed::Item-->
        </div>
        <!--ed::Items-->
      </div>
      <!--end::Tab pane-->

      <!--begin::Tab pane-->
      <div class="tab-pane fade" [ngClass]="{'active show': tab === 'Month'}" id="kt_chart_widget_8_month_tab"
        role="tabpanel">
        <!--begin::Statistics-->
        <div class="mb-5">
          <!--begin::Statistics-->
          <div class="d-flex align-items-center mb-2">
            <span class="fs-1 fw-semibold text-gray-400 me-1 mt-n1">$</span>

            <span class="fs-3x fw-bold text-gray-800 me-2 lh-1 ls-n2">8,55</span>

            <span class="badge badge-light-success fs-base">
              <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr066.svg'"
                class="svg-icon svg-icon-5 svg-icon-success ms-n1"></span>
              2.2%
            </span>

          </div>
          <!--end::Statistics-->

          <!--begin::Description-->
          <span class="fs-6 fw-semibold text-gray-400">Avarage cost per interaction</span>
          <!--end::Description-->
        </div>
        <!--end::Statistics-->

        <!--begin::Chart-->
        <div id="kt_chart_widget_8_month_chart" class="ms-n5 min-h-auto" [ngStyle]="{'height': chartHeight}">
          <ng-container *ngIf="hadDelay">

            <apx-chart #monthChart [series]="chart2Options.series" [chart]="chart2Options.chart"
              [xaxis]="chart2Options.xaxis" [yaxis]="chart2Options.yaxis" [dataLabels]="chart2Options.dataLabels"
              [stroke]="chart2Options.stroke" [legend]="chart2Options.legend" [fill]="chart2Options.fill"
              [states]="chart2Options.states" [tooltip]="chart2Options.tooltip" [colors]="chart2Options.colors"
              [markers]="chart2Options.markers" [plotOptions]="chart2Options.plotOptions"></apx-chart>

          </ng-container>

        </div>
        <!--end::Chart-->

        <!--begin::Items-->
        <div class="d-flex flex-wrap pt-5">
          <!--begin::Item-->
          <div class="d-flex flex-column me-7 me-lg-16 pt-sm-3 pt-6">
            <!--begin::Item-->
            <div class="d-flex align-items-center mb-3 mb-sm-6">
              <!--begin::Bullet-->
              <span class="bullet bullet-dot bg-primary me-2 h-10px w-10px"></span>
              <!--end::Bullet-->

              <!--begin::Label-->
              <span class="fw-bold text-gray-600 fs-6">Social Campaigns</span>
              <!--end::Label-->
            </div>
            <!--ed::Item-->

            <!--begin::Item-->
            <div class="d-flex align-items-center">
              <!--begin::Bullet-->
              <span class="bullet bullet-dot bg-danger me-2 h-10px w-10px"></span>
              <!--end::Bullet-->

              <!--begin::Label-->
              <span class="fw-bold text-gray-600 fs-6">Google Ads</span>
              <!--end::Label-->
            </div>
            <!--ed::Item-->
          </div>
          <!--ed::Item-->

          <!--begin::Item-->
          <div class="d-flex flex-column me-7 me-lg-16 pt-sm-3 pt-6">
            <!--begin::Item-->
            <div class="d-flex align-items-center mb-3 mb-sm-6">
              <!--begin::Bullet-->
              <span class="bullet bullet-dot bg-success me-2 h-10px w-10px"></span>
              <!--end::Bullet-->

              <!--begin::Label-->
              <span class="fw-bold text-gray-600 fs-6">Email Newsletter</span>
              <!--end::Label-->
            </div>
            <!--ed::Item-->

            <!--begin::Item-->
            <div class="d-flex align-items-center">
              <!--begin::Bullet-->
              <span class="bullet bullet-dot bg-warning me-2 h-10px w-10px"></span>
              <!--end::Bullet-->

              <!--begin::Label-->
              <span class="fw-bold text-gray-600 fs-6">Courses</span>
              <!--end::Label-->
            </div>
            <!--ed::Item-->
          </div>
          <!--ed::Item-->

          <!--begin::Item-->
          <div class="d-flex flex-column pt-sm-3 pt-6">
            <!--begin::Item-->
            <div class="d-flex align-items-center mb-3 mb-sm-6">
              <!--begin::Bullet-->
              <span class="bullet bullet-dot bg-info me-2 h-10px w-10px"></span>
              <!--end::Bullet-->

              <!--begin::Label-->
              <span class="fw-bold text-gray-600 fs-6">TV Campaign</span>
              <!--end::Label-->
            </div>
            <!--ed::Item-->

            <!--begin::Item-->
            <div class="d-flex align-items-center">
              <!--begin::Bullet-->
              <span class="bullet bullet-dot bg-success me-2 h-10px w-10px"></span>
              <!--end::Bullet-->

              <!--begin::Label-->
              <span class="fw-bold text-gray-600 fs-6">Radio</span>
              <!--end::Label-->
            </div>
            <!--ed::Item-->
          </div>
          <!--ed::Item-->
        </div>
        <!--ed::Items-->
      </div>
      <!--end::Tab pane-->
    </div>
    <!--end::Tab content-->
  </div>
  <!--end::Body-->
</div>
<!--end::Chart widget 8-->
