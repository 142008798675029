<div class="bucket-div">
  <div>
    <h2>Balde Potencial</h2>
    <div class="bucket">
      <div class="top">
      </div>
      <p style="z-index: 3;">
        <b>Potencial R$: </b>
        {{bucket?.potencialValue?.toFixed(2)}}
      </p>
      <p style="z-index: 3;">
        <b>Atual R$: </b>
        {{bucket?.nowValue?.toFixed(2)}}
      </p>
      <p style="z-index: 3;">
        <b>Porcentagem: </b> {{bucket?.nowIndex?.toFixed(2)}}
      </p>
      <div class="info" [style]="potencialHeight">
      </div>
      <div class="bottom">
      </div>
    </div>
  </div>
</div>
