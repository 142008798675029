import { Injectable } from '@angular/core';
import { SpeViewNBackofficeConsignedProxy } from 'src/app/core/proxy/view/spe-view-n-backoffice-consigned.proxy';
import { SpeViewNDashboardReposrtConsignedProxy } from 'src/app/core/proxy/view/spe-view-n-dashboard-report-consigned-proxy';

@Injectable({
  providedIn: 'root'
})
export class DashBoardService {

  constructor(private speViewNDashboardReposrtConsignedProxy: SpeViewNDashboardReposrtConsignedProxy, private speViewNBackofficeConsignedProxy: SpeViewNBackofficeConsignedProxy) { }

  public getRawOne(uuidCompany: string) {
    return this.speViewNDashboardReposrtConsignedProxy.getRawOne(uuidCompany);
  }

  public getRawTwo(uuidCompany: string) {
    return this.speViewNDashboardReposrtConsignedProxy.getRawTwo(uuidCompany);
  }

  public getRawInsurance(uuidCompany: string) {
    return this.speViewNDashboardReposrtConsignedProxy.getRawInsurance(uuidCompany);
  }

  public getUuidCompanyByEmail(email: string) {
    return this.speViewNBackofficeConsignedProxy.getUuidCompanyByEmail(email);
  }
}
