import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'copy-component',
  templateUrl: './copy.component.html',
  styleUrls: ['./copy.component.scss']
})
export class CopyComponent {
  @Input() content:string;
  constructor() { }

  public copyToClipboard(){
    if(navigator.clipboard){
      navigator.clipboard.writeText(this.content);

    }
  }
}
