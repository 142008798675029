import {Injectable, NgModule} from '@angular/core';
import { ScfiModule } from './scfi/scfi.module';
import { ConsignedModule } from './consigned/consigned.module';
import { PagesRoutingModule } from './pages.routing';
import { RouterModule } from '@angular/router';
import { ScfiRoutingModule } from './scfi/scfi.routing';

@Injectable({
  providedIn: 'root'
})
@NgModule({
  declarations: [],
  imports: [
    PagesRoutingModule,
    ScfiModule,
    ConsignedModule,
    RouterModule,
    ScfiRoutingModule

  ],
})
export class PagesModule { }