import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeesRoutes } from './fees.routing';
import { FeeFormComponent } from './fee-form/fee-form.component';
import { FeeListComponent } from './fee-list/fee-list.component';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { UiCInputModule } from 'src/app/shared/components/ui-c-input/ui-c-input.module';
import { ReactiveFormsModule } from '@angular/forms';
import { UiCTableModule } from 'src/app/shared/components/ui-c-table/ui-c-table.module';

@NgModule({
  imports: [
    CommonModule,
    FeesRoutes,
    MatTableModule,
    MatIconModule,
    MatPaginatorModule,
    InlineSVGModule,
    UiCInputModule,
    ReactiveFormsModule,
    UiCTableModule
  ],
  declarations: [FeeFormComponent, FeeListComponent]
})
export class FeesModule { }
