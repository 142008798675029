import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { AbstractScfi } from "../../abstract-scfi";
import {
  ErrorMessagesTypeProductsDto,
  ProductDto,
  UpdateTypeProductDto
} from "src/app/core/scfi/services/services-product/products.dto";
import { TypeProductDto, CreateTypeProductDto } from "src/app/core/scfi/services/services-product/type-product.dto";
import { CurrencyPipe, DatePipe, Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { ProductsService } from "src/app/core/scfi/services/services-product/products.service";
import { TableData } from "src/app/core/scfi/services/services-table/table.dto";
import { environment } from "src/environments/environment";
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-product-type-form',
  templateUrl: './product-type-form.component.html',
  styleUrls: ['./product-type-form.component.scss']
})
export class TypeProductFormComponent extends AbstractScfi implements OnInit {
  public errorMessagesTypeProduct: ErrorMessagesTypeProductsDto;
  public formTypeProduct: FormGroup;
  public isNewType: boolean;
  public nameType: string = "";
  public uuidType: string = "";
  public productsDto: ProductDto[];
  public tableProducts: TableData;

  constructor(
    private location: Location,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private productsService: ProductsService,
    protected currencyPipe: CurrencyPipe,
    protected datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    private cdr: ChangeDetectorRef,
  ) {
    super(currencyPipe, datePipe)
  }

  ngOnInit() {
    this.activateRoute.params.subscribe(params => {
      const uuid = params['uuid'];
      this.isNewType = !uuid;
      if (uuid) {
        this.uuidType = uuid;
        this.buildFormTypeProduct();
        this.findTypeProductByUuid(uuid);
      } else {
        this.buildFormTypeProduct();
      }
    });
  }

  public buildFormTypeProduct() {
    this.errorMessagesTypeProduct = this.productsService.getErrorMessagesTypeProduct();
    this.formTypeProduct = this.productsService.createFormTypeProduct();
  }

  public findTypeProductByUuid(uuid: string) {
    this.spinner.show();
    this.productsService.findProductTypeByUuid(uuid).subscribe({
      next: (typeProduct: TypeProductDto) => {
        this.formTypeProduct.patchValue({
          name: typeProduct.name,
        });
        this.nameType = typeProduct.name;
        this.cdr.detectChanges();
        this.findProductList(uuid);
        this.spinner.hide();
      },
      error: () => {
        this.spinner.hide();
        this.showErrorModal('Erro ao buscar tipo de produto!');
      }
    });
  }

  public async saveTypeProduct() {
    const confirmationMessage = this.isNewType
      ? 'Tem certeza que deseja criar este tipo de produto?'
      : 'Tem certeza que deseja alterar este tipo de produto?';

    const confirmed = await this.showConfirmationModal(confirmationMessage);

    if (!confirmed) return;

    const typeProductForm = this.formTypeProduct.value;

    if (this.isNewType) {
      const createTypeProductDto: CreateTypeProductDto = {
        name: typeProductForm.name,
        uuidWhiteLabel: environment.wlp
      }
      this.createTypeProduct(createTypeProductDto)
    } else {
      const updateTypeProduct: UpdateTypeProductDto = {
        uuid: this.uuidType,
        name: typeProductForm.name,
      }
      this.updateTypeProduct(updateTypeProduct);
    }
  }

  redirectToNewOrEditProduct(uuidProduct?: string) {
    if (!uuidProduct) {
      this.router.navigate([`scfi/products/type/${this.uuidType}/new`]);
    }
    else {
      this.router.navigate([`scfi/products/type/${this.uuidType}/new`, {
        type: this.uuidType,
        uuidProduct: uuidProduct
      }]);
    }
  }

  public findProductList(uuid: string) {
    this.productsService.findProductList(environment.wlp, uuid).subscribe({
      next: (productsList: ProductDto[]) => {
        this.productsDto = productsList;
        this.tableProducts = this.generateTableData();
        this.cdr.detectChanges();
      },
      error: () => {
        this.showErrorModal('Erro ao buscar lista de produtos!');
      }
    });
  }

  public generateTableData(): TableData {
    return {
      header: ['Produto', 'Ações'],
      rows: this.productsDto.map(row => {
        return {
          fields: [
            row.name
          ],
          actions: [
            { icon: './assets/media/icons/duotune/general/gen055.svg', onClick: () => this.redirectToNewOrEditProduct(row.uuid) },
            { icon: './assets/media/icons/duotune/general/gen027.svg', onClick: () => this.productsService.deleteProduct(row.uuid) }
          ]
        }
      }),
    }
  }

  backToLastPage(): void {
    this.location.back();
  }

  public createTypeProduct(createTypeProductDto: CreateTypeProductDto) {
    this.productsService.createTypeProduct(createTypeProductDto).subscribe({
      next: async () => {
        await this.showSuccessModal('Tipo de produto criado com sucesso!');
        return this.router.navigate(['scfi/products']);
      },
      error: () => {
        this.showErrorModal('Erro ao criar o tipo de produto!');
      }
    });
  }

  public updateTypeProduct(updateTypeProduct: UpdateTypeProductDto) {
    this.productsService.updateProductType(updateTypeProduct).subscribe({
      next: async () => {
        await this.showSuccessModal('Tipo de produto alterado com sucesso!');
        return this.router.navigate(['scfi/products']);
      },
      error: () => {
        this.showErrorModal('Erro ao alterar o tipo de produto!');
      }
    });
  }
}
