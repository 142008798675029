import { FundingDto } from "./funding.dto";

export const FUNDING_LIST_MOCK: FundingDto[] =
    [
        {
            uuid: "1",
            name: "XPTO-1",
            negotiatedRate: 0.7,
            capital: 300000,
            isExternal: true,
            integrationData: {
                identification: "1",
                manager: "Gestora 1"
            }
        },
        {
            uuid: "2",
            name: "ABC-2",
            negotiatedRate: 0.8,
            capital: 500000,
            isExternal: false
        },
        {
            uuid: "3",
            name: "DEF-3",
            negotiatedRate: 0.6,
            capital: 200000,
            isExternal: true,
            integrationData: {
                identification: "3",
                manager: "Gestora 3"
            }
        },
        {
            uuid: "4",
            name: "GHI-4",
            negotiatedRate: 0.75,
            capital: 450000,
            isExternal: false
        },
        {
            uuid: "5",
            name: "JKL-5",
            negotiatedRate: 0.9,
            capital: 700000,
            isExternal: true,
            integrationData: {
                identification: "5",
                manager: "Gestora 5"
            }
        },
        {
            uuid: "6",
            name: "MNO-6",
            negotiatedRate: 0.65,
            capital: 250000,
            isExternal: false
        },
        {
            uuid: "7",
            name: "PQR-7",
            negotiatedRate: 0.85,
            capital: 600000,
            isExternal: true,
            integrationData: {
                identification: "7",
                manager: "Gestora 7"
            }
        },
        {
            uuid: "8",
            name: "STU-8",
            negotiatedRate: 0.78,
            capital: 400000,
            isExternal: false
        },
        {
            uuid: "9",
            name: "VWX-9",
            negotiatedRate: 0.82,
            capital: 550000,
            isExternal: true,
            integrationData: {
                identification: "9",
                manager: "Gestora 9"
            }
        },
        {
            uuid: "10",
            name: "YZA-10",
            negotiatedRate: 0.73,
            capital: 350000,
            isExternal: false
        }
    ]