import { CommonModule, CurrencyPipe } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatTableModule } from "@angular/material/table";
import { InterceptService } from "src/app/core/_base/_auth/intercept/intercept.service";
import { SpeNBackofficeConsignedProxy } from "src/app/core/proxy/spe/spe-n-backoffice-consigned";
import { UiCDatePickerModule } from "src/app/shared/components/date-picker/date-picker.module";
import { TableFinanceContractDetailModule } from "src/app/shared/components/table-details-contract/table-finance-contract.module";
import { UiCInputModule } from "src/app/shared/components/ui-c-input/ui-c-input.module";
import { MaskerModule } from "src/app/shared/services-ultil/masker-service/masker-module.module";
import { TruncateService } from "src/app/shared/services-ultil/truncate-service/truncate.service";
import { SharedModule } from "src/app/shared/shared.module";
import { ProposalServices } from "../../operations/proposals/services-apis/proposals-services";
import { FgtsContractsListComponent } from "./fgts-contracts-list/fgts-contracts-list.component";
import { FgtsContractsLoansRoutingModule } from "./fgts-contracts-loans-routing.module";
import { FgtsFormsComponent } from "./fgts-contracts/fgts-contracts-forms.component";
import { CopyComponentModule } from "src/app/shared/components/copy-and-paste/copy.module";
import { NgxQRCodeModule } from "@techiediaries/ngx-qrcode";

@NgModule({
  declarations: [
    FgtsContractsListComponent,
    FgtsFormsComponent,
    //TablesimulatorComponent
    
  ],
  imports: [
    CommonModule,
    FgtsContractsLoansRoutingModule,
    SharedModule,
    MatIconModule,
    MatPaginatorModule,
    MatTableModule,
    MatFormFieldModule,
    UiCInputModule,
    MaskerModule,
    UiCDatePickerModule,
    FormsModule,
    TableFinanceContractDetailModule,
    MatCheckboxModule,
    CopyComponentModule,
    NgxQRCodeModule,
  ],
  providers: [
    // MaskService,
    InterceptService,
    SpeNBackofficeConsignedProxy,
    ProposalServices,
    CurrencyPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
    TruncateService
  ]
})
export class FgtsContractsLoansModule { }
