import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ErrorMessagesFeesDto, ConfigurationFeeDto, CreateFeeDto, UpdateFeeDto } from 'src/app/core/scfi/services/services-fees/fees.dto';
import { FeesService } from 'src/app/core/scfi/services/services-fees/fees.service';
import { CurrencyPipe, DatePipe, Location } from '@angular/common';
import { AbstractScfi } from '../../abstract-scfi';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-fee-form',
  templateUrl: './fee-form.component.html',
  styleUrls: ['./fee-form.component.scss']
})
export class FeeFormComponent extends AbstractScfi implements OnInit {
  public errorMessagesFees: ErrorMessagesFeesDto;
  public formFees: FormGroup;
  public isEditMode: boolean = false;
  public uuid: string;

  constructor(
    private location: Location,
    private feesService: FeesService,
    private router: Router,
    protected currencyPipe: CurrencyPipe,
    private route: ActivatedRoute,
    protected datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    private cdr: ChangeDetectorRef
  ) {
    super(currencyPipe, datePipe);
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.uuid = params['uuid'];
      this.isEditMode = !!this.uuid;

      if (this.uuid) {
        this.buildFormFees();
        this.findFeeByUuid(this.uuid);
      } else {
        this.buildFormFees();
      }
    });
  }

  public buildFormFees() {
    this.errorMessagesFees = this.feesService.getErrorMessages();
    this.formFees = this.feesService.createFormFees();
  }

  public backToLastPage(): void {
    this.location.back();
  }

  public findFeeByUuid(uuid: string) {
    this.spinner.show();
    this.feesService.findFeeByUuid(uuid).subscribe({
      next: (fee: ConfigurationFeeDto) => {
        this.formFees.patchValue({
          name: fee.name,
          minInterestRate: fee.minInterestRate,
          interestRate: fee.interestRate,
          typeTac: fee.typeTac,
          minValueTac: fee.minValueTac,
          valueTac: fee.valueTac,
        });
        this.cdr.detectChanges();
        this.spinner.hide();
      },
      error: (error) => {
        this.showErrorModal('Erro ao buscar tarifa!');
        this.spinner.hide();
      }
    });
  }

  public async saveFee() {
    const confirmationMessage = this.isEditMode
      ? 'Tem certeza que deseja alterar esta tarifa?'
      : 'Tem certeza que deseja criar esta tarifa?';

    const confirmed = await this.showConfirmationModal(confirmationMessage);

    if (confirmed) {
      const formFee: ConfigurationFeeDto = this.formFees.value;

      if (this.isEditMode) {
        const fee: UpdateFeeDto = {
          uuid: this.uuid,
          name: formFee.name,
          typeTac: formFee.typeTac,
          minValueTac: formFee.minValueTac,
          valueTac: formFee.valueTac,
          minInterestRate: formFee.minInterestRate,
          interestRate: formFee.interestRate,
        }
        this.updateFee(fee);
      } else {
        const fee: CreateFeeDto = {
          uuidWhiteLabel: environment.wlp,
          name: formFee.name,
          typeTac: formFee.typeTac,
          minValueTac: formFee.minValueTac,
          valueTac: formFee.valueTac,
          minInterestRate: formFee.minInterestRate,
          interestRate: formFee.interestRate,
        }
        this.createFee(fee);
      }
    }
  }

  public createFee(fee: CreateFeeDto) {
    this.feesService.createFee(fee).subscribe({
      next: async () => {
        await this.showSuccessModal('Tarifa criada com sucesso!');
        this.router.navigate(['/scfi/fees/fees-list']);
      },
      error: (error) => {
        this.showErrorModal('Erro ao criar a tarifa!');
      }
    });
  }

  public updateFee(fee: UpdateFeeDto) {
    this.feesService.updateFee(fee).subscribe({
      next: async () => {
        await this.showSuccessModal('Tarifa alterada com sucesso!');
        this.router.navigate(['/scfi/fees/fees-list']);
      },
      error: (error) => {
        this.showErrorModal('Erro ao alterar a tarifa!');
      }
    });
  }
}
