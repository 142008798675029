<div id="proposal-approval-details">
    <section class="card mb-4 shadow-sm">
        <div class="card-header border-0 d-flex align-items-center justify-content-start">
            <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr086.svg'"
                class="svg-icon svg-icon-2tx svg-icon-gray-800 me-4"></span>
            <h3 class="fw-bolder card-title mt-3">Histórico de Aprovação</h3>
        </div>

        <div class="card-body border-top p-9">
            <table
                class="text-center table-details mat-elevation-z1 table table-striped table-hover mb-0">
                <thead class="p-3">
                    <tr>
                        <th scope="col text-center">
                            Papel
                        </th>
                        <th scope="col text-center">
                            Quem
                        </th>
                        <th scope="col text-center">
                            Data
                        </th>
                        <th scope="col text-center">
                            Hora
                        </th>
                        <th scope="col text-center">
                            Status
                        </th>
                        <th scope="col text-center">
                            Observação
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let approval of approvalHistory">
                        <td> {{ translateRoleType(approval.roleType) }} </td>
                        <td> {{ approval?.customerName }}</td>
                        <td> {{ approval?.dateRegister | date: "dd/MM/yyyy" }} </td>
                        <td> {{ approval?.dateRegister | date: "HH:mm":"UTC-3" }} </td>
                        <td> {{ translateProposalStatus(approval.status) }}</td>
                        <td> {{ approval?.description }} </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </section>
</div>