import { EmployeeDismissalFormsComponent } from './employee-dismissal-forms/employee-dismissal-forms.component';
import { EmployeeDismissalListComponent } from './employee-dismissal-list/employee-dismissal-list.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', component: EmployeeDismissalListComponent, },
  { path: 'new', component: EmployeeDismissalFormsComponent, },
  { path: ':id/edit', component: EmployeeDismissalFormsComponent, },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EmployeeDismissalRoutingModule { }
