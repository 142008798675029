import { BillingInvoicesSettleFormsComponent } from './billing-invoices-settle-forms/billing-invoices-settle-forms.component';
import { BillingInvoicesGeneratorFormsComponent } from './billing-invoices-generator-forms/billing-invoices-generator-forms.component';
import { BillingInvoicesListComponent } from './billing-invoices-list/billing-invoices-list.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', component: BillingInvoicesListComponent, },
  { path: 'new', component: BillingInvoicesSettleFormsComponent, },
  { path: ':id/company/:company/edit', component: BillingInvoicesSettleFormsComponent, },
  //{ path: 'settle', component: BillingInvoicesSettleFormsComponent, },
  //{ path: ':id/settle/edit', component: BillingInvoicesGeneratorFormsComponent, },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BillingInvoicesRoutingModule { }
