import { UsersFormsComponent } from './users-forms/users-forms.component';
import { UsersListComponent } from './users-list/users-list.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {path: '', component: UsersListComponent,},
  {path: 'new', component: UsersFormsComponent,},
  {path: ':id/edit', component: UsersFormsComponent,},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule { }
