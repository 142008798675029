import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrMaskerModule } from "br-mask";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TableContractDetailComponent } from './detail-contract/detail-contract.component';
import { TableFinanceContractDetailComponent } from './finace-detail-contract/table-finance-contract.component';


@NgModule({
  imports: [
    CommonModule,
    BrMaskerModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    TableFinanceContractDetailComponent,
    TableContractDetailComponent
  ],
  exports: [
    TableFinanceContractDetailComponent,
    TableContractDetailComponent
  ],
})
export class TableFinanceContractDetailModule { }
