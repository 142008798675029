import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, of, take } from 'rxjs';

import { ProposalServices } from '../../../operations/proposals/services-apis/proposals-services';
import { TablesimulatorComponent } from '../../../operations/simulations/tablesimulator/tablesimulator.component';
import { TruncateService } from 'src/app/shared/services-ultil/truncate-service/truncate.service';
import { FgtsService } from '../services-apis/fgts.service';
import { MaskService } from 'src/app/shared/services-ultil/masker-service/masker.service';
import { IProposalEvent } from 'src/app/utils/proposal-event-types';
import { IConfirmFgtsProposalCancelationRequest, IProposalCancelation } from 'src/app/utils/proposal-cancelation-types';
import Swal from 'sweetalert2';
import { NgxQrcodeElementTypes } from '@techiediaries/ngx-qrcode';


@Component({
  selector: 'app-fgts-contracts-forms',
  templateUrl: './fgts-contracts-forms.component.html',
  styleUrls: ['./fgts-contracts-forms.component.scss']
})
export class FgtsFormsComponent {

  public goBackToInvoice = false;
  public uuidInvoice = null
  public uuidCompany = null;
  public urlInvoice = '/consigned/fgts';

  hide = true;
  cap: number = 0;
  dataSourceSimulador: any[] = [];
  eventsList: IProposalEvent[] = [];

  @ViewChild(TablesimulatorComponent, { static: false })
  tabela!: TablesimulatorComponent;
  @ViewChild("emprestimo", { static: true }) emprestimo!: ElementRef;

  public errorMessagesProposal: any;
  public resourceForm: any;
  public resource: any;

  public contractType: "contract"|"proposal";
  public isView: any = false;
  public loading: boolean;
  public listUf: { name: string; value: string; }[];
  public insurance: boolean = true;
  public isManegment: boolean = false;
  public isAudit: boolean = false;

  public status: string = "";
  public uuidLogedUser: string | undefined;
  public targetPartner: string;

  public installmentsDetails: any = {};

  public columnsToDisplayInstallment = ['repaymentDate', 'interestAmount', 'iofAmount', 'releasedAmount', 'repaymentAmount'];
  public columnsToDisplayEvents = ['status', 'dateRegister', 'device', 'description'];

  public cancelation:IProposalCancelation|null = null;
  public proposalUuid: string;
  public targetUuid: string;
  public cancelationReason: string = "";

  public qrCodeElType: NgxQrcodeElementTypes = NgxQrcodeElementTypes.IMG;
  public qrCodeValue:string = "";

  target: any;
  historyApprovation: any;
  email: string = "";

  constructor(
    protected proposalService: ProposalServices,
    private cdr: ChangeDetectorRef,
    protected activeRouter: ActivatedRoute,
    private spinner: NgxSpinnerService,
    protected truncateService: TruncateService,
    private fgtsService: FgtsService,
    protected maskerService: MaskService
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.activeRouter.paramMap.pipe(take(1))
      .subscribe(async(res: any) => {
        this.targetUuid = res.params['id'];
        this.contractType = res.params['contractType'];
      })

    this.buildData();
  }

  protected buildResourceForm() {
    this.errorMessagesProposal = this.proposalService.getFormErrors();
    this.resourceForm = this.proposalService.createFormProposal();
  }

  protected createPageTitle(): string {
    return `Detalhes ${this.contractType === "contract" ? 'do Contrato' : 'da Proposta'}`;
  }

  private async buildData(){
    try {
      if(this.contractType === "proposal") {
        await this.getProposal();
        
      } else if(this.contractType === "contract") {
        await this.getContract();
      }

    } catch (error) {
      console.error(error)
    }
    
    this.buildResourceForm();
    this.spinner.hide();
  }

  private async getCancelation(){
    this.fgtsService.getCancelationRequestByProposalUuid(this.proposalUuid)
    .subscribe(
      (res) => {
        this.cancelation = res;
        this.qrCodeValue = res.qrCode as string;
        this.cdr.detectChanges();
      })
  }

  private async getContract(){
    this.fgtsService.getContractByUuid(this.targetUuid)
    .subscribe(
      async(target) => {
        this.target = target;

        this.proposalUuid = this.target.uuidProposal;
        this.dataSourceSimulador = target.installments;
        this.sumValuesTableInstallment();
        this.cdr.detectChanges();

        await this.getCancelation();
        await this.getEvents();
      })
  }

  private async getProposal(){
    await this.fgtsService.getProposalByUuid(this.targetUuid)
    .subscribe(
      async(target) => {
        this.target = target;
        this.dataSourceSimulador = target.installments;
        this.sumValuesTableInstallment();
        this.proposalUuid = target.uuid;
        this.cdr.detectChanges();

        await this.getEvents();
      }),
      (err: Error) => {
        console.error(err);
        throw new Error(err.message);
      }
  }

  private async getEvents(){
    this.fgtsService.getEventsListByUuid(this.proposalUuid)
    .subscribe(
      res => {
        this.eventsList = res.map(<T extends IProposalEvent>(el:T) => {
          return {
            uuid: el.uuid,
            device: el.device||"-",
            status: el.status,
            dateRegister: el.dateRegister,
            description: el.description.length ? el.description : "-"
          }
        })

        this.cdr.detectChanges();
      
    })
  }

  public async postConfirmProposalCancelation(){
    this.cdr.detectChanges();
    const dto = this.mountConfirmProposalCancelationDto();
    this.fgtsService.postConfirmCancelationRequest(dto)
    .pipe(
      catchError((err: any) => {
        console.error("Error:", err);
        Swal.fire({
          title: `<strong>Erro!</strong>`,
          icon: 'error',
          text: "Erro ao concluir cancelamento."
        });
        return of(null); // Return a fallback observable
      })
    )
    .subscribe(
      (res) => {
        if(res){
          Swal.fire({
            title: `<strong>Sucesso!</strong>`,
            icon: 'success',
            text: "Operação concluída com sucesso"
          });

          setTimeout(()=> {
            window.location.reload();
          }, 3000)
        }

      },

    )
  }

  private mountConfirmProposalCancelationDto(): IConfirmFgtsProposalCancelationRequest{
    return {
      dto: {
        uuidProposal: this.proposalUuid,
        cancellationReason: this.cancelationReason
      }
    }
  }

  public mountStatus(){
    if(this.target?.status === 'PRE_CANCELED'){
      return "Solicitado pelo cliente, aguardando confirmação."
    } else if (this.target?.status === 'MONEY_CANCELLATION_REQUESTED'){
      return "Confirmado, solicitação enviada para a Money."
    }
  }

  sumElementus(...args: any) {
    let sum = 0;
    for (let i = 0; i < args.length; i++) {
      if (!!args[i]) sum += Number(args[i]);
    }
    return sum;
  }

  sumValuesTableInstallment() {
    let interestAmount: number = 0;
    let releasedAmount: number = 0;
    let repaymentAmount: number = 0;
    let iofAmount: number = 0;

    this.dataSourceSimulador.map((el: any) => {
      interestAmount += Number(el.interestAmount);
      releasedAmount += Number(el.releasedAmount);
      repaymentAmount += Number(el.repaymentAmount);
      iofAmount += Number(el.iofAmount) + Number(el.additionalIofAmount)
    });

    this.dataSourceSimulador = [...this.dataSourceSimulador, {
      interestAmount, iofAmount, releasedAmount, repaymentAmount
    }]
  }

  public onInputChange(val:Event){
    const inputElement = val.target as HTMLInputElement;
    this.cancelationReason = inputElement.value;
  }
}
