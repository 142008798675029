import { Routes, RouterModule } from '@angular/router';
import { SimulationListComponent } from './simulation-list/simulation-list.component';
import { SimulationFormComponent } from './simulation-form/simulation-form.component';

const routes: Routes = [
  {
    path: "simulation-list",
    component: SimulationListComponent
  },
  {
    path: "simulation-form",
    component: SimulationFormComponent
  },
];

export const SimulationRoutes = RouterModule.forChild(routes);
