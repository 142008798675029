import { ProductTypeEnum } from "../services-product/product-type.enum";
import { ProposalStatusEnum } from "./enum/proposal-status.enum";
import { RoleTypeEnum } from "./enum/role-type.enum";
import { ApprovalHistoryDto, ProposalDto } from "./proposal.dto";

export const PROPOSAL_MOCK: ProposalDto[] = [
    {
        uuid: "c03c2364-30a2-4b8a-28c1-23a730123c31",
        product: {
            uuid: "fdv8f9d889-frfe323-232frrf39v",
            name: "FINANCIAMENTO DE VEÍCULO LEVE",
            type: ProductTypeEnum.FINANCING,
        },
        requester: {
            uuid: "536fc99a-ad22-932b-80f8-669d8a3ec2b0",
            name: "LUIZ HENRIQUE FERREIRA DA SILVA",
            document: "47186572267",
            email: "teste@teste.com",
            phone: "11966656187",
            sex: "M",
            birthDate: "1990-10-23",
            motherName: "SILVIA FERREIRA ANJOS",
            fatherName: "SAMUEL LOPES DA SILVA",
            address: {
                zipcode: "06045-170",
                street: "Rua DOS DESBRAVADORES",
                number: "22956",
                complement: "",
                neighborhood: "VILLA YOLANDA",
                city: "OSASCO",
                state: "SP",
                country: "BRASIL"
            },
            account: {
                bankName: "Banco do Brasil",
                accountNumber: "146447",
                agencyNumber: "6798"
            },
        },
        proposalStatus: ProposalStatusEnum.REQUESTED,
        dateRegister: "2024-02-15T16:32:56.000Z",
        dateUpdated: "2024-02-15T12:33:44.000Z",
        dateDeleted: "",
        installments: [
            {
                dateInstallment: new Date(),
                insuranceBuy: 0.0749,
                numberInstallment: 1,
                interestInstallment: 4.3000,
                amortInstallment: 50.0000,
                installmentValue: 54.3000,
                amountDue: 54.3000,
                daysIofCount: 30.0000,
                daysIofIndex: 0.0082,
                insuranceIndex: 0.1117,
                insuranceBuyIndex: 0.073830
            },
            {
                dateInstallment: new Date(),
                insuranceBuy: 0.0383,
                numberInstallment: 2,
                interestInstallment: 4.3000,
                amortInstallment: 50.0000,
                installmentValue: 54.3000,
                amountDue: 0.0000,
                daysIofCount: 60.0000,
                daysIofIndex: 0.0082,
                insuranceIndex: 0.1117,
                insuranceBuyIndex: 0.073830
            }
        ],
        proposalNumber: "BRP-38138672854",
        proposalName: "202402823389164",
        price: {
            priceMaxInstallment: 33,
            priceMinInstallment: 33,
            priceInstallmentInterval: 1,
            priceMaxDaysIofCount: 0,
            priceInterestRate: 23.33,
            priceLifeInsurance: 0,
            priceTac: 164.4,
            priceIof: 3,
            priceCustomerType: 0,
            priceStatus: 0,
            priceStartStatus: "2024-02-15T12:33:44.000Z",
            priceEndStatus: "2024-02-15T12:33:44.000Z",
            priceDaysIofIndex: 0.0082,
            priceInsuranceIndex: 0.1117,
            lackValue: 0,
            interestDay: 0,
            differentDays: -5,
            installmentStartDay: "2024-03-10T03:00:00.000Z",
            installmentEndDay: "2027-11-10T03:00:00.000Z",
            requestDate: "2024-02-15T03:00:00.000Z",
            aproveDate: "",
            installmentOption: 33
        },
        priceDetails: {
            requestedPlusTac: 1397.7329,
            mainIofValue: 5.3114,
            plusIof: 1403.0443,
            plusLack: 1397.7329,
            totalIof: 35.61,
            totalInsurance: 23.4197,
            cet: 24.7421,
            cetIndex: 672.011
        },
        loan: {
            loan: 101.3838,
            amountProposal: 100.0000,
            alimony: 558,
            totalInterest: 8.6,
            iof: 0.3838,
            taxAdministration: 1,
            pinStallmentValue: 54.3,
            fvTotalAmountLoanValue: 108.6,
            iProposalInterestMonth: 5,
            installmentQuantityLoan: 2,
        },
        interestInstallment: 5,
        quantityInstallment: 2,
        valueInstallment: 54.3,
        loanValue: 101.38,
        totalValue: 108.6
    },
    {
        uuid: "c03c2364-30a2-4b8a-28c1-23a730123c31",
        product: {
            uuid: "fdv8f9d889-frfe323-232frrf39v",
            name: "FINANCIAMENTO DE VEÍCULO LEVE",
            type: ProductTypeEnum.FINANCING,
        },
        requester: {
            uuid: "536fc99a-ad22-932b-80f8-669d8a3ec2b0",
            name: "LUIZ HENRIQUE FERREIRA DA SILVA PJ",
            document: "44557543000180",
            email: "teste@teste.com",
            phone: "11966656187",
            stateRegistration: "539772444834",
            openingDate: "2020-10-29",
            address: {
                zipcode: "06045-170",
                street: "Rua DOS DESBRAVADORES",
                number: "22956",
                complement: "",
                neighborhood: "VILLA YOLANDA",
                city: "OSASCO",
                state: "SP",
                country: "BRASIL"
            },
            account: {
                bankName: "Banco do Brasil",
                accountNumber: "146447",
                agencyNumber: "6798"
            },
        },
        proposalStatus: ProposalStatusEnum.REQUESTED,
        dateRegister: "2024-02-15T16:32:56.000Z",
        dateUpdated: "2024-02-15T12:33:44.000Z",
        dateDeleted: "",
        installments: [
            {
                dateInstallment: new Date(),
                insuranceBuy: 0.0749,
                numberInstallment: 1,
                interestInstallment: 4.3000,
                amortInstallment: 50.0000,
                installmentValue: 54.3000,
                amountDue: 54.3000,
                daysIofCount: 30.0000,
                daysIofIndex: 0.0082,
                insuranceIndex: 0.1117,
                insuranceBuyIndex: 0.073830
            },
            {
                dateInstallment: new Date(),
                insuranceBuy: 0.0383,
                numberInstallment: 2,
                interestInstallment: 4.3000,
                amortInstallment: 50.0000,
                installmentValue: 54.3000,
                amountDue: 0.0000,
                daysIofCount: 60.0000,
                daysIofIndex: 0.0082,
                insuranceIndex: 0.1117,
                insuranceBuyIndex: 0.073830
            }
        ],
        proposalNumber: "BRP-38138672854",
        proposalName: "202402823389164",
        price: {
            priceMaxInstallment: 33,
            priceMinInstallment: 33,
            priceInstallmentInterval: 1,
            priceMaxDaysIofCount: 0,
            priceInterestRate: 23.33,
            priceLifeInsurance: 0,
            priceTac: 164.4,
            priceIof: 3,
            priceCustomerType: 0,
            priceStatus: 0,
            priceStartStatus: "2024-02-15T12:33:44.000Z",
            priceEndStatus: "2024-02-15T12:33:44.000Z",
            priceDaysIofIndex: 0.0082,
            priceInsuranceIndex: 0.1117,
            lackValue: 0,
            interestDay: 0,
            differentDays: -5,
            installmentStartDay: "2024-03-10T03:00:00.000Z",
            installmentEndDay: "2027-11-10T03:00:00.000Z",
            requestDate: "2024-02-15T03:00:00.000Z",
            aproveDate: "",
            installmentOption: 33
        },
        priceDetails: {
            requestedPlusTac: 1397.7329,
            mainIofValue: 5.3114,
            plusIof: 1403.0443,
            plusLack: 1397.7329,
            totalIof: 35.61,
            totalInsurance: 23.4197,
            cet: 24.7421,
            cetIndex: 672.011
        },
        loan: {
            loan: 101.3838,
            amountProposal: 100.0000,
            alimony: 558,
            totalInterest: 8.6,
            iof: 0.3838,
            taxAdministration: 1,
            pinStallmentValue: 54.3,
            fvTotalAmountLoanValue: 108.6,
            iProposalInterestMonth: 5,
            installmentQuantityLoan: 2,
        },
        interestInstallment: 5,
        quantityInstallment: 2,
        valueInstallment: 54.3,
        loanValue: 101.38,
        totalValue: 108.6
    },
    {
        uuid: "c03c2364-30a2-4b8a-28c1-23a730123c31",
        product: {
            uuid: "fdv8f9d889-frfe323-232frrf39v",
            name: "FINANCIAMENTO DE VEÍCULO LEVE",
            type: ProductTypeEnum.FINANCING,
        },
        requester: {
            uuid: "536fc99a-ad22-932b-80f8-669d8a3ec2b0",
            name: "LUIZ HENRIQUE FERREIRA DA SILVA",
            document: "47186572267",
            email: "teste@teste.com",
            phone: "11966656187",
            sex: "M",
            birthDate: "1990-10-23",
            motherName: "SILVIA FERREIRA ANJOS",
            fatherName: "SAMUEL LOPES DA SILVA",
            address: {
                zipcode: "06045-170",
                street: "Rua DOS DESBRAVADORES",
                number: "22956",
                complement: "",
                neighborhood: "VILLA YOLANDA",
                city: "OSASCO",
                state: "SP",
                country: "BRASIL"
            },
            account: {
                bankName: "Banco do Brasil",
                accountNumber: "146447",
                agencyNumber: "6798"
            },
        },
        proposalStatus: ProposalStatusEnum.REQUESTED,
        dateRegister: "2024-02-15T16:32:56.000Z",
        dateUpdated: "2024-02-15T12:33:44.000Z",
        dateDeleted: "",
        installments: [
            {
                dateInstallment: new Date(),
                insuranceBuy: 0.0749,
                numberInstallment: 1,
                interestInstallment: 4.3000,
                amortInstallment: 50.0000,
                installmentValue: 54.3000,
                amountDue: 54.3000,
                daysIofCount: 30.0000,
                daysIofIndex: 0.0082,
                insuranceIndex: 0.1117,
                insuranceBuyIndex: 0.073830
            },
            {
                dateInstallment: new Date(),
                insuranceBuy: 0.0383,
                numberInstallment: 2,
                interestInstallment: 4.3000,
                amortInstallment: 50.0000,
                installmentValue: 54.3000,
                amountDue: 0.0000,
                daysIofCount: 60.0000,
                daysIofIndex: 0.0082,
                insuranceIndex: 0.1117,
                insuranceBuyIndex: 0.073830
            }
        ],
        proposalNumber: "BRP-38138672854",
        proposalName: "202402823389164",
        price: {
            priceMaxInstallment: 33,
            priceMinInstallment: 33,
            priceInstallmentInterval: 1,
            priceMaxDaysIofCount: 0,
            priceInterestRate: 23.33,
            priceLifeInsurance: 0,
            priceTac: 164.4,
            priceIof: 3,
            priceCustomerType: 0,
            priceStatus: 0,
            priceStartStatus: "2024-02-15T12:33:44.000Z",
            priceEndStatus: "2024-02-15T12:33:44.000Z",
            priceDaysIofIndex: 0.0082,
            priceInsuranceIndex: 0.1117,
            lackValue: 0,
            interestDay: 0,
            differentDays: -5,
            installmentStartDay: "2024-03-10T03:00:00.000Z",
            installmentEndDay: "2027-11-10T03:00:00.000Z",
            requestDate: "2024-02-15T03:00:00.000Z",
            aproveDate: "",
            installmentOption: 33
        },
        priceDetails: {
            requestedPlusTac: 1397.7329,
            mainIofValue: 5.3114,
            plusIof: 1403.0443,
            plusLack: 1397.7329,
            totalIof: 35.61,
            totalInsurance: 23.4197,
            cet: 24.7421,
            cetIndex: 672.011
        },
        loan: {
            loan: 101.3838,
            amountProposal: 100.0000,
            alimony: 558,
            totalInterest: 8.6,
            iof: 0.3838,
            taxAdministration: 1,
            pinStallmentValue: 54.3,
            fvTotalAmountLoanValue: 108.6,
            iProposalInterestMonth: 5,
            installmentQuantityLoan: 2,
        },
        interestInstallment: 5,
        quantityInstallment: 2,
        valueInstallment: 54.3,
        loanValue: 101.38,
        totalValue: 108.6
    },
    {
        uuid: "c03c2364-30a2-4b8a-28c1-23a730123c31",
        product: {
            uuid: "fdv8f9d889-frfe323-232frrf39v",
            name: "FINANCIAMENTO DE VEÍCULO LEVE",
            type: ProductTypeEnum.FINANCING,
        },
        requester: {
            uuid: "536fc99a-ad22-932b-80f8-669d8a3ec2b0",
            name: "LUIZ HENRIQUE FERREIRA DA SILVA",
            document: "47186572267",
            email: "teste@teste.com",
            phone: "11966656187",
            sex: "M",
            birthDate: "1990-10-23",
            motherName: "SILVIA FERREIRA ANJOS",
            fatherName: "SAMUEL LOPES DA SILVA",
            address: {
                zipcode: "06045-170",
                street: "Rua DOS DESBRAVADORES",
                number: "22956",
                complement: "",
                neighborhood: "VILLA YOLANDA",
                city: "OSASCO",
                state: "SP",
                country: "BRASIL"
            },
            account: {
                bankName: "Banco do Brasil",
                accountNumber: "146447",
                agencyNumber: "6798"
            },
        },
        proposalStatus: ProposalStatusEnum.REQUESTED,
        dateRegister: "2024-02-15T16:32:56.000Z",
        dateUpdated: "2024-02-15T12:33:44.000Z",
        dateDeleted: "",
        installments: [
            {
                dateInstallment: new Date(),
                insuranceBuy: 0.0749,
                numberInstallment: 1,
                interestInstallment: 4.3000,
                amortInstallment: 50.0000,
                installmentValue: 54.3000,
                amountDue: 54.3000,
                daysIofCount: 30.0000,
                daysIofIndex: 0.0082,
                insuranceIndex: 0.1117,
                insuranceBuyIndex: 0.073830
            },
            {
                dateInstallment: new Date(),
                insuranceBuy: 0.0383,
                numberInstallment: 2,
                interestInstallment: 4.3000,
                amortInstallment: 50.0000,
                installmentValue: 54.3000,
                amountDue: 0.0000,
                daysIofCount: 60.0000,
                daysIofIndex: 0.0082,
                insuranceIndex: 0.1117,
                insuranceBuyIndex: 0.073830
            }
        ],
        proposalNumber: "BRP-38138672854",
        proposalName: "202402823389164",
        price: {
            priceMaxInstallment: 33,
            priceMinInstallment: 33,
            priceInstallmentInterval: 1,
            priceMaxDaysIofCount: 0,
            priceInterestRate: 23.33,
            priceLifeInsurance: 0,
            priceTac: 164.4,
            priceIof: 3,
            priceCustomerType: 0,
            priceStatus: 0,
            priceStartStatus: "2024-02-15T12:33:44.000Z",
            priceEndStatus: "2024-02-15T12:33:44.000Z",
            priceDaysIofIndex: 0.0082,
            priceInsuranceIndex: 0.1117,
            lackValue: 0,
            interestDay: 0,
            differentDays: -5,
            installmentStartDay: "2024-03-10T03:00:00.000Z",
            installmentEndDay: "2027-11-10T03:00:00.000Z",
            requestDate: "2024-02-15T03:00:00.000Z",
            aproveDate: "",
            installmentOption: 33
        },
        priceDetails: {
            requestedPlusTac: 1397.7329,
            mainIofValue: 5.3114,
            plusIof: 1403.0443,
            plusLack: 1397.7329,
            totalIof: 35.61,
            totalInsurance: 23.4197,
            cet: 24.7421,
            cetIndex: 672.011
        },
        loan: {
            loan: 101.3838,
            amountProposal: 100.0000,
            alimony: 558,
            totalInterest: 8.6,
            iof: 0.3838,
            taxAdministration: 1,
            pinStallmentValue: 54.3,
            fvTotalAmountLoanValue: 108.6,
            iProposalInterestMonth: 5,
            installmentQuantityLoan: 2,
        },
        interestInstallment: 5,
        quantityInstallment: 2,
        valueInstallment: 54.3,
        loanValue: 101.38,
        totalValue: 108.6
    },
    {
        uuid: "c03c2364-30a2-4b8a-28c1-23a730123c31",
        product: {
            uuid: "fdv8f9d889-frfe323-232frrf39v",
            name: "FINANCIAMENTO DE VEÍCULO LEVE",
            type: ProductTypeEnum.FINANCING,
        },
        requester: {
            uuid: "536fc99a-ad22-932b-80f8-669d8a3ec2b0",
            name: "LUIZ HENRIQUE FERREIRA DA SILVA",
            document: "47186572267",
            email: "teste@teste.com",
            phone: "11966656187",
            sex: "M",
            birthDate: "1990-10-23",
            motherName: "SILVIA FERREIRA ANJOS",
            fatherName: "SAMUEL LOPES DA SILVA",
            address: {
                zipcode: "06045-170",
                street: "Rua DOS DESBRAVADORES",
                number: "22956",
                complement: "",
                neighborhood: "VILLA YOLANDA",
                city: "OSASCO",
                state: "SP",
                country: "BRASIL"
            },
            account: {
                bankName: "Banco do Brasil",
                accountNumber: "146447",
                agencyNumber: "6798"
            },
        },
        proposalStatus: ProposalStatusEnum.REQUESTED,
        dateRegister: "2024-02-15T16:32:56.000Z",
        dateUpdated: "2024-02-15T12:33:44.000Z",
        dateDeleted: "",
        installments: [
            {
                dateInstallment: new Date(),
                insuranceBuy: 0.0749,
                numberInstallment: 1,
                interestInstallment: 4.3000,
                amortInstallment: 50.0000,
                installmentValue: 54.3000,
                amountDue: 54.3000,
                daysIofCount: 30.0000,
                daysIofIndex: 0.0082,
                insuranceIndex: 0.1117,
                insuranceBuyIndex: 0.073830
            },
            {
                dateInstallment: new Date(),
                insuranceBuy: 0.0383,
                numberInstallment: 2,
                interestInstallment: 4.3000,
                amortInstallment: 50.0000,
                installmentValue: 54.3000,
                amountDue: 0.0000,
                daysIofCount: 60.0000,
                daysIofIndex: 0.0082,
                insuranceIndex: 0.1117,
                insuranceBuyIndex: 0.073830
            }
        ],
        proposalNumber: "BRP-38138672854",
        proposalName: "202402823389164",
        price: {
            priceMaxInstallment: 33,
            priceMinInstallment: 33,
            priceInstallmentInterval: 1,
            priceMaxDaysIofCount: 0,
            priceInterestRate: 23.33,
            priceLifeInsurance: 0,
            priceTac: 164.4,
            priceIof: 3,
            priceCustomerType: 0,
            priceStatus: 0,
            priceStartStatus: "2024-02-15T12:33:44.000Z",
            priceEndStatus: "2024-02-15T12:33:44.000Z",
            priceDaysIofIndex: 0.0082,
            priceInsuranceIndex: 0.1117,
            lackValue: 0,
            interestDay: 0,
            differentDays: -5,
            installmentStartDay: "2024-03-10T03:00:00.000Z",
            installmentEndDay: "2027-11-10T03:00:00.000Z",
            requestDate: "2024-02-15T03:00:00.000Z",
            aproveDate: "",
            installmentOption: 33
        },
        priceDetails: {
            requestedPlusTac: 1397.7329,
            mainIofValue: 5.3114,
            plusIof: 1403.0443,
            plusLack: 1397.7329,
            totalIof: 35.61,
            totalInsurance: 23.4197,
            cet: 24.7421,
            cetIndex: 672.011
        },
        loan: {
            loan: 101.3838,
            amountProposal: 100.0000,
            alimony: 558,
            totalInterest: 8.6,
            iof: 0.3838,
            taxAdministration: 1,
            pinStallmentValue: 54.3,
            fvTotalAmountLoanValue: 108.6,
            iProposalInterestMonth: 5,
            installmentQuantityLoan: 2,
        },
        interestInstallment: 5,
        quantityInstallment: 2,
        valueInstallment: 54.3,
        loanValue: 101.38,
        totalValue: 108.6
    },
    {
        uuid: "c03c2364-30a2-4b8a-28c1-23a730123c31",
        product: {
            uuid: "fdv8f9d889-frfe323-232frrf39v",
            name: "FINANCIAMENTO DE VEÍCULO LEVE",
            type: ProductTypeEnum.FINANCING,
        },
        requester: {
            uuid: "536fc99a-ad22-932b-80f8-669d8a3ec2b0",
            name: "LUIZ HENRIQUE FERREIRA DA SILVA",
            document: "47186572267",
            email: "teste@teste.com",
            phone: "11966656187",
            sex: "M",
            birthDate: "1990-10-23",
            motherName: "SILVIA FERREIRA ANJOS",
            fatherName: "SAMUEL LOPES DA SILVA",
            address: {
                zipcode: "06045-170",
                street: "Rua DOS DESBRAVADORES",
                number: "22956",
                complement: "",
                neighborhood: "VILLA YOLANDA",
                city: "OSASCO",
                state: "SP",
                country: "BRASIL"
            },
            account: {
                bankName: "Banco do Brasil",
                accountNumber: "146447",
                agencyNumber: "6798"
            },
        },
        proposalStatus: ProposalStatusEnum.REQUESTED,
        dateRegister: "2024-02-15T16:32:56.000Z",
        dateUpdated: "2024-02-15T12:33:44.000Z",
        dateDeleted: "",
        installments: [
            {
                dateInstallment: new Date(),
                insuranceBuy: 0.0749,
                numberInstallment: 1,
                interestInstallment: 4.3000,
                amortInstallment: 50.0000,
                installmentValue: 54.3000,
                amountDue: 54.3000,
                daysIofCount: 30.0000,
                daysIofIndex: 0.0082,
                insuranceIndex: 0.1117,
                insuranceBuyIndex: 0.073830
            },
            {
                dateInstallment: new Date(),
                insuranceBuy: 0.0383,
                numberInstallment: 2,
                interestInstallment: 4.3000,
                amortInstallment: 50.0000,
                installmentValue: 54.3000,
                amountDue: 0.0000,
                daysIofCount: 60.0000,
                daysIofIndex: 0.0082,
                insuranceIndex: 0.1117,
                insuranceBuyIndex: 0.073830
            }
        ],
        proposalNumber: "BRP-38138672854",
        proposalName: "202402823389164",
        price: {
            priceMaxInstallment: 33,
            priceMinInstallment: 33,
            priceInstallmentInterval: 1,
            priceMaxDaysIofCount: 0,
            priceInterestRate: 23.33,
            priceLifeInsurance: 0,
            priceTac: 164.4,
            priceIof: 3,
            priceCustomerType: 0,
            priceStatus: 0,
            priceStartStatus: "2024-02-15T12:33:44.000Z",
            priceEndStatus: "2024-02-15T12:33:44.000Z",
            priceDaysIofIndex: 0.0082,
            priceInsuranceIndex: 0.1117,
            lackValue: 0,
            interestDay: 0,
            differentDays: -5,
            installmentStartDay: "2024-03-10T03:00:00.000Z",
            installmentEndDay: "2027-11-10T03:00:00.000Z",
            requestDate: "2024-02-15T03:00:00.000Z",
            aproveDate: "",
            installmentOption: 33
        },
        priceDetails: {
            requestedPlusTac: 1397.7329,
            mainIofValue: 5.3114,
            plusIof: 1403.0443,
            plusLack: 1397.7329,
            totalIof: 35.61,
            totalInsurance: 23.4197,
            cet: 24.7421,
            cetIndex: 672.011
        },
        loan: {
            loan: 101.3838,
            amountProposal: 100.0000,
            alimony: 558,
            totalInterest: 8.6,
            iof: 0.3838,
            taxAdministration: 1,
            pinStallmentValue: 54.3,
            fvTotalAmountLoanValue: 108.6,
            iProposalInterestMonth: 5,
            installmentQuantityLoan: 2,
        },
        interestInstallment: 5,
        quantityInstallment: 2,
        valueInstallment: 54.3,
        loanValue: 101.38,
        totalValue: 108.6
    },
    {
        uuid: "c03c2364-30a2-4b8a-28c1-23a730123c31",
        product: {
            uuid: "fdv8f9d889-frfe323-232frrf39v",
            name: "FINANCIAMENTO DE VEÍCULO LEVE",
            type: ProductTypeEnum.FINANCING,
        },
        requester: {
            uuid: "536fc99a-ad22-932b-80f8-669d8a3ec2b0",
            name: "LUIZ HENRIQUE FERREIRA DA SILVA",
            document: "47186572267",
            email: "teste@teste.com",
            phone: "11966656187",
            sex: "M",
            birthDate: "1990-10-23",
            motherName: "SILVIA FERREIRA ANJOS",
            fatherName: "SAMUEL LOPES DA SILVA",
            address: {
                zipcode: "06045-170",
                street: "Rua DOS DESBRAVADORES",
                number: "22956",
                complement: "",
                neighborhood: "VILLA YOLANDA",
                city: "OSASCO",
                state: "SP",
                country: "BRASIL"
            },
            account: {
                bankName: "Banco do Brasil",
                accountNumber: "146447",
                agencyNumber: "6798"
            },
        },
        proposalStatus: ProposalStatusEnum.REQUESTED,
        dateRegister: "2024-02-15T16:32:56.000Z",
        dateUpdated: "2024-02-15T12:33:44.000Z",
        dateDeleted: "",
        installments: [
            {
                dateInstallment: new Date(),
                insuranceBuy: 0.0749,
                numberInstallment: 1,
                interestInstallment: 4.3000,
                amortInstallment: 50.0000,
                installmentValue: 54.3000,
                amountDue: 54.3000,
                daysIofCount: 30.0000,
                daysIofIndex: 0.0082,
                insuranceIndex: 0.1117,
                insuranceBuyIndex: 0.073830
            },
            {
                dateInstallment: new Date(),
                insuranceBuy: 0.0383,
                numberInstallment: 2,
                interestInstallment: 4.3000,
                amortInstallment: 50.0000,
                installmentValue: 54.3000,
                amountDue: 0.0000,
                daysIofCount: 60.0000,
                daysIofIndex: 0.0082,
                insuranceIndex: 0.1117,
                insuranceBuyIndex: 0.073830
            }
        ],
        proposalNumber: "BRP-38138672854",
        proposalName: "202402823389164",
        price: {
            priceMaxInstallment: 33,
            priceMinInstallment: 33,
            priceInstallmentInterval: 1,
            priceMaxDaysIofCount: 0,
            priceInterestRate: 23.33,
            priceLifeInsurance: 0,
            priceTac: 164.4,
            priceIof: 3,
            priceCustomerType: 0,
            priceStatus: 0,
            priceStartStatus: "2024-02-15T12:33:44.000Z",
            priceEndStatus: "2024-02-15T12:33:44.000Z",
            priceDaysIofIndex: 0.0082,
            priceInsuranceIndex: 0.1117,
            lackValue: 0,
            interestDay: 0,
            differentDays: -5,
            installmentStartDay: "2024-03-10T03:00:00.000Z",
            installmentEndDay: "2027-11-10T03:00:00.000Z",
            requestDate: "2024-02-15T03:00:00.000Z",
            aproveDate: "",
            installmentOption: 33
        },
        priceDetails: {
            requestedPlusTac: 1397.7329,
            mainIofValue: 5.3114,
            plusIof: 1403.0443,
            plusLack: 1397.7329,
            totalIof: 35.61,
            totalInsurance: 23.4197,
            cet: 24.7421,
            cetIndex: 672.011
        },
        loan: {
            loan: 101.3838,
            amountProposal: 100.0000,
            alimony: 558,
            totalInterest: 8.6,
            iof: 0.3838,
            taxAdministration: 1,
            pinStallmentValue: 54.3,
            fvTotalAmountLoanValue: 108.6,
            iProposalInterestMonth: 5,
            installmentQuantityLoan: 2,
        },
        interestInstallment: 5,
        quantityInstallment: 2,
        valueInstallment: 54.3,
        loanValue: 101.38,
        totalValue: 108.6
    },
    {
        uuid: "c03c2364-30a2-4b8a-28c1-23a730123c31",
        product: {
            uuid: "fdv8f9d889-frfe323-232frrf39v",
            name: "FINANCIAMENTO DE VEÍCULO LEVE",
            type: ProductTypeEnum.FINANCING,
        },
        requester: {
            uuid: "536fc99a-ad22-932b-80f8-669d8a3ec2b0",
            name: "LUIZ HENRIQUE FERREIRA DA SILVA",
            document: "47186572267",
            email: "teste@teste.com",
            phone: "11966656187",
            sex: "M",
            birthDate: "1990-10-23",
            motherName: "SILVIA FERREIRA ANJOS",
            fatherName: "SAMUEL LOPES DA SILVA",
            address: {
                zipcode: "06045-170",
                street: "Rua DOS DESBRAVADORES",
                number: "22956",
                complement: "",
                neighborhood: "VILLA YOLANDA",
                city: "OSASCO",
                state: "SP",
                country: "BRASIL"
            },
            account: {
                bankName: "Banco do Brasil",
                accountNumber: "146447",
                agencyNumber: "6798"
            },
        },
        proposalStatus: ProposalStatusEnum.REQUESTED,
        dateRegister: "2024-02-15T16:32:56.000Z",
        dateUpdated: "2024-02-15T12:33:44.000Z",
        dateDeleted: "",
        installments: [
            {
                dateInstallment: new Date(),
                insuranceBuy: 0.0749,
                numberInstallment: 1,
                interestInstallment: 4.3000,
                amortInstallment: 50.0000,
                installmentValue: 54.3000,
                amountDue: 54.3000,
                daysIofCount: 30.0000,
                daysIofIndex: 0.0082,
                insuranceIndex: 0.1117,
                insuranceBuyIndex: 0.073830
            },
            {
                dateInstallment: new Date(),
                insuranceBuy: 0.0383,
                numberInstallment: 2,
                interestInstallment: 4.3000,
                amortInstallment: 50.0000,
                installmentValue: 54.3000,
                amountDue: 0.0000,
                daysIofCount: 60.0000,
                daysIofIndex: 0.0082,
                insuranceIndex: 0.1117,
                insuranceBuyIndex: 0.073830
            }
        ],
        proposalNumber: "BRP-38138672854",
        proposalName: "202402823389164",
        price: {
            priceMaxInstallment: 33,
            priceMinInstallment: 33,
            priceInstallmentInterval: 1,
            priceMaxDaysIofCount: 0,
            priceInterestRate: 23.33,
            priceLifeInsurance: 0,
            priceTac: 164.4,
            priceIof: 3,
            priceCustomerType: 0,
            priceStatus: 0,
            priceStartStatus: "2024-02-15T12:33:44.000Z",
            priceEndStatus: "2024-02-15T12:33:44.000Z",
            priceDaysIofIndex: 0.0082,
            priceInsuranceIndex: 0.1117,
            lackValue: 0,
            interestDay: 0,
            differentDays: -5,
            installmentStartDay: "2024-03-10T03:00:00.000Z",
            installmentEndDay: "2027-11-10T03:00:00.000Z",
            requestDate: "2024-02-15T03:00:00.000Z",
            aproveDate: "",
            installmentOption: 33
        },
        priceDetails: {
            requestedPlusTac: 1397.7329,
            mainIofValue: 5.3114,
            plusIof: 1403.0443,
            plusLack: 1397.7329,
            totalIof: 35.61,
            totalInsurance: 23.4197,
            cet: 24.7421,
            cetIndex: 672.011
        },
        loan: {
            loan: 101.3838,
            amountProposal: 100.0000,
            alimony: 558,
            totalInterest: 8.6,
            iof: 0.3838,
            taxAdministration: 1,
            pinStallmentValue: 54.3,
            fvTotalAmountLoanValue: 108.6,
            iProposalInterestMonth: 5,
            installmentQuantityLoan: 2,
        },
        interestInstallment: 5,
        quantityInstallment: 2,
        valueInstallment: 54.3,
        loanValue: 101.38,
        totalValue: 108.6
    },
    {
        uuid: "c03c2364-30a2-4b8a-28c1-23a730123c31",
        product: {
            uuid: "fdv8f9d889-frfe323-232frrf39v",
            name: "FINANCIAMENTO DE VEÍCULO LEVE",
            type: ProductTypeEnum.FINANCING,
        },
        requester: {
            uuid: "536fc99a-ad22-932b-80f8-669d8a3ec2b0",
            name: "LUIZ HENRIQUE FERREIRA DA SILVA",
            document: "47186572267",
            email: "teste@teste.com",
            phone: "11966656187",
            sex: "M",
            birthDate: "1990-10-23",
            motherName: "SILVIA FERREIRA ANJOS",
            fatherName: "SAMUEL LOPES DA SILVA",
            address: {
                zipcode: "06045-170",
                street: "Rua DOS DESBRAVADORES",
                number: "22956",
                complement: "",
                neighborhood: "VILLA YOLANDA",
                city: "OSASCO",
                state: "SP",
                country: "BRASIL"
            },
            account: {
                bankName: "Banco do Brasil",
                accountNumber: "146447",
                agencyNumber: "6798"
            },
        },
        proposalStatus: ProposalStatusEnum.REQUESTED,
        dateRegister: "2024-02-15T16:32:56.000Z",
        dateUpdated: "2024-02-15T12:33:44.000Z",
        dateDeleted: "",
        installments: [
            {
                dateInstallment: new Date(),
                insuranceBuy: 0.0749,
                numberInstallment: 1,
                interestInstallment: 4.3000,
                amortInstallment: 50.0000,
                installmentValue: 54.3000,
                amountDue: 54.3000,
                daysIofCount: 30.0000,
                daysIofIndex: 0.0082,
                insuranceIndex: 0.1117,
                insuranceBuyIndex: 0.073830
            },
            {
                dateInstallment: new Date(),
                insuranceBuy: 0.0383,
                numberInstallment: 2,
                interestInstallment: 4.3000,
                amortInstallment: 50.0000,
                installmentValue: 54.3000,
                amountDue: 0.0000,
                daysIofCount: 60.0000,
                daysIofIndex: 0.0082,
                insuranceIndex: 0.1117,
                insuranceBuyIndex: 0.073830
            }
        ],
        proposalNumber: "BRP-38138672854",
        proposalName: "202402823389164",
        price: {
            priceMaxInstallment: 33,
            priceMinInstallment: 33,
            priceInstallmentInterval: 1,
            priceMaxDaysIofCount: 0,
            priceInterestRate: 23.33,
            priceLifeInsurance: 0,
            priceTac: 164.4,
            priceIof: 3,
            priceCustomerType: 0,
            priceStatus: 0,
            priceStartStatus: "2024-02-15T12:33:44.000Z",
            priceEndStatus: "2024-02-15T12:33:44.000Z",
            priceDaysIofIndex: 0.0082,
            priceInsuranceIndex: 0.1117,
            lackValue: 0,
            interestDay: 0,
            differentDays: -5,
            installmentStartDay: "2024-03-10T03:00:00.000Z",
            installmentEndDay: "2027-11-10T03:00:00.000Z",
            requestDate: "2024-02-15T03:00:00.000Z",
            aproveDate: "",
            installmentOption: 33
        },
        priceDetails: {
            requestedPlusTac: 1397.7329,
            mainIofValue: 5.3114,
            plusIof: 1403.0443,
            plusLack: 1397.7329,
            totalIof: 35.61,
            totalInsurance: 23.4197,
            cet: 24.7421,
            cetIndex: 672.011
        },
        loan: {
            loan: 101.3838,
            amountProposal: 100.0000,
            alimony: 558,
            totalInterest: 8.6,
            iof: 0.3838,
            taxAdministration: 1,
            pinStallmentValue: 54.3,
            fvTotalAmountLoanValue: 108.6,
            iProposalInterestMonth: 5,
            installmentQuantityLoan: 2,
        },
        interestInstallment: 5,
        quantityInstallment: 2,
        valueInstallment: 54.3,
        loanValue: 101.38,
        totalValue: 108.6
    },
    {
        uuid: "c03c2364-30a2-4b8a-28c1-23a730123c31",
        product: {
            uuid: "fdv8f9d889-frfe323-232frrf39v",
            name: "FINANCIAMENTO DE VEÍCULO LEVE",
            type: ProductTypeEnum.FINANCING,
        },
        requester: {
            uuid: "536fc99a-ad22-932b-80f8-669d8a3ec2b0",
            name: "LUIZ HENRIQUE FERREIRA DA SILVA",
            document: "47186572267",
            email: "teste@teste.com",
            phone: "11966656187",
            sex: "M",
            birthDate: "1990-10-23",
            motherName: "SILVIA FERREIRA ANJOS",
            fatherName: "SAMUEL LOPES DA SILVA",
            address: {
                zipcode: "06045-170",
                street: "Rua DOS DESBRAVADORES",
                number: "22956",
                complement: "",
                neighborhood: "VILLA YOLANDA",
                city: "OSASCO",
                state: "SP",
                country: "BRASIL"
            },
            account: {
                bankName: "Banco do Brasil",
                accountNumber: "146447",
                agencyNumber: "6798"
            },
        },
        proposalStatus: ProposalStatusEnum.REQUESTED,
        dateRegister: "2024-02-15T16:32:56.000Z",
        dateUpdated: "2024-02-15T12:33:44.000Z",
        dateDeleted: "",
        installments: [
            {
                dateInstallment: new Date(),
                insuranceBuy: 0.0749,
                numberInstallment: 1,
                interestInstallment: 4.3000,
                amortInstallment: 50.0000,
                installmentValue: 54.3000,
                amountDue: 54.3000,
                daysIofCount: 30.0000,
                daysIofIndex: 0.0082,
                insuranceIndex: 0.1117,
                insuranceBuyIndex: 0.073830
            },
            {
                dateInstallment: new Date(),
                insuranceBuy: 0.0383,
                numberInstallment: 2,
                interestInstallment: 4.3000,
                amortInstallment: 50.0000,
                installmentValue: 54.3000,
                amountDue: 0.0000,
                daysIofCount: 60.0000,
                daysIofIndex: 0.0082,
                insuranceIndex: 0.1117,
                insuranceBuyIndex: 0.073830
            }
        ],
        proposalNumber: "BRP-38138672854",
        proposalName: "202402823389164",
        price: {
            priceMaxInstallment: 33,
            priceMinInstallment: 33,
            priceInstallmentInterval: 1,
            priceMaxDaysIofCount: 0,
            priceInterestRate: 23.33,
            priceLifeInsurance: 0,
            priceTac: 164.4,
            priceIof: 3,
            priceCustomerType: 0,
            priceStatus: 0,
            priceStartStatus: "2024-02-15T12:33:44.000Z",
            priceEndStatus: "2024-02-15T12:33:44.000Z",
            priceDaysIofIndex: 0.0082,
            priceInsuranceIndex: 0.1117,
            lackValue: 0,
            interestDay: 0,
            differentDays: -5,
            installmentStartDay: "2024-03-10T03:00:00.000Z",
            installmentEndDay: "2027-11-10T03:00:00.000Z",
            requestDate: "2024-02-15T03:00:00.000Z",
            aproveDate: "",
            installmentOption: 33
        },
        priceDetails: {
            requestedPlusTac: 1397.7329,
            mainIofValue: 5.3114,
            plusIof: 1403.0443,
            plusLack: 1397.7329,
            totalIof: 35.61,
            totalInsurance: 23.4197,
            cet: 24.7421,
            cetIndex: 672.011
        },
        loan: {
            loan: 101.3838,
            amountProposal: 100.0000,
            alimony: 558,
            totalInterest: 8.6,
            iof: 0.3838,
            taxAdministration: 1,
            pinStallmentValue: 54.3,
            fvTotalAmountLoanValue: 108.6,
            iProposalInterestMonth: 5,
            installmentQuantityLoan: 2,
        },
        interestInstallment: 5,
        quantityInstallment: 2,
        valueInstallment: 54.3,
        loanValue: 101.38,
        totalValue: 108.6
    }
]

export const APPROVAL_HISTORY_MOCK: ApprovalHistoryDto[] = [
    {
        status: ProposalStatusEnum.REQUESTED,
        description: "Solicitado pelo APP",
        customerName: "LUIZ HENRIQUE FERREIRA DA SILVA",
        roleType: RoleTypeEnum.CLIENT,
        roleName: "LUIZ HENRIQUE FERREIRA DA SILVA",
        dateRegister: "2024-02-15T16:32:56.000Z"
    },
    {
        status: ProposalStatusEnum.APPROVED,
        description: "Proposta aprovada pelo comitê",
        customerName: "EDUARDO CAMPOS BRAGA",
        roleType: RoleTypeEnum.COMMITTEE,
        roleName: "EDUARDO CAMPOS BRAGA",
        dateRegister: "2024-02-17T16:32:56.000Z"
    }
]