import { Injectable } from '@angular/core';
import { ApiService } from '../../_base/_auth/api.service';
import { Observable } from 'rxjs';
import { IProposalCancelation } from 'src/app/utils/proposal-cancelation-types';


const SPE_VIEW_N_FGTS = '/spe-view-n-fgts';

@Injectable({
  providedIn: 'root'
})

export class SpeViewNFgtsProxy {

  constructor(private api: ApiService) {
  }

  getProposalsList(startDate: string, endDate: string): Observable<any> {
    const uri = `${SPE_VIEW_N_FGTS}/v1/find/date/${startDate}/${endDate}`;
    return this.api.get(uri);
  }

  getEventsListByUuid(uuidProposal: string): Observable<any> {
    const uri = `${SPE_VIEW_N_FGTS}/v1/find/aprovation-history/${uuidProposal}`;
    return this.api.get(uri);
  }

  getProposalByUuid(uuidProposal: string): Observable<any> {
    const uri = `${SPE_VIEW_N_FGTS}/v1/find/proposal?uuid=${uuidProposal}`;
    return this.api.get(uri);
  }

  getContractByUuid(uuidProposal: string): Observable<any> {
    const uri = `${SPE_VIEW_N_FGTS}/v1/contract/find?uuid=${uuidProposal}`;
    return this.api.get(uri);
  }

  getCCB(uuidProposal: string): Observable<any> {
    const uri = `${SPE_VIEW_N_FGTS}/v1/find/ccb?uuidProposal=${uuidProposal}`;
    return this.api.get(uri);
  }

  getCancelationRequestByProposalUuid(uuidProposal: string): Observable<IProposalCancelation> {
    const uri = `${SPE_VIEW_N_FGTS}/v1/cancel/findByUUid/${uuidProposal}`;
    return this.api.get(uri);
  }
}




