import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TableData } from "src/app/core/scfi/services/services-table/table.dto";
import { CustomerDto } from "src/app/core/scfi/services/services-customer/customer.dto";
import { MaskService } from "src/app/shared/services-ultil/masker-service/masker.service";
import { NgxSpinnerService } from "ngx-spinner";
import { CustomerService} from "src/app/core/scfi/services/services-customer/customer.service";
import { AbstractScfi } from "../../abstract-scfi";
import { CurrencyPipe, DatePipe } from "@angular/common";
import { TypeCustomerEnum } from 'src/app/core/scfi/services/services-product/type-customer.enum';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss']
})
export class CustomerListComponent extends AbstractScfi implements OnInit  {
  customers: CustomerDto[];
  tableData: TableData;

  constructor(
    private router: Router,
    private maskService: MaskService,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private cdr: ChangeDetectorRef,
    private customerService: CustomerService,
    protected currencyPipe: CurrencyPipe,
    protected datePipe: DatePipe
  ) {
    super(currencyPipe, datePipe);
  }

  ngOnInit() {
    this.findCustomerList();
  }

  public findCustomerList() {
    this.spinner.show()
    this.customerService.findCustomerList().subscribe({
      next: (customers: CustomerDto[]) => {
        this.customers = customers;
        this.tableData = this.generateTableData();
        this.cdr.detectChanges();
        this.spinner.hide()
      },
      error: () => {
        this.spinner.hide()
        this.showErrorModal('Erro ao buscar clientes!');
      }
    });
  }

  translateTypeCustomer(name: string): string {
    switch (name) {
      case 'Pessoa física':
        return 'PF';
      case 'Pessoa jurídica':
        return 'PJ';
      default:
        return '-';
    }
  }

  generateTableData(): TableData {
    return {
      header: ['Nome', 'Documento', 'Tipo de Cliente', 'Ações'],
      rows: this.customers.map((row) => {
        const typeCustomer = row.typeCustomer.type;
        const formattedDocument = row.document.length > 11 ? this.maskService.formatDocumentCNPJ(row.document) : this.maskService.formatDocumentCPF(row.document);
        
        return {
          fields: [
            row.name,
            formattedDocument,
            this.translateTypeCustomer(row.typeCustomer.name)
          ],
          actions: [
            { icon: './assets/media/icons/duotune/general/gen055.svg', onClick: () => this.redirectToNewOrEditCustomer(typeCustomer, row.uuid) },
            { icon: './assets/media/icons/duotune/general/gen027.svg', onClick: () => console.log('Redirecionar para modal de exclusão de cliente') }
          ]
        }
      }),
    }
  }

  redirectToNewOrEditCustomer(typeCustomer?: TypeCustomerEnum, uuid?: string) {
    if (uuid) {
      this.router.navigate(['/scfi/customers/edit-customer', typeCustomer, uuid]);
    } else {
      this.router.navigate(['/scfi/customers/new-customer']);
    }
  }
}
