import { MaskService } from 'src/app/shared/services-ultil/masker-service/masker.service';
import { MaskerModule } from 'src/app/shared/services-ultil/masker-service/masker-module.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BillsChargeModule } from './bills-charge/bills-charge.module';
import { ContractsLoansModule } from './contracts-loans/contracts-loans.module';
import { EmployeeDismissalModule } from './employee-dismissal/employee-dismissal.module';
import { ProposalsModule } from './proposals/proposals.module';
import { OperationsRoutes } from './operations.routing';
import { FormsModule } from '@angular/forms';
import { InterceptService } from 'src/app/core/_base/_auth/intercept/intercept.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';


@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    BillsChargeModule,
    ContractsLoansModule,
    EmployeeDismissalModule,
    ProposalsModule,
    OperationsRoutes,
    MaskerModule,
    FormsModule,  ],
  providers: [
    InterceptService,
    MaskService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
  ],
})
export class OperationsModule { }
