import { Injectable } from '@angular/core';

@Injectable()
export class TruncateService {
    constructor(        
    ) { }   
    truncate(value: number, decimalPlaces: number = 2): string {
        if (!value) return '0.00';
        const factor = Math.pow(10, decimalPlaces);
        const truncatedValue = Math.floor(value * factor) / factor;
        return `R$ ${truncatedValue.toFixed(decimalPlaces)}`;
    }
}
