import { SpeViewNBackofficeConsignedProxy } from 'src/app/core/proxy/view/spe-view-n-backoffice-consigned.proxy';
import { SpeNBackofficeConsignedProxy } from 'src/app/core/proxy/spe/spe-n-backoffice-consigned';
import { ContractsTableModel } from './contracts-table.model';
import { Observable } from 'rxjs';
import { Injectable, Injector } from "@angular/core";

import { ApiService } from "src/app/core/_base/_auth/api.service";

@Injectable({
  providedIn: 'root'
})

export class ContractsService {

  constructor(
    protected injector: Injector,
    protected api: ApiService,
    private speViewNBackofficeConsignedProxy: SpeViewNBackofficeConsignedProxy,
    private speNBackofficeConsignedProxy: SpeNBackofficeConsignedProxy
  ) {

  }

  public getContractsList(startDate: string, endDate: string, role: string | undefined, email: string): Observable<ContractsTableModel[]> {
    return this.speViewNBackofficeConsignedProxy.getContracstList(startDate, endDate, role, email);
  }


  public getContractUiid(uuid: string): Observable<any> {
    return this.speViewNBackofficeConsignedProxy.getAllAtributesContract(uuid);
  }


  public paidContracg(uuid: string, email:string): Observable<any> {
    return this.speNBackofficeConsignedProxy.paycontract(uuid, email);
  }

  public getContractTerms(uuidContract: string) {
    return this.speViewNBackofficeConsignedProxy.getContractTerms(uuidContract);
  }

  public getContractTermsCCB(uuidContract: string) {
    return this.speViewNBackofficeConsignedProxy.getContractTermsCCB(uuidContract);
  }

  paymantContracts(contracts: string[], email: string) {
    return this.speNBackofficeConsignedProxy.paymantContracts(contracts, email);
  }

  retrySendContract(uuidProposal:string) {
    return this.speNBackofficeConsignedProxy.retrySendContract(uuidProposal);
  }
}
