import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SimulationRoutes } from './simulation.routing';
import { SimulationFormComponent } from './simulation-form/simulation-form.component';
import { SimulationListComponent } from './simulation-list/simulation-list.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatOptionModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { UiCInputModule } from "src/app/shared/components/ui-c-input/ui-c-input.module";
import { InlineSVGModule } from "ng-inline-svg-2";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { UiCTableModule } from "src/app/shared/components/ui-c-table/ui-c-table.module";
import { InstallmentsTableModule } from 'src/app/shared/components/installments-table/installments-table.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { SimulationTableDetailsModule } from 'src/app/shared/components/simulation-table-details/simulation-table-details.module';
import { SimulationTableFinanceModule } from 'src/app/shared/components/simulation-table-finance/simulation-table-finance.module';
import { SimulationInstallmentsDetailsModule } from 'src/app/shared/components/simulation-installments-details/simulation-installments-details.module';

@NgModule({
    imports: [
        CommonModule,
        SimulationRoutes,
        FormsModule,
        MatOptionModule,
        MatSelectModule,
        UiCInputModule,
        InlineSVGModule,
        MatSlideToggleModule,
        UiCTableModule,
        ReactiveFormsModule,
        InstallmentsTableModule,
        MatExpansionModule,
        SimulationTableDetailsModule,
        SimulationTableFinanceModule,
        SimulationInstallmentsDetailsModule
    ],
  declarations: [SimulationFormComponent, SimulationListComponent]
})
export class SimulationModule { }
