import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ScfiCalculatorResponseType } from 'src/app/core/scfi/services/services-simulation/dto/calculator.dto';
import { SimulationTypeTac } from 'src/app/core/scfi/services/services-simulation/type-tac.enum';
import { AbstractScfi } from 'src/app/pages/scfi/abstract-scfi';

@Component({
    selector: 'simulation-table-details',
    templateUrl: './simulation-table-details.component.html',
    styleUrls: ['./simulation-table-details.component.scss']
})
export class SimulationTableDetailsComponent extends AbstractScfi implements OnInit {
    @Input() sfiCalculatorResponseType: ScfiCalculatorResponseType;
    public simulationDetails: ScfiCalculatorResponseType;

    constructor(
        protected currencyPipe: CurrencyPipe,
        protected datePipe: DatePipe
    ) {
        super(currencyPipe, datePipe)
    }

    ngOnInit() {
        this.simulationDetails = this.sfiCalculatorResponseType;
    }

    public formatTacValue(value: number, typeTac: SimulationTypeTac): string {
        switch (typeTac) {
            case SimulationTypeTac.percentual:
                return this.formatValueToPercent(value);
            case SimulationTypeTac.fixo:
                return this.formatValueToMoney(value);
            default:
                return value.toString();
        }
    }
}
