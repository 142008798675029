<div id="funding-form-component">
  <h1 class="page-title mb-4">{{ isEditMode ? 'Alteração de Fundo' : 'Cadastro de Fundo' }}</h1>

  <div class="d-flex justify-content-end mb-4">
    <button type="button" class="btn btn-warning" (click)="backToLastPage()">
      <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr074.svg'" class="svg-icon svg-icon-1 me-4"></span>
      <span class="pt-2">Voltar</span>
    </button>
  </div>

  <form [formGroup]="formFunding">
    <section class="card mb-4 shadow-sm">
      <div class="card-header border-0 d-flex align-items-center justify-content-start">
        <span [inlineSVG]="'./assets/media/icons/duotune/general/gen045.svg'"
          class="svg-icon svg-icon-2tx svg-icon-gray-800 me-4"></span>
        <h3 class="fw-bolder card-title mt-3">Informações Gerais</h3>
      </div>

      <div class="card-body border-top p-9">
        <div class="d-flex">
          <div class="col-3 px-10">
            <app-ui-c-input [form]="formFunding" [errorMessages]="errorMessagesFunding" [formName]="'name'"
              [name]="'Nome:'" [type]="'text'" [placeholder]="'Digite o nome do fundo'"></app-ui-c-input>
          </div>
          <div class="col-3 px-10">
            <app-ui-c-input [form]="formFunding" [errorMessages]="errorMessagesFunding" [formName]="'negotiatedRate'"
              [name]="'Taxa Negociada:'" [isPercent]="true"></app-ui-c-input>
          </div>
          <div class="col-3 px-10">
            <app-ui-c-input [form]="formFunding" [errorMessages]="errorMessagesFunding" [formName]="'capital'"
              [name]="'Capital:'" [isMoney]="true"></app-ui-c-input>
          </div>
          <div class="col-3 px-10">
            <label class="form-check form-check-inline form-check-solid mt-15">
              <input class="form-check-input" name="isExternal" type="checkbox" formControlName="isExternal" />
              <span class="form-label fs-6 fw-bolder text-dark">Fundo Externo</span>
            </label>
          </div>
        </div>
      </div>
    </section>

    <section class="card mb-4 shadow-sm" *ngIf="formFunding.get('isExternal')?.value">
      <div class="card-header border-0 d-flex align-items-center justify-content-start">
        <span [inlineSVG]="'./assets/media/icons/duotune/coding/cod007.svg'"
          class="svg-icon svg-icon-2tx svg-icon-gray-800 me-4"></span>
        <h3 class="fw-bolder card-title mt-3">Dados da Integração (Order Buy)</h3>
      </div>

      <ng-container formGroupName="integrationData">
        <div class="card-body border-top p-9">
          <div class="d-flex">
            <div class="col-3 px-10">
              <app-ui-c-input [form]="integrationDataForm" [errorMessages]="errorMessagesFunding"
                [formName]="'identification'" [name]="'Identificação:'" [type]="'text'"></app-ui-c-input>
            </div>
            <div class="col-3 px-10">
              <app-ui-c-input [form]="integrationDataForm" [errorMessages]="errorMessagesFunding" [formName]="'manager'"
                [name]="'Gestora:'" [type]="'text'"></app-ui-c-input>
            </div>
          </div>
        </div>
      </ng-container>
    </section>
  </form>

  <div class="d-flex justify-content-end mb-10">
    <button type="button" class="btn btn-primary" [disabled]="formFunding.invalid" (click)="saveFunding()">
      Salvar
    </button>
  </div>

  <app-funding-extract *ngIf="isEditMode"></app-funding-extract>
</div>