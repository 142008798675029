import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { MaskService } from 'src/app/shared/services-ultil/masker-service/masker.service';
import Swal from 'sweetalert2';

import { InvoiceService } from '../services-api/invoice-service';
import { UserInfoService } from 'src/app/core/_base/_auth/user-info.service';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-billing-invoices-list',
  templateUrl: './billing-invoices-list.component.html',
  styleUrls: ['./billing-invoices-list.component.scss'],
})
export class BillingInvoicesListComponent implements OnInit {
  dataSource!: MatTableDataSource<any>;
  matSort: any;

  displayedColumns = [
    'id',
    'companie',
    'contract',
    'invoice',
    'dateRegister',
    'dueDate',
    'totalValue',
    'invoiceStatus',
    'sendDate',
    'action',
  ];
  cardInfo: any[];
  cardList: any;
  intervalDays = 30

  roleUser: string | undefined;

  companieList: Array<{ uuid: string; company: string }>;

  email: any = '';
  uuidCompanie: string = "";
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  constructor(
    private invoiceServices: InvoiceService,
    private userInfoService: UserInfoService,
    private spinner: NgxSpinnerService,
    public maskerService: MaskService,
    protected router: Router,
    private cdr: ChangeDetectorRef,
    private paginatorIntl: MatPaginatorIntl
  ) {
    this.getPaginatorIntl(this.paginatorIntl);
    this.spinner.show();
    this.roleUser = this.userInfoService.verifyRole();
  }

  async ngOnInit() {
    const currencydate = new Date();
    const startDate = moment(new Date().setDate(currencydate.getDate() - this.intervalDays)).format('YYYY-MM-DD');
    const endDate = moment(currencydate).format('YYYY-MM-DD');
    setTimeout(() => {
      this.userInfoService.getInfoProfile().then((res) => {
        if (res) {
          this.email = res.email;
          if (this.roleUser == "liquidation") {
            this.getCompaniesAllList(startDate, endDate, "liquidation", this.email);
          } if (this.roleUser == "audit") {
            this.getCompaniesAllList(startDate, endDate, "audit", this.email);
          }
        } else {
          this.email = '';
        }
      });
    }, 2500)
  }

  emitDate(event: any) {
    if (this.uuidCompanie.length > 0) {
      if (this.uuidCompanie == "ALL") {
        this.alert('Sucesso!', 'Filtro aplicado com sucesso!', 'success', 'Fechar')
        this.getCompaniesAllList(event.startDate, event.endDate, "liquidation", this.email)
      } else {
        this.getCompaniesList(event.startDate, event.endDate, this.uuidCompanie)
      }
    } if (this.roleUser == "audit") {
      this.alert('Sucesso!', 'Filtro aplicado com sucesso!', 'success', 'Fechar')
      this.getCompaniesAllList(event.startDate, event.endDate, this.roleUser, this.email)
    }
  }


  async download(uuidIvoice: string) {
    /* console.log('IIISIAOA', uuidIvoice) */
    this.spinner.show();
    try {
      (await this.invoiceServices.findInvoiceFileXls(uuidIvoice))
        .subscribe({
          next: (result) => {
            this.spinner.hide();
            const blob = new Blob([result], { type: 'application/vnd.ms-excel' });
            FileSaver.saveAs(blob, `lista-de-contratos-da-invoice.xlsx`);
          },
          error: (erro) => {
            this.spinner.hide();
          }
        })
    } catch (error) {
      this.spinner.hide();
    }
  }

  emitCompany(event: any) {
    this.uuidCompanie = event;
  }

  getCompaniesList(startDate: string, endDate: string, uuidCompanie: string) {
    this.invoiceServices
      .getCompaniesList(startDate, endDate, uuidCompanie)
      .subscribe(
        (res) => {
          this.spinner.show();
          if (res) {
            this.cardInfo = res.cards;
            this.spinner.hide();
            this.dataSource = new MatTableDataSource(res.invoices);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.matSort;
            this.cdr.detectChanges();
            this.alert('Sucesso!', 'Filtro aplicado com sucesso!', 'success', 'Fechar')
          }
        },
        (err) => {
          this.alert('Atenção!', 'Erro ao filtrar! tente novamente!', 'warning', 'OK')
          this.spinner.hide();
        }
      );
  }

  async getCompaniesAllList(startDate: string, endDate: string, role: string, email: string) {
    this.invoiceServices
      .getCompaniesAllList(startDate, endDate, role, email)
      .subscribe(
        (res) => {
          if (res) {
            this.cardInfo = res.cards;
            this.spinner.hide();
            this.dataSource = new MatTableDataSource(res.invoices);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.matSort;
            this.cdr.detectChanges();
          }
        },
        (err) => {
          this.alert('Atenção!', 'Erro! tente novamente!', 'warning', 'OK')
          this.spinner.hide();
        }
      );
  }

  prepareColorStatus(status: string) {
    switch (status) {
      case 'PREPARED':
        return 'bg-primary'

      case 'GENERATED':
        return 'bg-warning text-dark'

      case 'APPROVED':
        return 'bg-secondary text-dark'

      case 'PAID':
        return 'bg-success'
    }
  }

  prepareNameStatus(status: string) {
    switch (status) {
      case 'PREPARED':
        return 'Preparado'

      case 'GENERATED':
        return 'Gerado'

      case 'APPROVED':
        return 'Aprovada'

      case 'PAID':
        return 'Paga'
    }
  }


  filterData($event: any) {
    this.dataSource.filter = $event.target.value;
  }

  viewDetails(elem: any) {
    this.router.navigate([`/admin/billing-invoices/${elem.uuid}/company/${elem.uuidcompany}/edit`]);
  }

  alert(title: any, text: any, icon: any, confirmButtonText: any) {
    return Swal.fire({
      title,
      text,
      icon,
      confirmButtonText
    })
  }

  isLiquidation(): boolean {
    return !this.userInfoService.isLiquidation();
  }

  getPaginatorIntl(paginator: MatPaginatorIntl) {
    paginator.itemsPerPageLabel = 'Items por página';
    paginator.lastPageLabel = 'Página Anterior';
    paginator.nextPageLabel = 'Próxima Página';
    paginator.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return `0 de ${length}`;
      }

      length = Math.max(length, 0);

      const startIndex = page * pageSize;

      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

      return `${startIndex + 1} - ${endIndex} de ${length}`;
    };
  }
}
