import { StorageService } from 'src/app/shared/services-ultil/storage/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, of, take, throwError } from 'rxjs';
import { SpeNOpenAccountProxy } from 'src/app/core/proxy/spe/spe-n-open-account.proxy';
import { CompanyServices } from '../services-apis/companies.services';
import { ConsignedPartners } from '../services-apis/consigned-partners.enum';

import { OnDestroy } from '@angular/core';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-companies-forms',
  templateUrl: './companies-forms.component.html',
  styleUrls: ['./companies-forms.component.scss']
})
export class CompaniesFormsComponent implements OnInit, OnDestroy {

  public errorMessagesCompany: any;
  public listUf: any;
  public resourceForm: FormGroup;
  public resource: any;
  public uuidCompany: string;
  isEdit: boolean = false;
  isView: any = false;
  companyName: any;
  partnersList: any;


  constructor(
    protected companyServices: CompanyServices,
    protected injector: Injector,
    public formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private speNOpenAccountProxy: SpeNOpenAccountProxy,
    protected activeRouter: ActivatedRoute,
    private router: Router,
    private storageService: StorageService
  ) { }

  ngOnDestroy(): void {
    this.storageService.removeData("isView");
  }

  ngOnInit(): void {
    this.partnersList = this.companyServices.enumTypeToArray(ConsignedPartners);

    this.listUf = this.speNOpenAccountProxy.getListUf();
    this.isView = this.storageService.getData("isView");
    this.activeRouter.paramMap.pipe(take(1))
      .subscribe((res: any) => {
        this.uuidCompany = res.params['id'];
        if (this.uuidCompany) {
          this.spinner.show();
          this.isEdit = true;
          this.companyServices.getCompany(this.uuidCompany).subscribe((company: any) => {
            this.spinner.hide();
            this.companyName = company.name;
            this.companyServices.sycForm(company);
          })
        }
      });
    this.buildResourceForm();
  }

  save() {
    this.spinner.show();
    this.companyServices.createCompany()
      .pipe(take(1)).subscribe(res => {        
        this.spinner.hide();

        if (res.statusCode === 500) {
          this.alert('Atenção!', 'Erro ao processar! tente novamente!', 'warning', 'OK');
          /* this.save(); */
        } else
          if (res) {
            this.alert('Sucesso!', 'Empresa criada com sucesso!', 'success', 'OK')
              .then(res => {
                if (res.isConfirmed) {
                  this.router.navigate(['/admin/companies']);
                }
              })
          } else {
            this.spinner.hide();
            this.alert('Atenção!', 'Erro ao processar! tente novamente!', 'warning', 'OK');
          }
      });
  }

  protected buildResourceForm() {
    this.errorMessagesCompany = this.companyServices.getFormsErrors();
    this.resourceForm = this.companyServices.createFormCompany();
  }

  protected creationPageTitle(): string {
    if (this.isView) {
      return "Detalhes da empresa";
    } else if (this.isEdit) return `Editando Empresa: ${this.companyName}`
    else {
      return "Cadastro de Nova Empresa";
    }
  }


  onChangePostal(e: any) {
    this.spinner.show();
    this.speNOpenAccountProxy.searchPostalCode(e.target.value)
      .pipe(take(1)).subscribe(res => {
        if (res != null) {
          this.companyServices.updateAddressCompany(this.resourceForm, res);
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
      })
  }

  updateCompany() {
    this.companyServices.editCompany()
      .pipe(take(1)).subscribe((res:any) => {
        this.spinner.hide();
        if (res) {
          this.alert('Sucesso!', 'Empresa alterada com sucesso!', 'success', 'OK')
            .then(res => {
              if (res.isConfirmed) {
                this.router.navigate(['/admin/companies']);
              }
            })
        } else {
          this.spinner.hide();
          this.alert('Atenção!', 'Erro ao processar! tente novamente!', 'warning', 'OK')
        }
      });
  }

  alert(title: any, text: any, icon: any, confirmButtonText: any) {
    return Swal.fire({
      title,
      text,
      icon,
      confirmButtonText
    })
  }

}
