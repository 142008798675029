import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InlineSVGModule } from "ng-inline-svg-2";
import { UiCTableModule } from 'src/app/shared/components/ui-c-table/ui-c-table.module';
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { SharedModule } from "src/app/shared/shared.module";
import { UiCInputModule } from "src/app/shared/components/ui-c-input/ui-c-input.module";
import {AcceptProposalRoutes} from "./accept-proposal.routing";
import {AcceptProposalComponent} from "./accept-proposal.component";
import {FormsModule} from "@angular/forms";
import {TokenAcceptComponent} from "src/app/shared/components/token-accept-proposal/token-accept.component";
import {MatDialogModule} from "@angular/material/dialog";

@NgModule({
    imports: [
        CommonModule,
        InlineSVGModule,
        UiCTableModule,
        MatSlideToggleModule,
        InlineSVGModule,
        SharedModule,
        UiCInputModule,
        AcceptProposalRoutes,
        FormsModule,
        MatDialogModule
    ],
  declarations: [
    AcceptProposalComponent,
  ]
})
export class AcceptProposalModule { }
