import { Base64Service } from 'src/app/shared/services-ultil/base64-service/base64.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MaskService } from 'src/app/shared/services-ultil/masker-service/masker.service';
import { Component, OnInit, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { BaseResourceListComponent } from 'src/app/shared/components/base-resource-list/base-resource-list.component';
import { EmployeeServices } from '../services-apis/employees-services';
import * as moment from 'moment';
import { Employee } from '../services-apis/employees-model';
import { StorageService } from 'src/app/shared/services-ultil/storage/storage.service';
import { Router } from '@angular/router';
import { UserInfoService } from 'src/app/core/_base/_auth/user-info.service';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import Swal from 'sweetalert2';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-employees-list',
  templateUrl: './employees-list.component.html',
  styleUrls: ['./employees-list.component.scss']
})
export class EmployeesListComponent extends BaseResourceListComponent<Employee> implements OnInit {
  displayedColumns = ['name', 'identity', 'identityGov', 'ocuppation', 'dateRegister', 'customerStatus', 'action']
  file: { base64: string; title: any; };
  userInfo: any;
  email: any;
  isAudit: boolean = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  startDate: string;
  endDate: string;
  defaultDate: number = 90;

  constructor(
    private employeeServices: EmployeeServices,
    public maskerService: MaskService,
    private spinner: NgxSpinnerService,
    private storageService: StorageService,
    private router: Router,
    private base64Service: Base64Service,
    private userInfoService: UserInfoService,
    private paginatorIntl: MatPaginatorIntl,
    private cdr: ChangeDetectorRef,
    // private storageSerice: StorageService,
  ) {
    super(employeeServices, "");
    this.getPaginatorIntl(this.paginatorIntl);
  }

  async ngOnInit() {
    this.loadUserInfo()
    this.spinner.show();

    const currencydate = new Date();
    // let filterLS = this.storageSerice.getData(this.router.url) ? this.storageSerice.getData(this.router.url) : '' ;

    // if(filterLS){
    //   let aux = JSON.parse(filterLS);
    //   this.startDate = aux.startDate;
    //   this.endDate = aux.endDate;
    // }else{
      this.startDate = moment(new Date().setFullYear(currencydate.getFullYear() - 1)).format('YYYY-MM-DD');
      this.endDate = moment(currencydate).format('YYYY-MM-DD');
    // }

    this.isAudit = this.userInfoService.isUserInRole('bo-consigned-audit');
    let role = this.userInfoService.verifyRole();

    await this.userInfoService.getInfoProfile().then((res) => {
      if (res) {
        this.email = res.email;
      } else {
        this.email = "";
      }
    })

    this.userInfoService.getInfoProfile().then((res: any) => {        
      if(this.userInfoService.isAttendant()) {
        role = 'liquidation'
      }
      this.employeeServices.getEmployeesList(this.startDate, this.endDate, role, this.email).subscribe(
        res => {
          if (res) {
            this.spinner.hide();
            this.setDatasource(res);
            this.dataSource = new MatTableDataSource(res);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.matSort;
            this.cdr.detectChanges();
          }
        }
      )
    })
  }

  getPaginatorIntl(paginator: MatPaginatorIntl) {
    paginator.itemsPerPageLabel = 'Items por página';
    paginator.lastPageLabel = 'Página Anterior';
    paginator.nextPageLabel = 'Próxima Página';
    paginator.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return `0 de ${length}`;
      }

      length = Math.max(length, 0);

      const startIndex = page * pageSize;

      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

      return `${startIndex + 1} - ${endIndex} de ${length}`;
    };
  }

  checkStatus(status: string) {
    switch (status) {
      case 'ACTIVE':
        return {
          color: 'text-bg-success white',
          status: "Ativo"
        }

      case 'AWAY':
        return {
          color: 'text-bg-primary white',
          status: "Afastado"
        }

      case 'FIRED':
        return {
          color: 'text-bg-primary white',
          status: "Desligado"
        }

      case 'RETIRED':
        return {
          color: 'text-bg-danger white',
          status: "Aposentado"
        }
      default:
        return {
          color: 'text-bg-success white',
          status: "Ativo"
        }
    }
  }


  emitDate(event: any) {
    let role = this.userInfoService.verifyRole();

    this.spinner.show();
    this.employeeServices.getEmployeesList(event.startDate, event.endDate, role, this.email).subscribe(
      res => {
        if (res) {
          this.spinner.hide();
          this.setDatasource(res);
        }
      },
      (err) => {
        this.spinner.hide()
      }
    )
  }

  deleteEmployee(uuid: string) {
    if (window.confirm("Tem certeza que deseja excluir este item?")) {
      this.employeeServices.deleteEmployee(uuid);
      window.location.reload();
    }
  }

  viewDetails(uuid: string) {
    this.storageService.saveData("isView", "true")
    this.router.navigate([`admin/employees/${uuid}/edit`]);
  }

  public uploadFile(fileList: any) {
    let fileSelected = fileList.files.item(0);

    this.base64Service
      .convertFileToBase64(fileSelected)
      .then((data) => {
        this.employeeServices.uploadEmployess({
          "base64": data,
          "fileName": fileSelected.name,
          "authorizationCode": ""
        }).subscribe(res => {
          if (res.isExecuted) {
            this.alert('Sucesso!', res.message, 'success', 'OK')
              .then(result => {
                if (result.isConfirmed) {
                  window.location.reload();;
                }
              })
          } else {
            this.alert('Atenção!', res.message, 'warning', 'OK');
          }
        })
      })
      .catch((err) => {
        console.error('Erro no converte', err);
      });
  }

  loadUserInfo() {

  }

  alert(title: any, text: any, icon: any, confirmButtonText: any) {
    return Swal.fire({
      title,
      text,
      icon,
      confirmButtonText
    })
  }

}
