<div id="menu" #menu class="menu menu-rounded menu-column menu-title-gray-700 menu-icon-gray-500 menu-arrow-gray-500 menu-bullet-gray-500 menu-arrow-gray-500 fw-semibold w-250px" data-kt-menu="true">
  
  <div *ngFor="let itemMenu of menuList" class="menu-item menu-sub-indention menu-accordion" data-kt-menu-trigger="click">
    <!-- PAI -->
      <a class="menu-link py-3" routerLinkActive="active-item">
          <span class="menu-icon">
              <span [inlineSVG]="prepareIcon(itemMenu.icon)" class="svg-icon svg-icon-2x"></span>
          </span>
          <p class="menu-title">{{itemMenu.label}}</p>
          <span *ngIf="verifyIsArray(itemMenu.url)"  class="menu-arrow"></span>
      </a>
      <!-- PAI -->
      
      <div *ngIf="verifyIsArray(itemMenu.url)"  class="menu-sub menu-sub-accordion pt-3" >
                             
          <div *ngFor="let subMenu of itemMenu.url"  class="menu-item menu-accordion">
              <!-- SUB 1 -->  
              <a routerLink="{{verifyIsArray(subMenu.url) ? '' : subMenu.url}}" routerLinkActive="active-item" class="menu-link py-3">
                <span class="menu-icon">
                    <span [inlineSVG]="prepareIcon(subMenu.icon)" class="svg-icon svg-icon-2x"></span>
                </span>
                  <p class="menu-title">{{subMenu.label}}</p>
                  <span *ngIf="verifyIsArray(subMenu.url)"  class="menu-arrow"></span>
              </a>
              <!-- SUB 1 -->  
              
             <div *ngIf="verifyIsArray(subMenu.url)"  class="menu-sub menu-sub-accordion pt-3">
                  <!-- SUB 2 -->  
                  <div *ngFor="let subItem of subMenu.url" class="menu-item">
                      <a routerLink="{{verifyIsArray(subItem.url) ? '' : subItem.url}}" routerLinkActive="active-item" class="menu-link py-3">
                          <span class="menu-bullet">
                              <span class="bullet bullet-dot"></span>
                          </span>
                          <p class="menu-title">{{subMenu.label}}</p>
                      </a>
                  </div>          
                  <!-- SUB 2 -->             
              </div>
          </div>
      </div>    

  </div>  
</div>
