<div id="contract-list-component">

    <!--Título-->
    <h1 class="page-title mb-10"> Contratos do Cliente </h1>

    <!--Tabela-->
    <ng-container *ngIf="tableData">
        <ui-c-table [tableData]="tableData"></ui-c-table>
    </ng-container>

</div>
