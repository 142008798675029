<div id="doc-list-component">

    <!--Título-->
    <h1 class="page-title mb-10"> Lista de Dados de Clientes </h1>

    <!--Botão-->
    <div id="add-doc-btn-container">
        <button type="button" class="btn btn-success px-10 mb-4" id="add-doc-btn" (click)="redirectToNewOrEditData()">
            Criar Dados
        </button>
    </div>

    <!--Tabela-->
    <ng-container *ngIf="tableData">
        <ui-c-table [tableData]="tableData"></ui-c-table>
    </ng-container>

</div>
