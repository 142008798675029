import { BaseModel } from "src/app/shared/models/base-model";

export class Employee extends BaseModel {

  public uuidCompany?: string;
  public customerData?: EmployeeDataModel;
  public customerAddress?: EmployeeAddressModel;
  public work?: EmployeeWorkModel;
  public benefits?: EmployeeBenefitsModel;
  public loan?: EmployeeLoanModel;
  public customersType?: string;
  public customerStatus?: EmployeeEnum;
  public dateRegister?: Date;
  public dateUpdated?: Date;
  public deleted?: boolean;
  public uuiduserlastupdateregister?: string;
  public isImportation: boolean;

  static fromJson(jsonData: any): Employee {
    return Object.assign(new Employee(), jsonData);
  }
}


export class EmployeeWorkModel {
  public companyName: string;
  public streetWork: string;
  public workNumber: string;
  public complementWork: string;
  public neighborhoodWork: string;
  public zipCodeWork: string;
  public cityWork: string;
  public ufWork: string;
  public dddWork: string;
  public phoneWork: string;
  public emailWork: string;
  public ocuppation: string;
  public sector: string;
  public registration: string;
  public administrationDate: string;
  public salary: any;
  public bankNumber: string;
  public bankName: string;
  public agency: string;
  public bankAccount: string;
  public inss: string;
  public incomeTax: string;
  public unionsdues: string;
  public managerResponsible: string;
  public cellPhoneManagerResponsible: string;
  public responsibleManagerEmail: string;
  public bankAccountType: string;
  public responsibleIdentityGov: string;
}

export class EmployeeLoanModel {
  public loan?: string;
  public consignableValue?: string;
  public consignablePercent?: string;
}

export class EmployeeDataModel {
  public fullName?: string;
  public identityGov?: string;
  public identity?: string;
  public birthDate?: string;
  public fatherName?: string;
  public motherName?: string;
  public dddCellPhone?: string;
  public homePhone?: string;
  public email?: string;
  public sex?: string;
  public naturalNess?: string;
  public maritalStatus?: MaritalStatusEnum;
  public nationality?: string;
  public dependentNumber?: string;
  public ufIdentity?: string;
  public identityIssueDate?: string;
}

export class EmployeeBenefitsModel {
  public alimony?: string;
  public healthPlan?: string;
  public dentalPlan?: string;
  public lifeInsurance?: string;
  public otherDiscounts?: string;
  public courtCharges?: string;
  public fineInstallment?: string;
  public collisionInstallment?: string;
  public responsibilityPortion?: string;
  public installmentProducts?: string;
  public otherInstallment?: string;
  public pmtUrbano?: number;
}

export class EmployeeAddressModel {
  public residentialStreet?: string;
  public residenceNumber?: string;
  public residenceComplement?: string;
  public residentialNeighborhood?: string;
  public zipCodeResidence?: string;
  public ufResidence?: string;
  public homecity?: string;
}

export enum EmployeeEnum {
  ACTIVE = "ACTIVE",
  AWAY = "AWAY",
  FIRED = "FIRED",
  RETIRED = "RETIRED"
}

export enum MaritalStatusEnum {
  MARRIED = "MARRIED",
  DIVORCED = "DIVORCED",
  SINGLE = "SINGLE",
  WIDOWER = "WIDOWER"
}
