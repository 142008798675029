import { OnInit, AfterContentChecked, Injector, Directive } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";

import { BaseResourceModel } from "../../models/base-resource.model";
import { BaseResourceService } from "../../base-service/base-resource.service";

import { switchMap } from "rxjs/operators";

//import toastr from "toastr";


@Directive()
export abstract class BaseResourceFormComponent<T extends BaseResourceModel> implements OnInit, AfterContentChecked {

  currentAction!: string;
  resourceForm: FormGroup = new FormGroup({});
  pageTitle!: string;
  serverErrorMessages: string[] = [];
  submittingForm: boolean = false;

  protected route: ActivatedRoute;
  protected router: Router;
  protected formBuilder: FormBuilder;

  constructor(
    protected injector: Injector,
    public resource: T,
    protected resourceService: BaseResourceService<T>,
    protected jsonDataToResourceFn: (jsonData: any) => T
  ) {
    this.route = this.injector.get(ActivatedRoute);
    this.router = this.injector.get(Router);
    this.formBuilder = this.injector.get(FormBuilder);


  }

  ngOnInit() {
   
    // this.setCurrentAction();
    this.buildResourceForm();
    // this.loadResource();
  }

  ngAfterContentChecked() {
    this.setPageTitle();
  }

  submitForm() {
    this.setCurrentAction();
    this.submittingForm = true;
    if (this.currentAction == "new")
      this.createResource();
    else // currentAction == "edit"
      this.updateResource();
  }

  // PRIVATE METHODS
  protected setCurrentAction() {

    if (this.route.snapshot.url[0].path == "new")
      this.currentAction = "new"
    else
      this.currentAction = "edit"
  }

  // protected loadResource() {
  //   if (this.currentAction == "edit") {
  //     this.route.paramMap.pipe(
  //       switchMap(params => this.resourceService.getAllAtributesBtUuid(params.get("id")  || ``))
  //     ).subscribe(
  //       (response: any) => {
  //         console.log(response)
  //         this.resource = response;
  //         this.resourceForm.patchValue(response);
  //       },
  //     );
  //   }
  // }

  protected setPageTitle() {
    if (this.currentAction == 'new')
      this.pageTitle = this.creationPageTitle();
    else {
      this.pageTitle = this.editionPageTitle();
    }
  }

  protected creationPageTitle(): string {
    return "Novo"
  }

  protected editionPageTitle(): string {
    return "Edição"
  }


  protected createResource() {
    const resource: T = this.jsonDataToResourceFn(this.resourceForm.value);

    /* this.resourceService.create(resource)
      .subscribe(
        resource => this.actionsForSuccess(resource),
        error => this.actionsForError(error)
      ) */
  }


  protected updateResource() {
    const resource: T = this.jsonDataToResourceFn(this.resourceForm.value);

    this.resourceForm.patchValue(resource);
    //console.log(this.resourceForm);

    this.resourceService.update(resource)
      .subscribe(
        resource => this.actionsForSuccess(resource),
        error => this.actionsForError(error)

      );
  }


  protected actionsForSuccess(resource: any) {
    alert("Atualização processada com sucesso!");
    //toastr.success
    const baseComponentPath: string = this.route.snapshot.url[0].path;

    // redirect/reload component page
    // this.router.navigateByUrl(baseComponentPath, {skipLocationChange: true}).then(
    //  () => this.router.navigate([baseComponentPath, resource.id, "edit"])
    //)
  }


  protected actionsForError(error: { status: number; _body: string; }) {
    alert("Ocorreu um erro ao processar a sua solicitação!");
    //toastr.error
    this.submittingForm = false;

    if (error.status === 422)
      this.serverErrorMessages = JSON.parse(error._body).errors;
    else
      this.serverErrorMessages = ["Falha na comunicação com o servidor. Por favor, tente mais tarde."]
  }


  protected abstract buildResourceForm(): void;
}
