<!-- <app-bread-crumb [items]="[{text: 'Propostas', link: '/proposals'}, {text: pageTitle}]"></app-bread-crumb> -->

<app-page-header [page-title]="creationPageTitle()" button-text="Voltar" button-link="/operations/proposals/"
  button-class="btn-danger">
</app-page-header>

<div class="container-fluid px-0 mt-4 proposal-forms">
  <form [formGroup]="resourceForm">

    <div class="card g-12 disabled">
      <div class="card-header">
        <h3 class="card-title">Dados para Simulação</h3>
      </div>
      <div class="card-body">
        <!-- Linha 1-->
        <div class="row">
          <div class="col-md-3">
            <app-ui-c-input [name]="'CPF Colaborador'" [form]="resourceForm" [errorMessages]="errorMessagesProposal"
              [formName]="'employeeCpf'" [type]="'text'" [placeholder]="'Digite o CPF...'" [isCPF]="true">
            </app-ui-c-input>
          </div>
          <div class="col-md-3">
            <app-ui-c-input [name]="'Nome Colaborador'" [form]="resourceForm" [errorMessages]="errorMessagesProposal"
              [formName]="'name'" [type]="'text'" [placeholder]="'Digite o nome...'">
            </app-ui-c-input>
          </div>
          <div class="col-md-3">
            <app-ui-c-input [name]="'Empresa'" [form]="resourceForm" [errorMessages]="errorMessagesProposal"
              [formName]="'company'" [type]="'text'" [placeholder]="'Digite a empresa...'">
            </app-ui-c-input>
          </div>
          <div class="col-md-3">
            <app-ui-c-input [name]="'Admissão'" [form]="resourceForm" [errorMessages]="errorMessagesProposal"
              [formName]="'admission'" [type]="'text'" [placeholder]="'Digite a admissão...'">
            </app-ui-c-input>
          </div>
          <div class="col-md-2">
            <app-ui-c-input [name]="'Celular'" [form]="resourceForm" [errorMessages]="errorMessagesProposal"
              [formName]="'celphone'" [type]="'text'" [placeholder]="'(99) 99999-9999'" [isPhone]="true">
            </app-ui-c-input>
          </div>
          <div class="col-md-4">
            <app-ui-c-input [name]="'E-mail'" [form]="resourceForm" [errorMessages]="errorMessagesProposal"
              [formName]="'email'" [type]="'text'" [placeholder]="'E-mail...'">
            </app-ui-c-input>
          </div>
          <!-- </div> -->
          <!--Linha 2-->
          <!-- <div class="row my-1"> -->
          <div class="col-md-3">
            <app-ui-c-input [name]="'Celular'" [form]="resourceForm" [errorMessages]="errorMessagesProposal"
              [formName]="'celphone'" [type]="'text'" [placeholder]="'(99) 99999-9999'" [isPhone]="true">
            </app-ui-c-input>
          </div>
          <!--  <div class="col-md-3">
            <app-ui-c-input [name]="'E-mail'" [form]="resourceForm" [errorMessages]="errorMessagesProposal"
              [formName]="'email'" [type]="'text'" [placeholder]="'E-mail...'">
            </app-ui-c-input>
          </div> -->
          <!-- <div class="col-md-3" *ngIf="isAudit">
            <app-ui-c-input [name]="'Dependentes'" [form]="resourceForm" [errorMessages]="errorMessagesProposal"
              [formName]="'dependents'" [type]="'text'" [placeholder]="'Informe os dependentes...'">
            </app-ui-c-input>
          </div> -->
          <div class="col-md-3" *ngIf="isAudit">
            <app-ui-c-input [name]="'Salário'" [form]="resourceForm" [errorMessages]="errorMessagesProposal"
              [formName]="'salary'" [type]="'text'" [placeholder]="'Informe o salário...'" [isMoney]="true"
              [isNumber]="true">
            </app-ui-c-input>
          </div>
          <div class="col-md-3">
            <app-ui-c-input [name]="'Gerente Departamento'" [form]="resourceForm"
              [errorMessages]="errorMessagesProposal" [formName]="'manResponsible'" [type]="'text'"
              [placeholder]="'Informe o gerente departamento...'">
            </app-ui-c-input>
          </div>
        </div>
        <!-- Linha 2 FINAL-->
        <!-- Linha de Ações Continuar-->
        <div class="row my-2 none">
          <div class="col-md-12 float-right mt-3">
            <button class="btn btn-info float-right">Continuar</button>
          </div>
        </div>
        <!-- Final Linha de Ações Continuar-->
      </div>
      <!-- SOU O LATERAL-->

      <!-- FIM DO LATERAL-->
    </div>

    <!-- Iniciamos Dados do ContraCheques-->
    <div class="card g-12 my-2 disabled" *ngIf="isAudit && !isManager()">
      <div class="card-header">
        <h3 class="card-title">Dados Contracheque/Holerite</h3>
      </div>
      <div class="card-body">

        <!-- Linha 1-->
        <div class="row my-1">
          <div class="col-md-3">
            <app-ui-c-input [name]="'Pensão Alimentícia'" [form]="resourceForm" [errorMessages]="errorMessagesProposal"
              [formName]="'alimony'" [type]="'text'" [placeholder]="'Informe a pensão alimentícia...'" [isNumber]="true"
              [isMoney]="true">
            </app-ui-c-input>
          </div>
          <div class="col-md-2">
            <app-ui-c-input [name]="'Cobrança Judicial'" [form]="resourceForm" [errorMessages]="errorMessagesProposal"
              [formName]="'judicialcollection'" [type]="'text'" [placeholder]="'Informe a cobrança judicial...'"
              [isNumber]="true" [isMoney]="true">
            </app-ui-c-input>
          </div>
          <div class="col-md-2">
            <app-ui-c-input [name]="'Mensalidade Sindical'" [form]="resourceForm"
              [errorMessages]="errorMessagesProposal" [formName]="'unionmonthly'" [type]="'text'"
              [placeholder]="'Informe a mensalidade sindical...'" [isNumber]="true" [isMoney]="true">
            </app-ui-c-input>
          </div>
          <div class="col-md-2">
            <app-ui-c-input [name]="'Outros Emprest. BrasPress'" [form]="resourceForm"
              [errorMessages]="errorMessagesProposal" [formName]="'otherdiscounts'" [type]="'text'"
              [placeholder]="'Informe os Outros Emprest. BrasPress...'" [isNumber]="true" [isMoney]="true">
            </app-ui-c-input>
          </div>
          <div class="col-md-3">
            <app-ui-c-input [name]="'Outros Empréstimos'" [form]="resourceForm" [errorMessages]="errorMessagesProposal"
              [formName]="'otherloans'" [type]="'text'" [placeholder]="'Informe os outros empréstimos...'"
              [isNumber]="true" [isMoney]="true">
            </app-ui-c-input>
          </div>
        </div>
        <!--Linha 2-->
        <div class="row my-1 mt-4">
          <div class="col-md-3">
            <app-ui-c-input [name]="'Empréstimos Urbano Bank'" [form]="resourceForm"
              [errorMessages]="errorMessagesProposal" [formName]="'urbanobankloan'" [type]="'text'"
              [placeholder]="'Informe os empréstimos Urbano Bank...'" [isNumber]="true" [isMoney]="true">
            </app-ui-c-input>
          </div>
          <div class="col-md-2">
            <app-ui-c-input [name]="'INSS'" [form]="resourceForm" [errorMessages]="errorMessagesProposal"
              [formName]="'inss'" [type]="'text'" [placeholder]="'Informe o INSS...'" [isNumber]="true"
              [isMoney]="true">
            </app-ui-c-input>
          </div>
          <div class="col-md-2">
            <app-ui-c-input [name]="'Imposto de Renda'" [form]="resourceForm" [errorMessages]="errorMessagesProposal"
              [formName]="'incometax'" [type]="'text'" [placeholder]="'Informe o imposto de renda...'" [isNumber]="true"
              [isMoney]="true">
            </app-ui-c-input>
          </div>

          <div class="col-md-2">
            <app-ui-c-input [name]="'Total de Descontos'" [form]="resourceForm" [errorMessages]="errorMessagesProposal"
              [formName]="'tatoaldiscounts'" [type]="'text'" [placeholder]="'Informe o total de descontos...'"
              [isNumber]="true" [isMoney]="true">
            </app-ui-c-input>
          </div>

          <div class="col-md-3">
            <app-ui-c-input [name]="'PRÉ Aprovado'" [form]="resourceForm" [errorMessages]="errorMessagesProposal"
              [formName]="'preaproved'" [type]="'text'" [placeholder]="'PRÉ Aprovado...'">
            </app-ui-c-input>
          </div>
        </div>
        <!-- Linha 2 FINAL-->
        <!-- Linha de Ações Continuar-->
        <div class="row my-1">
          <div class="col-md-4 d-flex align-items-end">
            <button class="btn btn-info float-right none">Valor Máximo da Prestação</button>
          </div>
          <!-- FIM DO LATERAL-->
          <!-- Final Linha de Ações Continuar-->
        </div>

      </div>
    </div>
  </form>

  <!-- NOVO HOLE -->
  
  <!-- NOVO -->
   <div class="card g-12 my-2">
    <div class="card-header">
      <h3 class="card-title">Descontos no Contracheque/Holerite Colaborador Atualizado</h3>
    </div>

    <div class="card-body">
     <ng-container *ngIf="formCustomer">
      <form [formGroup]="formCustomer">
        <div class="row d-flex">
  
          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'Pensão Alimentícia *'" [form]="formCustomer"
              [errorMessages]="errorMessagesEmployees" [isNumber]="true" [isMoney]="true" [formName]="'alimony'"
              [placeholder]="'Pensão alimentícia'">
            </app-ui-c-input>
          </div>
          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'Cobranças Judiciais'" [form]="formCustomer"
              [errorMessages]="errorMessagesEmployees" [isNumber]="true" [isMoney]="true" [formName]="'courtCharges'"
              [placeholder]="'Cobranças Judiciais'">
            </app-ui-c-input>
          </div>
          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'INSS *'" [form]="formCustomer" [errorMessages]="errorMessagesEmployees"
              [isMoney]="true" [isNumber]="true" [formName]="'inss'" [placeholder]="'INSS'">
            </app-ui-c-input>
          </div>
          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'Imposto de Renda *'" [form]="formCustomer" [errorMessages]="errorMessagesEmployees"
              [isMoney]="true" [isNumber]="true" [formName]="'incomeTax'" [placeholder]="'Imposto de Renda'">
            </app-ui-c-input>
          </div>
          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'Sindicato *'" [form]="formCustomer" [errorMessages]="errorMessagesEmployees"
              [isMoney]="true" [isNumber]="true" [formName]="'unionsdues'" [placeholder]="'Sindicato'">
            </app-ui-c-input>
          </div>
  
          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'Plano de Saúde *'" [form]="formCustomer" [errorMessages]="errorMessagesEmployees"
              [isMoney]="true" [isNumber]="true" [formName]="'healthPlan'" [placeholder]="'Plano de Saúde'">
            </app-ui-c-input>
          </div>
  
          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'Plano Odontológico *'" [form]="formCustomer"
              [errorMessages]="errorMessagesEmployees" [isMoney]="true" [isNumber]="true" [formName]="'dentalPlan'"
              [placeholder]="'Plano Odontológico'">
            </app-ui-c-input>
          </div>
  
          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'Seguro de Vida *'" [form]="formCustomer" [errorMessages]="errorMessagesEmployees"
              [isMoney]="true" [isNumber]="true" [formName]="'lifeInsurance'" [placeholder]="'Seguro de Vida'">
            </app-ui-c-input>
          </div>
      
          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'Outros Emprest. BrasPress *'" [form]="formCustomer"
              [errorMessages]="errorMessagesEmployees" [isMoney]="true" [isNumber]="true" [formName]="'otherDiscounts'"
              [placeholder]="'Outros Emprest. BrasPress'">
            </app-ui-c-input>
          </div>
  
          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'Valor da parcela'" [form]="formCustomer" [errorMessages]="errorMessagesEmployees"
              [isMoney]="true" [isNumber]="true" [formName]="'installmentAmount'" [placeholder]="'Valor da parcela'">
            </app-ui-c-input>
          </div>
  
          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'Parcela Multa'" [form]="formCustomer" [errorMessages]="errorMessagesEmployees"
              [isMoney]="true" [isNumber]="true" [formName]="'fineInstallment'" [placeholder]="'Parcela Multa'">
            </app-ui-c-input>
          </div>
  
          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'Parcela Colisao'" [form]="formCustomer" [errorMessages]="errorMessagesEmployees"
              [isMoney]="true" [isNumber]="true" [formName]="'collisionInstallment'" [placeholder]="'Parcela Colisao'">
            </app-ui-c-input>
          </div>
  
          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'Parcela Responsabilidade'" [form]="formCustomer"
              [errorMessages]="errorMessagesEmployees" [isMoney]="true" [isNumber]="true"
              [formName]="'responsibilityPortion'" [placeholder]="'Parcela Responsabilidade'">
            </app-ui-c-input>
          </div>
  
          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'Parcela Produtos'" [form]="formCustomer" [errorMessages]="errorMessagesEmployees"
              [isMoney]="true" [isNumber]="true" [formName]="'installmentProducts'" [placeholder]="'Parcela Produtos'">
            </app-ui-c-input>
          </div>
  
          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'Parcela Outros'" [form]="formCustomer" [errorMessages]="errorMessagesEmployees"
              [isMoney]="true" [isNumber]="true" [formName]="'otherInstallment'" [placeholder]="'Parcela Outros'">
            </app-ui-c-input>
          </div>

          <!--  -->
          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'Parcela máxima'" [form]="formCustomer" [errorMessages]="errorMessagesEmployees"
              [isMoney]="true" [isNumber]="true" [formName]="'installmentLiquid'" [placeholder]="'Parcela Outros'">
            </app-ui-c-input>
          </div>
  
        </div>
      </form>
     </ng-container>
    </div>
  </div>

  <ng-container *ngIf="price != undefined && !isManager()">
    <div class="card g-12 my-2">
      <detail-contract [contractName]="proposal?.proposalName" [price]="price"></detail-contract>
    </div>
  </ng-container>

  <ng-container *ngIf="proposal?.priceDetails != undefined && !isManager()">
    <div class="card g-12 my-2">
      <table-finance-contract-detail [proposal]="proposal"></table-finance-contract-detail>
    </div>
  </ng-container>

  <div class="card g-12 my-2" *ngIf="!isManager()">
    <div class="card-header">
      <h3 class="card-title">Detalhes das parcelas</h3>
    </div>
    <div class="card-body">

      <div class="row my-1">
        <h5 class="col-3">
          Quantidade
        </h5>
        <h5 class="col-3">
          Valor da parcela
        </h5>
        <h5 class="col-3">
          (M) Valor total da proposta
        </h5>
        <h5 class="col-3">
          Juros totais
        </h5>
      </div>

      <div class="row my-1">
        <div class="col-3">
          {{installmentsDetails.installmentQuantityLoan}}x
        </div>
        <div class="col-3">
          {{installmentsDetails.pinStallmentValue | currency:"BRL" }}
        </div>
        <div class="col-3">
          {{installmentsDetails.fvTotalAmountLoanValue | currency:"BRL" }}
        </div>
        <div class="col-3">
          {{installmentsDetails.totalInterest | currency:"BRL" }}
        </div>
      </div>
      <br>
      <div class="row my-1">
        <h5 class="col-3">
          Valor Solicitado
        </h5>

        <h5 class="col-3">
          Taxa de juros
        </h5>

        <h5 class="col-3">
          Tributos(IOF)
        </h5>

        <h5 class="col-3">
          TAC
        </h5>

      </div>
      <div class="row my-1">
        <div class="col-3">
          {{installmentsDetails.amountProposal | currency:"BRL" }}
        </div>

        <div class="col-3">
          {{installmentsDetails.iProposalInterestMonth}}%
        </div>

        <div class="col-3">
          {{installmentsDetails.iof | currency:"BRL"}}
        </div>

        <div class="col-3">
          {{installmentsDetails.taxAdministration | currency:"BRL" }}
        </div>
      </div>
    </div>
  </div>

  <!-- Parcelas -->
  <div class="card g-12 my-2" *ngIf="!isManager()">
    <div class="card-header">
      <h3 class="card-title">Parcelas</h3>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <table mat-table matSort [dataSource]="dataSourceSimulador" multiTemplateDataRows
            class="table-installment mat-elevation-z1 table table-striped table-hover mb-0">

            <ng-container matColumnDef="dateInstallment">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Data de vencimento
              </th>
              <td mat-cell *matCellDef="let element"> {{element.dateInstallment | date: "dd/MM/yyyy"}} </td>
            </ng-container>

            <ng-container matColumnDef="installmentsNumber">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Período
              </th>
              <td mat-cell *matCellDef="let element"> {{element.numberInstallment}} </td>
            </ng-container>

            <ng-container matColumnDef="interestInstallment">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                (J) Juros*
              </th>
              <td mat-cell *matCellDef="let element"> {{element.interestInstallment | currency:"BRL"}} </td>
            </ng-container>

            <ng-container matColumnDef="amortInstallment">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Amortização
              </th>
              <td mat-cell *matCellDef="let element"> {{element.amortInstallment | currency:"BRL" }} </td>
            </ng-container>

            <ng-container matColumnDef="installmentValue">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                (P) Parcela
              </th>
              <td mat-cell *matCellDef="let element"> {{element.installmentValue | currency:"BRL" }} </td>
            </ng-container>

            <ng-container matColumnDef="amountDue">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Saldo devedor
              </th>
              <td mat-cell *matCellDef="let element"> {{element.amountDue | currency:"BRL" }} </td>
            </ng-container>

            <ng-container matColumnDef="daysIofIndex">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                IOF
              </th>
              <td mat-cell *matCellDef="let element"> {{element.valueDaysIof | currency:"BRL" }} </td>
            </ng-container>

            <ng-container matColumnDef="insuranceValue">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Seguro
              </th>
              <td mat-cell *matCellDef="let element"> {{element.insuranceValue | currency:"BRL" }} </td>
            </ng-container>

            <ng-container matColumnDef="total">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Total Folha
              </th>
              <td mat-cell *matCellDef="let element">
                <!-- {{sumElemets(element.installmentValue,element.valueDaysIof,element.insuranceValue) | currency:"BRL" }} -->
                {{element.installmentValue | currency:"BRL" }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplayInstallment"></tr>
            <tr class="center" mat-row *matRowDef="let row; columns: columnsToDisplayInstallment;"></tr>
          </table>
        </div>
      </div>
    </div>
  </div>

  <!-- Detalhes de aprovação -->
  <div class="card g-12 my-2">
    <div class="card-header">
      <h3 class="card-title">Detalhes de Aprovação</h3>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <table
            class="detail-contract text-center table-details mat-elevation-z1 table table-striped table-hover mb-0">
            <thead class="p-3">
              <tr>
                <th class="bold" scope="col">
                  Papel
                </th>
                <th scope="col text-center">
                  Quem
                </th>
                <th scope="col text-center">
                  Data
                </th>
                <th scope="col text-center">
                  Hora
                </th>
                <th scope="col text-center">
                  Status
                </th>
                <th scope="col text-center">
                  Observação
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let history of historyApprovation">
                <td> {{ preprarePaper(history?.status) }}</td>
                <td> {{history?.customerName}}</td>
                <td> {{history?.dateRegister | date: "dd/MM/yyyy" }} </td>
                <td> {{history?.dateRegister | date: "HH:mm":"UTC-3" }} </td>
                <td> {{prepareStatus(history?.status)}} </td>
                <td> {{history?.description}} </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!-- Detalhes de aprovação -->

  <!-- Combo de aprovação -->
  <div *ngIf="canApproveProposal && proposal?.statusProposal != 'EXPIRED'" class="card g-12 my-2">
    <div class="card-header">
      <h3 class="card-title">Aprovação</h3>
    </div>
    <div class="card-body">
      <div class="row mb-2">
        <ng-container *ngIf="isManegment">         

          <ng-container *ngIf="isManegment && proposal?.statusProposal === 'REQUESTED'">
            <h4 class="card-title">Existe previsão de demissão nos próximos 30 dias?</h4>
            <div class="row mt-5">
              <div class="col-2">
                <button (click)="aproveProposal(true)" class="w-100 btn btn-danger">SIM</button>
              </div>
              <div class="col-2">
                <button (click)="aproveProposal(false)" class="w-100 btn btn-success">NÃO</button>
              </div>
            </div>
          </ng-container>         

          <ng-container *ngIf="proposal?.statusProposal != 'MANAGER_APPROVED' && isAudit">
            <div class="col-6">
              <h3 class="mt-3 mb-3 mb-0 fs-6 fw-bolder text-dark">
                Aguardando aprovação do gestor
              </h3>
            </div>
          </ng-container>

          <ng-container *ngIf="isManegment && proposal?.statusProposal === 'MANAGER_APPROVED'">
            <div class="col-6">
              <h3 class="mt-3 mb-3 mb-0 fs-6 fw-bolder text-dark">
                Proposta já aprovada!
              </h3>
            </div>
          </ng-container>

          <!-- Bloqueio de mais de uma aprovação -->
          <!--  <ng-container *ngIf="proposal?.statusProposal === 'MANAGER_APPROVED'">
            <div class="col-6">            
              <div class=" mb-0 bold p-5 text-center text-bg-primary text_color_white form-select-solid form-select-lg fw-bold w-100">
              Essa proposta já aprovado pelo gestor.
            </div>
            </div>
          </ng-container> -->
        </ng-container>


        <!-- Auditoria -->
        <ng-container *ngIf="(proposal?.statusProposal === 'MANAGER_APPROVED' || proposal?.statusProposal === 'AUDIT_ANALYSIS') && isAudit">
          <div class="col-6">
            <h3 class="mt-3 mb-3 mb-0 fs-6 fw-bolder text-dark">
              Defina a situação:</h3>
            <select [(ngModel)]="status" class="mt-2" name="status" data-control="status" data-hide-search="false"
              class="form-select form-select-solid form-select-lg fw-bold w-100">
              <option value="" disabled selected>Selecione uma opção</option>
              <option value="AUDIT_ANALYSIS"> Análise de auditoria </option>
              <option value="AUDIT_APPROVED"> Aprovado auditoria </option>
              <option value="AUDIT_REPROVED"> Reprovado auditoria </option>

              <ng-container *ngIf="isLiquidation()">
                <option value="SPECIAL_FINANCE_APROVED"> Aprovado Especial FINCEIRA </option>
                <option value="SPECIAL_FINANCE_REPROVED"> Reprovado Especial FINCEIRA </option>
              </ng-container>

              <ng-container *ngIf="isMaster()">
                <option value="SPECIAL_MASTER_APROVED"> Aprovado Especial MASTER </option>
                <option value="SPECIAL_MASTER_REPROVED"> Reprovado Especial MASTER</option>
              </ng-container>

            </select>

            <div class="col-6" *ngIf="proposal?.isNonStandard">
              <h3 class="mt-3 mb-3 mb-0 fs-6 fw-bolder text-dark">Situação</h3>
              <div class="mt-2"
                class="mt-3 mb-3 mb-0 bold p-5 text-center text-bg-danger text_color_white form-select-solid form-select-lg fw-bold w-100">
                Proposta fora do padrão.
              </div>
            </div>

            <div class="col-6" *ngIf="proposal?.isSpecial">
              <h3 class="mt-3 mb-3 mb-0 fs-6 fw-bolder text-dark">Situação</h3>
              <div class="mt-2"
                class="mt-3 mb-3 mb-0 bold p-5 text-center text-bg-primary text_color_white form-select-solid form-select-lg fw-bold w-100">
                Proposta Especial.
              </div>
            </div>
          </div>

          <div class="col-6">
            <h3 class="mt-3 mb-3 mb-0 fs-6 fw-bolder text-dark">
              Por favor, colocar sua consideração:</h3>
            <textarea [(ngModel)]="textAreaValue" class="w-100"></textarea> <br>
            <p>mínimo de 20 caracteres</p>
          </div>

        </ng-container>
      </div>

      <div class="row mt-5" *ngIf="isAudit">
        <div class="col-12">
          <button class="w-100" [disabled]="status == '' || textAreaValue.length < 20" (click)="aproveProposal()"
            class="btn btn-success">Continuar</button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!canApproveProposal" class="card g-12 my-2">
    <div class="card-header" style="background-color: red; border-radius: 10px !important;">
      <h3 class="card-title" style="color: white;">Proposta Reprovada</h3>
    </div>
  </div>

  <div *ngIf="proposal?.statusProposal === 'EXPIRED'" class="card g-12 my-2">
    <div class="card-header" style="background-color: red; border-radius: 10px !important;">
      <h3 class="card-title" style="color: white;">Proposta Expirada</h3>
    </div>
  </div>


</div>