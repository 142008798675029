<div id="product-type-list-component">

    <!--Título-->
    <h1 class="page-title mb-10"> Tipos de Produto </h1>

    <!--Botão-->
    <div id="add-product-type-btn-container">
        <button type="button" class="btn btn-success px-10 mb-4" id="add-product-type-btn" (click)="redirectToNewOrEditProductType()">
            Criar Tipo de Produto
        </button>
    </div>

    <!--Tabela-->
    <ng-container *ngIf="tableProductsType">
        <ui-c-table [tableData]="tableProductsType"></ui-c-table>
    </ng-container>

</div>
