import { Routes, RouterModule } from '@angular/router';
import { TaxFormComponent } from './tax-form/tax-form.component';

const routes: Routes = [
  {
    path: "tax-form",
    component: TaxFormComponent
  }
];

export const TaxesRoutes = RouterModule.forChild(routes);
