import { NgModule } from '@angular/core';
import { CopyComponent } from './copy.component';

@NgModule({
    imports: [
    ],
    declarations: [
        CopyComponent
    ],
    exports: [
        CopyComponent
    ],
})
export class CopyComponentModule { }