import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';


@Component({
  selector: 'app-ui-c-input',
  templateUrl: './ui-c-input.component.html',
  styleUrls: ['./ui-c-input.component.scss']
})
export class UiCInputComponent implements OnInit {

  hasMasker = false;

  @Input() form: FormGroup;
  @Input() formName: string;

  @Input() name?: string;
  @Input() minlength?: string;
  @Input() maxlength?: string;
  @Input() class?: string = '';
  @Input() placeholder?: string = '';
  @Input() tooltip?: string = '';
  @Input() type?: string = 'text';
  @Input() iconName?: string;

  @Input() isDate?: boolean;
  @Input() noMask?: boolean;
  @Input() isCPF?: boolean;
  @Input() isCNPJ?: boolean;
  @Input() isMoney?: boolean;
  @Input() isPhone?: boolean;
  @Input() isCEP?: boolean;
  @Input() isNumber?: boolean;
  @Input() isPerson?: boolean;
  @Input() isPercent?: boolean;
  @Input() isEpv?: boolean;
  @Input() isReproved?: boolean = false;
  @Input() isAgency?: boolean;
  @Input() isAccount?: boolean;
  @Input() isOnlyLetters?: boolean;
  @Input() block?: boolean;
  @Input() errorMessages?: any;

  @Output() onBlur?= new EventEmitter();
  @Output() keyup?= new EventEmitter();
  @Output() togglePassword?= new EventEmitter();

  public isDevolution: boolean = false;
  count: number = 0;
  prefix = "R$ ";

  constructor() { }

  ngOnInit() {
    if (this.isDate || this.isCPF || this.isPercent || this.isOnlyLetters || this.isCNPJ || this.isMoney || this.isPhone || this.isCEP || this.isNumber || this.isPerson || this.isEpv || this.isAgency ||
      this.isAccount) {
      this.hasMasker = true;
    }
  }

  checkValue(event: any) {
    if (this.isNumber && this.isMoney) {
      let value = this.form.get(this.formName)?.value;
      this.form.get(this.formName)?.patchValue(value);
    }

    if (this.isCPF) {
      event.target.value = event.target.value.replace(/[^\d.-]/g, '');
    }
  }
  changeMoney() {
  }


  changeMasker(): any {

    this.hasMasker = true;
    if (this.isDate) {
      return { mask: '00/00/0000', len: 10 };
    }
    if (this.isAccount) {
      return { money: true, decimalCaracter: '-', decimal: '1' };
    }
    if (this.isAgency) {
      return { mask: '00000', type: 'num' };
    }
    if (this.isCPF) {
      return { mask: '000.000.000-00', len: 14 };
    }
    if (this.isCNPJ) {
      return { mask: '00.000.000/0000-00', len: 18 };
    }
    if (this.isMoney) {
      /* this.prefix = "R$ " */
      return { money: true, thousand: '.', decimalCaracter: ',', decimal: '2' };
    }
    if (this.isPhone) {
      return { phone: true };
    }
    if (this.isCEP) {
      return { mask: '00000-000', len: 9, type: 'num' };
    }
    if (this.isEpv) {
      return { mask: '00000000-0000-0000-0000-000000000000', len: 36 };
    }
    if (this.isNumber) {
      return { len: this.maxlength, type: 'num' };
    }
    if (this.isPerson) {
      return { person: true };
    }
    if (this.isPercent) {
      /* console.log('pecent')
      this.prefix = "% " */
      return { percent: true };
    }
    this.type = 'text';
    this.hasMasker = false;
    return { mask: false, userCaracters: true };
  }

}


