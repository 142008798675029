<ng-container *ngIf="roleUser == 'liquidation' || roleUser == 'audit'">
  <ng-container *ngIf="roleUser == 'liquidation'">
    <h3 style="margin-top: 10px;">Selecione uma empresa</h3>
    <app-select-companies (emitCompany)="emitCompany($event)"></app-select-companies>
  </ng-container>
  <br><br><br>
  <div class="row ">
    <div class="col-md-8 col-12 ">
      <h6>Balde Potencial</h6>
      <div class="hover-elevate-up shadow-sm parent-hover">
        <div class="chart" #pieChart id="pieChart"></div>
      </div>
    </div>
  </div>

  <div class="row">
    <h6 class="mt-4">Contratos e Valores recebidos</h6>
    <div class="col-xl-3 col-lg-6 col-12 mt-5 mb-5">
      <app-dashboard-card [cardColor]="'background-color: #003577'" [title]="'Contratos útimo ano'"
        [props]="[{key: 'Total de contratos', value: rawOne?.paidContractsLastYearCard?.quantity, type:'none'}, {key: 'Valor total', value: rawOne?.paidContractsLastYearCard?.totalValue, type:'money'}, {key: 'Ticket médio', value: rawOne?.paidContractsLastYearCard?.avarage, type:'money'}]">
      </app-dashboard-card>
    </div>

    <div class="col-xl-3 col-lg-6 col-12 mt-5 mb-5">
      <app-dashboard-card [cardColor]="'background-color: #014589'" [title]="'Contratos mês atual'" [props]="[{key: 'Total de contratos', value: rawOne?.paidContractsMonthCard?.quantity}, 
        {key: 'Valor total', value: rawOne?.paidContractsMonthCard?.totalValue, type:'money'}, 
        {key: 'Ticket médio', value: rawOne?.paidContractsMonthCard?.avarage, type:'money'}]">
      </app-dashboard-card>
    </div>

    <div class="col-xl-3 col-lg-6 col-12 mt-5 mb-5">
      <app-dashboard-card [cardColor]="'background-color: #01549b'" [title]="'Contratos mês passado'" [props]="[{key: 'Total de contratos', value: rawOne?.paidContractsLastMonthCard?.quantity}, {key: 'Valor total', value: parseFloatValueAndToFixedOnTwo(rawOne?.paidContractsLastMonthCard?.totalValue), type:'money'},
         {key: 'Ticket médio', value: rawOne?.paidContractsLastMonthCard?.avarage, type:'money'}]">
      </app-dashboard-card>
    </div>

    <div class="col-xl-3 col-lg-6 col-12 mt-5 mb-5">
      <app-dashboard-card [cardColor]="'background-color: #006ab1'" [title]="'Faturas do último ano'" [props]="[{key: 'Total de contratos', value: rawOne?.invoicesYearCard?.quantity}, 
        {key: 'Valor Total', value: rawOne?.invoicesYearCard?.value?.toFixed(2), type:'money'}]">
      </app-dashboard-card>
    </div>

    <div class="col-xl-3 col-lg-6 col-12 mt-5 mb-5">
      <app-dashboard-card [cardColor]="'background-color: #0264ad'" [title]="'Fatura do mês atual'" [props]="[{key: 'Total de contratos', value: rawOne?.invoicesMonthCard?.quantity}, 
        {key: 'Valor Total', value: rawOne?.invoicesMonthCard?.value?.toFixed(2), type:'money'}]">
      </app-dashboard-card>
    </div>

    <div class="col-xl-3 col-lg-6 col-12 mt-5 mb-5">
      <app-dashboard-card [cardColor]="'background-color: #0273bf'" [title]="'Fatura do mês passado'"
        [props]="[{key: 'Total de contratos', value: rawOne?.lastMonthInvoicesCard?.quantity}, {key: 'Valor total', value: rawOne?.lastMonthInvoicesCard?.value?.toFixed(2), type:'money'}]">
      </app-dashboard-card>
    </div>

    <div class="col-xl-3 col-lg-6 col-12 mt-5 mb-5">
      <app-dashboard-card [cardColor]="'background-color: #0273bf'" [title]="'IOF mês atual'"
        [props]="[{key: 'Total IOF ', value: parseFloatValueAndToFixedOnTwo(rawOne?.totalIofCard?.totalIof?.toString()), type:'money'}]">
      </app-dashboard-card>
    </div>

    <div class="col-xl-3 col-lg-6 col-12 mt-5 mb-5">
      <app-dashboard-card [cardColor]="'background-color: #0273bf'" [title]="'Juros mês atual'"
        [props]="[{key: 'Juros totais', value: parseFloatValueAndToFixedOnTwo(rawOne?.totalInteresCard?.totalInterest?.toString()), type:'money'}]">
      </app-dashboard-card>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-4 col-lg-6 col-12 mt-5 mb-5">
      <div class="bg-white p-3 hover-elevate-up shadow-sm parent-hover">
        <h1 class="text-center">Contratos</h1>
        <div class="chart" #contractChart id="contractChart"></div>
      </div>
    </div>


    <div class="col-xl-4 col-lg-6 col-12 mt-5 mb-5">
      <div class="bg-white p-3 hover-elevate-up shadow-sm parent-hover">
        <h1>Faturas</h1>
        <div class="chart" #invoiceChart id="invoiceChart"></div>
      </div>
    </div>

    <div class="col-xl-4 col-lg-6 col-12 mt-5 mb-5">
      <div class="bg-white p-3 hover-elevate-up shadow-sm parent-hover">
        <h1>Juros e IOF</h1>
        <div class="chart" #interestChart id="interestChart"></div>
      </div>
    </div>
  </div>

  <div class="row">
    <h6>Simulações, Propostas e Colaboradores</h6>
    <div class="col-xl-3 col-lg-6 col-12 mt-5 mb-5">
      <app-dashboard-card [cardColor]="'background-color: #141160'" [title]="'Colaboradores'"
        [props]="[{key: 'Quantidade', value: rawTwo?.customers?.customerQuantity}]"></app-dashboard-card>
    </div>

    <div class="col-xl-3 col-lg-6 col-12 mt-5 mb-5">
      <app-dashboard-card [cardColor]="'background-color: #2d2678'" [title]="'Simulações'"
        [props]="[{key: 'Quantidade', value: rawTwo?.simulations?.totalSimulationApp}]"></app-dashboard-card>
    </div>

    <div class="col-xl-3 col-lg-6 col-12 mt-5 mb-5">
      <app-dashboard-card [cardColor]="'background-color: #473a90'" [title]="'Propostas solicitadas'"
        [props]="[{key: 'Quantidade', value: rawTwo?.requestedProposals?.countAndSum?.invoiceQuantity}, {key: 'Total', value: rawTwo?.requestedProposals?.countAndSum?.totalValue?.toFixed(2), type:'money'}]"
        [customerList]="rawTwo?.requestedProposals?.oldestRequestedProposals"></app-dashboard-card>
    </div>

    <div class="col-xl-3 col-lg-6 col-12 mt-5 mb-5">
      <app-dashboard-card [cardColor]="'background-color: #604fa7'" [title]="'Propostas aprovadas gestor'"
        [props]="[{key: 'Quantidade', value: rawTwo?.mannagerApprovedProposals?.countAndSum?.invoiceQuantity}, {key: 'Total', value: rawTwo?.mannagerApprovedProposals?.countAndSum?.totalValue?.toFixed(2), type:'money'}]"
        [customerList]="rawTwo?.mannagerApprovedProposals?.oldestRequestedProposals"></app-dashboard-card>
    </div>

    <div class="col-xl-3 col-lg-6 col-12 mt-5 mb-5">
      <app-dashboard-card [cardColor]="'background-color: #7963bf'" [title]="'Contratos aguardando pagamento'"
        [props]="[{key: 'Quantidade', value: rawTwo?.contrcatsWatingPayment?.countAndSum?.quantity}, {key: 'Total', value: rawTwo?.contrcatsWatingPayment?.countAndSum?.totalValue?.toFixed(2), type:'money'}]"
        [customerList]="rawTwo?.contrcatsWatingPayment?.oldestRequestedProposals"></app-dashboard-card>
    </div>
  </div>

  <ng-container *ngIf="roleUser == 'liquidation'">
    <h6>Seguro</h6>
    <div class="row">
      <div class="col-xl-3 col-lg-6 col-12 mt-5 mb-5">
        <app-dashboard-card [cardColor]="'background-color: #199ab9'" [title]="'Total este mês'"
          [props]="[{key: 'Valor total', value: rawInsurance?.totalMonth?.totalValue?.toFixed(2), type:'money'}]"></app-dashboard-card>
      </div>
      <div class="col-xl-3 col-lg-6 col-12 mt-5 mb-5">
        <app-dashboard-card [cardColor]="'background-color: #39b3cb'" [title]="'Digilog'"
          [props]="[{key: 'Total', value: rawInsurance?.digilog?.totalValue?.toFixed(2), type:'money'}]"></app-dashboard-card>
      </div>
      <div class="col-xl-3 col-lg-6 col-12 mt-5 mb-5">
        <app-dashboard-card [cardColor]="'background-color: #58ccdc'" [title]="'Porto Seguro'"
          [props]="[{key: 'Total', value: rawInsurance?.portoSeguro?.totalInsurance?.toFixed(2), type:'money'}]"></app-dashboard-card>
      </div>

      <div class="col-xl-3 col-lg-6 col-12 mt-5 mb-5">
        <app-dashboard-card [cardColor]="'background-color: #58ccdc'" [title]="'Urbano Bank'"
          [props]="[{key: 'Total', value: rawInsurance?.urbanRevenue?.totalValue?.toFixed(2), type:'money'}]"></app-dashboard-card>
      </div>
    </div>
  </ng-container>
</ng-container>