import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InvestimentFundsRoutingModule } from './investiment-funds-routing.module';
import { InvestimentFundsListComponent } from './investiment-funds-list/investiment-funds-list.component';
import { InvestimentFundsFormsComponent } from './investiment-funds-forms/investiment-funds-forms.component';


@NgModule({
  declarations: [
    InvestimentFundsListComponent,
    InvestimentFundsFormsComponent
  ],
  imports: [
    CommonModule,
    InvestimentFundsRoutingModule
  ]
})
export class InvestimentFundsModule { }
