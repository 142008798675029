import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TableData } from "src/app/core/scfi/services/services-table/table.dto";
import { AbstractScfi } from "../../abstract-scfi";
import { Router } from "@angular/router";
import { CurrencyPipe, DatePipe } from "@angular/common";
import { ProductsService } from "src/app/core/scfi/services/services-product/products.service";
import { TypeProductDto } from "src/app/core/scfi/services/services-product/type-product.dto";
import { environment } from "src/environments/environment";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-product-type-list',
  templateUrl: './product-type-list.component.html',
  styleUrls: ['./product-type-list.component.scss']
})
export class ProductTypeListComponent extends AbstractScfi implements OnInit {
  productsType: TypeProductDto[];
  tableProductsType: TableData;

  constructor(
      private router: Router,
      private productsService: ProductsService,
      private spinner: NgxSpinnerService,
      private cdr: ChangeDetectorRef,
      protected currencyPipe: CurrencyPipe,
      protected datePipe: DatePipe
  ) {
    super(currencyPipe, datePipe)
  }

  ngOnInit() {
    this.findProductsTypeList();
  }

  public findProductsTypeList() {
    this.spinner.show();
    this.productsService.findProductTypeList(environment.wlp).subscribe({
      next: (productsTypeList: TypeProductDto[]) => {
        this.productsType = productsTypeList;
        this.tableProductsType = this.generateTableData();
        this.cdr.detectChanges();
        this.spinner.hide();
      },
      error: () => {
        this.showErrorModal('Erro ao buscar os tipos de produto!');
        this.spinner.hide();
      }
    });
  }

  public generateTableData(): TableData {
    return {
      header: ['Tipo', 'Ações'],
      rows: this.productsType.map(row => {
        return {
          fields: [
            row.name,
          ],
          actions: [
            { icon: './assets/media/icons/duotune/general/gen055.svg', onClick: () => this.redirectToNewOrEditProductType(row.uuid) },
            { icon: './assets/media/icons/duotune/general/gen027.svg', onClick: () => this.productsService.deleteProductType(row.uuid) }
          ]
        }
      }),
    }
  }

  redirectToNewOrEditProductType (uuid?: string) {
    if (uuid) {
      this.router.navigate(['scfi/products/new', uuid]);
    }
    else {
      this.router.navigate(['scfi/products/new']);
    }
  }

}
