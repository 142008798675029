import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {

  @Input('page-title') pageTitle!: string;
  @Input('show-button') showButton: boolean = true;
  @Input('button-class') buttonClass!: string;
  @Input('button-text') buttonText!: string;
  @Input('button-link') buttonLink!: string;
  @Input() isBack?: boolean = false;
  @Input() noForm?: boolean = false;

  constructor(private router: Router) { }

  ngOnInit() {
  }
}
