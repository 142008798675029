import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-simulation-list',
  templateUrl: './simulation-list.component.html',
  styleUrls: ['./simulation-list.component.scss']
})
export class SimulationListComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
