import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CalculationEngineSimulatorComponent } from './calculation-engine-simulator/calculation-engine-simulator.component';

const routes: Routes = [
  { path: '', component: CalculationEngineSimulatorComponent, },
  ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CalculationEngineRoutingModule { }
