import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentsRoutes } from './documents.routing';
import { DocumentFormComponent } from './document-form/document-form.component';
import { DocumentListComponent } from './document-list/document-list.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { UiCInputModule } from "src/app/shared/components/ui-c-input/ui-c-input.module";
import { InlineSVGModule } from "ng-inline-svg-2";
import {UiCTableModule} from "src/app/shared/components/ui-c-table/ui-c-table.module";

@NgModule({
    imports: [
        CommonModule,
        DocumentsRoutes,
        FormsModule,
        MatSlideToggleModule,
        UiCInputModule,
        InlineSVGModule,
        UiCTableModule,
        ReactiveFormsModule
    ],
  declarations: [
      DocumentFormComponent,
      DocumentListComponent
  ]
})
export class DocumentsModule { }
