import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ScfiCalculatorResponseType } from 'src/app/core/scfi/services/services-simulation/dto/calculator.dto';
import { SimulationTypeTac } from 'src/app/core/scfi/services/services-simulation/type-tac.enum';
import { AbstractScfi } from 'src/app/pages/scfi/abstract-scfi';

@Component({
    selector: 'simulation-installments-details',
    templateUrl: './simulation-installments-details.component.html',
    styleUrls: ['./simulation-installments-details.component.scss']
})
export class SimulationInstallmentsDetailsComponent extends AbstractScfi implements OnInit, OnChanges {
    @Input() sfiCalculatorResponseType: ScfiCalculatorResponseType;
    public simulationDetails: ScfiCalculatorResponseType;

    constructor(
        protected currencyPipe: CurrencyPipe,
        protected datePipe: DatePipe
    ) {
        super(currencyPipe, datePipe)
    }

    ngOnInit() {
        this.simulationDetails = this.sfiCalculatorResponseType;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.sfiCalculatorResponseType) {
            this.simulationDetails = changes.sfiCalculatorResponseType.currentValue;
        }
    }

    public formatTacValue(value: number, typeTac: SimulationTypeTac): string {
        switch (typeTac) {
            case SimulationTypeTac.percentual:
                return this.formatValueToPercent(value);
            case SimulationTypeTac.fixo:
                return this.formatValueToMoney(value);
            default:
                return value.toString();
        }
    }

    public isIOFIncluded(isIOFIncluded: boolean): string {
        return isIOFIncluded ? "Sim" : "Não";
    }
}
