import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-settle-loans-forms',
  templateUrl: './settle-loans-forms.component.html',
  styleUrls: ['./settle-loans-forms.component.scss']
})
export class SettleLoansFormsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
