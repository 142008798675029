import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RoleTypeEnum } from 'src/app/core/scfi/services/services-proposal/enum/role-type.enum';
import { ApprovalHistoryDto, ProposalDto } from 'src/app/core/scfi/services/services-proposal/proposal.dto';
import { ProposalService } from 'src/app/core/scfi/services/services-proposal/proposal.service';
import { AbstractScfi } from 'src/app/pages/scfi/abstract-scfi';

@Component({
    selector: 'proposal-approval-details',
    templateUrl: './proposal-approval-details.component.html',
    styleUrls: ['./proposal-approval-details.component.scss']
})
export class ProposalApprovalDetailsComponent extends AbstractScfi implements OnInit {
    @Input() proposal: ProposalDto;
    public approvalHistory: ApprovalHistoryDto[];

    constructor(
        private proposalService: ProposalService,
        protected currencyPipe: CurrencyPipe,
        protected datePipe: DatePipe,
    ) {
        super(currencyPipe, datePipe);
    }

    ngOnInit() {
        this.findApprovalHistory();
    }

    public findApprovalHistory() {
        this.proposalService.findApprovalHistory(this.proposal.uuid).subscribe({
            next: (approvalHistory: ApprovalHistoryDto[]) => {
                this.approvalHistory = approvalHistory;
            },
            error: (error) => {
                console.error('Error loading approval history:', error);
                this.showErrorModal('Erro ao buscar histórico de aprovações!');
            }
        });
    }
}
