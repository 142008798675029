import { OnInit, ViewChild, Directive } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BaseResourceModel } from "../../models/base-resource.model";
import { BaseResourceService } from "../../base-service/base-resource.service";

@Directive()
export abstract class BaseResourceListComponent<T extends BaseResourceModel> implements OnInit {

  dataSource!: MatTableDataSource<any>;


  @ViewChild('paginator') paginator!: MatPaginator;
  @ViewChild(MatSort) matSort!: MatSort;

  resources: any;
  isEmpty: boolean;

  constructor(
    protected resourceService: BaseResourceService<T>,
    protected apipath: string) { }

  ngOnInit() {
    this.resourceService.getAll().subscribe(
      resources => this.resources = resources,
      /* error => alert('Erro ao carregar a lista') */
    )
  }


  setDatasource(data: any) {
    //console.log('data: ' + data)
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.matSort;
  }

  getByuuid(uuid: string) {
    return this.resourceService.getById(uuid).subscribe((response: any) => {
      this.resources = response;
    });
  }

  deleteResource(resource: T) {
    const mustDelete = confirm('Deseja realmente excluir este item?');
    if (mustDelete) {
      // this.resourceService.delete(resource.id).subscribe(
      //   () => this.resources = this.resources.filter(element => element != resource),
      //   () => alert("Erro ao tentar excluir!")
      // )
    }
  }

  filterData($event: any) {
    this.dataSource.filter = $event.target.value;
  }


}
