import { UserInfoService } from 'src/app/core/_base/_auth/user-info.service';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {SpeViewNScfiProxy} from "src/app/core/proxy/view/spe-view-n-scfi.proxy";
import {MenuDto} from "src/app/core/scfi/services/services-dto/menu.dto";
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss']
})
export class SidebarMenuComponent implements OnInit {

  public isClient: boolean = false;
  public isCommittee: boolean = false;
  public isBackOffice: boolean = false;
  public isCommercial: boolean = false;
  isLiquidation: boolean;
  menuList: MenuDto[];
  enableFuture: boolean;

  constructor(
    private userInfoService: UserInfoService,
    private menuService: SpeViewNScfiProxy,
    private cdr: ChangeDetectorRef,
    protected keycloackService: KeycloakService
  ) { }


  ngOnInit(): void {
    const currentRoles = this.keycloackService.getUserRoles();
    this.menuService.getMenuSide("BO_SCFI", currentRoles.join(","))
      .subscribe((result: MenuDto[]) => {
        this.menuList = result.sort((a: MenuDto, b: MenuDto) => a.order > b.order ? 1 : -1);
        this.cdr.detectChanges();
      })

    this.isCommittee = this.userInfoService.isCommittee();
    this.isClient = this.userInfoService.isClient();
    this.isBackOffice = this.userInfoService.isBackOffice();
    this.isCommercial =this.userInfoService.isCommercial();
  }



  prepareIcon(icon: string) {
    return './assets/media/' + icon;
  }

  public verifyIsArray(obj: any) {
    return Array.isArray(obj)
  }

}
