<!-- <app-bread-crumb [items]="[{text: 'Empresas', link: '/companies'}, {text: pageTitle}]"></app-bread-crumb> -->

<app-page-header [page-title]="creationPageTitle()" button-text="Voltar" button-link="/admin/companies"
  button-class="btn-danger">
</app-page-header>

<!--  [NgClass]="isView ? 'disabled':'' -->
<form [formGroup]="resourceForm" class="mt-4" [ngClass]="isView ? 'disabled':''">

  <div class="card g-12">
    <div class="card-header">
      <h3 class="card-title">Parceiros</h3>
    </div>
    <div class="card-body my-2">
      <div class="row">
        <div class="col-xl-6 col-md-12">     
          <label class="mt-3 mb-0 form-label fs-6 fw-bolder text-dark">Selecione o parceiro</label>
          <select formControlName="consignedPartner" class="form-select form-select-solid form-select-lg fw-bold w-100">
            <option *ngFor="let partner of partnersList"> {{partner}} </option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <div class="card g-12">
    <div class="card-header">
      <h3 class="card-title">Informações sobre a Empresa</h3>
    </div>
    <div class="card-body my-2">
      <div class="row">
        <div class="col-xl-6 col-md-12">
          <app-ui-c-input [name]="'CNPJ'" [form]="resourceForm" [errorMessages]="errorMessagesCompany"
            [formName]="'documentGov'" [type]="'text'" [placeholder]="'Digite o cnpj da empresa...'" [isCNPJ]="true">
          </app-ui-c-input>
        </div>
        <div class="col-xl-6 col-md-12">
          <app-ui-c-input [name]="'Razão Social'" [form]="resourceForm" [errorMessages]="errorMessagesCompany"
            [formName]="'name'" [type]="'text'" [placeholder]="'Razão social...'">
          </app-ui-c-input>
        </div>
        <div class="col-xl-3 col-md-12">
          <app-ui-c-input [name]="'Inscrição Estadual'" [form]="resourceForm" [errorMessages]="errorMessagesCompany"
            [formName]="'documentStates'" [type]="'text'" [placeholder]="'Digite a inscrção estadual...'">
          </app-ui-c-input>
        </div>
        <div class="col-xl-3 col-md-12">
          <app-ui-c-input [name]="'Inscrição Municipal'" [form]="resourceForm" [errorMessages]="errorMessagesCompany"
            [formName]="'documentCity'" [type]="'text'" [placeholder]="'Digite a inscrção municipal...'">
          </app-ui-c-input>
        </div>
      </div>
    </div>
  </div>
  <div class="card g-12 my-2">
    <div class="card-header">
      <h3 class="card-title">Endereço da Empresa</h3>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-4">
          <app-ui-c-input [name]="'CEP'" [form]="resourceForm" [errorMessages]="errorMessagesCompany"
            [formName]="'zipcode'" [type]="'text'" (change)="onChangePostal($event)" [placeholder]="'Informe o CEP...'"
            [isCEP]="true">
          </app-ui-c-input>
        </div>
        <div class="col-12 col-md-4">
          <app-ui-c-input [name]="'Logradouro'" [form]="resourceForm" [errorMessages]="errorMessagesCompany"
            [formName]="'address'" [type]="'text'" [placeholder]="'Digite o Logradouro...'">
          </app-ui-c-input>
        </div>
        <div class="col-12 col-md-4">
          <app-ui-c-input [name]="'Número'" [form]="resourceForm" [errorMessages]="errorMessagesCompany"
            [formName]="'addressNumber'" [isNumber]="true" [placeholder]="'N°'">
          </app-ui-c-input>
        </div>
        <div class="col-12 col-md-4">
          <app-ui-c-input [name]="'Complemento'" [form]="resourceForm" [errorMessages]="errorMessagesCompany"
            [formName]="'complement'" [type]="'text'" [placeholder]="'Digite o complemento...'">
          </app-ui-c-input>
        </div>
        <div class="col-12 col-md-4">
          <app-ui-c-input [name]="'Bairro'" [form]="resourceForm" [errorMessages]="errorMessagesCompany"
            [formName]="'district'" [type]="'text'" [placeholder]="'Digite o bairro...'">
          </app-ui-c-input>
        </div>
        <div class="col-12 col-md-4">
          <app-ui-c-input [name]="'Cidade'" [form]="resourceForm" [errorMessages]="errorMessagesCompany"
            [formName]="'city'" [type]="'text'" [placeholder]="'Informe a cidade...'">
          </app-ui-c-input>
        </div>
        <div class="col-12 col-md-4">
          <label class="mt-3 mb-0 form-label fs-6 fw-bolder text-dark">Estado</label>
          <select formControlName="states" name="states" data-control="states" data-hide-search="true"
            class="form-select form-select-solid form-select-lg fw-bold w-100">
            <option *ngFor="let state of listUf"> {{state.name}} </option>
          </select>
        </div>
        <div class="col-12 col-md-4">
          <app-ui-c-input [name]="'País'" [form]="resourceForm" [errorMessages]="errorMessagesCompany"
            [formName]="'country'" [type]="'text'" [placeholder]="'Informe o país...'">
          </app-ui-c-input>
        </div>
        <div class="col-12 col-md-4">
          <app-ui-c-input [name]="'E-mail'" [form]="resourceForm" [errorMessages]="errorMessagesCompany"
            [formName]="'emailCompany'" [type]="'text'" [placeholder]="'Digite o e-mail...'">
          </app-ui-c-input>
        </div>
        <div class="col-12 col-md-4">
          <app-ui-c-input [name]="'Telefone Comercial'" [form]="resourceForm" [errorMessages]="errorMessagesCompany"
            [formName]="'phone'" [type]="'text'" [placeholder]="'Digite o telefone...'" [isPhone]="true">
          </app-ui-c-input>
        </div>
      </div>
    </div>
  </div>

  <div class="row border">
    <div class="col-12 col-lg-6">
      <div class="card g-12 my-2">
        <div class="card-header">
          <h3 class="card-title">Dados Contato</h3>
        </div>
        <div class="card-body">
          <div class="row my-2">
            <div class="col-md-12 col-12 col-lg-6">
              <app-ui-c-input [name]="'Nome Contato'" [form]="resourceForm" [errorMessages]="errorMessagesCompany"
                [formName]="'nameContact'" [type]="'text'" [placeholder]="'Informe o nome do contato...'">
              </app-ui-c-input>
            </div>
            <div class="col-md-12 col-12 col-lg-6">
              <app-ui-c-input [name]="'Telefone Contato'" [form]="resourceForm" [errorMessages]="errorMessagesCompany"
                [formName]="'phoneContact'" [type]="'text'" [placeholder]="'Informe o telefone do contato...'"
                [isPhone]="true">
              </app-ui-c-input>
            </div>
            <div class="col-md-12 col-12 col-lg-6">
              <app-ui-c-input [name]="'E-mail Contato'" [form]="resourceForm" [errorMessages]="errorMessagesCompany"
                [formName]="'emailContact'" [type]="'text'" [placeholder]="'Informe o e-mail do contato...'">
              </app-ui-c-input>
            </div>
            <div class="col-md-12 col-12 col-lg-6">
              <app-ui-c-input [name]="'RG'" [form]="resourceForm" [errorMessages]="errorMessagesCompany"
                [formName]="'documentContact'" [type]="'text'" [placeholder]="'RG'">
              </app-ui-c-input>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-12">
      <div class="card g-12 my-2">
        <div class="card-header">
          <h3 class="card-title">Responsável Assinatura</h3>
        </div>
        <div class="card-body">
          <div class="row my-2">
            <div class="col-md-12 col-12 col-lg-6">
              <app-ui-c-input [name]="'Nome Contato'" [form]="resourceForm" [errorMessages]="errorMessagesCompany"
                [formName]="'nameResponsibleAssign'" [type]="'text'" [placeholder]="'Informe o nome do contato...'">
              </app-ui-c-input>
            </div>
            <div class="col-md-12 col-12 col-lg-6">
              <app-ui-c-input [name]="'Telefone Contato'" [form]="resourceForm" [errorMessages]="errorMessagesCompany"
                [formName]="'phoneResponsibleAssing'" [type]="'text'" [placeholder]="'Informe o telefone do contato...'"
                [isPhone]="true">
              </app-ui-c-input>
            </div>
            <div class="col-md-12 col-12 col-lg-6">
              <app-ui-c-input [name]="'E-mail Contato'" [form]="resourceForm" [errorMessages]="errorMessagesCompany"
                [formName]="'emailResponsibleAssign'" [type]="'text'" [placeholder]="'Informe o e-mail do contato...'">
              </app-ui-c-input>
            </div>
            <div class="col-md-12 col-12 col-lg-6">
              <app-ui-c-input [name]="'CPF Responsável'" [form]="resourceForm" [errorMessages]="errorMessagesCompany"
                [formName]="'cpfResponsibleAssign'" [type]="'text'" [placeholder]="'CPF'" [isCPF]="true">
              </app-ui-c-input>
            </div>
            <div class="col-md-12 col-12 col-lg-6">
              <app-ui-c-input [name]="'RG Responsável'" [form]="resourceForm" [errorMessages]="errorMessagesCompany"
                [formName]="'rgResponsibleAssign'" [type]="'text'" [placeholder]="'RG Responsável'">
              </app-ui-c-input>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row form-button" *ngIf="!isView && !isEdit">
    <div class="col-12 col-md-4 col-sm-6">
      <button (click)="save()" [disabled]="resourceForm.invalid" type=" submit"
        class="btn btn-primary btn-lg float-right mt-3">
        Salvar
      </button>
    </div>
  </div>

  <div class="row form-button" *ngIf="!isView && isEdit">
    <div class="col-12 col-md-4 col-sm-6">
      <button (click)="updateCompany()" [disabled]="resourceForm.invalid" type=" submit"
        class="btn btn-primary btn-lg float-right mt-3">
        Editar
      </button>
    </div>
  </div>
</form>