import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { UiCInputModule } from '../ui-c-input/ui-c-input.module';
import { ProposalInstallmentsDetailsComponent } from './proposal-installments-details.component';

@NgModule({
    imports: [
        CommonModule,
        InlineSVGModule,
        UiCInputModule
    ],
    declarations: [ProposalInstallmentsDetailsComponent],
    exports: [ProposalInstallmentsDetailsComponent],
})
export class ProposalInstallmentsDetailsModule { }