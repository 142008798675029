import { EmployeesFormsComponent } from './employees-forms/employees-forms.component';
import { EmployeesListComponent } from './employees-list/employees-list.component';

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', component: EmployeesListComponent, },
  { path: 'new', component: EmployeesFormsComponent, },
  { path: ':id/edit', component: EmployeesFormsComponent, },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EmployeesRoutingModule { }
