import {Injectable} from '@angular/core';
import {KeycloakService} from 'keycloak-angular';
import {ExtraAttribute} from './dto/user-info-profile';
import {KeycloakProfileCustom} from './dto/keycloak-profile-custom';
import {SpeViewNAccountProxy} from '../../proxy/view/spe-view-n-account.proxy';
import {SpeNAccountsProxy} from '../../proxy/spe/spe-n-accounts.proxy';
import {RoleTypeEnum} from "../../scfi/services/services-proposal/enum/role-type.enum";

@Injectable(
  {
    providedIn: "root"
  }
)
export class UserInfoService {


  constructor(protected keycloakService: KeycloakService,
    private speViewNAccountProxy: SpeViewNAccountProxy,
    private speNAccountsProxy: SpeNAccountsProxy,) {
  }

  setUuidAccount(uuid: number, uuidWl: string) {
    localStorage.setItem('uuid_account_' + uuidWl, String(uuid));
  }

  changeAccount(userId: string) {
    /*  return this.speNAccountsProxy.changeAccount(userId).subscribe(res => {
       return true;
     }); */
  }

  async getInfoProfile() {
    try {
      let userProfile: any;
      const userDetails: Promise<KeycloakProfileCustom> = this.keycloakService.loadUserProfile();      


      userDetails.then(res => {        
      })

      if (userDetails) {
        return userDetails;
      }
    } catch (e) {
      console.log('Failed to load user details', e);
    }
  }

  searchAttribute(keycloakProfile: KeycloakProfileCustom, attributeSearch: string) {
    if (keycloakProfile.attributes) {
      const keyNames = Object.keys(keycloakProfile.attributes);
      for (const attribute of keyNames) {
        if (attribute === attributeSearch) {
          return this.captureValue(keycloakProfile.attributes[attribute]);
        }
      }
    }
    return null;
  }

  private cleanAttribute(attribute: any) {
    if (attribute) {
      // @ts-ignore
      return (attribute + '').replace('"', '').replace('"', '');
    }
    return attribute;
  }

  private preparerExtraAttribute(keycloakProfile: KeycloakProfileCustom): ExtraAttribute[] {
    const extras: Array<ExtraAttribute> = new Array<ExtraAttribute>();

    if (keycloakProfile.attributes) {
      const keyNames = Object.keys(keycloakProfile.attributes);
      for (const attribute of keyNames) {
        if (
          attribute !== 'uuidAccount' &&
          attribute !== 'uuidWhiteLabel' &&
          attribute !== 'nameInitials' &&
          attribute !== 'codeBank' &&
          attribute !== 'nameBank' &&
          attribute !== 'agencyBank' &&
          attribute !== 'numberAccountBank' &&
          attribute !== 'isEC' &&
          attribute !== 'uuidWl' &&
          attribute !== 'uuidAccount'
        ) {
          extras.push({ key: attribute, value: this.captureValue(keycloakProfile.attributes[attribute]) });
        }
      }
      return extras;
    }

    return extras;
  }

  private captureValue(value: any) {
    if (value && typeof value === 'object' && value.constructor === Array) {
      return value[0];
    } else {
      return value;
    }
  }

  getRoles(): string[] {
    return this.keycloakService.getUserRoles(true);
  }

  isUserInRole(role: string, resource?: string): boolean {
    return this.keycloakService.isUserInRole(role, resource);
  }

  public logoutForTimeOut() {
    let redirectUrl = document.location.protocol + '//' + document.location.hostname;

    if (document.location.hostname.includes('localhost')) {
      redirectUrl += ':' + document.location.port;
    }
    this.onLogout(redirectUrl);
  }

  onLogout(redirectUri: string) {
    this.keycloakService.logout(redirectUri);
  }

  verifyRole(): string | undefined {
    if (this.keycloakService.isUserInRole('bo-consigned-audit')) {
      return 'audit'
    }

    if (this.keycloakService.isUserInRole('bo-consigned-liquidation')) {
      return 'liquidation'
    }

    if (this.keycloakService.isUserInRole('bo-consigned-manager')) {
      return 'manager'
    }

    if (this.keycloakService.isUserInRole('bo-consigned-insurance')) {
      return 'bo-consigned-insurance'
    }

    if (this.keycloakService.isUserInRole('bo-consigned-financial')) {
      return 'bo-consigned-financial'
    }

    if (this.keycloakService.isUserInRole(RoleTypeEnum.COMMITTEE)) {
      return RoleTypeEnum.COMMITTEE
    }

    if (this.keycloakService.isUserInRole(RoleTypeEnum.BACKOFFICE)) {
      return RoleTypeEnum.BACKOFFICE
    }

    if (this.keycloakService.isUserInRole(RoleTypeEnum.CLIENT)) {
      return RoleTypeEnum.CLIENT
    }

    if (this.keycloakService.isUserInRole(RoleTypeEnum.TREASURY)) {
      return RoleTypeEnum.TREASURY
    }

    if (this.keycloakService.isUserInRole(RoleTypeEnum.GUARANTOR)) {
      return RoleTypeEnum.GUARANTOR
    }

  };

  verifyRoleReturn(): string {
    if (this.keycloakService.isUserInRole('bo-consigned-audit')) {
      return 'bo-consigned-audit'
    }

    if (this.keycloakService.isUserInRole('bo-consigned-liquidation')) {
      return 'bo-consigned-liquidation'
    }

    if (this.keycloakService.isUserInRole('bo-consigned-manager')) {
      return 'bo-consigned-manager'
    }


    if (this.keycloakService.isUserInRole('bo-consigned-financial')) {
      return 'bo-consigned-financial'
    }

    if (this.keycloakService.isUserInRole(RoleTypeEnum.COMMITTEE)) {
      return RoleTypeEnum.COMMITTEE;
    }

    if (this.keycloakService.isUserInRole(RoleTypeEnum.BACKOFFICE)) {
      return RoleTypeEnum.BACKOFFICE
    }

    if (this.keycloakService.isUserInRole(RoleTypeEnum.CLIENT)) {
      return RoleTypeEnum.CLIENT
    }

    if (this.keycloakService.isUserInRole(RoleTypeEnum.COMMERCIAL)) {
      return RoleTypeEnum.COMMERCIAL
    }

    if (this.keycloakService.isUserInRole(RoleTypeEnum.GUARANTOR)) {
      return RoleTypeEnum.GUARANTOR
    }

    if (this.keycloakService.isUserInRole(RoleTypeEnum.TREASURY)) {
      return RoleTypeEnum.TREASURY
    }

    return '';
  };

  isAudit(): boolean {
    return this.keycloakService.isUserInRole('bo-consigned-audit')
  }

   getRoleOperation()  : RoleTypeEnum | undefined {

    if(this.isCommittee()) return RoleTypeEnum.COMMITTEE;
    if(this.isGuarantor()) return RoleTypeEnum.GUARANTOR;
    if(this.isTreasury()) return RoleTypeEnum.TREASURY;
    if(this.isBackOffice()) return RoleTypeEnum.BACKOFFICE;
    if(this.isClient()) return RoleTypeEnum.CLIENT;

    return undefined;
  }

  isCommittee() : boolean{
    return this.keycloakService.isUserInRole(RoleTypeEnum.COMMITTEE)
  }

  isTreasury(): boolean{
    return this.keycloakService.isUserInRole(RoleTypeEnum.TREASURY)
  }

  isGuarantor(): boolean{
    return this.keycloakService.isUserInRole(RoleTypeEnum.GUARANTOR)
  }

  isClient(): boolean {
    return this.keycloakService.isUserInRole(RoleTypeEnum.CLIENT)
  }

  isBackOffice() : boolean{
    return this.keycloakService.isUserInRole(RoleTypeEnum.BACKOFFICE)
  }

  isCommercial() : boolean{
    return this.keycloakService.isUserInRole(RoleTypeEnum.COMMERCIAL)
  }

  isLiquidation(): boolean {
    return this.keycloakService.isUserInRole('bo-consigned-liquidation')
  }

  isFinancial(): boolean {
    return this.keycloakService.isUserInRole('bo-consigned-financial')
  }

  isAttendant(): boolean {
    return this.keycloakService.isUserInRole('BC_GPC_CT_VIEW_001') ||
      this.keycloakService.isUserInRole('BC_GPC_PP_VIEW_001') ||
      this.keycloakService.isUserInRole('BC_GPC_CL_VIEW_001');
  }

  isManager(): boolean {
    return this.keycloakService.isUserInRole('bo-consigned-manager')
  }

}

export class UserInfoRole {
  @Injectable()
  public keycloakService: KeycloakService

  constructor(

  ) { }


  isAudit(): boolean {
    return this.keycloakService.isUserInRole('bo-consigned-audit')
  }

  isLiquidation(): boolean {
    return this.keycloakService.isUserInRole('bo-consigned-liquidation')
  }


}
