import { UserInfoService } from './../../../../../core/_base/_auth/user-info.service';
import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  @Input() appHeaderDefaulMenuDisplay: boolean;
  @Input() isRtl: boolean;

  itemClass: string = 'ms-1 ms-lg-3';
  btnClass: string =
    'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px';
  userAvatarClass: string = 'symbol-35px symbol-md-40px';
  btnIconClass: string = 'svg-icon-1';
  name: any;

  constructor(
    private user: UserInfoService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.user.getInfoProfile().then((res: any) => {
      this.name = res.firstName;
    })
  }
}
