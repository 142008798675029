import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { CurrencyPipe, DatePipe, ViewportScroller } from "@angular/common";
import { FormGroup } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { SimulationService } from "src/app/core/scfi/services/services-simulation/simulation.service";
import { CustomerService } from "src/app/core/scfi/services/services-customer/customer.service";
import { ProductsService } from "src/app/core/scfi/services/services-product/products.service";
import { AbstractScfi } from "../../abstract-scfi";
import { ProductDto } from "src/app/core/scfi/services/services-product/products.dto";
import { TypeProductDto } from "src/app/core/scfi/services/services-product/type-product.dto";
import {
    ErrorMessagesSimulationRequestDto,
    SimulationEntryParamsType,
} from "src/app/core/scfi/services/services-simulation/dto/simulation.dto";
import { CreateCustomerDtoType, CustomerContactDto, CustomerDto } from "src/app/core/scfi/services/services-customer/customer.dto";
import { TypeTacEnum } from "src/app/core/scfi/services/services-fees/type-tac.enum";
import { ScfiCalculatorResponseType, ScfiPickSimulationResponseType } from 'src/app/core/scfi/services/services-simulation/dto/calculator.dto';
import { InstallmentDetailsDto } from 'src/app/core/scfi/services/services-proposal/proposal.dto';
import { TypeCustomerEnum } from 'src/app/core/scfi/services/services-product/type-customer.enum';

@Component({
    selector: 'app-simulation-form',
    templateUrl: './simulation-form.component.html',
    styleUrls: ['./simulation-form.component.scss']
})

export class SimulationFormComponent extends AbstractScfi implements OnInit {
    formSimulation: FormGroup;
    errorMessagesSimulation: ErrorMessagesSimulationRequestDto;
    isPjClient: boolean;
    customer: CustomerDto;
    productTypeList: TypeProductDto[];
    productsList: ProductDto[];
    quantityInstallments: number[];
    isMoneyTac: boolean = true;
    isPercentTac: boolean = false;
    hasSimulation: boolean = false;
    installments: InstallmentDetailsDto[] = [];
    customerTypeUuid: string;
    panelOpenState: boolean = false;
    sfiCalculatorResponseType: ScfiCalculatorResponseType;

    constructor(
        private simulationService: SimulationService,
        private customerService: CustomerService,
        private productService: ProductsService,
        private spinner: NgxSpinnerService,
        private cdr: ChangeDetectorRef,
        protected currencyPipe: CurrencyPipe,
        protected datePipe: DatePipe,
        private viewportScroller: ViewportScroller
    ) {
        super(currencyPipe, datePipe)
    }

    ngOnInit() {
        this.buildFormSimulation();
        this.findProductTypeList();
    }

    public findProductTypeList() {
        this.productService.findProductTypeWithProductConfiguredList(environment.wlp).subscribe({
            next: (productTypeList: TypeProductDto[]) => {
                this.productTypeList = productTypeList;
                this.cdr.detectChanges();
            },
            error: () => {
                this.showErrorModal('Erro ao buscar os tipos de produto!');
            }
        });
    }

    public findProductList(e: Event) {
        const uuidProductType = (e.target as HTMLInputElement).value;
        this.productService.findFullRegisterProductList(environment.wlp, uuidProductType).subscribe({
            next: (productsList: ProductDto[]) => {
                this.productsList = productsList;
                this.cdr.detectChanges();
            },
            error: (error) => {
                this.showErrorModal('Erro ao buscar os produtos!');
            }
        });
    }

    findProductByUuid(uuid: string) {
        this.productService.getProductByUuid(uuid).subscribe({
            next: (product: ProductDto) => {
                if (product && product.configuration) {
                    const configByCustomerType = this.getConfigByCustomerType(product);

                    if (configByCustomerType) {
                        const detail = configByCustomerType.detail;
                        const fee = configByCustomerType.fee.productConfigurationFee;
                        const typeCustomer = configByCustomerType.typeCustomer;

                        this.formSimulation.patchValue({
                            minValue: detail.minValue,
                            maxValue: detail.maxValue,
                            minInstallment: detail.minInstallment,
                            maxInstallment: detail.maxInstallment,
                            typeFee: fee.name,
                            valueTac: fee.valueTac,
                            interestRate: fee.interestRate
                        });

                        this.isMoneyTac = fee.typeTac === TypeTacEnum.FIXED;
                        this.isPercentTac = !this.isMoneyTac;
                        this.quantityInstallments = Array.from({ length: detail.maxInstallment - detail.minInstallment + 1 }, (_, index) => index + detail.minInstallment);
                        this.customerTypeUuid = typeCustomer.uuid;
                        this.cdr.detectChanges();
                    }
                }
            },
            error: (error) => {
                this.showErrorModal('Erro ao buscar detalhes do produto!');
            }
        });
    }

    getConfigByCustomerType(product: ProductDto) {
        const selectedCustomerType = this.isPjClient ? TypeCustomerEnum.PJ : TypeCustomerEnum.PF;
        return product.configuration.find(config => config.typeCustomer.type === selectedCustomerType);
    }

    changeClientType(e: MatSlideToggleChange) {
        this.isPjClient = e.checked;
        this.buildFormSimulation();
        this.clearFormFields();
        this.productTypeList = [];
        this.productsList = [];
        this.findProductTypeList();
    }

    async findCustomerByDocument(e: Event) {
        const document = (e.target as HTMLInputElement).value;
        const numericDocument = document.replace(/\D/g, '');
        const isCnpjValid = numericDocument.length === 14;
        const isCpfValid = numericDocument.length === 11;

        if (!isCnpjValid && !isCpfValid) {
            this.showInvalidDocumentError();
            this.spinner.hide();
            return;
        }

        if (isCpfValid && this.isPjClient) {
            this.showInvalidDocumentError();
            this.spinner.hide();
            return;
        }

        try {
            this.spinner.show();
            const customer = await this.customerService.findCustomerByDocument(document).toPromise();
            if (customer) {
                const contact = await this.findContactCustomer(customer.uuid).toPromise();
                this.customer = customer;
                this.customer.contact[0] = contact as CustomerContactDto;

                if (isCnpjValid) {
                    this.formSimulation.patchValue({
                        cpf: '',
                        cnpj: customer.document,
                        name: customer.name,
                        phone: contact?.phone,
                        email: contact?.email
                    });
                } else {
                    this.formSimulation.patchValue({
                        cnpj: '',
                        cpf: customer.document,
                        name: customer.name,
                        phone: contact?.phone,
                        email: contact?.email
                    });
                }
            } else {
                this.clearFormFields();
            }
            this.spinner.hide();
        } catch (error) {
            this.spinner.hide();
        }
    }

    findContactCustomer(uuid: string): Observable<CustomerContactDto> {
        return this.customerService.findContactCustomerByUuid(uuid);
    }

    buildFormSimulation() {
        this.errorMessagesSimulation = this.simulationService.getErrorMessages();
        this.formSimulation = this.simulationService.createSimulation();
    }

    simulate() {
        const simulationEntryParams = this.prepareSimulationParams();

        this.spinner.show();

        this.simulationService.simulate(simulationEntryParams).subscribe({
            next: (scfiCalculatorResponseType: ScfiCalculatorResponseType) => {
                this.processSimulationResults(scfiCalculatorResponseType);
                this.spinner.hide();
            },
            error: () => {
                this.showErrorModal('Erro ao simular!');
                this.spinner.hide();
            }
        });
    }

    prepareSimulationParams(): SimulationEntryParamsType {
        const formSimulate = this.formSimulation.value;

        return {
            uuidProduct: formSimulate.product,
            uuidTypeCustomer: this.customerTypeUuid,
            value: parseFloat(formSimulate.loanAmount),
            valueTac: parseFloat(formSimulate.valueTac),
            interestRate: parseFloat(formSimulate.interestRate),
            installmentsNumber: parseFloat(formSimulate.quantityInstallments),
            gracePeriod: parseInt(formSimulate.gracePeriod),
            includeIofInFinancing: formSimulate.isIofIncluded !== null ? formSimulate.isIofIncluded : false
        };
    }

    prepareCustomer(): CreateCustomerDtoType {
        const formSimulate = this.formSimulation.value;

        return {
            uuidTypeCustomer: this.customerTypeUuid,
            name: formSimulate.name,
            document: formSimulate.document,
            contact: {
                email: formSimulate.email,
                phone: formSimulate.phone
            }
        };
    }

    processSimulationResults(scfiCalculatorResponseType: ScfiCalculatorResponseType) {
        this.installments = scfiCalculatorResponseType.parcelas_price.map((parcela) => ({
            dateInstallment: parcela.data_parcela,
            numberInstallment: parcela.periodo,
            interestInstallment: parcela.juros,
            amortInstallment: parcela.amortizacao,
            installmentValue: parcela.prestacao,
            amountDue: parcela.saldo_devedor
        }));

        this.hasSimulation = true;
        this.sfiCalculatorResponseType = scfiCalculatorResponseType;
        this.cdr.detectChanges();
        this.viewportScroller.scrollToAnchor('bottom');
    }

    clearFormFields() {
        this.formSimulation.patchValue({
            name: '',
            phone: '',
            email: ''
        });
    }

    showInvalidDocumentError() {
        this.clearFormFields();
        this.errorMessagesSimulation.document = [{ type: 'invalid', message: 'Documento inválido' }];
        this.formSimulation.controls['document'].setErrors({ 'invalid': true });
    }

    pickSimulation() {
        const simulationEntryParams = this.prepareSimulationParams();
        const simulationCustomer = this.prepareCustomer();

        const pickSimulationEntryParams = {
            simulation: simulationEntryParams,
            customer: simulationCustomer
        }

        this.spinner.show();

        this.simulationService.pickSimulation(pickSimulationEntryParams).subscribe({
            next: async (scfiPickSimulationResponseType: ScfiPickSimulationResponseType) => {
                this.spinner.hide();
                await this.showSuccessModal('Simulação enviada com sucesso!');
                this.formSimulation.reset();
                this.hasSimulation = false;
            },
            error: () => {
                this.spinner.hide();
                this.showErrorModal('Erro ao enviar simulação!');
            }
        });
    }
}
