import { SpeNBackofficeConsignedProxy } from 'src/app/core/proxy/spe/spe-n-backoffice-consigned';
import { FormsModule } from '@angular/forms';
import { ProposalServices } from './services-apis/proposals-services';
import { UiCDatePickerModule } from 'src/app/shared/components/date-picker/date-picker.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProposalsRoutingModule } from './proposals-routing.module';
import { ProposalsListComponent } from './proposals-list/proposals-list.component';
import { ProposalsFormsComponent } from './proposals-forms/proposals-forms.component';
import { TablesimulatorComponent } from './tablesimulator/tablesimulator.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { UiCInputModule } from 'src/app/shared/components/ui-c-input/ui-c-input.module';
import { MaskerModule } from 'src/app/shared/services-ultil/masker-service/masker-module.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptService } from 'src/app/core/_base/_auth/intercept/intercept.service';
import { TableFinanceContractDetailModule } from 'src/app/shared/components/table-details-contract/table-finance-contract.module';
import { TruncateService } from 'src/app/shared/services-ultil/truncate-service/truncate.service';




@NgModule({
  declarations: [
    ProposalsListComponent,
    ProposalsFormsComponent,
    TablesimulatorComponent,
  ],
  imports: [
    CommonModule,
    ProposalsRoutingModule,
    SharedModule,
    MatIconModule,
    MatPaginatorModule,
    MatTableModule,
    MatFormFieldModule,
    UiCInputModule,
    MaskerModule,
    UiCDatePickerModule,
    FormsModule,
    TableFinanceContractDetailModule
  ],
  providers: [
    // MaskService,
    InterceptService,
    SpeNBackofficeConsignedProxy,
    ProposalServices,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
    TruncateService
  ],
})
export class ProposalsModule { }
