<div class="date-picker w-100">
  <form autocomplete="off">
    <div class="card-body">
      <div class="form-group p-0 m-0">
        <div class="d-flex p-0 w-100">
          <div class="companies d-flex">
            <mat-form-field appearance="fill" class="partners w-100 p-0">
              <mat-label class="font-lg-5">Parceiras</mat-label>
              <mat-select [(value)]="companie">
                <mat-option
                  *ngFor="let item of companieList"
                  value="{{ item?.uuid }}"
                  (click)="changeCompanies(item.uuid)"
                >
                  {{ item?.company?.toUpperCase() }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
