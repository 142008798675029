import { Routes, RouterModule } from '@angular/router';
import { CustomerListComponent } from './customer-list/customer-list.component';
import { CustomerFormComponent } from './customer-form/customer-form.component';

const routes: Routes = [
  {
    path: "customer-list",
    component: CustomerListComponent
  },
  {
    path: "edit-customer/:type/:uuid",
    component: CustomerFormComponent
  },
  {
    path: "new-customer",
    component: CustomerFormComponent
  },
];

export const CustomersRoutes = RouterModule.forChild(routes);
