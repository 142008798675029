import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { ProposalCustomerDetailsComponent } from './proposal-customer-details.component';
import { UiCInputModule } from '../ui-c-input/ui-c-input.module';

@NgModule({
    imports: [
        CommonModule,
        InlineSVGModule,
        UiCInputModule
    ],
    declarations: [ProposalCustomerDetailsComponent],
    exports: [ProposalCustomerDetailsComponent],
})
export class ProposalCustomerDetailsModule { }
