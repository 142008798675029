import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiService } from 'src/app/core/_base/_auth/api.service';
import { FormControl, Validators } from '@angular/forms';
import { SpeViewNScfiProxy } from 'src/app/core/proxy/view/spe-view-n-scfi.proxy';
import { Observable } from 'rxjs';
import { SpeNScfiProxy } from 'src/app/core/proxy/spe/spe-n-scfi.proxy';
import { ConfigurationFeeDto, CreateFeeDto, UpdateFeeDto } from './fees.dto';

@Injectable({
    providedIn: 'root'
})
export class FeesService {

    formFees: FormGroup;

    constructor(
        protected api: ApiService,
        private formBuilder: FormBuilder,
        private speViewNScfiProxy: SpeViewNScfiProxy,
        private speNScfiProxy: SpeNScfiProxy
    ) { }

    public createFormFees(): FormGroup {
        this.formFees = this.formBuilder.group({
            name: new FormControl('', Validators.compose([
                Validators.required
            ])),
            minInterestRate: new FormControl('', Validators.compose([
                Validators.required,
                Validators.min(0.000001)
            ])),
            interestRate: new FormControl('', Validators.compose([
                Validators.required,
                Validators.min(0.000001)
            ])),
            typeTac: new FormControl('', Validators.compose([
                Validators.required
            ])),
            minValueTac: new FormControl('', Validators.compose([
                Validators.required
            ])),
            valueTac: new FormControl('', Validators.compose([
                Validators.required
            ])),
        })
        return this.formFees;
    }

    public getErrorMessages() {
        return {
            name: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
            ],
            minInterestRate: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
                {
                    type: 'min',
                    message: 'A taxa deve ser maior do que zero',
                }
            ],
            interestRate: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
                {
                    type: 'min',
                    message: 'A taxa deve ser maior do que zero',
                }
            ],
            typeTac: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                }
            ],
            minValueTac: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                }
            ],
            valueTac: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                }
            ]
        }
    }

    public findFeeList(): Observable<ConfigurationFeeDto[]> {
        return this.speViewNScfiProxy.findFeeList();
    }

    public findFeeByUuid(uuid: string): Observable<ConfigurationFeeDto> {
        return this.speViewNScfiProxy.findFeeByUuid(uuid);
    }

    public createFee(fee: CreateFeeDto): Observable<ConfigurationFeeDto> {
        return this.speNScfiProxy.createFee(fee);
    }

    public updateFee(fee: UpdateFeeDto): Observable<ConfigurationFeeDto> {
        return this.speNScfiProxy.updateFee(fee);
    }

    public deleteFee(uuid: string): Observable<ConfigurationFeeDto[]> {
        return this.speNScfiProxy.deleteFee(uuid);
    }
}