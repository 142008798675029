<div id="customer-form-component">
    <h1 class="page-title mb-4">{{ isEditMode ? 'Alteração de Dados do Cliente' : 'Cadastro de Cliente' }}</h1>

    <div class="d-flex justify-content-end mb-4">
        <button type="button" class="btn btn-warning" (click)="backToLastPage()">
            <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr074.svg'"
                class="svg-icon svg-icon-1 me-4"></span>
            <span class="pt-2">Voltar</span>
        </button>
    </div>

    <div class="toggle-container mb-8">
        <p class="toggle-label">PF</p>
        <mat-slide-toggle class="mx-6" color="primary" [checked]="isPjClient" (change)="changeClientType($event)">
        </mat-slide-toggle>
        <p class="toggle-label">PJ</p>
    </div>

    <ng-container *ngIf="isPjClient; else isPfClient">
        <section class="card mb-4 shadow-sm">
            <div class="card-header border-0 d-flex align-items-center justify-content-start">
                <span [inlineSVG]="'./assets/media/icons/duotune/communication/com014.svg'"
                    class="svg-icon svg-icon-2tx svg-icon-gray-800 me-4"></span>
                <h3 class="fw-bolder card-title mt-3">Identificação</h3>
            </div>

            <div class="card-body border-top p-9">

                <div class="d-flex">
                    <div class="col-3 px-10">
                        <app-ui-c-input [form]="formCustomerIdentification" [errorMessages]="errorMessagesCustomer"
                            [formName]="'document'" [name]="'CNPJ:'" [isCNPJ]="true"
                            [placeholder]="'Digite o CNPJ'"></app-ui-c-input>
                    </div>
                    <div class="col-3 px-10">
                        <app-ui-c-input [form]="formCustomerIdentification" [errorMessages]="errorMessagesCustomer"
                            [formName]="'name'" [name]="'Razão Social:'" [type]="'text'"
                            [placeholder]="'Digite a razão social'"></app-ui-c-input>
                    </div>
                    <div class="col-3 px-10">
                        <app-ui-c-input [form]="formCustomerIdentification" [formName]="'fantasyName'" [name]="'Nome Fantasia:'"
                            [type]="'text'" [placeholder]="'Digite o nome fantasia'"></app-ui-c-input>
                    </div>
                </div>
            </div>
        </section>

        <div class="d-flex justify-content-end my-8">
            <button type="button" class="btn btn-primary btn-default" [disabled]="formCustomerIdentification.invalid"
                (click)="saveCustomerIdentification()">
                Salvar
            </button>
        </div>

        <div class="data-container">
            <section class="card mb-4 shadow-sm" *ngIf="isPjClient; else isPfClient">
                <div class="card-header border-0 d-flex align-items-center justify-content-start">
                    <span [inlineSVG]="'./assets/media/icons/duotune/general/gen045.svg'"
                        class="svg-icon svg-icon-2tx svg-icon-gray-800 me-4"></span>
                    <h3 class="fw-bolder card-title mt-3">Dados</h3>
                </div>

                <div class="card-body border-top p-9">

                    <div class="d-flex">
                        <div class="col-3 px-10">
                            <app-ui-c-input [form]="formCustomerData" [formName]="'stateRegistration'"
                                [name]="'Inscrição Estadual:'" [isNumber]="true"
                                [placeholder]="'Digite a inscrição estadual'"></app-ui-c-input>
                        </div>
                        <div class="col-3 px-10">
                            <app-ui-c-input [form]="formCustomerData" [errorMessages]="errorMessagesCustomer"
                                [formName]="'score'" [name]="'Serasa:'" [isNumber]="true"
                                [placeholder]="'Digite o score'"></app-ui-c-input>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <div class="d-flex justify-content-end my-8">
            <button type="button" class="btn btn-primary btn-default" [disabled]="formCustomerData.invalid"
                (click)="saveCustomerData()">
                Salvar
            </button>
        </div>

    </ng-container>

    <ng-template #isPfClient>
        <section class="card mb-4 shadow-sm">
            <div class="card-header border-0 d-flex align-items-center justify-content-start">
                <span [inlineSVG]="'./assets/media/icons/duotune/communication/com013.svg'"
                    class="svg-icon svg-icon-2tx svg-icon-gray-800 me-4"></span>
                <h3 class="fw-bolder card-title mt-3">Identificação</h3>
            </div>

            <div class="card-body border-top p-9">
                <div class="d-flex">
                    <div class="col-3 px-10">
                        <app-ui-c-input [form]="formCustomerIdentification" [errorMessages]="errorMessagesCustomer"
                            [formName]="'document'" [name]="'CPF:'" [isCPF]="true"
                            [placeholder]="'Digite o CPF'"></app-ui-c-input>
                    </div>
                    <div class="col-3 px-10">
                        <app-ui-c-input [form]="formCustomerIdentification" [errorMessages]="errorMessagesCustomer"
                            [formName]="'name'" [name]="'Nome:'" [type]="'text'"
                            [placeholder]="'Digite o nome do cliente'"></app-ui-c-input>
                    </div>
                    <div class="col-3 px-10">
                        <app-ui-c-input [form]="formCustomerIdentification" [formName]="'fantasyName'" [name]="'Nome Social:'"
                            [type]="'text'" [placeholder]="'Digite o nome social'"></app-ui-c-input>
                    </div>
                </div>
            </div>
        </section>

        <div class="d-flex justify-content-end my-8">
            <button type="button" class="btn btn-primary btn-default" [disabled]="formCustomerIdentification.invalid"
                (click)="saveCustomerIdentification()">
                Salvar
            </button>
        </div>

        <div class="data-container">
            <section class="card mb-4 shadow-sm">
                <div class="card-header border-0 d-flex align-items-center justify-content-start">
                    <span [inlineSVG]="'./assets/media/icons/duotune/general/gen045.svg'"
                        class="svg-icon svg-icon-2tx svg-icon-gray-800 me-4"></span>
                    <h3 class="fw-bolder card-title mt-3">Dados</h3>
                </div>

                <div class="card-body border-top p-9">

                    <div class="d-flex">
                        <div class="col-3 px-10">
                            <app-ui-c-input [form]="formCustomerData" [formName]="'motherName'" [name]="'Nome da Mãe:'"
                                [type]="'text'" [placeholder]="'Digite o nome da mãe'"></app-ui-c-input>
                        </div>
                        <div class="col-3 px-10">
                            <app-ui-c-input [form]="formCustomerData" [formName]="'fatherName'" [name]="'Nome do Pai:'"
                                [type]="'text'" [placeholder]="'Digite o nome do pai'"></app-ui-c-input>
                        </div>
                        <div class="col-3 px-10">
                            <app-ui-c-input [form]="formCustomerData" [errorMessages]="errorMessagesCustomer"
                                [formName]="'birthday'" [name]="'Data de Nascimento:'" [isDate]="true"
                                [placeholder]="'Selecione a data de nascimento'"></app-ui-c-input>
                        </div>
                        <div class="col-3 px-10">
                            <app-ui-c-input [form]="formCustomerData" [errorMessages]="errorMessagesCustomer"
                                [formName]="'rg'" [name]="'RG:'" [isNumber]="true"
                                [placeholder]="'Digite o número do RG'"></app-ui-c-input>
                        </div>
                    </div>

                    <div class="d-flex mt-10">
                        <div class="col-3 px-10">
                            <app-ui-c-input [form]="formCustomerData" [errorMessages]="errorMessagesCustomer"
                                [formName]="'issuer'" [name]="'Órgão Emissor:'" [type]="'text'"
                                [placeholder]="'Digite o orgão emissor do RG'"></app-ui-c-input>
                        </div>
                        <div class="col-3 px-10">
                            <app-ui-c-input [form]="formCustomerData" [errorMessages]="errorMessagesCustomer"
                                [formName]="'issueDate'" [name]="'Data de Emissão:'" [isDate]="true"
                                [placeholder]="'Selecione a data de emissão do RG'"></app-ui-c-input>
                        </div>
                        <div class="col-3 px-10">
                            <app-ui-c-input [form]="formCustomerData" [errorMessages]="errorMessagesCustomer"
                                [formName]="'score'" [name]="'Serasa:'" [isNumber]="true"
                                [placeholder]="'Digite o score'"></app-ui-c-input>
                        </div>
                    </div>
                </div>
            </section>

            <div class="d-flex justify-content-end my-8">
                <button type="button" class="btn btn-primary btn-default" [disabled]="formCustomerData.invalid"
                    (click)="saveCustomerData()">
                    Salvar
                </button>
            </div>
        </div>
    </ng-template>

    <div class="contact-container">
        <section class="card mb-4 shadow-sm">
            <div class="card-header border-0 d-flex align-items-center justify-content-start">
                <span [inlineSVG]="'./assets/media/icons/duotune/communication/com012.svg'"
                    class="svg-icon svg-icon-2tx svg-icon-gray-800 me-4"></span>
                <h3 class="fw-bolder card-title mt-3">Contato</h3>
            </div>

            <div class="card-body border-top p-9">
                <div class="d-flex">
                    <div class="col-3 px-10">
                        <app-ui-c-input [form]="formCustomerContact" [errorMessages]="errorMessagesCustomer"
                            [formName]="'phone'" [name]="'Celular:'" [isPhone]="true"
                            [placeholder]="'Digite o celular'"></app-ui-c-input>
                    </div>
                    <div class="col-3 px-10">
                        <app-ui-c-input [form]="formCustomerContact" [errorMessages]="errorMessagesCustomer"
                            [formName]="'email'" [name]="'E-mail:'" [type]="'text'"
                            [placeholder]="'Digite o e-mail'"></app-ui-c-input>
                    </div>
                </div>
            </div>
        </section>

        <div class="d-flex justify-content-end my-8">
            <button type="button" class="btn btn-primary btn-default" [disabled]="formCustomerContact.invalid"
                (click)="saveCustomerContact()">
                Salvar
            </button>
        </div>
    </div>

    <div class="address-container">
        <section class="card mb-4 shadow-sm">
            <div class="card-header border-0 d-flex align-items-center justify-content-start">
                <span [inlineSVG]="'./assets/media/icons/duotune/maps/map008.svg'"
                    class="svg-icon svg-icon-2tx svg-icon-gray-800 me-4"></span>
                <h3 class="fw-bolder card-title mt-3">Endereço</h3>
            </div>

            <div class="card-body border-top p-9">
                <form [formGroup]="formCustomerAddress">
                    <div class="d-flex">
                        <div class="col-3 px-10">
                            <label class="form-label fs-6 fw-bolder text-dark mt-3">Tipo de Endereço:</label>
                            <select class="form-select form-select-solid form-select-lg text-dark"
                                formControlName="uuidTypeAddress" (change)="findAddressTypeList()">
                                <option *ngFor="let addressType of addressTypeList" [value]="addressType.uuid">{{
                                    addressType.name }}</option>
                            </select>
                        </div>
                        <div class="col-3 px-10">
                            <app-ui-c-input [form]="formCustomerAddress" [errorMessages]="errorMessagesCustomer"
                                [formName]="'zipcode'" [name]="'CEP:'" [isCEP]="true"
                                [placeholder]="'Digite o CEP'" (change)="getAddressByCep(formCustomerAddress.get('zipcode')?.value)"></app-ui-c-input>
                        </div>
                        <div class="col-3 px-10">
                            <app-ui-c-input [form]="formCustomerAddress" [errorMessages]="errorMessagesCustomer"
                                [formName]="'street'" [name]="'Rua:'" [type]="'text'"
                                [placeholder]="'Digite a rua'"></app-ui-c-input>
                        </div>
                        <div class="col-3 px-10">
                            <app-ui-c-input [form]="formCustomerAddress" [errorMessages]="errorMessagesCustomer"
                                [formName]="'number'" [name]="'Número:'" [isNumber]="true"
                                [placeholder]="'Digite o número'"></app-ui-c-input>
                        </div>
                    </div>
                    <div class="d-flex mt-10">
                        <div class="col-3 px-10">
                            <app-ui-c-input [form]="formCustomerAddress" [errorMessages]="errorMessagesCustomer"
                                [formName]="'complement'" [name]="'Complemento:'" [type]="'text'"
                                [placeholder]="'Digite o complemento'"></app-ui-c-input>
                        </div>
                        <div class="col-3 px-10">
                            <app-ui-c-input [form]="formCustomerAddress" [errorMessages]="errorMessagesCustomer"
                                [formName]="'neighborhood'" [name]="'Bairro:'" [type]="'text'"
                                [placeholder]="'Digite o bairro'"></app-ui-c-input>
                        </div>
                        <div class="col-3 px-10">
                            <app-ui-c-input [form]="formCustomerAddress" [errorMessages]="errorMessagesCustomer"
                                [formName]="'city'" [name]="'Cidade:'" [type]="'text'"
                                [placeholder]="'Digite a cidade'"></app-ui-c-input>
                        </div>
                        <div class="col-3 px-10">
                            <label class="form-label fs-6 fw-bolder text-dark mt-3">Estado:</label>
                            <select class="form-select form-select-solid form-select-lg text-dark"
                                formControlName="state">
                                <option *ngFor="let state of states"> {{state.name}} </option>
                            </select>
                        </div>
                    </div>
                    <div class="d-flex mt-10">
                        <div class="col-3 px-10">
                            <app-ui-c-input [form]="formCustomerAddress" [errorMessages]="errorMessagesCustomer"
                                [formName]="'country'" [name]="'País:'" [type]="'text'"
                                [placeholder]="'Digite o país'"></app-ui-c-input>
                        </div>
                    </div>
                </form>
            </div>
        </section>

        <div class="d-flex justify-content-end my-8">
            <button type="button" class="btn btn-primary btn-default" [disabled]="formCustomerAddress.invalid"
                (click)="saveCustomerAddress()">
                Salvar
            </button>
        </div>
    </div>

    <div class="bank-account-container">
        <section class="card mb-4 shadow-sm">
            <div class="card-header border-0 d-flex align-items-center justify-content-start">
                <span [inlineSVG]="'./assets/media/icons/duotune/finance/fin001.svg'"
                    class="svg-icon svg-icon-2tx svg-icon-gray-800 me-4"></span>
                <h3 class="fw-bolder card-title mt-3">Dados Bancários</h3>
            </div>

            <div class="card-body border-top p-9">
                <form [formGroup]="formCustomerBankAccount">
                    <div class="d-flex">
                        <div class="col-3 px-10">
                            <label class="form-label fs-6 fw-bolder text-dark mt-3"> Tipo de Conta: </label>
                            <select class="form-select form-select-solid form-select-lg text-dark"
                                formControlName="accountType">
                                <option value="" selected hidden>Selecione um tipo</option>
                                <option value="PG">Conta Pagamento</option>
                                <option value="CC">Conta Corrente</option>
                                <option value="PP">Conta-Poupança</option>
                            </select>
                        </div>
                        <div class="col-3 px-10">
                            <app-ui-c-input [form]="formCustomerBankAccount" [formName]="'bankNumber'" [name]="'Número do Banco:'"
                                [isNumber]="true" [placeholder]="'Digite o número do banco'"></app-ui-c-input>
                        </div>
                        <div class="col-3 px-10">
                            <app-ui-c-input [form]="formCustomerBankAccount" [formName]="'bankName'" [name]="'Nome do Banco:'"
                                [type]="'text'" [placeholder]="'Digite o nome do banco'"></app-ui-c-input>
                        </div>
                        <div class="col-3 px-10">
                            <app-ui-c-input [form]="formCustomerBankAccount" [formName]="'agency'" [name]="'Agência:'"
                                [isAgency]="true" [placeholder]="'Digite a agência'"></app-ui-c-input>
                        </div>
                    </div>
                    <div class="d-flex mt-10">
                        <div class="col-3 px-10">
                            <app-ui-c-input [form]="formCustomerBankAccount" [formName]="'accountNumber'"
                                [name]="'Número da Conta (sem dígito):'"
                                [placeholder]="'Digite o número da conta'"></app-ui-c-input>
                        </div>
                        <div class="col-3 px-10">
                            <app-ui-c-input [form]="formCustomerBankAccount" [formName]="'accountDigit'"
                                [name]="'Dígito da Conta:'" [placeholder]="'Digite o dígito da conta'"></app-ui-c-input>
                        </div>
                    </div>
                </form>
            </div>
        </section>

        <div class="d-flex justify-content-end my-8">
            <button type="button" class="btn btn-primary btn-default" [disabled]="formCustomerBankAccount.invalid"
                (click)="saveCustomerBankAccount()">
                Salvar
            </button>
        </div>
    </div>

    <div class="docs-container">
        <section class="card mb-8 shadow-sm">
            <div class="card-header border-0 d-flex align-items-center justify-content-start">
                <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr097.svg'"
                    class="svg-icon svg-icon-2tx svg-icon-gray-800 me-4"></span>
                <h3 class="fw-bolder card-title mt-3"> Upload de Documentos </h3>
            </div>

            <div class="card-body border-top p-9">

                <div class="d-flex">

                    <div class="approve-container col-4">
                        <select class="form-select form-select-solid form-select-lg text-dark w-50">
                            <option value="" selected hidden> Selecione um status </option>
                            <option value=""> Status: Aprovado </option>
                            <option value=""> Status: Reprovado </option>
                        </select>
                        <button type="button" class="btn btn-primary">
                            Visualizar
                        </button>
                    </div>

                    <div class="upload-container col-4">
                        <div class="upload-title-container">
                            <p class="upload-title"> Comprovante de residência </p>
                            <p class="upload-subtitle"> | Data {{ documentDate ? (documentDate | date:'dd/MM/yyyy') :
                                '-' }}
                                às {{ documentDate ? (documentDate | date:'HH:mm') : '-' }} </p>
                        </div>
                        <div class="border border-gray-500 border-dashed py-10">
                            <p> Para sobrepor o doc anterior <br>
                                Solte o arquivo aqui ou acione o botão </p>
                            <button type="button" class="btn btn-info">
                                Fazer Upload
                            </button>
                        </div>
                    </div>

                    <div class="comentary-container col-4 px-10">
                        <app-ui-c-input [form]="formCustomerDoc" [formName]="'comment'" [type]="'text'"
                            [placeholder]="'Deixe seu comentário...'"></app-ui-c-input>
                        <div class="comentary-btn-container">
                            <button type="button" class="btn btn-light-info">
                                Histórico
                            </button>
                            <button type="button" class="btn btn-info">
                                Salvar
                            </button>
                        </div>
                    </div>

                </div>

            </div>
        </section>
    </div>

    <section *ngIf="isPjClient">
        <ng-container *ngIf="partnerTable">
            <ui-c-table [tableData]="partnerTable"></ui-c-table>
        </ng-container>

        <div class="d-flex justify-content-end my-8">
            <button type="button" class="btn btn-success btn-default" (click)="redirectToEditPartner(true)">
                Adicionar Sócio
            </button>
        </div>
    </section>

</div>