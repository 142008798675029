import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { CurrencyPipe, DatePipe, Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { AbstractScfi } from "../../abstract-scfi";
import { KitsService } from "src/app/core/scfi/services/services-kits/kits.service";
import {
  ErrorMessagesKitsDto,
  CreateKitDto,
  ConfigurationKitDto,
  UpdateKitDto
} from "src/app/core/scfi/services/services-kits/kits.dto";
import { TypeCustomerDataDto } from 'src/app/core/scfi/services/services-customer-data/type-customer-data.dto';
import { TypeCustomerDataService } from 'src/app/core/scfi/services/services-customer-data/type-customer-data.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-kit-form',
  templateUrl: './kit-form.component.html',
  styleUrls: ['./kit-form.component.scss']
})
export class KitFormComponent extends AbstractScfi implements OnInit {
  public uuidKit: string;
  public isNewKit: boolean;
  public errorMessagesKit: ErrorMessagesKitsDto;
  public formKit: FormGroup;
  public selectedData: string[] = [];
  public dataList: string[];
  public data: TypeCustomerDataDto[];

  constructor(
    private location: Location,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private kitService: KitsService,
    protected currencyPipe: CurrencyPipe,
    protected datePipe: DatePipe,
    private dataService: TypeCustomerDataService,
    private spinner: NgxSpinnerService,
  ) {
    super(currencyPipe, datePipe)
  }

  ngOnInit() {
    this.activateRoute.params.subscribe(params => {
      this.uuidKit = params['uuidKit'];
      this.isNewKit = !this.uuidKit;

      this.buildFormKit();
      this.findDataList();

      if (this.uuidKit) {
        this.findByUuidWithData(this.uuidKit);
      }
    });
  }

  public findByUuidWithData(uuid: string) {
    this.spinner.show();
    this.kitService.findByUuidWithDocs(uuid).subscribe({
      next: (kit: ConfigurationKitDto) => {

        if (kit.documents) {
          this.selectedData = kit.documents
            .map(data => data.typeCustomerData?.name)
            .filter(name => name !== undefined) as string[];
        }

        this.formKit.patchValue({
          name: kit.name,
          documents: this.selectedData
        });

        this.spinner.hide();
        this.cdr.detectChanges();
      },
      error: () => {
        this.spinner.hide();
        this.showErrorModal('Erro ao buscar kit!');
      }
    });
  }

  public buildFormKit() {
    this.errorMessagesKit = this.kitService.getErrorMessages();
    this.formKit = this.kitService.createFormKit();
  }

  public async saveKit() {
    const confirmationMessage = this.isNewKit
      ? 'Tem certeza que deseja criar este kit?'
      : 'Tem certeza que deseja alterar este kit?';

    const confirmed = await this.showConfirmationModal(confirmationMessage);

    if (!confirmed) return;

    const selectedDataUuids: string[] = this.getSelectedDataUuids();
    const formKit = this.formKit.value;

    if (this.isNewKit) {
      const kit: CreateKitDto = {
        uuidWhiteLabel: environment.wlp,
        name: formKit.name,
        documents: selectedDataUuids
      }
      this.createKit(kit);
    } else {
      const kit: UpdateKitDto = {
        uuid: this.uuidKit,
        uuidWhiteLabel: environment.wlp,
        name: formKit.name,
        documents: selectedDataUuids
      }
      this.updateKit(kit);
    }
  }

  backToLastPage(): void {
    this.location.back();
  }

  public findDataList() {
    this.dataService.findDataList().subscribe({
      next: (dataListData: TypeCustomerDataDto[]) => {
        this.data = dataListData;
        this.dataList = dataListData.map(item => item.name);
        this.cdr.detectChanges();
      },
      error: () => {
        this.showErrorModal('Erro ao buscar dados!');
      }
    });
  }

  private getSelectedDataUuids(): string[] {
    return this.formKit.value.documents
      .map((name: string) => {
        const dataItem = this.data.find(data => data.name === name);
        return dataItem && dataItem.uuid;
      })
      .filter((uuid: string) => uuid);
  }

  public createKit(kit: CreateKitDto) {
    this.kitService.createKit(kit).subscribe({
      next: async () => {
        await this.showSuccessModal('Kit criado com sucesso!');
        return this.router.navigate(['scfi/kits/kit-list']);
      },
      error: () => {
        this.showErrorModal('Erro ao criar o kit!');
      }
    });
  }

  public updateKit(kit: UpdateKitDto) {
    this.kitService.updateKit(kit).subscribe({
      next: async () => {
        await this.showSuccessModal('Kit alterado com sucesso!');
        return this.router.navigate(['scfi/kits/kit-list']);
      },
      error: () => {
        this.showErrorModal('Erro ao alterar kit!');
      }
    });
  }
}
