import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/core/_base/_auth/api.service';
import { SpeViewNScfiProxy } from 'src/app/core/proxy/view/spe-view-n-scfi.proxy';
import { Observable } from 'rxjs';
import { FundingDto, StatementDto } from './funding.dto';
import { SpeNScfiProxy } from 'src/app/core/proxy/spe/spe-n-scfi.proxy';

@Injectable({
    providedIn: 'root'
})
export class FundingService {

    formFunding: FormGroup;

    constructor(
        protected api: ApiService,
        private speViewNScfiProxy: SpeViewNScfiProxy,
        private speNScfiProxy: SpeNScfiProxy,
        private formBuilder: FormBuilder,
    ) { }

    public createFormFunding(): FormGroup {
        this.formFunding = this.formBuilder.group({
            name: new FormControl('', Validators.compose([
                Validators.required
            ])),
            negotiatedRate: new FormControl('', Validators.compose([
                Validators.required,
                Validators.min(0.000001)
            ])),
            capital: new FormControl('', Validators.compose([
                Validators.required,
                Validators.min(0.01)
            ])),
            isExternal: new FormControl(false, Validators.compose([
                Validators.required
            ])),
            integrationData: this.createFormIntegrationData(),
        });
        return this.formFunding;
    }

    public createFormIntegrationData(): FormGroup {
        return this.formBuilder.group({
            identification: new FormControl(''),
            manager: new FormControl('')
        })
    }

    public getErrorMessages() {
        return {
            name: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
            ],
            negotiatedRate: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
                {
                    type: 'min',
                    message: 'A taxa deve ser maior do que zero',
                }
            ],
            capital: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
                {
                    type: 'min',
                    message: 'O capital deve ser maior do que zero',
                }
            ],
            isExternal: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                }
            ]
        };
    }

    public findFundingList(): Observable<FundingDto[]> {
        return this.speViewNScfiProxy.findFundingList();
    }

    public createFunding(createFundingDto: FundingDto): Observable<FundingDto> {
        return this.speNScfiProxy.createFunding(createFundingDto);
    }

    public findFundingByUuid(uuid: string): Observable<FundingDto> {
        return this.speViewNScfiProxy.findFundingByUuid(uuid);
    }

    public updateFunding(updateFundingDto: FundingDto): Observable<FundingDto> {
        return this.speNScfiProxy.updateFunding(updateFundingDto);
    }

    public getFundingStatement(uuid: string): Observable<StatementDto[]> {
        return this.speViewNScfiProxy.getFundingStatement(uuid);
    }
}