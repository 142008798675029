import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/core/_base/_auth/api.service';
import { SpeViewNScfiProxy } from 'src/app/core/proxy/view/spe-view-n-scfi.proxy';
import { Observable } from 'rxjs';
import {ApprovalHistoryDto, ProposalDto, ProposalModel} from './proposal.dto';
import {ProposalStatusRoleEnum} from "./enum/proposal-status-role.enum";

@Injectable({
    providedIn: 'root'
})
export class ProposalService {

    formProposal: FormGroup;

    constructor(
        protected api: ApiService,
        private speViewNScfiProxy: SpeViewNScfiProxy,
        private formBuilder: FormBuilder,
    ) { }

    public createFormProposal(): FormGroup {
        this.formProposal = this.formBuilder.group({
            document: new FormControl('', Validators.compose([
                Validators.required
            ])),
            name: new FormControl('', Validators.compose([
                Validators.required
            ])),
            motherName: new FormControl('', Validators.compose([
                Validators.required
            ])),
            fatherName: new FormControl('', Validators.compose([
                Validators.required
            ])),
            birthDate: new FormControl('', Validators.compose([
                Validators.required
            ])),
            stateRegistration: new FormControl('', Validators.compose([
                Validators.required
            ])),
            openingDate: new FormControl('', Validators.compose([
                Validators.required
            ])),
            phone: new FormControl('', Validators.compose([
                Validators.required
            ])),
            email: new FormControl('', Validators.compose([
                Validators.required
            ])),
        })
        return this.formProposal;
    }

    public getErrorMessages() {
        return {
            document: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
            ],
            name: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                }
            ],
            motherName: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                }
            ],
            fatherName: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                }
            ],
            birthDate: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                }
            ],
            stateRegistration: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                }
            ],
            openingDate: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                }
            ],
            phone: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                }
            ],
            email: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                }
            ]
        }
    }

    public findProposalsList(): Observable<ProposalDto[]> {
        return this.speViewNScfiProxy.findProposalsList();
    }

    public findProposalByUuid(uuid: string): Observable<ProposalDto> {
        return this.speViewNScfiProxy.findProposalByUuid(uuid);
    }

    public findApprovalHistory(uuid: string): Observable<ApprovalHistoryDto[]> {
        return this.speViewNScfiProxy.findApprovalHistory(uuid);
    }

    public findProposalByStatus(status: ProposalStatusRoleEnum): Observable<ProposalModel[]> {
        return this.speViewNScfiProxy.findProposalByStatus(status);
    }
}
