import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CurrencyPipe, DatePipe, Location } from '@angular/common';
import { AbstractScfi } from '../../abstract-scfi';
import { ActivatedRoute, Router } from '@angular/router';
import { FundingService } from 'src/app/core/scfi/services/services-funding/funding.service';
import { ErrorMessagesFundingDto, FundingDto } from 'src/app/core/scfi/services/services-funding/funding.dto';


@Component({
  selector: 'app-funding-form',
  templateUrl: './funding-form.component.html',
  styleUrls: ['./funding-form.component.scss']
})
export class FundingFormComponent extends AbstractScfi implements OnInit {
  public errorMessagesFunding: ErrorMessagesFundingDto;
  public formFunding: FormGroup;
  public isEditMode: boolean = false;

  constructor(
    private location: Location,
    private fundingService: FundingService,
    private router: Router,
    protected currencyPipe: CurrencyPipe,
    private route: ActivatedRoute,
    protected datePipe: DatePipe
  ) {
    super(currencyPipe, datePipe);
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const uuid = params['uuid'];
      this.isEditMode = !!uuid;

      if (uuid) {
        this.buildFormFees();
        this.findFundingByUuid(uuid);
      } else {
        this.buildFormFees();
      }
    });
  }

  public buildFormFees() {
    this.errorMessagesFunding = this.fundingService.getErrorMessages();
    this.formFunding = this.fundingService.createFormFunding();
  }

  get integrationDataForm() {
    return this.formFunding.get("integrationData") as FormGroup;
  }

  public backToLastPage(): void {
    this.location.back();
  }

  public findFundingByUuid(uuid: string) {
    this.fundingService.findFundingByUuid(uuid).subscribe({
      next: (funding: FundingDto) => {
        this.formFunding.patchValue({
          name: funding.name,
          negotiatedRate: funding.negotiatedRate,
          capital: funding.capital,
          isExternal: funding.isExternal,
          integrationData: {
            identification: funding.integrationData?.identification,
            manager: funding.integrationData?.manager
          }
        });
      },
      error: (error) => {
        this.showErrorModal('Erro ao buscar fundo!');
      }
    });
  }

  public async saveFunding() {
    const confirmationMessage = this.isEditMode
      ? 'Tem certeza que deseja alterar esta tarifa?'
      : 'Tem certeza que deseja criar esta tarifa?';

    const confirmed = await this.showConfirmationModal(confirmationMessage);

    if (confirmed) {
      const formFundingData: FundingDto = this.formFunding.value;

      if (this.isEditMode) {
        this.updateFunding(formFundingData);
      } else {
        this.createFunding(formFundingData);
      }
    }
  }

  public createFunding(formFundingData: FundingDto) {
    this.fundingService.createFunding(formFundingData).subscribe({
      next: async () => {
        await this.showSuccessModal('Fundo criado com sucesso!');
        this.router.navigate(['/scfi/funding/funding-list']);
      },
      error: (error) => {
        this.showErrorModal('Erro ao criar o fundo!');
      }
    });
  }

  public updateFunding(formFundingData: FundingDto) {
    this.fundingService.updateFunding(formFundingData).subscribe({
      next: async () => {
        await this.showSuccessModal('Fundo alterado com sucesso!');
        this.router.navigate(['/scfi/funding/funding-list']);
      },
      error: (error) => {
        this.showErrorModal('Erro ao alterar o fundo!');
      }
    });
  }
}
