import { NgModule } from '@angular/core';
import { SimulationTableDetailsComponent } from './simulation-table-details.component';

@NgModule({
    imports: [
    ],
    declarations: [
        SimulationTableDetailsComponent
    ],
    exports: [
        SimulationTableDetailsComponent
    ],
})
export class SimulationTableDetailsModule { }