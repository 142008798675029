import { Component, OnInit } from "@angular/core";
import { StatementDto } from "src/app/core/scfi/services/services-funding/funding.dto";
import { FundingService } from "src/app/core/scfi/services/services-funding/funding.service";
import { AbstractScfi } from "../../abstract-scfi";
import { CurrencyPipe, DatePipe } from "@angular/common";
import { TableData } from "src/app/core/scfi/services/services-table/table.dto";

@Component({
    selector: 'app-funding-extract',
    templateUrl: './funding-extract.component.html',
    styleUrls: ['./funding-extract.component.scss']
})
export class FundingExtractComponent extends AbstractScfi implements OnInit {
    public panelOpenState = false;
    public statement: StatementDto[];
    public displayedColumns = ['dateRegister', 'description', 'reference', 'credit', 'debit', 'balance'];

    constructor(
        private fundingService: FundingService,
        protected currencyPipe: CurrencyPipe,
        protected datePipe: DatePipe
    ) {
        super(currencyPipe, datePipe)
    }

    ngOnInit() {
        this.getFundingStatement();
    }

    public getFundingStatement() {
        this.fundingService.getFundingStatement("1").subscribe({
            next: (statement: StatementDto[]) => {
                this.statement = statement;
            },
            error: (error) => {
                this.showErrorModal('Erro ao buscar extrato do fundo!');
            }
        });
    }
}