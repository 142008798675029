import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProposalsFormsComponent } from './proposals-forms/proposals-forms.component';
import { ProposalsListComponent } from './proposals-list/proposals-list.component';

const routes: Routes = [
  { path: '', component: ProposalsListComponent, },
  { path: 'new', component: ProposalsFormsComponent, },
  { path: ':id/edit', component: ProposalsFormsComponent, },
  { path: ':id/edit/special', component: ProposalsFormsComponent, },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProposalsRoutingModule { }
