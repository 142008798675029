import { Injectable } from '@angular/core';
import { ApiService } from '../../_base/_auth/api.service';
import { Observable } from 'rxjs';


const SPE_N_OPEN_ACCOUNT = '/spe-n-open-account';

@Injectable(
  {providedIn: 'root'}
)
export class SpeNOpenAccountProxy {

  constructor(private api: ApiService) {
  }

  searchPostalCode(zipcode: string) {
    const uri = `${SPE_N_OPEN_ACCOUNT}/v1/cep/${zipcode}`;
    return this.api.get(uri);
  }

  getListUf() {
    return [
      { 'name': 'AC', 'value': 'AC' },
      { 'name': 'AL', 'value': 'AL' },
      { 'name': 'AM', 'value': 'AM' },
      { 'name': 'AP', 'value': 'AP' },
      { 'name': 'BA', 'value': 'BA' },
      { 'name': 'CE', 'value': 'CE' },
      { 'name': 'DF', 'value': 'DF' },
      { 'name': 'ES', 'value': 'ES' },
      { 'name': 'GO', 'value': 'GO' },
      { 'name': 'MA', 'value': 'MA' },
      { 'name': 'MG', 'value': 'MG' },
      { 'name': 'MS', 'value': 'MS' },
      { 'name': 'MT', 'value': 'MT' },
      { 'name': 'PA', 'value': 'PA' },
      { 'name': 'PB', 'value': 'PB' },
      { 'name': 'PE', 'value': 'PE' },
      { 'name': 'PI', 'value': 'PI' },
      { 'name': 'PR', 'value': 'PR' },
      { 'name': 'RJ', 'value': 'RJ' },
      { 'name': 'RN', 'value': 'RN' },
      { 'name': 'RR', 'value': 'RR' },
      { 'name': 'RS', 'value': 'RS' },
      { 'name': 'SC', 'value': 'SC' },
      { 'name': 'SE', 'value': 'SE' },
      { 'name': 'SP', 'value': 'SP' },
      { 'name': 'TO', 'value': 'TO' },
      { 'name': 'RO', 'value': 'RO' }
    ];
  }

}
