import { ContractsLoansFormsComponent } from './contracts-loans-forms/contracts-loans-forms.component';
import { ContractsLoansListComponent } from './contracts-loans-list/contracts-loans-list.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', component: ContractsLoansListComponent, },
  { path: 'new', component: ContractsLoansFormsComponent, },
  { path: ':id/edit', component: ContractsLoansFormsComponent, },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContractsLoansRoutingModule { }
