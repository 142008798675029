<div id="fee-form-component">
  <h1 class="page-title mb-4">{{ isEditMode ? 'Alteração da Tarifa' : 'Cadastro de Tarifa' }}</h1>

  <div class="d-flex justify-content-end mb-4">
    <button type="button" class="btn btn-warning" (click)="backToLastPage()">
      <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr074.svg'" class="svg-icon svg-icon-1 me-4"></span>
      <span class="pt-2">Voltar</span>
    </button>
  </div>

  <section class="card mb-4 shadow-sm">
    <div class="card-header border-0 d-flex align-items-center justify-content-start">
      <span [inlineSVG]="'./assets/media/icons/duotune/general/gen045.svg'"
        class="svg-icon svg-icon-2tx svg-icon-gray-800 me-4"></span>
      <h3 class="fw-bolder card-title mt-3">Informações Gerais</h3>
    </div>

    <div class="card-body border-top p-9">
      <form>
        <div class="d-flex">
          <div class="col-3 px-10">
            <app-ui-c-input [form]="formFees" [errorMessages]="errorMessagesFees" [formName]="'name'" [name]="'Nome:'"
              [type]="'text'" [placeholder]="'Digite o nome da tarifa'"></app-ui-c-input>
          </div>
          <div class="col-3 px-10">
            <app-ui-c-input [form]="formFees" [errorMessages]="errorMessagesFees" [formName]="'minInterestRate'"
              [name]="'Taxa de Juros Mínima:'" [isPercent]="true"></app-ui-c-input>
          </div>
          <div class="col-3 px-10">
            <app-ui-c-input [form]="formFees" [errorMessages]="errorMessagesFees" [formName]="'interestRate'"
              [name]="'Taxa de Juros Padrão:'" [isPercent]="true"></app-ui-c-input>
          </div>
        </div>
      </form>
    </div>
  </section>

  <section class="card mb-4 shadow-sm">
    <div class="card-header border-0 d-flex align-items-center justify-content-start">
      <span [inlineSVG]="'./assets/media/icons/duotune/ecommerce/ecm003.svg'"
        class="svg-icon svg-icon-2tx svg-icon-gray-800 me-4"></span>
      <h3 class="fw-bolder card-title mt-3">TAC</h3>
    </div>

    <div class="card-body border-top p-9">
      <form [formGroup]="formFees">
        <div class="d-flex">
          <div class="col-3 px-10">
            <label class="form-label fs-6 fw-bolder text-dark mt-3">Tipo:</label>
            <select class="form-select form-select-solid form-select-lg text-dark" formControlName="typeTac" [ngClass]="{
              'is-invalid': (formFees?.get('typeTac')?.invalid && formFees?.get('typeTac')?.dirty),
              'is-valid': (formFees?.get('typeTac')?.valid && formFees?.get('typeTac')?.dirty)
            }">
              <option value="">Selecione um tipo</option>
              <option value="PERCENT">Percentual</option>
              <option value="FIXED">Valor Fixo</option>
            </select>
          </div>

          <ng-container *ngIf="formFees.value.typeTac === 'PERCENT'">
            <div class="col-3 px-10">
              <app-ui-c-input [form]="formFees" [errorMessages]="errorMessagesFees" [formName]="'minValueTac'"
                [name]="'Valor Mínimo:'" [isPercent]="true"></app-ui-c-input>
            </div>

            <div class="col-3 px-10">
              <app-ui-c-input [form]="formFees" [errorMessages]="errorMessagesFees" [formName]="'valueTac'"
                [name]="'Valor Padrão:'" [isPercent]="true"></app-ui-c-input>
            </div>
          </ng-container>

          <ng-container *ngIf="formFees.value.typeTac === 'FIXED'">
            <div class="col-3 px-10">
              <app-ui-c-input [form]="formFees" [errorMessages]="errorMessagesFees" [formName]="'minValueTac'"
                [name]="'Valor Mínimo:'" [isMoney]="true" [isNumber]="true"></app-ui-c-input>
            </div>

            <div class="col-3 px-10">
              <app-ui-c-input [form]="formFees" [errorMessages]="errorMessagesFees" [formName]="'valueTac'"
                [name]="'Valor Padrão:'" [isMoney]="true" [isNumber]="true"></app-ui-c-input>
            </div>
          </ng-container>
        </div>
      </form>
    </div>
  </section>

  <div class="d-flex justify-content-end">
    <button type="button" class="btn btn-primary" [disabled]="formFees.invalid" (click)="saveFee()">
      Salvar
    </button>
  </div>
</div>