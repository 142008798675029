import { MaskService } from 'src/app/shared/services-ultil/masker-service/masker.service';
import { SpeViewNBackofficeConsignedProxy } from 'src/app/core/proxy/view/spe-view-n-backoffice-consigned.proxy';
import { SpeNBackofficeConsignedProxy } from 'src/app/core/proxy/spe/spe-n-backoffice-consigned';
import { ProposalsTableModel } from './proposals-table.model';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { Proposal } from "./poposals-modal";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ApiService } from 'src/app/core/_base/_auth/api.service';
import * as moment from 'moment';
import { UserInfoService } from 'src/app/core/_base/_auth/user-info.service';

@Injectable({
  providedIn: 'root'
})
export class ProposalServices {

  public formProposal: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    protected api: ApiService,
    protected speNBackofficeConsignedProxy: SpeNBackofficeConsignedProxy,
    protected speViewNBackofficeConsignedProxy: SpeViewNBackofficeConsignedProxy,
    private maskService: MaskService,
    private userInfoService: UserInfoService
  ) { }

  aproveProposal(uuidProposal: string, uuidLogeUser: string, status: string, name: any, email: any, latitude: string, longetude: string, device: string, aproveEmail?: string, description?: string) {
    return this.speNBackofficeConsignedProxy.aproveProposal(uuidProposal, uuidLogeUser, status, name, email, latitude, longetude, device, aproveEmail, description);
  }


  getProposalsList(startDate: string, endDate: string, role: string | undefined, email: string): Observable<ProposalsTableModel[]> {
    return this.speViewNBackofficeConsignedProxy.getProposalsList(startDate, endDate, role, email);
  }

  cardFilters(role: string, emailManager: string): Observable<ProposalsTableModel[]> {
    return this.speViewNBackofficeConsignedProxy.cardFilters(role, emailManager);
  }

  getProposal(uuid: string): Observable<any> {
    return this.speViewNBackofficeConsignedProxy.getAllAtributesProposal(uuid)
  }

  cardsCustomers(role: string, emailManager: string, uudiCustomer: string): Observable<any> {
    return this.speViewNBackofficeConsignedProxy.cardsCustomers(role, emailManager, uudiCustomer)
  }

  /* /v1/proposal/find/cardsCustomers/{role}/{emailManager}/{uudiCustomer} */

  createFormProposal(): FormGroup {
    this.formProposal = this.formBuilder.group({
      employeeCpf: new FormControl('', Validators.compose([
        Validators.maxLength(14),
      ])),
      name: new FormControl('', Validators.compose([
        Validators.maxLength(100),
      ])),
      company: new FormControl('', Validators.compose([
        Validators.maxLength(100),
      ])),
      admission: new FormControl('', Validators.compose([
        Validators.maxLength(100),
      ])),
      celphone: new FormControl('', Validators.compose([
        Validators.maxLength(100),
      ])),
      email: new FormControl('', Validators.compose([
        Validators.maxLength(100),
        Validators.email
      ])),
      dependents: new FormControl('', Validators.compose([
      ])),
      salary: new FormControl('', Validators.compose([
        Validators.maxLength(100),
      ])),
      departamentManager: new FormControl('', Validators.compose([
        Validators.maxLength(100),
      ])),
      alimony: new FormControl('', Validators.compose([
        Validators.maxLength(100),
      ])), //pensão alimentícia
      judicialcollection: new FormControl('', Validators.compose([
        Validators.maxLength(100),
      ])),
      unionmonthly: new FormControl('', Validators.compose([ //mensalidade sindical
        Validators.maxLength(100),
      ])), //mensalidade sindical
      otherdiscounts: new FormControl('', Validators.compose([
        Validators.maxLength(100),
      ])),
      otherloans: new FormControl('', Validators.compose([
        Validators.maxLength(100),
      ])),
      urbanobankloan: new FormControl('', Validators.compose([
        Validators.maxLength(100),
      ])),
      inss: new FormControl('', Validators.compose([
        Validators.maxLength(100),
      ])),
      incometax: new FormControl('', Validators.compose([
        Validators.maxLength(100),
      ])),
      tatoaldiscounts: new FormControl('', Validators.compose([
        Validators.maxLength(100),
      ])),
      preaproved: new FormControl('', Validators.compose([
        Validators.maxLength(100),
      ])),
      installmentvalue: new FormControl('', Validators.compose([
        Validators.maxLength(100),
      ])),
      loanvalue: new FormControl('', Validators.compose([
        Validators.maxLength(100),
      ])),
      payofdebt: new FormControl('', Validators.compose([
        Validators.maxLength(100),
      ])),
      refinancing: new FormControl('', Validators.compose([
        Validators.maxLength(100),
      ])),
      bankNumber: new FormControl('', Validators.compose([
      ])),
      bankName: new FormControl('', Validators.compose([
      ])),
      agency: new FormControl('', Validators.compose([
      ])),
      bankAccountType: new FormControl('', Validators.compose([
      ])),
      bankAccount: new FormControl('', Validators.compose([
      ])),
      manResponsible: new FormControl('', Validators.compose([
      ])),
    });

    return this.formProposal;
  }

  getFormErrors() {
    return {
      employeeCpf: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        },
        {
          type: 'minlength',
          message: 'O cpf é inválido',
        },
        {
          type: 'maxlength',
          message: 'O cpf é inválido',
        }
      ],
      name: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        },
        {
          type: 'minlength',
          message: 'O nome precisa ter no mínimo 2 caracteres',
        }
      ],
      company: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        }
      ],
      admission: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        }
      ],
      celphone: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        }
      ],
      email: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        },
        {
          type: 'email',
          message: 'Email inválido'
        }
      ],
      dependents: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        }
      ],
      salary: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        }
      ],
      departamentManager: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        }
      ],
      alimony: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        }
      ],
      judicialcollection: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        }
      ],
      unionmonthly: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        }
      ],
      otherdiscounts: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        }
      ],
      otherloans: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        }
      ],
      urbanobankloan: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        }
      ],
      inss: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        }
      ],
      incometax: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        }
      ],
      tatoaldiscounts: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        }
      ],
      preaproved: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        }
      ],
      installmentvalue: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        }
      ],
      loanvalue: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        }
      ],
      payofdebt: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        }
      ],
      refinancing: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        }
      ]
    }
  }



  sycForm(proposal: any) {

    const administrationDate = moment(proposal.customerWork.administrationDate).format("DD/MM/YYYY");

    this.formProposal.patchValue({
      employeeCpf: proposal.customer.identityGov,
      name: proposal.customer.fullName,
      company: proposal.customerWork.companyName,
      admission: administrationDate,
      celphone: proposal.customer.homePhone,
      email: proposal.customer.email,
      dependents: proposal.customer.dependentNumber,
      salary: proposal.customerWork.salary,
      departamentManager: proposal.managerApproval,
      alimony: proposal.loan.alimony,
      judicialcollection: proposal.loan.judicialCollection,
      unionmonthly: proposal.loan.unionMonthly,
      otherdiscounts: proposal.loan.otherDiscounts,
      otherloans: proposal.loan.otherLoans,
      urbanobankloan: proposal.loan.urbanoBankLoans,
      inss: proposal.customerWork?.inss,
      incometax: proposal.customerWork.incomeTax,
      tatoaldiscounts: proposal.loan.totalDiscounts,
      preaproved: proposal.loan.preAproved,
      installmentvalue: proposal.loan.pinStallmentValue,
      loanvalue: proposal.loan.loan,
      payofdebt: proposal.loan.payOfDebt,
      refinancing: proposal.loan.refinancing,
      bankNumber: proposal.customerWork?.bankNumber,
      agency: proposal.customerWork?.agency,
      bankName: proposal.customerWork?.bankName,
      bankAccount: proposal.customerWork?.bankAccount,
      bankAccountType: proposal.customerWork?.accountType,
      manResponsible: proposal.loan.manResponsible
    })

  }

  getHistoryApprovation(uuidProposal: string) {
    return this.speViewNBackofficeConsignedProxy.getHistoryApprovation(uuidProposal);
  }

  public findByUuidProposaldetailsInsurance() {
    return this.speViewNBackofficeConsignedProxy.findByUuidProposaldetailsInsurance();
  }

  public findByUuidProposaldetailsInsuranceXls() {
    return this.speViewNBackofficeConsignedProxy.findByUuidProposaldetailsInsuranceXls();
  }
}













