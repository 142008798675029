import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-tablesimulator',
  templateUrl: './tablesimulator.component.html',
  styleUrls: ['./tablesimulator.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class TablesimulatorComponent implements OnInit{

  @Input('interest') i: number=0;
  @Input('numbertime') n: number=0;
  @Input('pmt') p: number=0;
  @Input('capital') c: number=0;
  @Input('security') s: number=0;
  @Input() dataSource: PeriodicElement[] = [];


  columnsToDisplay = ['prazo', 'valorparcela', 'valoremprestimo', 'seguro',];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement!: PeriodicElement | null;
  tabelaCalc!: PeriodicElement[];

  constructor( private cdr: ChangeDetectorRef){}

ngOnInit(): void {
  //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
  //Add 'implements OnInit' to the class.
 // this.calcularSim(8720);
}

// Motor de Cálculo de Empréstimo por base no valor Solicitado
  //C/((1 - (( 1 + i)^(n * -1)))/i)
  // R$ 8.720,00 C
  // 24 n
  //4,75% i  0,0475
   //R$ 616,67 p

   /*
calcularSim(C: number){

  this.c = C;
  const parcelas = [12,18,24,30,36,42,48];

  for(var parc in parcelas){

    //alert(parcelas[parc]);
    this.createTableOption(0.0475, parcelas[parc], this.c , 425.00, "TAC 393,00 i=4,75 a.m");
    this.dataSource.filter;
  }


  this.createTableOption(0.0475, 12, this.c , 425.00, "TAC 393,00 i=4,75 a.m");
  this.createTableOption(0.0475, 24, this.c , 425.00, "TAC 393,00 i=4,75 a.m");
  this.createTableOption(0.0475, 30, this.c , 425.00, "TAC 393,00 i=4,75 a.m");
  this.createTableOption(0.0475, 36, this.c , 425.00, "TAC 393,00 i=4,75 a.m");
  this.createTableOption(0.0475, 40, this.c , 425.00, "TAC 393,00 i=4,75 a.m");
  this.createTableOption(0.0475, 48, this.c , 425.00, "TAC 393,00 i=4,75 a.m");

  }

  createTableOption(i: number, n: number, c: number, seg: number, detail: string){

   let parc: number = (c/((1-((1+i)**(n*-1)))/i));
    alert(n + "x" + "R$" + parc.toPrecision(5));
   this.dataSource.push({prazo: n.toString()+"X", valorparcela: "R$ " + parc.toPrecision(5).toString(), valoremprestimo: "R$ " + c.toPrecision(5).toString(), seguro: "R$ " +  seg.toPrecision(5).toString(), detalhes: detail});

  }
*/
}


export interface PeriodicElement {
  valorparcela: string;
  prazo: string;
  valoremprestimo: string;
  seguro: string;
  detalhes: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    prazo: '12x',
    valorparcela: 'R$ 685,20',
    valoremprestimo: 'R$ 5.000,00',
    seguro: 'R$ 632,00',
    detalhes: `Seu empréstimo é composto pelos valor CTE 9,83% TAC R$ 500,00 IOF R$ 178,20 TAC R$ 500,00 Taxa de Juros 6,75% ao Mês `,
  },
  {
    prazo: '18x',
    valorparcela: 'R$ 685,20',
    valoremprestimo: 'R$ 5.000,00',
    seguro: 'R$ 632,00',
    detalhes: `Seu empréstimo é composto pelos valor CTE 9,83% TAC R$ 500,00 IOF R$ 178,20 TAC R$ 500,00 Taxa de Juros 6,75% ao Mês `,

  },
  {
    prazo: '24x',
    valorparcela: 'R$ 685,20',
    valoremprestimo: 'R$ 5.000,00',
    seguro: 'R$ 632,00',
    detalhes: `Seu empréstimo é composto pelos valor CTE 9,83% TAC R$ 500,00 IOF R$ 178,20 TAC R$ 500,00 Taxa de Juros 6,75% ao Mês `,

  },
  {
    prazo: '30x',
    valorparcela: 'R$ 685,20',
    valoremprestimo: 'R$ 5.000,00',
    seguro: 'R$ 632,00',
    detalhes: `Seu empréstimo é composto pelos valor CTE 9,83% TAC R$ 500,00 IOF R$ 178,20 TAC R$ 500,00 Taxa de Juros 6,75% ao Mês `,

  },
  {
    prazo: '36x',
    valorparcela: 'R$ 685,20',
    valoremprestimo: 'R$ 5.000,00',
    seguro: 'R$ 632,00',
    detalhes: `Seu empréstimo é composto pelos valor CTE 9,83% TAC R$ 500,00 IOF R$ 178,20 TAC R$ 500,00 Taxa de Juros 6,75% ao Mês `,

  },
  {
    prazo: '42x',
    valorparcela: 'R$ 685,20',
    valoremprestimo: 'R$ 5.000,00',
    seguro: 'R$ 632,00',
    detalhes: `Seu empréstimo é composto pelos valor CTE 9,83% TAC R$ 500,00 IOF R$ 178,20 TAC R$ 500,00 Taxa de Juros 6,75% ao Mês `,

  },
  {
    prazo: '48x',
    valorparcela: 'R$ 685,20',
    valoremprestimo: 'R$ 5.000,00',
    seguro: 'R$ 632,00',
    detalhes: `Seu empréstimo é composto pelos valor CTE 9,83% TAC R$ 500,00 IOF R$ 178,20 TAC R$ 500,00 Taxa de Juros 6,75% ao Mês `,

  },



];
