<nav class="navbar navbar-expand-md navbar-dark bg-dark" >
  <a class="navbar-brand" routerLink="/">
    <img src="../../../../assets/logos/Logo-horizontal_branco.png" width="120" height="35" class="logo">
  </a>

  <div class="collapse navbar-collapse md-6" id="navbarNav">
    <ul class="navbar-nav">
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" routerLink="/employees">Colaboradores</a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" routerLink="/proposals">Propostas</a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" routerLink="/proposals">Aprovação</a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" routerLink="/contractsloans">Contratos</a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" routerLink="/dismissals">Desligamento</a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" routerLink="/billscharge">Boletos</a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" routerLink="/invoices">Faturas</a>
      </li>
    </ul>
  </div>
  <div class="collapse navbar-collapse md-6" id="navbarNav">
    <ul class="navbar-nav" >
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" routerLink="/companies">Conveniadas</a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" routerLink="/dashboard/signin">Login</a>
      </li>
    </ul>
  </div>
</nav>
<br/>
