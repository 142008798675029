import { ProposalsTableModel } from './../services-apis/proposals-table.model';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { NgxSpinnerService } from 'ngx-spinner';
import { MaskService } from 'src/app/shared/services-ultil/masker-service/masker.service';
import { BaseResourceListComponent } from 'src/app/shared/components/base-resource-list/base-resource-list.component';
import { Proposal } from "../services-apis/poposals-modal";
import { ProposalServices } from '../services-apis/proposals-services';
import { MatTableDataSource } from '@angular/material/table';
import { StorageService } from 'src/app/shared/services-ultil/storage/storage.service';
import { Router } from '@angular/router';
import { UserInfoService } from 'src/app/core/_base/_auth/user-info.service';
import { TruncateService } from 'src/app/shared/services-ultil/truncate-service/truncate.service';

@Component({
  selector: 'app-proposals-list',
  templateUrl: './proposals-list.component.html',
  styleUrls: ['./proposals-list.component.scss']
})

export class ProposalsListComponent implements OnInit {

  displayedColumns = ['proposalDate', 'proposalName', 'fullName', 'identityGov', 'salary', 'netCapitalAmountLoan',
    'installmentQuantityLoan', 'installmentValue', 'iProposalInterestMonth', 'fvTotalAmountLoanValue', 'statusProposal', 'action'];

  dataSource!: MatTableDataSource<any>;
  matSort: any;
  isAudit: boolean = false;
  email: any = "";
  startDate: string;
  endDate: string;
  defaultDate = 90;
  public cardsByFilter: any = {};

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  listProposals: any[];
  emailUser: any;

  constructor(private proposalServices: ProposalServices,
    public maskerService: MaskService,
    private spinner: NgxSpinnerService,
    private cdr: ChangeDetectorRef,
    private storageService: StorageService,
    private router: Router,
    private userInfoService: UserInfoService,
    protected truncate:TruncateService
  ) { }

  ngOnInit() {
    this.spinner.show();
    const currencydate = new Date();

    this.startDate = moment(new Date().setFullYear(currencydate.getFullYear() - 1)).format('YYYY-MM-DD');
    this.endDate = moment(currencydate).format('YYYY-MM-DD');

    this.isAudit = this.userInfoService.isAudit();
    this.getListProposal(this.startDate, this.endDate, this.isAudit);


    /* Verifica se o acesso é atendimentoe remove o salário da listagem */
    if (this.userInfoService.isAttendant()) {
      let index = this.displayedColumns.indexOf('salary');
      if (index != -1) {
        this.displayedColumns.splice(index, 1);
      }      
    }
  }

  emitDate(event: any) {
    this.getListProposal(event.startDate, event.endDate, this.isAudit);
  }

  getByuuid(id: string) {
  }

  filterData($event: any) {
    this.dataSource.filter = $event.target.value;
  }

  async getListProposal(startDate: string, endDate: string, isAudit: boolean) {
    this.spinner.show();
    await this.userInfoService.getInfoProfile().then((res) => {
      if (res) {
        this.email = res.email;
      } else {
        this.email = "";
      }
    })

    let role = this.userInfoService.verifyRole() || '';
    
    if(this.userInfoService.isAttendant()) {
      role = 'liquidation'
    }

    this.proposalServices.getProposalsList(startDate, endDate, role, this.email).subscribe(
      (res: any) => {
        if (res) {
          this.spinner.hide();
          this.dataSource = new MatTableDataSource(res);
          this.listProposals = res;
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.matSort;
          this.cdr.detectChanges();
          this.processProposalByStatus(role, this.email)
        }
      },
      (err) => {
        this.spinner.hide()
      }
    )

  }

  viewDetails(uuid: string) {
    this.storageService.saveData("isView", "true")
    this.router.navigate([`operations/proposals/${uuid}/edit`]);
  }

  checkStatus(status: string, isNonStandard?: boolean, isSpecial?: boolean) {
    if (isNonStandard && (status === 'REQUESTED')) {
      return {
        color: 'text-bg-danger text_color_white',
        status: "Fora do Padrão"
      }
    }

    if (isSpecial && (status === 'REQUESTED')) {
      return {
        color: 'text-bg-primary text_color_white',
        status: "Proposta Especial"
      }
    }

    switch (status) {
      case 'SPECIAL_FINANCE_APROVED':
        return {
          color: 'text-bg-success text_color_white',
          status: "Aprovado Financeiro"
        }

      case 'REQUESTED':
        return {
          color: 'text-bg-secondary text_color_black',
          status: "Solicitado"
        }

      case "EXPIRED":  return {
        color: 'text-bg-danger text_color_white',
        status: "Expirada"
      }

      case "SPECIAL_FINANCE_REPROVED":  return {
        color: 'text-bg-danger text_color_white',
        status: "Reprovado Financeiro"
      }

      case 'MANAGER_IN_ANALYSIS':
        return {
          color: 'text-bg-primary text_color_white',
          status: "Anál. Gestor"
        }

      case 'MANAGER_APPROVED':
        return {

          color: 'text-bg-primary text_color_white',
          status: "Ap. Gestor"
        }

      case 'MANAGER_REPROVED':
        return {
          color: 'text-bg-danger text_color_white',
          status: "Rep. Gestor"
        }

      case 'AUDIT_ANALYSIS':
        return {
          color: 'text-bg-primary text_color_white',
          status: "Anál. Auditor"
        }

      case 'AUDIT_APPROVED':
        return {
          color: 'text-bg-info text_color_white',
          status: "Ap. Auditor"
        }

      case 'AUDIT_REPROVED':
        return {
          color: 'text_color_white text-bg-danger ',
          status: "Rep. Auditor"
        }
      case 'CONTRACT_WORK_SIGNATURE':
        return {
          color: 'text_color_white text-bg-success ',
          status: "Aprovado"
        }

      case 'ALL':
        return {
          color: 'text_color_white text-bg-success ',
          status: "Todos"
        }
      case 'SIGNEDCCB': {
        return {
          color: 'text_color_white text-bg-success ',
          status: "Aguad. depósito"
        }
      }

      default:
        return {
          color: "",
          status: ""
        }
    }
  }
  getPaginatorIntl(paginator: MatPaginatorIntl) {
    paginator.itemsPerPageLabel = 'Items por página';
    paginator.lastPageLabel = 'Página Anterior';
    paginator.nextPageLabel = 'Próxima Página';
    paginator.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return `0 de ${length}`;
      }

      length = Math.max(length, 0);

      const startIndex = page * pageSize;

      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

      return `${startIndex + 1} - ${endIndex} de ${length}`;
    };
  }

  processProposalByStatus(role: string, email: string) {
    this.proposalServices.cardFilters(role, email)
      .subscribe(res => {
        this.cardsByFilter = res;
        this.cdr.detectChanges();
      })
  }

  filterListByStatus(status: string) {
    if (status == "ALL") {
      this.dataSource.data = this.listProposals;
      return;
    }

    let result = this.listProposals.filter(item => {
      if (item.status === status) return item;
    })
    this.dataSource.data = result;
  }

}
