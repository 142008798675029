import { SpeNOpenAccountProxy } from 'src/app/core/proxy/spe/spe-n-open-account.proxy';
import { EmployeesTableModel } from './employees-table.mode';
import { Observable } from 'rxjs';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Injectable, Injector } from '@angular/core';

import { BaseResourceService } from 'src/app/shared/base-service/base-resource.service';
import { ApiService } from 'src/app/core/_base/_auth/api.service';
import { Employee } from './employees-model';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class EmployeeServices extends BaseResourceService<Employee> {

  public formEmployee: FormGroup;
  public deleted: boolean = false;
  constructor(
    protected injector: Injector,
    private speNOpenAccountProxy: SpeNOpenAccountProxy,
    private formBuilder: FormBuilder,
    protected api: ApiService,
  ) {
    super('', injector, Employee.fromJson, api);
  }

  public getEmployeesList(startDate: string, endDate: string, role: string | undefined, emailManager: string): Observable<EmployeesTableModel[]> {
    return this.speViewNBackofficeConsignedProxy.getEmployeesList(startDate, endDate, role, emailManager);
  }

  public getEmployee(uuid: string): Observable<Employee> {
    return this.speViewNBackofficeConsignedProxy.getAllAtributesEmployee(uuid);
  }

  public crateEmployee(): Observable<Employee> {
    let employee = this.prepareEmployee(this.formEmployee);
    let uuidWl = environment.wlp;
    return this.speNBackofficeConsignedProxy.createEmployee(employee, uuidWl);
  }

  public editEmployee(): Observable<Employee> {
    let employee = this.prepareEmployee(this.formEmployee);
    if (this.deleted) {
      employee.deleted = true;
    }
    return this.speNBackofficeConsignedProxy.editEmployee(employee);
  }

  public deleteEmployee(uuid: string): any {
    this.speNBackofficeConsignedProxy.deleteEmployee(uuid);
  }

  createFormEmployee(): FormGroup {
    this.formEmployee = this.formBuilder.group({
      fullName: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(100),
      ])),
      companyName: new FormControl('', Validators.compose([

      ])),

      dependentNumber: new FormControl('', Validators.compose([
      ])),

      identityIssueDate: new FormControl('', Validators.compose([
      ])),

      healthPlan: new FormControl('', Validators.compose([
        Validators.required,
      ])),

      dentalPlan: new FormControl('', Validators.compose([
        Validators.required,
      ])),

      lifeInsurance: new FormControl('', Validators.compose([
        Validators.required,
      ])),

      fatherName: new FormControl('', Validators.compose([
      ])),

      motherName: new FormControl('', Validators.compose([
      ])),

      identityGov: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(100),
      ])),
      identity: new FormControl('', Validators.compose([
      ])),
      birthDate: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
      ])),

      residentialStreet: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(100),
      ])),
      residenceNumber: new FormControl('', Validators.compose([
        Validators.required
      ])),
      residenceComplement: new FormControl('', Validators.compose([
      ])),
      residentialNeighborhood: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100),
      ])),

      zipCodeResidence: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(9),
        Validators.maxLength(9),
      ])),
      homecity: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(100),
      ])),
      ufResidence: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(2),
      ])),
      dddCellPhone: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(15),
      ])),
      homePhone: new FormControl('', Validators.compose([
      ])),
      email: new FormControl('', Validators.compose([
        Validators.email,
      ])),
      sex: new FormControl('', Validators.compose([
      ])),
      maritalStatus: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(100),
      ])),
      nationality: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(3),
      ])),
      naturalNess: new FormControl('', Validators.compose([
      ])),
      ufIdentity: new FormControl('', Validators.compose([
      ])),
      streetWork: new FormControl('', Validators.compose([])),
      workNumber: new FormControl('', Validators.compose([])),
      complementWork: new FormControl('', Validators.compose([
      ])),
      neighborhoodWork: new FormControl('', Validators.compose([])),
      zipCodeWork: new FormControl('', Validators.compose([])),
      cityWork: new FormControl('', Validators.compose([])),
      ufWork: new FormControl('', Validators.compose([])),
      dddWork: new FormControl('', Validators.compose([])),
      phoneWork: new FormControl('', Validators.compose([])),
      emailWork: new FormControl('', Validators.compose([])),
      ocuppation: new FormControl('', Validators.compose([])),
      sector: new FormControl('', Validators.compose([])),
      registration: new FormControl('', Validators.compose([])),
      administrationDate: new FormControl('', Validators.compose([])),
      salary: new FormControl('', Validators.compose([Validators.required,])),
      bankNumber: new FormControl('', Validators.compose([])),
      bankName: new FormControl('', Validators.compose([])),
      agency: new FormControl('', Validators.compose([])),
      bankAccountType: new FormControl('', Validators.compose([])),
      bankAccount: new FormControl('', Validators.compose([])),
      inss: new FormControl('', Validators.compose([Validators.required,])),
      incomeTax: new FormControl('', Validators.compose([Validators.required,])),
      unionsdues: new FormControl('', Validators.compose([Validators.required,])),
      courtCharges: new FormControl('', Validators.compose([])),
      alimony: new FormControl('', Validators.compose([Validators.required,])),
      otherDiscounts: new FormControl('', Validators.compose([Validators.required])),

      /* outros descontos braspress */
      fineInstallment: new FormControl('', Validators.compose([Validators.required])),
      collisionInstallment: new FormControl('', Validators.compose([Validators.required])),
      responsibilityPortion: new FormControl('', Validators.compose([Validators.required])),
      installmentProducts: new FormControl('', Validators.compose([Validators.required])),
      otherInstallment: new FormControl('', Validators.compose([Validators.required])),

      installmentAmount: new FormControl('', Validators.compose([Validators.required])),
      managerResponsible: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(100),
      ])),
      cellPhoneManagerResponsible: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(15),
      ])),
      responsibleManagerEmail: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(6),
        Validators.email,
        Validators.maxLength(100),
      ])),
      responsibleIdentityGov: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(100),
      ])),
      uuid: "",
      id: "",
      uuidCompany: "",
      deleted: false
    });

    return this.formEmployee;
  }


  getFormErrosMessage() {
    return {
      fullName: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        },
        {
          type: 'minlength',
          message: 'O nome precisar ter 6 caracteres',
        }
      ],
      companyName: [
      ],
      identityGov: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        },
        {
          type: 'minlength',
          message: 'O nome precisar ter 6 caracteres',
        }
      ],
      identity: [
      ],
      birthDate: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        }
      ],
      fatherName: [
      ],
      motherName: [
      ],
      residentialStreet: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        },
        {
          type: 'minlength',
          message: 'O nome precisar ter 6 caracteres',
        }
      ],
      residenceNumber: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        }
      ],
      residentialNeighborhood: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        },
        {
          type: 'minlength',
          message: 'O nome precisar ter 6 caracteres',
        }
      ],
      zipCodeResidence: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        },
        {
          type: 'minlength',
          message: 'O nome precisar ter 6 caracteres',
        }
      ],
      homecity: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        },
        {
          type: 'minlength',
          message: 'O nome precisar ter 4 caracteres',
        }
      ],
      ufResidence: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        }
      ],
      dddCellPhone: [
      ],
      homePhone: [
      ],
      email: [
      ],
      sex: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        }
      ],
      maritalStatus: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        },
        {
          type: 'minlength',
          message: 'O nome precisar ter 6 caracteres',
        }
      ],
      nationality: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        },
        {
          type: 'minlength',
          message: 'O nome precisar ter 3 caracteres',
        }
      ],
      naturalNess: [
      ],
      dependentNumber: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        },
        {
          type: 'minlength',
          message: 'O nome precisar ter 6 caracteres',
        }
      ],
      ufIdentity: [
      ],
      identityIssueDate: [
      ],
      streetWork: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        },
        {
          type: 'minlength',
          message: 'O nome precisar ter 6 caracteres',
        }
      ],
      workNumber: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        }
      ],
      neighborhoodWork: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        },
        {
          type: 'minlength',
          message: 'O nome precisar ter 6 caracteres',
        }
      ],
      zipCodeWork: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        },
        {
          type: 'minlength',
          message: 'O nome precisar ter 6 caracteres',
        }
      ],
      cityWork: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        },
        {
          type: 'minlength',
          message: 'O nome precisar ter 6 caracteres',
        }
      ],
      ufWork: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        },
      ],
      dddWork: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        },
        {
          type: 'minlength',
          message: 'O nome precisar ter 6 caracteres',
        }
      ],
      phoneWork: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        }
      ],
      emailWork: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        },
        {
          type: 'minlength',
          message: 'O nome precisar ter 6 caracteres',
        }
      ],
      ocuppation: [
      ],
      sector: [
      ],
      registration: [
      ],
      administrationDate: [
      ],
      salary: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        }
      ],
      bankNumber: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        }
      ],
      bankName: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        }
      ],
      agency: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        },
        {
          type: 'minlength',
          message: 'O nome precisar ter 6 caracteres',
        }
      ],
      bankAccountType: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        }
      ],
      inss: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        }
      ],
      incomeTax: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        }
      ],
      unionsdues: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        }
      ],

      courtCharges: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        }
      ],
      alimony: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        }
      ],
      healthPlan: [
      ],
      dentalPlan: [
      ],
      lifeInsurance: [

      ],
      otherDiscounts: [
      ],
      installmentAmount: [],
      managerResponsible: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        },
        {
          type: 'minlength',
          message: 'O nome precisar ter 6 caracteres',
        }
      ],
      cellPhoneManagerResponsible: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        },
      ],
      responsibleManagerEmail: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        },
        {
          type: 'minlength',
          message: 'O nome precisar ter 6 caracteres',
        }
      ],
      responsibleIdentityGov: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        },
        {
          type: 'minlength',
          message: 'O nome precisar ter 6 caracteres',
        }
      ],
    }
  }

  getEmployeesBraspress(cpf: string) {
    return this.speViewNBackofficeConsignedProxy.getEmployeesBraspress(cpf);
  }

  public sycForm(employee: any) {
    const dateBirth = moment(employee.customerData?.birthDate).format("DD/MM/YYYY");

    this.formEmployee.patchValue({

      uuid: employee.uuid,
      id: employee.id,

      // EMPLOYEE DATA
      fullName: employee.customerData?.fullName,
      identityGov: employee.customerData?.identityGov,
      identity: employee.customerData?.identity,
      birthDate: dateBirth,
      fatherName: employee.customerData?.fatherName,
      motherName: employee.customerData?.motherName,
      dddCellPhone: this.setPhoneGeneric(employee.customerData?.dddCellPhone),
      homePhone: employee.customerData?.homePhone,
      email: employee.customerData?.email,
      sex: (!!employee.customerData?.sex ? employee.customerData?.sex?.toUpperCase() : ""),
      ufIdentity: employee.customerData?.ufIdentity,
      maritalStatus: this.setMaritalStatus(employee.customerData?.maritalStatus),
      nationality: (!!employee.customerData?.nationality && employee.customerData?.nationality === 'B') ? 'Brasileiro' : employee.customerData?.nationality,
      naturalNess: employee.customerData?.naturalNess,
      dependentNumber: employee.customerData?.dependentNumber,
      identityIssueDate: moment(employee.customerData?.identityIssueDate).format("DD/MM/YYYY"),


      //EMPLOYEE ADDRESS

      residentialStreet: employee.customerAddress?.residentialStreet,
      residenceNumber: employee.customerAddress?.residenceNumber,
      residentialNeighborhood: employee.customerAddress?.residentialNeighborhood,
      zipCodeResidence: employee.customerAddress?.zipCodeResidence,
      homecity: employee.customerAddress?.homecity,
      ufResidence: (!!employee.customerAddress?.ufResidence ? employee.customerAddress?.ufResidence.toUpperCase() : ""),
      residenceComplement: employee.customerAddress?.residenceComplement,


      //EMPLOYEE WORK

      streetWork: employee.work?.streetWork,
      companyName: employee.work?.companyName,
      workNumber: employee.work?.workNumber,
      neighborhoodWork: employee.work?.neighborhoodWork,
      zipCodeWork: employee.work?.zipCodeWork,
      cityWork: employee.work?.cityWork,
      ufWork: (!!employee.work?.ufWork ? employee.work?.ufWork?.toUpperCase() : ""),
      dddWork: employee.work?.phoneWork,
      phoneWork: employee.work?.phoneWork,
      emailWork: employee.work?.emailWork,
      ocuppation: employee.work?.ocuppation,
      sector: employee.work?.sector,
      registration: employee.work?.registration,
      administrationDate: moment(employee.work?.administrationDate).format("DD/MM/YYYY"),
      salary: employee.work?.salary,
      bankNumber: employee.work?.bankNumber,
      bankName: employee.work?.bankName,
      agency: employee.work?.agency,
      bankAccount: employee.work?.bankAccount,
      responsibleManagerEmail: employee.work?.responsibleManagerEmail,
      bankAccountType: employee.work?.bankAccountType,
      inss: employee.work?.inss,
      incomeTax: employee.work?.incomeTax,
      cellPhoneManagerResponsible: this.setPhoneGeneric(employee.work?.cellPhoneManagerResponsible),
      managerResponsible: employee.work?.managerResponsible,
      unionsdues: employee.work?.unionsdues,
      complementWork: employee.work?.complementWork,
      responsibleIdentityGov: employee.work?.responsibleIdentityGov,
      uuidCompany: employee.uuidCompany,


      //EMPLOYEE BENEFITS

      alimony: employee.benefits?.alimony,
      healthPlan: employee.benefits?.healthPlan,
      courtCharges: employee.benefits?.courtCharges,
      dentalPlan: employee.benefits?.dentalPlan,
      lifeInsurance: employee.benefits?.lifeInsurance,
      otherDiscounts: employee.benefits?.otherDiscounts,

      fineInstallment: employee.benefits?.fineInstallment,
      collisionInstallment: employee.benefits?.collisionInstallment,
      responsibilityPortion: employee.benefits?.responsibilityPortion,
      installmentProducts: employee.benefits?.installmentProducts,
      otherInstallment: employee.benefits?.otherInstallment,
      installmentAmount: employee.benefits?.pmtUrbano,
    })
  }

  public sycFormBrasspress(employee: any) {
    const dateBirth = moment(new Date(Number(employee.birthDate))).format("DD/MM/YYYY");
    const dateIdentityIssueDate = moment(new Date(Number(employee.identityIssueDate))).format("DD/MM/YYYY");
    const administrationDate = moment(new Date(Number(employee.admissionDate))).format("DD/MM/YYYY");

    this.formEmployee.patchValue({
      id: employee.id,
      // EMPLOYEE DATA
      fullName: employee.fullName,
      identityGov: employee.cpf,
      identity: employee.identity,
      birthDate: dateBirth,
      fatherName: employee.fatherName,
      motherName: employee.motherName,
      dddCellPhone: this.setPhoneGeneric(employee.CellPhone),
      homePhone: employee.homePhone,
      email: employee.email,
      sex: employee.sex.substr(0, 1).toUpperCase(),
      ufIdentity: employee.identityGov,
      maritalStatus: this.setMaritalStatus(employee.maritalStatus),
      nationality: employee.nationality.toUpperCase() === 'B' ? 'Brasileiro' : employee.nationality,
      naturalNess: employee.naturalNess,
      dependentNumber: employee.dependentNumber,
      identityIssueDate: dateIdentityIssueDate != 'Invalid date' ? dateIdentityIssueDate : '',


      //EMPLOYEE ADDRESS

      residentialStreet: employee.residentialStreet,
      residenceNumber: employee.registerNumber,
      residentialNeighborhood: employee.residentialNeighborhood,
      zipCodeResidence: employee.zipCodeResidence,
      homecity: employee.homecity,
      ufResidence: employee.ufResidence?.toUpperCase(),
      residenceComplement: employee.residenceComplement,


      //EMPLOYEE WORK

      streetWork: employee.streetWork,
      companyName: employee.companyName,
      workNumber: employee.workNumber,
      neighborhoodWork: employee.neighborhoodWork,
      zipCodeWork: employee.zipCodeWork,
      cityWork: employee.cityWork,
      ufWork: employee.ufWork.toUpperCase(),
      dddWork: employee.phoneWork,
      phoneWork: employee.phoneWork,
      emailWork: employee.emailWork,
      ocuppation: employee.ocuppation,
      sector: employee.sector,
      registration: employee.registerNumber,
      administrationDate: administrationDate,
      salary: employee.salary,
      bankNumber: employee.bankNumber,
      bankName: employee.bankName,
      agency: employee.agency,
      bankAccount: employee.bankAccount,
      responsibleManagerEmail: employee.responsibleManagerEmail,
      bankAccountType: employee.bankAccountType,
      inss: employee.inss,
      incomeTax: employee.incomeTax,
      cellPhoneManagerResponsible: this.setPhoneGeneric(employee.cellPhoneManagerResponsible),
      managerResponsible: employee.managerResponsible,
      unionsdues: employee.unionsdues,
      complementWork: employee.complementWork,
      responsibleIdentityGov: employee.responsibleIdentityGov,
      uuidCompany: employee.uuidCompany,


      //EMPLOYEE BENEFITS
      alimony: employee.alimony,
      healthPlan: employee.healthPlan,
      courtCharges: employee.courtCharges,
      dentalPlan: employee.dentalPlan,
      lifeInsurance: employee.lifeInsurance,
      otherDiscounts: employee.otherDiscounts + employee.othersBazar,
      fineInstallment: employee.fineInstallment,
      collisionInstallment: employee.collisionInstallment,
      responsibilityPortion: employee.responsibilityPortion,
      installmentProducts: employee.installmentProducts,
      otherInstallment: employee.otherInstallment + employee.installmentAmountBazar,      
      installmentAmount: employee.installmentAmount,
    });        
  }

  public prepareEmployee(employeeForm: FormGroup): Employee {
    let values = employeeForm.getRawValue();
    const splitBirthDate = values.birthDate.split("/");
    const splitadministrationDate = values.administrationDate.split("/");
    const splitdentityIssueDate = values.identityIssueDate.split("/");

    return {
      "uuid": values.uuid ? values.uuid : "",
      "id": values.id ? values.id : "",
      "isImportation": false,
      "uuidCompany": values.uuidCompany ? values.uuidCompany : "",
      "customerData": {
        "fullName": values.fullName,
        "identityGov": values.identityGov,
        "identity": values.identity,
        "birthDate": `${splitBirthDate[2]}-${splitBirthDate[1]}-${splitBirthDate[0]}`,
        "fatherName": values.fatherName,
        "motherName": values.motherName,
        "dddCellPhone": this.setPhoneGeneric(values.dddCellPhone),
        "homePhone": values.homePhone,
        "email": values.email,
        "sex": values.sex.toUpperCase(),
        "naturalNess": values.naturalNess,
        "maritalStatus": this.setMaritalStatus(values.maritalStatus),
        "nationality": values.nationality,
        "dependentNumber": values.dependentNumber,
        "ufIdentity": values.ufIdentity,
        "identityIssueDate": `${splitdentityIssueDate[2]}-${splitdentityIssueDate[1]}-${splitdentityIssueDate[0]}`,
      },
      "customerAddress": {
        "residentialStreet": values.residentialStreet,
        "residenceNumber": values.residenceNumber.toString(),
        "residenceComplement": values.residenceComplement,
        "residentialNeighborhood": values.residentialNeighborhood,
        "zipCodeResidence": values.zipCodeResidence,
        "ufResidence": values.ufResidence.toUpperCase(),
        "homecity": values.homecity,
      },
      "work": {
        "companyName": values.companyName,
        "streetWork": values.streetWork,
        "workNumber": values.workNumber.toString(),
        "complementWork": values.complementWork,
        "neighborhoodWork": values.neighborhoodWork,
        "zipCodeWork": values.zipCodeWork,
        "cityWork": values.cityWork,
        "ufWork": values.ufWork.toUpperCase(),
        "dddWork": values.dddWork,
        "phoneWork": values.phoneWork,
        "emailWork": values.emailWork,
        "ocuppation": values.ocuppation,
        "sector": values.sector,
        "registration": values.registration,
        "administrationDate": `${splitadministrationDate[2]}-${splitadministrationDate[1]}-${splitadministrationDate[0]}`,
        "salary": values.salary,
        "bankNumber": values.bankNumber,
        "bankName": values.bankName,
        "agency": values.agency,
        "bankAccount": values.bankAccount,
        "inss": values.inss,
        "incomeTax": values.incomeTax,
        "unionsdues": values.unionsdues,
        "managerResponsible": values.managerResponsible,
        "cellPhoneManagerResponsible": this.setPhoneGeneric(values.cellPhoneManagerResponsible),
        "responsibleManagerEmail": values.responsibleManagerEmail,
        "bankAccountType": values.bankAccountType,
        "responsibleIdentityGov": values.responsibleIdentityGov
      },
      "benefits": {
        "alimony": values.alimony,
        "healthPlan": values.healthPlan,
        "dentalPlan": values.dentalPlan,
        "lifeInsurance": values.lifeInsurance,
        "otherDiscounts": values.otherDiscounts,
        "courtCharges": values.courtCharges,
        "fineInstallment": values.fineInstallment,
        "collisionInstallment": values.collisionInstallment,
        "responsibilityPortion": values.responsibilityPortion,
        "installmentProducts": values.installmentProducts,
        "otherInstallment": values.otherInstallment,
        "pmtUrbano": values.installmentAmount
      },
      "loan": {
        "loan": values.loan,
        "consignableValue": values.consignableValue,
        "consignablePercent": values.consignablePercent,
      },
      "customersType": values.customersType,
      "customerStatus": values.customerStatus,
      "dateRegister": values.dateRegister,
      "deleted": values.deleted
    }
  }


  public updateAddressResentential(form: any, address: any): void {
    form.patchValue({
      residentialStreet: address.logradouro,
      residentialNeighborhood: address.bairro,
      residenceComplement: address.complemmento,
      ufResidence: address.uf,
      homecity: address.localidade,
      country: 'br'
    });
  }

  public updateAddressWork(form: any, address: any): void {
    form.patchValue({
      streetWork: address.logradouro,
      neighborhoodWork: address.bairro,
      complementWork: address.complemmento,
      ufWork: address.uf,
      cityWork: address.localidade,
      country: 'br'
    });
  }

  uploadEmployess(body: any) {
    return this.speNBackofficeConsignedProxy.uploadEmployees(body);
  }

  private setPhoneGeneric(cell: any) {    
    if (!!cell && cell.length >= 11 && !isNaN(cell)) {
      return cell;
    } else {
      return '99999999999';
    }
  }

  private setMaritalStatus(status: any) {

    if (!isNaN(status)) return status;
    status = status.toLowerCase();
    /* Solteiro */
    if (status.includes('sol')) return 0;
    /* Casado */
    if (status.includes('ca')) return 1;
    /* União estável */
    if (status.includes('div')) return 2;
    /* Divorciado */
    if (status.includes('uni')) return 3;
    /* Viúvo */
    if (status.includes('vi')) return 4;
  }
}


