import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CalculationEngineRoutingModule } from './calculation-engine-routing.module';
import { CalculationEngineSimulatorComponent } from './calculation-engine-simulator/calculation-engine-simulator.component';
import { InterceptService } from 'src/app/core/_base/_auth/intercept/intercept.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';


@NgModule({
  declarations: [
    CalculationEngineSimulatorComponent
  ],
  imports: [
    CommonModule,
    CalculationEngineRoutingModule
  ],
  providers: [
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
  ],
})
export class CalculationEngineModule { }
