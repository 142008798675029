<div id="product-type-form-component">

    <!--Título-->
    <h1 class="page-title mb-4"> {{ isNewType ? 'Cadastro de Tipo de Produto' : 'Tipos de Produto / ' + nameType }} </h1>

    <!--Botão Voltar-->
    <div id="back-to-last-page-btn-container">
        <button type="button" class="btn btn-warning btn-default px-10 mb-4" id="back-to-last-page-btn" (click)="backToLastPage()">
            <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr074.svg'" class="svg-icon svg-icon-1 me-4"></span>
            <span class="pt-2"> Voltar </span>
        </button>
    </div>

    <!--Formulário Informações Gerais-->
    <section class="card mb-4">
        <div class="card-header border-0 d-flex align-items-center justify-content-start">
            <span [inlineSVG]="'./assets/media/icons/duotune/general/gen045.svg'"
                  class="svg-icon svg-icon-2tx svg-icon-gray-800 me-4"></span>
            <h3 class="fw-bolder card-title mt-3"> Informações Gerais </h3>
        </div>
        <div class="card-body border-top p-9">
            <form>
                <div class="d-flex">
                    <div class="col-3 px-10">
                        <app-ui-c-input [form]="formTypeProduct" [errorMessages]="errorMessagesTypeProduct" [formName]="'name'" [name]="'Nome:'"
                                        [type]="'text'" [placeholder]="'Digite o nome'"></app-ui-c-input>
                    </div>
                </div>
            </form>
        </div>
    </section>

    <!--Botão Salvar-->
    <div class="d-flex justify-content-end my-8">
        <button type="button" class="btn btn-primary btn-default" [disabled]="formTypeProduct.invalid" (click)="saveTypeProduct()">
            Salvar
        </button>
    </div>

    <ng-container *ngIf="!isNewType">

        <!--Container de Produtos-->
        <div class="product-container d-flex justify-content-between align-items-center my-8">
            <div class="card-header">
                <h3 class="card-title"> Produtos </h3>
            </div>
            <button type="button" class="btn btn-success btn-default" (click)="redirectToNewOrEditProduct()">
                Criar Produto
            </button>
        </div>

        <!--Tabela-->
        <ng-container *ngIf="tableProducts">
            <ui-c-table [tableData]="tableProducts"></ui-c-table>
        </ng-container>

    </ng-container>


</div>
