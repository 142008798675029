import { NgModule } from '@angular/core';
import { SimulationTableFinanceComponent } from './simulation-table-finance.component';

@NgModule({
    imports: [
    ],
    declarations: [
        SimulationTableFinanceComponent
    ],
    exports: [
        SimulationTableFinanceComponent
    ],
})
export class SimulationTableFinanceModule { }