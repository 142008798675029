import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import Swal from 'sweetalert2';
// import { StorageService } from '../../services-ultil/storage/storage.service';
import { SearchBoxUtils } from './extends/search-box.utils';
import { Router } from '@angular/router';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent extends SearchBoxUtils implements OnInit {

  dataTable: MatTableDataSource<any>;

  @ViewChild('searchInput') searchInput: ElementRef;
  form: FormGroup;

  @Input() startDate: any;
  @Input() endDate: any;
  @Input() textButton?: string = 'Pesquisar';
  @Input() showDate?: boolean = true;
  @Input() defaultDate: number = 90;
  @Output() emitDate = new EventEmitter();
  @Input() disabled?: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    // private storageSerice: StorageService,
    private router: Router
  ) {
    super()
    this.form = this.searchForm();
  }

  ngOnInit(): void {
    // let filterLS = this.storageSerice.getData(this.router.url) ? this.storageSerice.getData(this.router.url) : '' ;

    // if(filterLS){
    //   let aux = JSON.parse(filterLS);
    //   this.startDate = aux.startDate;
    //   this.endDate = aux.endDate;

    //   this.form.controls.startDate.setValue(this.startDate);
    //   this.form.controls.endDate.setValue(this.endDate);
    // }else{
      const currencydate = new Date();
      this.startDate = moment(new Date().setDate(currencydate.getDate() - this.defaultDate)).format('YYYY-MM-DD');
      this.endDate = moment(new Date().setDate(currencydate.getDate() + 1)).format('YYYY-MM-DD');

      this.form.controls.startDate.setValue(this.startDate);
      this.form.controls.endDate.setValue(this.endDate);
    // }
  }

  searchForm() {
    return this.formBuilder.group({
      field: [
        '',
      ],
      value: [
        '',
      ],
      startDate: [
        '',
      ],
      endDate: [
        '',
      ],
      status: [
        '',
      ],
    })
  }

  getData() {
    let startDate = this.formatDate(this.form.controls.startDate.value);
    let endDate = this.formatDate(this.form.controls.endDate.value);

    if (this.form.controls.startDate.value == '' || this.form.controls.endDate.value == '') {
      this.getDefaultDate();
      return;
    }

    if (!this.showDate) {
      this.emitDate.emit({ startDate });
    } else {
      if (startDate > endDate) {
        Swal.fire({
          title: 'Atenção!',
          text: 'A data de inicial não poder ser maior que a final',
          icon: 'error',
          confirmButtonText: 'fechar'
        })
      } else {
        // this.storageSerice.saveData(this.router.url, JSON.stringify({'startDate': startDate, 'endDate': endDate}));
        this.emitDate.emit({ startDate, endDate });
      }
    }

  }


  formatDate(date: any) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-');
  }

  getDefaultDate(): any {
    const currencydate = new Date();
    this.startDate = moment(new Date().setDate(currencydate.getDate() - this.defaultDate)).format('YYYY-MM-DD');
    this.endDate = moment(currencydate).format('YYYY-MM-DD');
    this.emitDate.emit({ startDate: this.startDate, endDate: this.endDate })
  }
}
