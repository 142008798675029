<div id="tax-form-component">
    <h1 class="page-title principal-title mb-4  d-flex justify-content-center align-items-center fw-bolder">Aqui está sua proposta! </h1>
    <div>
        <h2 class="principal-subtitle d-flex justify-content-center">Financiamento de Carro</h2>
    </div>


    <section class="card mb-4 shadow-sm">
        <div class="card-header border-0 d-flex align-items-center justify-content-start">
      <span [inlineSVG]="'./assets/media/icons/duotune/general/gen045.svg'"
            class="svg-icon svg-icon-2tx svg-icon-gray-800 me-4"></span>
            <h3 class="fw-bolder card-title mt-3">Seus Dados</h3>
        </div>

        <div class="card-body border-top p-9">
            <form>
                <div class="d-flex">
                    <div class="col-3 px-10">
                        <app-ui-c-input   type="text"  [block]="true" [form]="formAccept" [formName]="'name'" [name]="'Nome:'" ></app-ui-c-input>
                    </div>
                    <ng-container *ngIf="isPf" >
                    <div class="col-3 px-10">
                        <app-ui-c-input  type="text" [block]="true" [form]="formAccept" [formName]="'document'" [name]="'CPF:'"></app-ui-c-input>
                    </div>
                    </ng-container>
                    <ng-container *ngIf="!isPf" >
                        <div class="col-3 px-10">
                            <app-ui-c-input  type="text" [block]="true" [form]="formAccept" [formName]="'document'" [name]="'CNPJ:'"></app-ui-c-input>
                        </div>
                    </ng-container>
                    <div class="col-3 px-10">
                        <app-ui-c-input type="text" [block]="true" [form]="formAccept" [formName]="'phone'" [name]="'Telefone:'"></app-ui-c-input>
                    </div>
                    <div class="col-3 px-10">
                        <app-ui-c-input type="text"  [block]="true" [form]="formAccept" [formName]="'email'" [name]="'E-mail:'"></app-ui-c-input>
                    </div>
                </div>
            </form>
        </div>
    </section>

    <section class="card mb-4 shadow-sm card-bottom">
        <div class="card-header border-0 d-flex align-items-center justify-content-start">
      <span [inlineSVG]="'./assets/media/icons/duotune/general/gen045.svg'"
            class="svg-icon svg-icon-2tx svg-icon-gray-800 me-4"></span>
            <h3 class="fw-bolder card-title mt-3">Parcelas e detalhes</h3>
        </div>

        <div class="d-flex justify-content-center flex-column align-items-center p-9 border-top">
            <h1>R$ 10.000,00</h1>
            <p class="fs-6 fw-bold">6x R$619,14</p>
        </div>

    <ng-container *ngIf="tableProposal" >
        <ui-c-table [tableData]="tableProposal"></ui-c-table>
    </ng-container>
    </section>

    <div class="d-flex justify-content-end">
        <button  (click)="openModal()"  type="button"  class="btn btn-primary">
            Aceitar proposta
        </button>
    </div>
</div>
