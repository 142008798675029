import { Component, Input, OnInit, ChangeDetectorRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-tablesimulator',
  templateUrl: './tablesimulator.component.html',
  styleUrls: ['./tablesimulator.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class TablesimulatorComponent implements OnInit {

  @Input() dataSource: any;
  @Input() simulationData?: any;
  @Output() emitInstallmentNumber? = new EventEmitter();

  columnsToDisplay = ['installmentsNumber', 'installmentValue', 'totalAmountProposal', 'totalInterest'];
  //columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement!: any;
  tabelaCalc!: any;
  checked: any

  index: any;
  @ViewChild('paginator') paginator!: MatPaginator;
  @ViewChild(MatSort) matSort!: MatSort;
  dataSourceInstallment: MatTableDataSource<any>;

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.simulationData.installments)
    this.dataSourceInstallment = new MatTableDataSource();
  }

  ngAfterViewInit() {
    this.dataSourceInstallment.paginator = this.paginator;
  }


  createDataSource(data: any) {
    this.dataSourceInstallment = new MatTableDataSource(data);
    this.dataSourceInstallment.paginator = this.paginator;
  }


  selected(itemSelected: any, index?: any) {
    var checkboxes = document.getElementsByName('check');
    let position = this.dataSource.filteredData.indexOf(itemSelected);
    checkboxes.forEach((item: any) => {
      item.checked = false;
    })
    let checkItem: any = checkboxes[position];
    checkItem.checked = true;
   // console.log(itemSelected)
   /*  this.emitInstallmentNumber.emit(itemSelected.installmentsNumber); */
  }
}
