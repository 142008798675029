import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomersRoutes } from './customers.routing';
import { CustomerFormComponent } from './customer-form/customer-form.component';
import { CustomerListComponent } from './customer-list/customer-list.component';
import { InlineSVGModule } from "ng-inline-svg-2";
import { UiCTableModule } from 'src/app/shared/components/ui-c-table/ui-c-table.module';
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { SharedModule } from "src/app/shared/shared.module";
import { UiCInputModule } from "src/app/shared/components/ui-c-input/ui-c-input.module";

@NgModule({
    imports: [
        CommonModule,
        CustomersRoutes,
        InlineSVGModule,
        UiCTableModule,
        MatSlideToggleModule,
        InlineSVGModule,
        SharedModule,
        UiCInputModule
    ],
  declarations: [
      CustomerFormComponent,
      CustomerListComponent,
  ]
})
export class CustomersModule { }
