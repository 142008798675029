import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiService } from 'src/app/core/_base/_auth/api.service';
import { FormControl, Validators } from '@angular/forms';
import { ConfigurationTaxDto, UpdateTaxDto } from './tax.dto';
import { SpeViewNScfiProxy } from 'src/app/core/proxy/view/spe-view-n-scfi.proxy';
import { Observable } from 'rxjs';
import { SpeNScfiProxy } from 'src/app/core/proxy/spe/spe-n-scfi.proxy';

@Injectable({
    providedIn: 'root'
})
export class TaxService {

    formTax: FormGroup;

    constructor(
        protected api: ApiService,
        private formBuilder: FormBuilder,
        private speViewNScfiProxy: SpeViewNScfiProxy,
        private speNScfiProxy: SpeNScfiProxy
    ) { }

    public createFormTax(): FormGroup {
        this.formTax = this.formBuilder.group({
            pfIofIndex: new FormControl('', Validators.compose([
                Validators.required,
                Validators.min(0.000001)
            ])),
            pfIofDailyIndex: new FormControl('', Validators.compose([
                Validators.required,
                Validators.min(0.000001)
            ])),
            pfIofMaxDays: new FormControl('', Validators.compose([
                Validators.required
            ])),
            pjIofIndex: new FormControl('', Validators.compose([
                Validators.required,
                Validators.min(0.000001)
            ])),
            pjIofDailyIndex: new FormControl('', Validators.compose([
                Validators.required,
                Validators.min(0.000001)
            ])),
            pjIofMaxDays: new FormControl('', Validators.compose([
                Validators.required
            ]))
        })
        return this.formTax;
    }

    public getErrorMessages() {
        return {
            pfIofIndex: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
                {
                    type: 'min',
                    message: 'O imposto deve ser maior do que zero',
                }
            ],
            pfIofDailyIndex: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
                {
                    type: 'min',
                    message: 'O imposto deve ser maior do que zero',
                }
            ],
            pfIofMaxDays: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                }
            ],
            pjIofIndex: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
                {
                    type: 'min',
                    message: 'O imposto deve ser maior do que zero',
                }
            ],
            pjIofDailyIndex: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
                {
                    type: 'min',
                    message: 'O imposto deve ser maior do que zero',
                }
            ],
            pjIofMaxDays: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                }
            ]
        }
    }

    public findTaxes(): Observable<ConfigurationTaxDto[]> {
        return this.speViewNScfiProxy.findTaxes();
    }

    public findTaxByUuid(uuid: string): Observable<ConfigurationTaxDto> {
        return this.speViewNScfiProxy.findTaxByUuid(uuid);
    }

    public updateTaxData(tax: UpdateTaxDto): Observable<ConfigurationTaxDto> {
        return this.speNScfiProxy.updateTax(tax);
    }
}