<div class="date-picker w-100">
  <form [formGroup]="form" autocomplete="off">
    <div class="card-body">
      <div class="form-group p-0 m-0">
        <div class="d-flex p-0 w-100">
          <div class="d-flex">
            <div class="">
              <mat-form-field appearance="fill" class="mt-0 mb-0">
                <label>Data Inicial</label>
                <input matInput (dateInput)="onDateChange(form.value.startDate, 1)" [matDatepicker]="picker1"
                  formControlName="startDate" />
                <mat-datepicker #picker1></mat-datepicker>
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              </mat-form-field>
            </div>

            <div class="ms-3" *ngIf="showDate">
              <mat-form-field appearance="fill" class="pt-0 pb-0 ms-3">
                <label>Data Final</label>
                <input matInput (dateInput)="onDateChange(form.value.endDate, 2)" [matDatepicker]="picker2"
                  formControlName="endDate" />
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
              </mat-form-field>
            </div>

          </div>
          <div class="mx-3 mt-6 ms-5">
            <button (click)="getData()" [disabled]="disabled" class="btn btn-info" type="button">
              {{textButton}} <i class="fas fa-chevron-right icon-xs ml-3"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>