import { RawInsuranceModel } from './dashboard-model/raw-insurance-model';
import { RawTwoModel } from './dashboard-model/raw-two-model';
import { DashBoardService } from './dashboard-service/dashboard-service';
import { Component, ViewChild, OnInit, ChangeDetectorRef, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { ModalConfig, ModalComponent  } from 'src/app/_metronic/partials';
import { environment } from 'src/environments/environment';
import { RawOneModel } from './dashboard-model/raw-one-model';
import { NgxSpinnerService } from 'ngx-spinner';
import { STRING_TYPE } from '@angular/compiler';
import { UserInfoService } from 'src/app/core/_base/_auth/user-info.service';
declare var google: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  modalConfig: ModalConfig = {
    modalTitle: 'Modal title',
    dismissButtonLabel: 'Submit',
    closeButtonLabel: 'Cancel'
  };
  @ViewChild('modal') private modalComponent: ModalComponent;
  appVersion: any;
  emailUser: string;

  public rawOne: RawOneModel = new RawOneModel();
  public rawTwo: RawTwoModel;
  public rawInsurance: RawInsuranceModel;
  public potencialHeight = 'height: 0% !important;';
  public roleUser: string | undefined;
  public chart: any;


  @ViewChild('pieChart') pieChart: ElementRef;  
  @ViewChild('MyChart') MyChart: ElementRef;
  @ViewChild('contractChart') contractChart: ElementRef;
  @ViewChild('invoiceChart') invoiceChart: ElementRef;
  @ViewChild('interestChart') interestChart: ElementRef;
  isContainsDataInterestChart: boolean;

  constructor(
    private dashBoardService: DashBoardService,
    private spinner: NgxSpinnerService,
    private cdr: ChangeDetectorRef,
    private userInfoService: UserInfoService,
    private renderer: Renderer2
  ) {
    this.appVersion = environment.appVersion;
    this.rawOne = new RawOneModel();
    this.roleUser = this.userInfoService.verifyRole();
  }


  async ngOnInit() {
    this.spinner.show();
    if (this.roleUser == 'liquidation') {
      this.setDashboard('all');
    } else {
      await this.userInfoService.getInfoProfile().then(async res => {
        if (res?.email) {
          this.emailUser = await res.email;
        }
      })

      if (await this.emailUser) {
        await this.dashBoardService.getUuidCompanyByEmail(this.emailUser).subscribe(async (res:any) => {
          if (res) {
            this.setDashboard(res.company)
          }
        })
      }
    }
  }

  setDashboard(uuidCompany: string) {
    this.spinner.show();
    if (uuidCompany == 'ALL')
      uuidCompany = 'all'

    this.dashBoardService.getRawOne(uuidCompany).subscribe((res:any) => {
      if (res) {        
        this.rawOne = res;        
        google.load("visualization", "1", { packages: ["corechart"] });
        google.charts.setOnLoadCallback(this.drawBaldeChart);
        google.charts.setOnLoadCallback(this.drawBaldeChartGeContracts);
        google.charts.setOnLoadCallback(this.drawInterestChart);
        google.charts.setOnLoadCallback(this.drawInvoiceChart);
        this.cdr.detectChanges();
        this.spinner.hide();
      }
    })

    this.dashBoardService.getRawTwo(uuidCompany).subscribe((res:any) => {
      if (res) {
        this.rawTwo = res;
        this.cdr.detectChanges();
        this.spinner.hide();
      }
    })

    this.dashBoardService.getRawInsurance(uuidCompany).subscribe((res:any) => {
      if (res) {
        this.rawInsurance = res;
        this.cdr.detectChanges();
        this.spinner.hide();
      }
    })
  }

  emitCompany(event: string) {
    this.setDashboard(event);
    this.cdr.detectChanges();
  }

  parseFloatValueAndToFixedOnTwo(value?: any) {
    if (value)
      return Number(value).toFixed(2);
    return 0;
  }

  async openModal() {
    return await this.modalComponent.open();
  }



  drawHistoryChart = () => {
    const dataBar = google.visualization.arrayToDataTable([
      ['Task', ''],
      ['Jan', 11],
      ['Fev', 7],
      ['Mar', 7],
      ['Abr', 11],
      ['MAi', 11],
      ['Jun', 7],
      ['Jul', 11],
      ['Ago', 7],
      ['Set', 11],
      ['Out', 7],
      ['Nov', 11],
      ['Dez', 7],
    ]);

    const optionsBar = {
      legend: { position: 'none' },
      'width': '100%',
      'height': 300,
      'chartArea': { 'width': '98%' },
    };    
  }

  public formatter(value: number): string {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
  }

  drawBaldeChart = () => {
    const data = google.visualization.arrayToDataTable([
      ['Task', 'Potencial Balde'],
      [`Potencial - ${this.formatter(this.rawOne.bucket.potencialValue)}`, this.rawOne.bucket.potencialValue],
      [`Atual - ${this.formatter(this.rawOne.bucket.nowValue)} `, this.rawOne.bucket.nowValue],
    ]);

    const options = {
      title: `Uso do Balde - ${this.rawOne.bucket.nowIndex.toFixed(2)}%`,
      legend: { position: 'rigth' },
      pieHole: 0.6,
      responsive: true,
      'width': '100%',
      'height': 300,
      'chartArea': { 'width': '98%' },
    };

    const chart = new google.visualization.PieChart(this.pieChart.nativeElement);
    chart.draw(data, options);
  }


  drawBaldeChartGeContracts = () => {

    const paidContractsLastYearCard = this.rawOne.paidContractsLastYearCard.totalValue != null ? parseFloat(this.rawOne.paidContractsLastYearCard.totalValue.toString()) : 0;
    const paidContractsMonthCard = this.rawOne.paidContractsMonthCard.totalValue != null ? parseFloat(this.rawOne.paidContractsMonthCard.totalValue.toString()) : 0;
    const paidContractsLastMonthCard = this.rawOne.paidContractsLastMonthCard.totalValue != null ? parseFloat(this.rawOne.paidContractsLastMonthCard.totalValue.toString()) : 0;

    const dataArtray = [paidContractsLastYearCard, paidContractsMonthCard, paidContractsLastMonthCard];
    this.isContainsDataInterestChart = this.verifyArrayOnlyZero(dataArtray);
    if (this.isContainsDataInterestChart) {
      this.setNotFountChartElement(this.contractChart);
    } else {
      const data = google.visualization.arrayToDataTable([
        ['Task', 'Potencial Balde'],
        [`Ultimo Ano - ${this.formatter(paidContractsLastYearCard)}`, paidContractsLastYearCard],
        [`Mês Atual -  ${this.formatter(paidContractsMonthCard)}`, paidContractsMonthCard],
        [`Mês Passado -  ${this.formatter(paidContractsLastMonthCard)}`, paidContractsLastMonthCard],
      ]);
      this.genericChart(data, this.contractChart, '', 'top');
    }
  }

  drawInvoiceChart = () => {

    const invoicesYearCard = this.rawOne.invoicesYearCard.value != null ? parseFloat(this.rawOne.invoicesYearCard.value.toString()) : 0;
    const invoicesMonthCard = this.rawOne.invoicesMonthCard.value != null ? parseFloat(this.rawOne.invoicesMonthCard.value.toString()) : 0;
    const lastMonthInvoicesCard = this.rawOne.lastMonthInvoicesCard.value != null ? parseFloat(this.rawOne.lastMonthInvoicesCard.value.toString()) : 0;

    const dataArtray = [invoicesYearCard, invoicesMonthCard, lastMonthInvoicesCard];

    this.isContainsDataInterestChart = this.verifyArrayOnlyZero(dataArtray);
    if (this.isContainsDataInterestChart) {
      this.setNotFountChartElement(this.invoiceChart);
    } else {

      const data = google.visualization.arrayToDataTable([
        ['Task', 'Faturas'],
        [`Ultimo Ano -  ${this.formatter(invoicesYearCard)}`, invoicesYearCard],
        [`Mês Atual -  ${this.formatter(invoicesMonthCard)}`, invoicesMonthCard],
        [`Mês Passado -  ${this.formatter(lastMonthInvoicesCard)}`, lastMonthInvoicesCard],
      ]);
      this.genericChart(data, this.invoiceChart, '', 'top');
    }
  }

  drawInterestChart = () => {
    const interrest = this.rawOne.totalInteresCard.totalInterest != null ? parseFloat(this.rawOne.totalInteresCard.totalInterest.toString()) : 0;
    const iof = this.rawOne.totalIofCard.totalIof != null ? parseFloat(this.rawOne.totalIofCard.totalIof.toString()) : 0;
    const dataArtray = [iof, interrest];
    this.isContainsDataInterestChart = this.verifyArrayOnlyZero(dataArtray);
    if (this.isContainsDataInterestChart) {
      this.setNotFountChartElement(this.interestChart);
    } else {
      const data = google.visualization.arrayToDataTable([
        ['Task', 'Faturas'],
        [`Juros - ${this.formatter(interrest)}`, interrest],
        [`IOF - ${this.formatter(iof)}`, iof],
      ]);
      this.genericChart(data, this.interestChart, '', 'top');
    }
  }

  verifyArrayOnlyZero(dataArtray: any): boolean {
    return dataArtray.every((item: any) => item === 0)
  }

  setNotFountChartElement(elementRef: any) {
    this.renderer.setProperty(
      elementRef.nativeElement,
      'innerHTML',
      '<h3 style="color:gray">Não existem dados no momento!</h3>'
    );
  }

  genericChart = (data: any, elementChart: any, title: string, position?: string) => {
    const options = {
      title,
      titleTextStyle: {
        fontSize: 26
      },
      legend: { position },
      pieHole: 0.3,
      responsive: true,
      'width': '100%',
      'height': 300,
      'chartArea': { 'width': '98%' },
    };

    const chart = new google.visualization.PieChart(elementChart.nativeElement);
    chart.draw(data, options);
  }

  verifyValue(value: any) {
    return value != null ? value : 0;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {    
    google.charts.setOnLoadCallback(this.drawBaldeChart);
    google.charts.setOnLoadCallback(this.drawBaldeChartGeContracts);
    google.charts.setOnLoadCallback(this.drawInterestChart);
    google.charts.setOnLoadCallback(this.drawInvoiceChart);
    this.cdr.detectChanges();
  }
}
