import { UserInfoService } from 'src/app/core/_base/_auth/user-info.service';
import { MatInputModule } from '@angular/material/input';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CompaniesRoutingModule } from './companies-routing.module';
import { CompaniesListComponent } from './companies-list/companies-list.component';
import { CompaniesFormsComponent } from './companies-forms/companies-forms.component';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { UiCInputModule } from 'src/app/shared/components/ui-c-input/ui-c-input.module';
import { MaskerModule } from 'src/app/shared/services-ultil/masker-service/masker-module.module';
import { UiCDatePickerModule } from 'src/app/shared/components/date-picker/date-picker.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptService } from 'src/app/core/_base/_auth/intercept/intercept.service';
import { SpeViewNBackofficeConsignedProxy } from 'src/app/core/proxy/view/spe-view-n-backoffice-consigned.proxy';
import { MatSlideToggleModule } from "@angular/material/slide-toggle";

@NgModule({
  declarations: [
    CompaniesListComponent,
    CompaniesFormsComponent
  ],
  imports: [
    CommonModule,
    CompaniesRoutingModule,
    SharedModule,
    MatIconModule,
    MatPaginatorModule,
    MatTableModule,
    MatInputModule,
    UiCInputModule,
    MaskerModule,
    UiCDatePickerModule,
    MatSlideToggleModule

  ],
  providers: [
    InterceptService,
    SpeViewNBackofficeConsignedProxy,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
    UserInfoService
  ]
})
export class CompaniesModule { }
