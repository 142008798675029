import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KitsRoutes } from './kits.routing';
import { KitFormComponent } from './kit-form/kit-form.component';
import { KitListComponent } from './kit-list/kit-list.component';
import { UiCTableModule } from "src/app/shared/components/ui-c-table/ui-c-table.module";
import { UiCInputModule } from "src/app/shared/components/ui-c-input/ui-c-input.module";
import { InlineSVGModule } from "ng-inline-svg-2";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSelectModule } from "@angular/material/select";

@NgModule({
    imports: [
    CommonModule,
    InlineSVGModule,
    KitsRoutes,
    UiCInputModule,
    UiCTableModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule
  ],
  declarations: [KitFormComponent, KitListComponent]
})
export class KitsModule { }
