import { Injectable } from '@angular/core';
import { ApiService } from '../../_base/_auth/api.service';
import { Observable } from 'rxjs';
import { IConfirmFgtsProposalCancelationRequest } from 'src/app/utils/proposal-cancelation-types';


const SPE_N_FGTS = '/spe-n-fgts';

@Injectable({
  providedIn: 'root'
})

export class SpeNFgtsProxy {

  constructor(private api: ApiService) {
  }

  postConfirmCancelationRequest(data:IConfirmFgtsProposalCancelationRequest){
    const uri = `${SPE_N_FGTS}/v1/confirm-cancelation`;
		console.log(uri)
    return this.api.post(uri, data);
	}   
}
