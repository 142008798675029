import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FundingRoutes } from './funding.routing';
import { FundingFormComponent } from './funding-form/funding-form.component';
import { FundingListComponent } from './funding-list/funding-list.component';
import { UiCTableModule } from 'src/app/shared/components/ui-c-table/ui-c-table.module';
import { UiCInputModule } from 'src/app/shared/components/ui-c-input/ui-c-input.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { FundingExtractComponent } from './funding-extract/funding-extract.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';

@NgModule({
  imports: [
    CommonModule,
    FundingRoutes,
    UiCTableModule,
    UiCInputModule,
    FormsModule,
    ReactiveFormsModule,
    InlineSVGModule,
    MatExpansionModule,
    MatTableModule
  ],
  declarations: [FundingFormComponent, FundingListComponent, FundingExtractComponent]
})
export class FundingModule { }
