import { SharedModule } from 'src/app/shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EmployeeDismissalRoutingModule } from './employee-dismissal-routing.module';
import { EmployeeDismissalListComponent } from './employee-dismissal-list/employee-dismissal-list.component';
import { EmployeeDismissalFormsComponent } from './employee-dismissal-forms/employee-dismissal-forms.component';


@NgModule({
  declarations: [
    EmployeeDismissalListComponent,
    EmployeeDismissalFormsComponent
  ],
  imports: [
    CommonModule,
    EmployeeDismissalRoutingModule,
    SharedModule
  ]
})
export class EmployeeDismissalModule { }
