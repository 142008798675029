import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calculation-engine-simulator',
  templateUrl: './calculation-engine-simulator.component.html',
  styleUrls: ['./calculation-engine-simulator.component.scss']
})
export class CalculationEngineSimulatorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
