import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bills-charge-list',
  templateUrl: './bills-charge-list.component.html',
  styleUrls: ['./bills-charge-list.component.scss']
})
export class BillsChargeListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
