import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'bills-charge',
    loadChildren: () =>
      import('./bills-charge/bills-charge.module').then((m) => m.BillsChargeModule),
  },
  {
    path: 'contracts-loans',
    loadChildren: () =>
      import('./contracts-loans/contracts-loans.module').then((m) => m.ContractsLoansModule),
  },
  {
    path: 'employee-dismissal',
    loadChildren: () =>
      import('./employee-dismissal/employee-dismissal.module').then((m) => m.EmployeeDismissalModule),
  },
  {
    path: 'proposals',
    loadChildren: () =>
      import('./proposals/proposals.module').then((m) => m.ProposalsModule),
  },
  {
    path: 'simulations',
    loadChildren: () =>
      import('./simulations/simulations.module').then((m) => m.SimulationsModule),
  },
];

export const OperationsRoutes = RouterModule.forChild(routes);
