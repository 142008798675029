import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ScfiCalculatorResponseType } from 'src/app/core/scfi/services/services-simulation/dto/calculator.dto';
import { AbstractScfi } from 'src/app/pages/scfi/abstract-scfi';

@Component({
    selector: 'simulation-table-finance',
    templateUrl: './simulation-table-finance.component.html',
    styleUrls: ['./simulation-table-finance.component.scss']
})
export class SimulationTableFinanceComponent extends AbstractScfi implements OnInit {
    @Input() sfiCalculatorResponseType: ScfiCalculatorResponseType;
    public simulationFinance: ScfiCalculatorResponseType;

    constructor(
        protected currencyPipe: CurrencyPipe,
        protected datePipe: DatePipe
    ) {
        super(currencyPipe, datePipe)
    }

    ngOnInit() {
        this.simulationFinance = this.sfiCalculatorResponseType;
    }
}
