import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContractListComponent } from './contract-list/contract-list.component';
import { ContractFormComponent } from './contract-form/contract-form.component';
import { ContractsRoutes } from './contracts.routing';
import { FormsModule } from "@angular/forms";
import { UiCInputModule } from "src/app/shared/components/ui-c-input/ui-c-input.module";
import { UiCTableModule } from "src/app/shared/components/ui-c-table/ui-c-table.module";

@NgModule({
    imports: [
        CommonModule,
        ContractsRoutes,
        FormsModule,
        UiCInputModule,
        UiCTableModule
    ],
  declarations: [ContractListComponent, ContractFormComponent]
})
export class ContractsModule { }
