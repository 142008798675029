import { Routes, RouterModule } from '@angular/router';
import { ProposalListComponent } from './proposal-list/proposal-list.component';
import { ProposalFormComponent } from './proposal-form/proposal-form.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: "proposal-list",
        component: ProposalListComponent
      },
      {
        path: "proposal-form/:uuid",
        component: ProposalFormComponent
      },
    ],
  }
  ];

export const ProposalsRoutes = RouterModule.forChild(routes);
