// Angular
import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
// RxJS
import {from, Observable} from 'rxjs';
import {mergeMap, switchMap} from 'rxjs/operators';


import {KeycloakService} from "keycloak-angular";
import {environment} from "src/environments/environment";

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class InterceptService implements HttpInterceptor {

  private clientId = environment.keycloakConfig.clientId;
  private clientVersion = environment.appVersion;
  private realm = environment.keycloakConfig.realm;
  private whiteLabel = environment.wlp;

  constructor(private keycloak: KeycloakService) {
  }

  /**
   * Checks if the url is excluded from having the Bearer Authorization
   * header added.
   *
   * @param req http request from @angular http module.
   * @param excludedUrlRegex contains the url pattern and the http methods,
   * excluded from adding the bearer at the Http Request.
   */
  private isUrlExcluded(
    {method, url}: HttpRequest<any>,
    {urlPattern, httpMethods}: ExcludedUrlRegex,
  ): boolean | undefined{
    let httpTest = httpMethods &&
      httpMethods.length === 0 ||
      ( httpMethods && httpMethods.join().indexOf(method.toUpperCase()) > -1 );

    let urlTest = urlPattern.test(url);

    return httpTest && urlTest;
  }

  /**
   * Intercept implementation that checks if the request url matches the excludedUrls.
   * If not, adds the Authorization header to the request if the user is logged in.
   *
   * @param req
   * @param next
   */

  // intercept request and add token
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    // console.log('Passando no intercptor 1 ');
    const {enableBearerInterceptor, excludedUrls} = this.keycloak;
    // console.log('Passando no intercptor 2', enableBearerInterceptor, excludedUrls);
    if (!enableBearerInterceptor) {
      return next.handle(request);
    }

    // Client-Id,X-Client-Version,X-Request-Id,X-Response-Id,X-Request-UAC,X-Realm
    request = request.clone({ headers: request.headers.set('Client-Id', this.clientId) });
    request = request.clone({ headers: request.headers.set('X-Client-Version', this.clientVersion) });
    request = request.clone({ headers: request.headers.set('X-Realm', this.realm) });
    request = request.clone({ headers: request.headers.set('X-WL-ID', this.whiteLabel) });

    const shallPass: boolean = excludedUrls.findIndex(item => this.isUrlExcluded(request, item)) > -1;
    if (shallPass) {
      return next.handle(request);
    }

    return from(this.keycloak.isLoggedIn()).pipe(
      mergeMap((loggedIn: boolean) => loggedIn
        ? this.handleRequestWithTokenHeader(request, next)
        : next.handle(request)),
    );

  }

  /**
   * Adds the token of the current user to the Authorization header
   *
   * @param req
   * @param next
   */
  private handleRequestWithTokenHeader(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<any> {
    return from(this.keycloak.updateToken(4)).pipe(
      switchMap(() => {
        return this.keycloak.addTokenToHeader(req.headers).pipe(
          mergeMap(headersWithBearer => {
            const kcReq = req.clone({headers: headersWithBearer});
            return next.handle(kcReq);
          }),
        );
      }),
    );
  }

}

/**
 * Similar to ExcludedUrl, contains the HTTP methods and a regex to
 * include the url patterns.
 * This interface is used internally by the KeycloakService.
 */
export interface ExcludedUrlRegex {
  urlPattern: RegExp;
  httpMethods?: HttpMethods[];
}

/**
 * HTTP Methods
 */
export type HttpMethods =
  | 'GET'
  | 'POST'
  | 'PUT'
  | 'DELETE'
  | 'OPTIONS'
  | 'HEAD'
  | 'PATCH';
