import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiService } from 'src/app/core/_base/_auth/api.service';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from "rxjs";
import { SpeViewNScfiProxy } from "../../../proxy/view/spe-view-n-scfi.proxy";
import { CreateTypeDataCustomerDto, DeleteTypeCustomerDataDto, TypeCustomerDataDto } from "./type-customer-data.dto";
import { SpeNScfiProxy } from "../../../proxy/spe/spe-n-scfi.proxy";

@Injectable({
    providedIn: 'root'
})
export class TypeCustomerDataService {
    formData: FormGroup;

    constructor(
        private speViewNScfiProxy: SpeViewNScfiProxy,
        private speNScfi: SpeNScfiProxy,
        protected api: ApiService,
        private formBuilder: FormBuilder,
    ) { }

    public createFormDocument(): FormGroup {
        this.formData = this.formBuilder.group({
            uuid: new FormControl('', Validators.compose([])),
            uuidWhiteLabel: new FormControl('', Validators.compose([])),
            name: new FormControl('', Validators.compose([
                Validators.required
            ])),
            uuidTypeCustomer: new FormControl('', Validators.compose([])),
            hasAttachment: new FormControl(false, Validators.compose([])),
            typeCustomerDataParent: new FormControl(null, Validators.compose([]))
        })
        return this.formData;
    }

    public getErrorMessages() {
        return {
            name: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
            ],
        }
    }

    public findDataByUuid(uuid: string): Observable<TypeCustomerDataDto> {
        return this.speViewNScfiProxy.findDataByUuid(uuid);
    }

    public findDataList(): Observable<TypeCustomerDataDto[]> {
        return this.speViewNScfiProxy.findDataList();
    }

    public deleteData( data: DeleteTypeCustomerDataDto): Observable<TypeCustomerDataDto> {
        return this.speNScfi.deleteData(data);
        }

    public createData(createDataDto: CreateTypeDataCustomerDto): Observable<CreateTypeDataCustomerDto> {
        return this.speNScfi.createData(createDataDto);
    }

}
