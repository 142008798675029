<!-- ps-0 -->
<div class="row header">
  <div class="col-md-10">
    <h1 class="h2 border-left">
      {{pageTitle}}
    </h1>
  </div>
  <div *ngIf="!isBack && !noForm" class="button col-md-2 text-right p-2">
    <a [routerLink]=[buttonLink] class="btn" [ngClass]=[buttonClass]>
      {{buttonText}}
    </a>
  </div>

  <div *ngIf="isBack" class="button col-md-2 text-right">
    <button [routerLink]=[buttonLink] class="btn btn-danger">
      <i _ngcontent-deo-c33="" class="fa fa-chevron-left"></i>
      Voltar
    </button>
  </div>
</div>
