import { Injectable } from '@angular/core';
import { ApiService } from '../../_base/_auth/api.service';
import { Observable, of } from 'rxjs';
import { ConfigurationTaxDto, UpdateTaxDto } from '../../scfi/services/services-tax/tax.dto';
import {
    ConfigurationFeeDto,
    CreateFeeDto,
    UpdateFeeDto
} from '../../scfi/services/services-fees/fees.dto';
import { FundingDto } from '../../scfi/services/services-funding/funding.dto';
import { FUNDING_MOCK } from '../../scfi/services/services-funding/funding.mock';
import {
    ConfigurationKitDto,
    CreateKitDto,
    DeleteKitDto,
    UpdateKitDto
} from "../../scfi/services/services-kits/kits.dto";
import { CreateProductDto, ProductDto, UpdateTypeProductDto } from "../../scfi/services/services-product/products.dto";
import { TypeProductDto, CreateTypeProductDto } from '../../scfi/services/services-product/type-product.dto';
import { SimulationEntryParamsType } from "../../scfi/services/services-simulation/dto/simulation.dto";
import {
    CreateTypeDataCustomerDto,
    DeleteTypeCustomerDataDto,
    TypeCustomerDataDto
} from "../../scfi/services/services-customer-data/type-customer-data.dto";
import { ScfiCalculatorResponseType, ScfiPickSimulationResponseType } from '../../scfi/services/services-simulation/dto/calculator.dto';
import { PickSimulationEntryParamsType } from '../../scfi/services/services-simulation/dto/pick-simulation.dto';
import { CreateCustomerAddressDto, CustomerAddressDto, CustomerDto } from '../../scfi/services/services-customer/customer.dto';
import { AccountProposalModel, GenerateAccountTokenValidationRequestBodyDtoType, ValidateAccountCodeDtoRequestType, ValidateAccountCodeResponseWithData } from '../../scfi/services/services-accept/accept-proposal.dto';

const SPE_N_SCFI = '/spe-n-scfi';

@Injectable(
    { providedIn: 'root' }
)
export class SpeNScfiProxy {

    constructor(
        private api: ApiService
    ) { }

    updateTax(tax: UpdateTaxDto): Observable<ConfigurationTaxDto> {
        const uri = `${SPE_N_SCFI}/v1/tax`;
        return this.api.put(uri, tax);
    }

    createFee(fee: CreateFeeDto): Observable<ConfigurationFeeDto> {
        const uri = `${SPE_N_SCFI}/v1/fee`;
        return this.api.post(uri, fee);
    }

    updateFee(fee: UpdateFeeDto): Observable<ConfigurationFeeDto> {
        const uri = `${SPE_N_SCFI}/v1/fee`;
        return this.api.put(uri, fee);
    }

    deleteFee(uuid: string): Observable<ConfigurationFeeDto[]> {
        return of();
    }

    createFunding(createFundingDto: FundingDto): Observable<FundingDto> {
        return of(FUNDING_MOCK);
    }

    updateFunding(updateFundingDto: FundingDto): Observable<FundingDto> {
        return of(FUNDING_MOCK);
    }

    createKit(kit: CreateKitDto): Observable<CreateKitDto> {
        const uri = `${SPE_N_SCFI}/v1/kit/kit-document`;
        return this.api.post(uri, kit);
    }

    updateKit(updateKitDto: UpdateKitDto): Observable<ConfigurationKitDto> {
        const uri = `${SPE_N_SCFI}/v1/kit/update-kit-document`;
        return this.api.put(uri, updateKitDto);
    }

    deleteKit(deleteKitDto: DeleteKitDto): Observable<ConfigurationKitDto> {
        const uri = `${SPE_N_SCFI}/v1/kit`;
        return this.api.delete(uri, deleteKitDto);
    }

    createTypeProduct(typeProduct: CreateTypeProductDto): Observable<TypeProductDto> {
        const uri = `${SPE_N_SCFI}/v1/type-product`;
        return this.api.post(uri, typeProduct);
    }

    updateProductType(updateTypeProductDto: UpdateTypeProductDto): Observable<TypeProductDto> {
        const uri = `${SPE_N_SCFI}/v1/type-product`;
        return this.api.put(uri, updateTypeProductDto);
    }

    deleteProductType(uuid: string): Observable<TypeProductDto[]> {
        return of();
    }

    createProduct(product: CreateProductDto): Observable<ProductDto> {
        const uri = `${SPE_N_SCFI}/v1/product`;
        return this.api.post(uri, product);
    }

    updateProduct(updateProductDto: CreateProductDto): Observable<ProductDto> {
        return of();
    }

    deleteProduct(uuid: string): Observable<ProductDto[]> {
        return of();
    }

    deleteData(data: DeleteTypeCustomerDataDto): Observable<TypeCustomerDataDto>{
        const uri = `${SPE_N_SCFI}/v1/type-customer-data/white-label/${data.uuidWhiteLabel}/uuid/${data.uuid}/`
        return this.api.delete(uri);
    }

    createData(createDataDto: CreateTypeDataCustomerDto): Observable<CreateTypeDataCustomerDto> {
        const uri = `${SPE_N_SCFI}/v1/type-customer-data`;
        return this.api.post(uri, createDataDto);
    }

    simulate(simulationEntryParams: SimulationEntryParamsType): Observable<ScfiCalculatorResponseType> {
        const uri = `${SPE_N_SCFI}/v1/proposal/simulate`;
        return this.api.post(uri, simulationEntryParams);
    }

    pickSimulation(pickSimulationEntryParams: PickSimulationEntryParamsType): Observable<ScfiPickSimulationResponseType> {
        const uri = `${SPE_N_SCFI}/v1/proposal/pick-simulation`;
        return this.api.post(uri, pickSimulationEntryParams);
    }

    createCustomer(customer: CustomerDto): Observable<CustomerDto> {
        return of();
    }

    updateCustomer(customer: CustomerDto): Observable<CustomerDto> {
        return of();
    }

    generateAuthToken(data: GenerateAccountTokenValidationRequestBodyDtoType): Observable<void> {
        const uri = `${SPE_N_SCFI}/v1/sign/validation`;
        return this.api.post(uri, data);
    }

    validateAuthToken(data: ValidateAccountCodeDtoRequestType): Observable<ValidateAccountCodeResponseWithData<AccountProposalModel>> {
        const uri = `${SPE_N_SCFI}/v1/sign/confirmation`;
        return this.api.post(uri, data);
    }

    createOrUpdateCustomerAddress(customerAddres: CreateCustomerAddressDto): Observable<CustomerAddressDto> {
        const uri = `${SPE_N_SCFI}/v1/customer/address`;
        return this.api.post(uri, customerAddres);
    }
}
