import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiCInputComponent } from './ui-c-input.component';
import { BrMaskerModule } from "br-mask";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CurrencyMaskModule } from 'ng2-currency-mask';

@NgModule({
  imports: [
    CommonModule,
    BrMaskerModule,
    FormsModule,
    ReactiveFormsModule,
    CurrencyMaskModule
  ],
  declarations: [UiCInputComponent],
  exports: [UiCInputComponent],
})
export class UiCInputModule { }
