import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'table-finance-contract-detail',
  templateUrl: './table-finance-contract.component.html',
  styleUrls: ['./table-finance-contract.component.scss']
})
export class TableFinanceContractDetailComponent implements OnInit {

  @Input() proposal?: any;

  constructor() { }

  ngOnInit(): void {
  }

  converterNumber(value: string) {
    return Number(value).toFixed(2)
  }

}
