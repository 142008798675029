import { Component } from '@angular/core';
import { OnInit, Output, EventEmitter } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one && two && two.year === one.year && two.month === one.month && two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two
    ? false
    : one.year === two.year
      ? one.month === two.month
        ? one.day === two.day
          ? false
          : one.day < two.day
        : one.month < two.month
      : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two
    ? false
    : one.year === two.year
      ? one.month === two.month
        ? one.day === two.day
          ? false
          : one.day > two.day
        : one.month > two.month
      : one.year > two.year;
@Component({
  template: ''
})
export abstract class SearchBoxUtils implements OnInit {
  @Output() searchUsers: EventEmitter<any> = new EventEmitter();

  public searchFor = '';
  public typeSearch = '';
  public fieldSearch = '';
  public advancedFilter = false;
  @Output() public advancedFilterEvt: EventEmitter<boolean> = new EventEmitter();
  public riskType = '';
  public accountList: any;
  public datePicker: boolean;

  public fromDate: any;
  public toDate: any;
  public hoveredDate: any;

  public selectedDateOne: boolean;
  public selectedDateTwo: boolean;

  constructor() { }

  public ngOnInit() {
    this.datePicker = false;
    this.selectedDateOne = false;
    this.selectedDateTwo = false;
  }

  public chooseSearch(event: Event, type: string, option: string) {
    event.preventDefault();
    this.typeSearch = type;
    this.searchFor = option;
  }

  public moreFilters(event: Event) {
    // event.preventDefault();
    this.advancedFilter ? (this.advancedFilter = false) : (this.advancedFilter = true);
    this.advancedFilterEvt.emit();
  }

  public search(event: any) {
    const objSearch = {
      fromDate: event.value.startDate,
      toDate: event.value.endDate,
    };
    this.searchUsers.emit(objSearch);
  }

  public isEnable(event: Event, picker: number) {
    if (event.target === event.currentTarget) {
      if (picker == 1) {
        this.selectedDateOne = this.selectedDateOne ? false : true;
        this.selectedDateTwo = false;
      }
      if (picker == 2) {
        this.selectedDateTwo = this.selectedDateTwo ? false : true;
        this.selectedDateOne = false;
      }

      if (this.selectedDateOne) {
        this.fromDate = null;
      }
      if (this.selectedDateTwo) {
        this.toDate = null;
      }
    }
  }

  public onDateChange(date: any, picker: number) {
    if (picker == 1) {
      if (this.fromDate !== null) {
        this.fromDate = null;
      }
      if (!this.fromDate) {
        this.fromDate = date;
        this.isHovered(date);
        this.selectedDateOne = false;
      }
    }
    if (picker == 2) {
      if (this.toDate !== null) {
        this.toDate = null;
      }
      if (!this.toDate) {
        this.toDate = date;
        this.isHovered(date);
        this.selectedDateTwo = false;
      }
    }
  }

  isHovered = (date: any) =>
    this.fromDate && !this.toDate && this.hoveredDate && after(date, this.fromDate) && before(date, this.hoveredDate);
  isInside = (date: any) => after(date, this.fromDate) && before(date, this.toDate);
  isFrom = (date: any) => equals(date, this.fromDate);
  isTo = (date: any) => equals(date, this.toDate);
}
