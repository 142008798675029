import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FeesService } from 'src/app/core/scfi/services/services-fees/fees.service';
import { TableData } from 'src/app/core/scfi/services/services-table/table.dto';
import { AbstractScfi } from '../../abstract-scfi';
import { ConfigurationFeeDto } from 'src/app/core/scfi/services/services-fees/fees.dto';
import { Router } from '@angular/router';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { TypeTacEnum } from 'src/app/core/scfi/services/services-fees/type-tac.enum';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-fee-list',
  templateUrl: './fee-list.component.html',
  styleUrls: ['./fee-list.component.scss']
})
export class FeeListComponent extends AbstractScfi implements OnInit {
  public tableData: TableData;
  public feeList: ConfigurationFeeDto[];

  constructor(
    private feesService: FeesService,
    private router: Router,
    protected currencyPipe: CurrencyPipe,
    protected datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    private cdr: ChangeDetectorRef,
  ) {
    super(currencyPipe, datePipe);
  }

  ngOnInit() {
    this.findFeeList();
  }

  public findFeeList() {
    this.spinner.show();
    this.feesService.findFeeList().subscribe({
      next: (feeList: ConfigurationFeeDto[]) => {
        this.feeList = feeList;
        this.tableData = this.generateTableData();
        this.spinner.hide();
        this.cdr.detectChanges();
      },
      error: (error) => {
        this.showErrorModal('Erro ao buscar tarifas!');
        this.spinner.hide();
      }
    });
  }

  public generateTableData(): TableData {
    return {
      header: ['Tarifa', 'Taxa de Juros Padrão', 'TAC Padrão', 'Ações'],
      rows: this.feeList.map(row => {
        const formattedFeesDefault = this.formatValueToPercent(row.interestRate);
        const formattedTacValue = this.formatTacValue(row.valueTac, row.typeTac);

        return {
          fields: [
            row.name,
            formattedFeesDefault,
            formattedTacValue
          ],
          actions: [
            { icon: './assets/media/icons/duotune/general/gen055.svg', onClick: () => this.goToCreateOrEditFee(row.uuid) },
            { icon: './assets/media/icons/duotune/general/gen027.svg', onClick: () => this.deleteFee(row.uuid) }
          ]
        }
      }),
    }
  }

  public formatTacValue(value: number, typeTac: TypeTacEnum): string {
    switch (typeTac) {
      case TypeTacEnum.PERCENT:
        return this.formatValueToPercent(value);
      case TypeTacEnum.FIXED:
        return this.formatValueToMoney(value);
      default:
        return value.toString();
    }
  }

  public async deleteFee(uuid: string) {
    const confirmed = await this.showConfirmationModal('Tem certeza que deseja excluir esta tarifa?')

    if (confirmed) {
      this.feesService.deleteFee(uuid).subscribe({
        next: () => {
          this.findFeeList();
          this.showSuccessModal('Tarifa excluída com sucesso!');
        },
        error: (error) => {
          this.showErrorModal('Erro ao excluir tarifa!');
        }
      });
    }
  }

  public goToCreateOrEditFee(uuid?: string) {
    if (uuid) {
      this.router.navigate(['scfi/fees/edit', uuid]);
    } else {
      this.router.navigate(['scfi/fees/create']);
    }
  }
}
