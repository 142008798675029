<div id="product-form-component">
    <h1 class="page-title mb-4">
        {{ isEditMode ? nameType + ' / ' + nameProduct : 'Cadastro de Produto'}}
    </h1>

    <div class="d-flex justify-content-end mb-4">
        <button type="button" class="btn btn-warning" (click)="backToLastPage()">
            <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr074.svg'"
                class="svg-icon svg-icon-1 me-4"></span>
            <span class="pt-2">Voltar</span>
        </button>
    </div>

    <form [formGroup]="formProduct">
        <section class="card mb-4">
            <div class="card-header border-0 d-flex align-items-center justify-content-start">
                <span [inlineSVG]="'./assets/media/icons/duotune/general/gen045.svg'"
                    class="svg-icon svg-icon-2tx svg-icon-gray-800 me-4"></span>
                <h3 class="fw-bolder card-title mt-3"> Informações Gerais </h3>
            </div>
            <div class="card-body border-top p-9">
                <div class="d-flex">
                    <div class="col-3 px-10">
                        <app-ui-c-input [form]="formProduct" [errorMessages]="errorMessagesProduct" [formName]="'name'"
                            [name]="'Nome do Produto:'" [type]="'text'"
                            [placeholder]="'Digite o nome do produto'"></app-ui-c-input>
                    </div>

                    <div class="col-3 px-10 d-flex flex-column">
                        <label class="form-label fs-6 fw-bolder text-dark mt-3">Tipo de Cliente:</label>
                        <div>
                            <label class="form-check form-check-inline form-check-solid mt-4">
                                <input class="form-check-input" name="isPF" type="checkbox" formControlName="isPF" />
                                <span class="form-label fs-6 fw-bolder text-dark">PF</span>
                            </label>

                            <label class="form-check form-check-inline form-check-solid mt-4">
                                <input class="form-check-input" name="isPJ" type="checkbox" formControlName="isPJ" />
                                <span class="form-label fs-6 fw-bolder text-dark">PJ</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div *ngIf="formProduct.get('isPF')?.value">
            <mat-accordion>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title class="fw-bolder card-title">
                            PF
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <section class="card mb-4">
                        <div class="card-header border-0 d-flex align-items-center justify-content-start">
                            <span [inlineSVG]="'./assets/media/icons/duotune/files/fil003.svg'"
                                  class="svg-icon svg-icon-2tx svg-icon-gray-800 me-4"></span>
                            <h3 class="fw-bolder card-title mt-3">Kits</h3>
                        </div>
                        <div class="pb-9">
                            <div class="d-flex">
                                <div class="col-3 px-10">
                                    <label class="form-label fs-6 fw-bolder text-dark mt-3">Kit:</label>
                                    <select class="form-select form-select-solid form-select-lg text-dark"
                                            formControlName="kitNamePF" (ngModelChange)="onKitChangePF($event)" [ngClass]="{
                                            'is-invalid': (formProduct?.get('kitNamePF')?.invalid && formProduct?.get('kitNamePF')?.dirty),
                                            'is-valid': (formProduct?.get('kitNamePF')?.valid && formProduct?.get('kitNamePF')?.dirty)
                                          }">
                                        <option *ngFor="let kit of kitList" [value]="kit.uuid">
                                            {{ kit.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section class="card mb-4">
                        <div class="card-header border-0 d-flex align-items-center justify-content-start">
                            <span [inlineSVG]="'./assets/media/icons/duotune/abstract/abs012.svg'"
                                class="svg-icon svg-icon-2tx svg-icon-gray-800 me-4"></span>
                            <h3 class="fw-bolder card-title mt-3">Parcelas</h3>
                        </div>
                        <div class="border-bottom pb-9">
                            <div class="d-flex">
                                <div class="col-3 px-10">
                                    <app-ui-c-input [form]="formProduct" [errorMessages]="errorMessagesProduct"
                                        [formName]="'minInstallmentPF'" [name]="'Número Mínimo de Parcelas:'"
                                        [placeholder]="'Digite o nº mínimo de parcelas'"></app-ui-c-input>
                                </div>
                                <div class="col-3 px-10">
                                    <app-ui-c-input [form]="formProduct" [errorMessages]="errorMessagesProduct"
                                        [formName]="'maxInstallmentPF'" [name]="'Número Máximo de Parcelas:'"
                                        [placeholder]="'Digite o nº máximo de parcelas'"></app-ui-c-input>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section class="card mb-4">
                        <div class="card-header border-0 d-flex align-items-center justify-content-start">
                            <span [inlineSVG]="'./assets/media/icons/duotune/finance/fin010.svg'"
                                class="svg-icon svg-icon-2tx svg-icon-gray-800 me-4"></span>
                            <h3 class="fw-bolder card-title mt-3">Valor</h3>
                        </div>
                        <div class="border-bottom pb-9">
                            <div class="d-flex">
                                <div class="col-3 px-10">
                                    <app-ui-c-input [form]="formProduct" [errorMessages]="errorMessagesProduct"
                                        [formName]="'minValuePF'" [name]="'Valor Mínimo:'" [isMoney]="true"
                                        [placeholder]="'Digite o valor mínimo'"></app-ui-c-input>
                                </div>
                                <div class="col-3 px-10">
                                    <app-ui-c-input [form]="formProduct" [errorMessages]="errorMessagesProduct"
                                        [formName]="'maxValuePF'" [name]="'Valor Máximo:'" [isMoney]="true"
                                        [placeholder]="'Digite o valor máximo'"></app-ui-c-input>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section class="card mb-4">
                        <div class="card-header border-0 d-flex align-items-center justify-content-start">
                            <span [inlineSVG]="'./assets/media/icons/duotune/ecommerce/ecm003.svg'"
                                class="svg-icon svg-icon-2tx svg-icon-gray-800 me-4"></span>
                            <h3 class="fw-bolder card-title mt-3">Tarifas</h3>
                        </div>
                        <div class="pb-9">
                            <div class="d-flex">
                                <div class="col-3 px-10">
                                    <label class="form-label fs-6 fw-bolder text-dark mt-3">Tarifa:</label>
                                    <select class="form-select form-select-solid form-select-lg text-dark"
                                        formControlName="feeNamePF" (ngModelChange)="onFeeChangePF($event)" [ngClass]="{
                                            'is-invalid': (formProduct?.get('feeNamePF')?.invalid && formProduct?.get('feeNamePF')?.dirty),
                                            'is-valid': (formProduct?.get('feeNamePF')?.valid && formProduct?.get('feeNamePF')?.dirty)
                                          }">
                                        <option *ngFor="let fee of feeList" [value]="fee.uuid">
                                            {{ fee.name }}
                                        </option>
                                    </select>
                                </div>

                                <div class="col-3 px-10">
                                    <app-ui-c-input [form]="formProduct"
                                                    [formName]="'typeTacPF'"
                                                    [name]="'Tipo da TAC:'"
                                                    [type]="'text'"
                                                    [block]="true">
                                    </app-ui-c-input>
                                </div>

                                <ng-container *ngIf="formProduct?.value.typeTacPJ === 'Percentual'">
                                    <div class="col-3 px-10">
                                        <app-ui-c-input [form]="formProduct"
                                            [formName]="'minValueTacPF'"
                                            [name]="'Valor Mínimo da TAC:'"
                                            [isPercent]="true"
                                            [block]="true"></app-ui-c-input>
                                    </div>

                                    <div class="col-3 px-10">
                                        <app-ui-c-input [form]="formProduct" [errorMessages]="errorMessagesProduct"
                                            [formName]="'valueTacPF'"
                                            [name]="'Valor Padrão da TAC:'"
                                            [isPercent]="true"
                                            [block]="true"></app-ui-c-input>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="formProduct?.value.typeTacPF === 'Valor Fixo'">
                                    <div class="col-3 px-10">
                                        <app-ui-c-input [form]="formProduct" [errorMessages]="errorMessagesProduct"
                                            [formName]="'minValueTacPF'"
                                            [name]="'Valor Mínimo da TAC:'"
                                            [isMoney]="true"
                                            [block]="true"></app-ui-c-input>
                                    </div>

                                    <div class="col-3 px-10">
                                        <app-ui-c-input [form]="formProduct" [errorMessages]="errorMessagesProduct"
                                            [formName]="'valueTacPF'"
                                            [name]="'Valor Padrão da TAC:'"
                                            [isMoney]="true"
                                            [block]="true"></app-ui-c-input>
                                    </div>
                                </ng-container>
                            </div>

                            <div class="d-flex mt-10">
                                <div class="col-3 px-10">
                                    <app-ui-c-input [form]="formProduct" [errorMessages]="errorMessagesProduct"
                                        [formName]="'minInterestRatePF'" [name]="'Taxa de Juros Mínima:'"
                                        [isPercent]="true"
                                        [placeholder]="'Digite a taxa de juros mínima'"
                                        [block]="true"></app-ui-c-input>
                                </div>

                                <div class="col-3 px-10">
                                    <app-ui-c-input [form]="formProduct" [errorMessages]="errorMessagesProduct"
                                        [formName]="'interestRatePF'" [name]="'Taxa de Juros Padrão:'"
                                        [isPercent]="true"
                                        [placeholder]="'Digite a taxa de juros padrão'"
                                        [block]="true"></app-ui-c-input>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section class="card mb-4">
                        <div class="card-header border-0 d-flex align-items-center justify-content-start">
                            <span [inlineSVG]="'./assets/media/icons/duotune/ecommerce/ecm003.svg'"
                                  class="svg-icon svg-icon-2tx svg-icon-gray-800 me-4"></span>
                            <h3 class="fw-bolder card-title mt-3">Imposto</h3>
                        </div>
                        <div class="pb-9">
                            <div class="d-flex">
                                <div class="col-3 px-10">
                                    <app-ui-c-input [form]="formProduct" [errorMessages]="errorMessagesProduct"
                                                    [formName]="'pfIofIndex'" [name]="'IOF Adicional PF:'"
                                                    [isPercent]="true"
                                                    [block]="true"></app-ui-c-input>
                                </div>
                                <div class="col-3 px-10">
                                    <app-ui-c-input [form]="formProduct" [errorMessages]="errorMessagesProduct"
                                                    [formName]="'pfIofDailyIndex'" [name]="'IOF Diário PF:'"
                                                    [isPercent]="true"
                                                    [block]="true"></app-ui-c-input>
                                </div>
                                <div class="col-3 px-10">
                                    <app-ui-c-input [form]="formProduct" [errorMessages]="errorMessagesProduct"
                                                    [formName]="'pfIofMaxDays'" [name]="'Máximo de Dias PF:'"
                                                    [block]="true"></app-ui-c-input>
                                </div>
                            </div>
                        </div>
                    </section>

                </mat-expansion-panel>
            </mat-accordion>
        </div>

        <div *ngIf="formProduct.get('isPJ')?.value" class="mt-4">
            <mat-accordion>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title class="fw-bolder card-title">
                            PJ
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <section class="card mb-4">
                        <div class="card-header border-0 d-flex align-items-center justify-content-start">
                            <span [inlineSVG]="'./assets/media/icons/duotune/files/fil003.svg'"
                                  class="svg-icon svg-icon-2tx svg-icon-gray-800 me-4"></span>
                            <h3 class="fw-bolder card-title mt-3">Kits</h3>
                        </div>
                        <div class="pb-9">
                            <div class="d-flex">
                                <div class="col-3 px-10">
                                    <label class="form-label fs-6 fw-bolder text-dark mt-3">Kit:</label>
                                    <select class="form-select form-select-solid form-select-lg text-dark"
                                            formControlName="kitNamePJ" (ngModelChange)="onKitChangePJ($event)" [ngClass]="{
                                            'is-invalid': (formProduct?.get('kitNamePJ')?.invalid && formProduct?.get('kitNamePJ')?.dirty),
                                            'is-valid': (formProduct?.get('kitNamePJ')?.valid && formProduct?.get('kitNamePJ')?.dirty)
                                          }">
                                        <option *ngFor="let kit of kitList" [value]="kit.uuid">
                                            {{ kit.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section class="card mb-4">
                        <div class="card-header border-0 d-flex align-items-center justify-content-start">
                            <span [inlineSVG]="'./assets/media/icons/duotune/abstract/abs012.svg'"
                                class="svg-icon svg-icon-2tx svg-icon-gray-800 me-4"></span>
                            <h3 class="fw-bolder card-title mt-3">Parcelas</h3>
                        </div>
                        <div class="border-bottom pb-9">
                            <div class="d-flex">
                                <div class="col-3 px-10">
                                    <app-ui-c-input [form]="formProduct" [errorMessages]="errorMessagesProduct"
                                        [formName]="'minInstallmentPJ'" [name]="'Número Mínimo de Parcelas:'"
                                        [placeholder]="'Digite o nº mínimo de parcelas'"></app-ui-c-input>
                                </div>
                                <div class="col-3 px-10">
                                    <app-ui-c-input [form]="formProduct" [errorMessages]="errorMessagesProduct"
                                        [formName]="'maxInstallmentPJ'" [name]="'Número Máximo de Parcelas:'"
                                        [placeholder]="'Digite o nº máximo de parcelas'"></app-ui-c-input>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section class="card mb-4">
                        <div class="card-header border-0 d-flex align-items-center justify-content-start">
                            <span [inlineSVG]="'./assets/media/icons/duotune/finance/fin010.svg'"
                                class="svg-icon svg-icon-2tx svg-icon-gray-800 me-4"></span>
                            <h3 class="fw-bolder card-title mt-3">Valor</h3>
                        </div>
                        <div class="border-bottom pb-9">
                            <div class="d-flex">
                                <div class="col-3 px-10">
                                    <app-ui-c-input [form]="formProduct" [errorMessages]="errorMessagesProduct"
                                        [formName]="'minValuePJ'" [name]="'Valor Mínimo:'" [isMoney]="true"
                                        [placeholder]="'Digite o valor mínimo'"></app-ui-c-input>
                                </div>
                                <div class="col-3 px-10">
                                    <app-ui-c-input [form]="formProduct" [errorMessages]="errorMessagesProduct"
                                        [formName]="'maxValuePJ'" [name]="'Valor Máximo:'" [isMoney]="true"
                                        [placeholder]="'Digite o valor máximo'"></app-ui-c-input>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section class="card mb-4">
                        <div class="card-header border-0 d-flex align-items-center justify-content-start">
                            <span [inlineSVG]="'./assets/media/icons/duotune/ecommerce/ecm003.svg'"
                                  class="svg-icon svg-icon-2tx svg-icon-gray-800 me-4"></span>
                            <h3 class="fw-bolder card-title mt-3">Tarifas</h3>
                        </div>
                        <div class="pb-9">
                            <div class="d-flex">
                                <div class="col-3 px-10">
                                    <label class="form-label fs-6 fw-bolder text-dark mt-3">Tarifa:</label>
                                    <select class="form-select form-select-solid form-select-lg text-dark"
                                            formControlName="feeNamePJ" (ngModelChange)="onFeeChangePJ($event)" [ngClass]="{
                                            'is-invalid': (formProduct?.get('feeNamePJ')?.invalid && formProduct?.get('feeNamePJ')?.dirty),
                                            'is-valid': (formProduct?.get('feeNamePJ')?.valid && formProduct?.get('feeNamePJ')?.dirty)
                                          }">
                                        <option *ngFor="let fee of feeList" [value]="fee.uuid">
                                            {{ fee.name }}
                                        </option>
                                    </select>
                                </div>

                                <div class="col-3 px-10">
                                    <app-ui-c-input [form]="formProduct" [errorMessages]="errorMessagesProduct"
                                                    [formName]="'typeTacPJ'"
                                                    [name]="'Tipo da TAC:'"
                                                    [type]="'text'"
                                                    [block]="true">
                                    </app-ui-c-input>
                                </div>

                                <ng-container *ngIf="formProduct?.value.typeTacPJ === 'Percentual'">
                                    <div class="col-3 px-10">
                                        <app-ui-c-input [form]="formProduct" [errorMessages]="errorMessagesProduct"
                                                        [formName]="'minInterestRatePJ'"
                                                        [name]="'Taxa de Juros Mínima:'"
                                                        [isPercent]="true"
                                                        [block]="true"></app-ui-c-input>
                                    </div>

                                    <div class="col-3 px-10">
                                        <app-ui-c-input [form]="formProduct" [errorMessages]="errorMessagesProduct"
                                                        [formName]="'interestRatePJ'"
                                                        [name]="'Taxa de Juros Padrão:'"
                                                        [isPercent]="true"
                                                        [block]="true"></app-ui-c-input>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="formProduct?.value.typeTacPJ === 'Valor Fixo'">
                                    <div class="col-3 px-10">
                                        <app-ui-c-input [form]="formProduct" [errorMessages]="errorMessagesProduct"
                                            [formName]="'minValueTacPJ'"
                                            [name]="'Valor Mínimo da TAC:'"
                                            [isMoney]="true"
                                            [block]="true"></app-ui-c-input>
                                    </div>

                                    <div class="col-3 px-10">
                                        <app-ui-c-input [form]="formProduct" [errorMessages]="errorMessagesProduct"
                                            [formName]="'valueTacPJ'"
                                            [name]="'Valor Padrão da TAC:'"
                                            [isMoney]="true"
                                            [block]="true"></app-ui-c-input>
                                    </div>
                                </ng-container>

                            </div>

                            <div class="d-flex mt-10">
                                <div class="col-3 px-10">
                                    <app-ui-c-input [form]="formProduct" [errorMessages]="errorMessagesProduct"
                                                    [formName]="'minInterestRatePJ'" [name]="'Taxa de Juros Mínima:'"
                                                    [isPercent]="true"
                                                    [placeholder]="'Digite a taxa de juros mínima'"
                                                    [block]="true"></app-ui-c-input>
                                </div>

                                <div class="col-3 px-10">
                                    <app-ui-c-input [form]="formProduct" [errorMessages]="errorMessagesProduct"
                                                    [formName]="'interestRatePJ'" [name]="'Taxa de Juros Padrão:'"
                                                    [isPercent]="true"
                                                    [placeholder]="'Digite a taxa de juros padrão'"
                                                    [block]="true"></app-ui-c-input>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section class="card mb-4">
                        <div class="card-header border-0 d-flex align-items-center justify-content-start">
                            <span [inlineSVG]="'./assets/media/icons/duotune/ecommerce/ecm003.svg'"
                                  class="svg-icon svg-icon-2tx svg-icon-gray-800 me-4"></span>
                            <h3 class="fw-bolder card-title mt-3">Imposto</h3>
                        </div>
                        <div class="pb-9">
                            <div class="d-flex">
                                <div class="col-3 px-10">
                                    <app-ui-c-input [form]="formProduct" [errorMessages]="errorMessagesProduct"
                                                    [formName]="'pjIofIndex'" [name]="'IOF Adicional PJ:'"
                                                    [isPercent]="true"
                                                    [block]="true"></app-ui-c-input>
                                </div>
                                <div class="col-3 px-10">
                                    <app-ui-c-input [form]="formProduct" [errorMessages]="errorMessagesProduct"
                                                    [formName]="'pjIofDailyIndex'" [name]="'IOF Diário PJ:'"
                                                    [isPercent]="true"
                                                    [block]="true"></app-ui-c-input>
                                </div>
                                <div class="col-3 px-10">
                                    <app-ui-c-input [form]="formProduct" [errorMessages]="errorMessagesProduct"
                                                    [formName]="'pjIofMaxDays'" [name]="'Máximo de Dias PJ:'"
                                                    [block]="true"></app-ui-c-input>
                                </div>
                            </div>
                        </div>
                    </section>

                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </form>

    <div class="d-flex justify-content-end my-8">
        <button type="button" class="btn btn-primary btn-default" (click)="saveProduct()">
            Salvar
        </button>
    </div>

</div>

