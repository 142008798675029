import { take } from 'rxjs';
import { ContractsService } from './../services-apis/contracts-service';

import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MaskService } from 'src/app/shared/services-ultil/masker-service/masker.service';
// import { Proposal } from "../../proposals/services-apis/poposals-modal";
import * as moment from 'moment';
// import { NgxSpinnerService } from 'ngx-spinner';
import { Contract } from '../services-apis/contracts-model';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTableDataSource } from '@angular/material/table';
import { UserInfoService } from 'src/app/core/_base/_auth/user-info.service';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';


import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { SelectionModel } from '@angular/cdk/collections';
import Swal from 'sweetalert2';
import { CurrencyPipe } from '@angular/common';
// import { StorageService } from 'src/app/shared/services-ultil/storage/storage.service';
import { Router } from '@angular/router';
import { TruncateService } from 'src/app/shared/services-ultil/truncate-service/truncate.service';

@Component({
  selector: 'app-contracts-loans-list',
  templateUrl: './contracts-loans-list.component.html',
  styleUrls: ['./contracts-loans-list.component.scss']
})
export class ContractsLoansListComponent implements OnInit {
  displayedColumns = ['uuidContract', 'numberCCB', 'fullName',
    'identityGov', 'netCapitalAmountLoan', 'installmentQuantityLoan', 'installmentValue',
    'fvTotalAmountLoanValue', 'dtAssignatureCCB', 'iProposalInterestMonth', 'statusProposal', 'action'];
  dataSource: any;
  selection = new SelectionModel<any>(true, []);
  matSort: any;
  startDate: string;
  endDate: string;

  email: any = "";
  defaultDate = 90;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  constructor(
    private contractService: ContractsService,
    public maskerService: MaskService,
    private spinner: NgxSpinnerService,
    private cdr: ChangeDetectorRef,
    private userInfoService: UserInfoService,
    private currenty: CurrencyPipe,
    protected truncate: TruncateService,
    private router: Router
    // private storageSerice: StorageService,
  ) {
  }

  async ngOnInit(): Promise<void> {

    if (this.isliquidation()) {
      this.displayedColumns.unshift('select');
      this.cdr.detectChanges();
    }

    this.spinner.show()
    const currencydate = new Date();

    // let filterLS = this.storageSerice.getData(this.router.url) ? this.storageSerice.getData(this.router.url) : '' ;

    // if(filterLS){
    //   let aux = JSON.parse(filterLS);
    //   this.startDate = aux.startDate;
    //   this.endDate = aux.endDate;
    // }else{
    this.startDate = moment(new Date().setFullYear(currencydate.getFullYear() - 1)).format('YYYY-MM-DD');
    this.endDate = moment(currencydate).format('YYYY-MM-DD');
    // }

    await this.userInfoService.getInfoProfile().then((res) => {
      if (res) {
        this.email = res.email;
      } else {
        this.email = "";
      }
    })

    let role = this.userInfoService.verifyRole() || '';

    if (this.userInfoService.isAttendant()) {
      role = 'liquidation'
    }
    this.contractService.getContractsList(this.startDate, this.endDate, role, this.email).subscribe(
      res => {
        this.spinner.hide();
        this.dataSource = new MatTableDataSource(res);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.matSort;
        this.cdr.detectChanges();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  isliquidation() {
    return this.userInfoService.isLiquidation();
  }

  filterData($event: any) {
    this.dataSource.filter = $event.target.value;
  }

  emitDate(event: any) {

    let role = this.userInfoService.verifyRole() || '';

    if (this.userInfoService.isAttendant()) {
      role = 'liquidation'
    }

    this.contractService.getContractsList(event.startDate, event.endDate, role, this.email).subscribe(
      res => {
        this.spinner.hide();
        this.dataSource = new MatTableDataSource(res);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.matSort;
        this.cdr.detectChanges();
      },
      (err) => {
        this.spinner.hide()
      }

    )
  }

  checkStatus(isLate: boolean, status: string) {
    if (isLate) return "text-bg-danger text_color_white";
    if (status == 'Quitado') return "text-bg-success";
    if (status == null) return "text-bg-success text_color_white";
    return "text-bg-warning";
  }

  getContractTermsCCB(uuidContract: any) {
    this.spinner.show();
    this.contractService.getContractUiid(uuidContract)
      .subscribe(res => {        
        this.contractService.getContractTermsCCB(res.proposalPartner.uuidProposalPartner)
          .pipe(
            take(1)
          )
          .subscribe((result: any) => {
            this.spinner.hide();
            let element = document.createElement('a');
            element.setAttribute('href', result.base64);
            element.setAttribute('download', `${uuidContract}.pdf`);

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);

          })
      }, err => {
        this.spinner.hide();
      })
  }

  exportAsPDF(contract: any, uuidContract: string) {
    let doc = new jsPDF();
    doc.html(contract, {
      callback: function (doc) {
        doc.save(`${uuidContract}.pdf`);
      },
      x: 15,
      y: 15,
      width: 170,
      windowWidth: 650
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    let qtDisabled: number = this.dataSource.data.filter((el: any) => {
      return el.statusProposal == "PAID"
    }).length;

    const numRows = this.dataSource.data.length;
    return numSelected + qtDisabled === numRows;
    return 1 == 1;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach((row: any) => {
        if (row.statusProposal != "PAID") {
          this.selection.select(row)
        }
      });
  }

  logSelection() {
    this.selection.selected.forEach(s => console.log(s.name));
  }

  depositContracts() {
    /* this.spinner.show(); */
    let arrayId: string[] = [];
    let valueContract: number = 0;
    this.selection.selected.filter(el => {
      arrayId.push(el.uuid);
      valueContract += Number(el.totalValue);
    });

    Swal.fire({
      title: `<strong>Atenção!</strong>`,
      icon: 'warning',
      html: `<p>Tem certeza que deseja liquidar ${arrayId.length} contrato(s) no valor total de
      <b>${this.currenty.transform(valueContract.toFixed(2), 'BRL')} ?</b>
       </p>`,
      showCancelButton: true,
      focusConfirm: true,
      confirmButtonText: 'Liquidar',
      cancelButtonText: 'Cancelar',
    }).then(res => {
      if (res.isConfirmed) {
        this.contractService.paymantContracts(arrayId, this.email)
          .subscribe((result:any) => {
            if (result.succes) {
              Swal.fire({
                icon: 'success',
                title: "Sucesso!",
                text: "Contratos pagos!"
              }).then(res => {
                if (res.isConfirmed) {
                  this.spinner.hide();
                  window.location.reload()
                }
              })
            }
          }, (err:any) => {
            this.spinner.hide();
          });
      }
    })


  }

  getPaginatorIntl(paginator: MatPaginatorIntl) {
    paginator.itemsPerPageLabel = 'Items por página';
    paginator.lastPageLabel = 'Página Anterior';
    paginator.nextPageLabel = 'Próxima Página';
    paginator.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return `0 de ${length}`;
      }

      length = Math.max(length, 0);

      const startIndex = page * pageSize;

      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

      return `${startIndex + 1} - ${endIndex} de ${length}`;
    };
  }
}
