import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {ApiService} from "src/app/core/_base/_auth/api.service";


const SPE_N_OPEN_ACCOUNT = '/spe-n-open-account';

@Injectable()
export class PostalCodeService {

    constructor(
        private api: ApiService
    ) {
    }
    getCep(zipcode: string) {
        const uri = `${SPE_N_OPEN_ACCOUNT}/v1/cep/63210000`;        
        this.api.get(uri).subscribe(res=> {            
        });
        return this.api.get(uri);
    }
}
