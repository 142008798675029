<a routerLink="/dashboard">
  <ng-container *ngIf="currentLayoutType === 'dark-sidebar'">
    <img alt="Logo"
      src="https://urbanobank.com/wp-content/uploads/thegem-logos/logo_a80309d73921b66687d96d9be5dd0dd2_1x.png"
      class="h-50px app-sidebar-logo-default" />
  </ng-container>
  <ng-container *ngIf="currentLayoutType !== 'dark-sidebar'">
    <img alt="Logo" src="./assets/media/logos/default.svg" class="h-50px app-sidebar-logo-default theme-light-show" />
    <img alt="Logo"
      src="https://urbanobank.com/wp-content/uploads/thegem-logos/logo_a80309d73921b66687d96d9be5dd0dd2_1x.png"
      class="h-50px app-sidebar-logo-default theme-dark-show" />
  </ng-container>
</a>

<ng-container *ngIf="true">
  <!--begin::Toggle-->
  <button class="brand-toggle btn btn-sm px-0" id="kt_aside_toggle" (click)="toggleAsideClick()">
    <span class="svg-icon svg-icon-xl" [inlineSVG]="'./assets/media/svg/icons/Navigation/Angle-double-left.svg'"></span>
  </button>
  <!--end::Toolbar-->
</ng-container>