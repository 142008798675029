import { ContractTable } from "./contract.dto";

export const CONTRACT_LIST_MOCK: ContractTable[] = [
    {
        name: 'Financiamento',
        value: 588.50,
        installments: 12,
        fees: 6,
        totalFees: 180,
        createdDate: new Date(),
        status: '3/12'
    },
    {
        name: 'Financiamento',
        value: 588.50,
        installments: 12,
        fees: 6,
        totalFees: 180,
        createdDate: new Date(),
        status: '3/12'
    },
    {
        name: 'Financiamento',
        value: 588.50,
        installments: 12,
        fees: 6,
        totalFees: 180,
        createdDate: new Date(),
        status: '3/12'
    },
    {
        name: 'Financiamento',
        value: 588.50,
        installments: 12,
        fees: 6,
        totalFees: 180,
        createdDate: new Date(),
        status: '3/12'
    },
    {
        name: 'Financiamento',
        value: 588.50,
        installments: 12,
        fees: 6,
        totalFees: 180,
        createdDate: new Date(),
        status: '3/12'
    }
];
