<div id="tax-form-component">
  <h1 class="page-title mb-4">Tarifas</h1>

  <div class=" d-flex justify-content-end mb-4">
    <button type="button" class="btn btn-success" (click)="goToCreateOrEditFee()">
      Criar Tarifa
    </button>
  </div>

  <ng-container *ngIf="tableData">
    <ui-c-table [tableData]="tableData"></ui-c-table>
  </ng-container>
</div>
