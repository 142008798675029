import { UserInfoService } from 'src/app/core/_base/_auth/user-info.service';
import { StorageService } from 'src/app/shared/services-ultil/storage/storage.service';
import { Router } from '@angular/router';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { MaskService } from 'src/app/shared/services-ultil/masker-service/masker.service';
import { CompanyServices } from '../services-apis/companies.services';

@Component({
  selector: 'app-companies-list',
  templateUrl: './companies-list.component.html',
  styleUrls: ['./companies-list.component.scss']
})
export class CompaniesListComponent implements OnInit {

  displayedColumns = ['id', 'name', 'documentGov', 'dateRegister', 'companiesStatus', 'action'];
  dataSource: any;
  paginator: any;
  matSort: any;
  isPermissionAdmin: any;

  constructor(
    private companyServices: CompanyServices,
    public maskerService: MaskService,
    private spinner: NgxSpinnerService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private storageService: StorageService,
    private userInfoService: UserInfoService
  ) { }

  ngOnInit(): void {
    this.spinner.show();

    const currencydate = new Date();
    const startDate = moment(new Date().setDate(currencydate.getDate() - 7)).format('YYYY-MM-DD');
    const endDate = moment(currencydate).format('YYYY-MM-DD');

    this.isPermissionAction();

    this.getListCompanies(startDate, endDate);
  }

  emitDate(event: any) {
    this.getListCompanies(event.startDate, event.endDate);
  }

  getListCompanies(startDate: string, endDate: string) {
    this.spinner.show();
    this.companyServices.getCompaniesList(startDate, endDate).subscribe(
      res => {
        if (res) {
          this.spinner.hide();
          this.dataSource = new MatTableDataSource(res);
          this.dataSource.paginator = this.paginator;              
          this.dataSource.sort = this.matSort;
          this.cdr.detectChanges();
        }
      }
    )
  }

  getByuuid(id: string) {
    /* console.log(id); */
  }

  viewDetail(uuid: string) {
    this.storageService.saveData("isView", "true")
    this.router.navigate([`admin/companies/${uuid}/edit`]);
  }

  editCompany(uuid: string) {
    this.router.navigate([`admin/companies/${uuid}/edit`]);
  }

  filterData($event: any) {
    this.dataSource.filter = $event.target.value;
  }

  checkStatus(status: any) {

    switch (status) {
      case "1":
        return "Ativa";
      case "4":
        return "Bloqueada";
      case "2":
        return "Inativa";
      case "3":
        return "Contrato Aguardando assinatura";
      default:
        return "Ativa";
    }

  }

  isPermissionAction() {
    this.isPermissionAdmin = this.userInfoService.isUserInRole('bo-consigned-audit');
    this.userInfoService.getInfoProfile().then(res => {
      
    })
    return this.isPermissionAdmin;
  }
}

