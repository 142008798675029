import {Component, OnInit} from '@angular/core';
import {
    ApprovalFundingEnum,
    ApprovalStatusEnum
} from "src/app/core/scfi/services/services-proposal/enum/approve-status.enum";

@Component({
    selector: 'approval-proposal',
    templateUrl: './approval-proposal.component.html',
    styleUrls: ['./approval-proposal.component.scss']
})
export class ApprovalProposal implements OnInit {

    fundos = Object.values(ApprovalFundingEnum)
    opcoes = Object.values(ApprovalStatusEnum);
    opcaoSelecionada: string = 'seleciona';
    opcaoFundo: string = 'seleciona'


    constructor() {
    }

    ngOnInit(): void {
    }

}
