import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {TableData} from "src/app/core/scfi/services/services-table/table.dto";
import {AbstractScfi} from "../abstract-scfi";
import {NgxSpinnerService} from "ngx-spinner";
import {CurrencyPipe, DatePipe} from "@angular/common";
import {AcceptProposalService} from "src/app/core/scfi/services/services-accept/accept-proposal.service";
import {AcceptProposalDto, ConfDataAccept} from "src/app/core/scfi/services/services-accept/accept-proposal.dto";
import {FormGroup} from "@angular/forms";
import {MaskService} from "src/app/shared/services-ultil/masker-service/masker.service";
import {MatDialog} from "@angular/material/dialog";
import {TokenAcceptComponent} from "src/app/shared/components/token-accept-proposal/token-accept.component";

@Component({
  selector: 'accept-proposal',
  templateUrl: './accept-proposal.component.html',
  styleUrls: ['./accept-proposal.component.scss']
})
export class AcceptProposalComponent extends AbstractScfi implements OnInit {

  public tableProposal: TableData;
  public accept: AcceptProposalDto[];
  public formAccept: FormGroup;
  public isPf: boolean = true;


  constructor(
      public dialog: MatDialog,
      private  mask :MaskService,
      private spinner: NgxSpinnerService,
      private cdr: ChangeDetectorRef,
      protected currencyPipe: CurrencyPipe,
      protected datePipe: DatePipe,
  public acceptService: AcceptProposalService,
  ) {  super(currencyPipe, datePipe)}

  ngOnInit(): void {
   this.findAcceptDetails();
   this.buildFormAcceptProposal();
  }

  public buildFormAcceptProposal() {
    this.formAccept = this.acceptService.createFormAccept();
    this.findAcceptData();

  }
  findAcceptDetails(){
    this.acceptService.findAcceptDetails().subscribe({
      next: (dataAccept: AcceptProposalDto[]) => {
        this.accept = dataAccept;
        this.tableProposal = this.generateTableAccept();
      },
      error: (error) => {
        this.showErrorModal('Erro ao buscar dados!');
      }
    });
  }

  generateTableAccept(): TableData {
    return {
      header: [ 'Vencimento da Parcela', 'periodo', 'Juros', 'Amortização', 'Parcela', 'Salvo Devedor', ''],
      rows: this.accept.map(row => {
        const formatDateTime = this.formatDate(row.dueDate.toString())
        return {
          fields: [
            formatDateTime,
            row.quantityInstallments,
              row.totalAmortization,
              row.installmentAmount,
              row.totalToPay,
            row.totalFees,

          ],
        }
      }),
    }
  }

  public findAcceptData() {
    this.acceptService.findAcceptData().subscribe({
      next: (data: ConfDataAccept) => {

        this.isPf = data.document.length === 11;
        this.formAccept.patchValue({
          name: data.name,
          document: this.isPf ? this.mask.formatDocumentCPF(data.document) : this.mask.formatDocumentCNPJ(data.document),
          phone: this.mask.formatPhone(data.phone),
          email: data.email,
        });
        this.cdr.detectChanges();
        this.spinner.hide();
      },
      error: (error) => {
        this.showErrorModal('Erro ao buscar detalhes da parcela!');
        this.spinner.hide();
      }
    })
  }

  openModal(): void {
    const dialogRef = this.dialog.open(TokenAcceptComponent, {
      width: '400px',
      height: '500px'
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }


}
