import { UiCCardinfoModule } from 'src/app/shared/components/cards-infos/cards-infos.module';
import { UiCSelectCompanieModule } from 'src/app/shared/components/select-companies/select-companies.module';
import { UiCDatePickerModule } from 'src/app/shared/components/date-picker/date-picker.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';

import { BillingInvoicesRoutingModule } from './billing-invoices-routing.module';
import { BillingInvoicesListComponent } from './billing-invoices-list/billing-invoices-list.component';
import { BillingInvoicesGeneratorFormsComponent } from './billing-invoices-generator-forms/billing-invoices-generator-forms.component';
import { BillingInvoicesSettleFormsComponent } from './billing-invoices-settle-forms/billing-invoices-settle-forms.component';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { UiCInputModule } from 'src/app/shared/components/ui-c-input/ui-c-input.module';
import { MaskerModule } from 'src/app/shared/services-ultil/masker-service/masker-module.module';
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { MatSelectModule } from '@angular/material/select';
import { InvoiceService } from './services-api/invoice-service';
import { InterceptService } from 'src/app/core/_base/_auth/intercept/intercept.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TruncateService } from 'src/app/shared/services-ultil/truncate-service/truncate.service';


@NgModule({
  declarations: [
    BillingInvoicesListComponent,
    BillingInvoicesGeneratorFormsComponent,
    BillingInvoicesSettleFormsComponent
  ],
  imports: [
    CommonModule,
    BillingInvoicesRoutingModule,
    SharedModule,
    MatIconModule,
    MatPaginatorModule,
    MatTableModule,
    UiCInputModule,
    MaskerModule,
    UiCDatePickerModule,
    UiCSelectCompanieModule,
    UiCCardinfoModule,
    MatCheckboxModule,
    InlineSVGModule,
    MatSelectModule
  ],
  providers:[
    InvoiceService,
    CurrencyPipe,
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
    TruncateService
  ]
})
export class BillingInvoicesModule { }
