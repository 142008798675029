<app-page-header [page-title]="creationPageTitle()" button-text="Voltar" button-link="/admin/employees"
  button-class="btn-danger">
</app-page-header>


<div class="container-fluid px-0 mt-4">
  <div class=" row p-0" *ngIf="cardList">
    <div class="card card-custom">
      <div class="card-header ps-0">
        <div class="card-title">
          <h4 class="card-label">
            Histórico de propostas
          </h4>
        </div>
      </div>
      <div class="row">
        <ng-container *ngFor="let item of cardList?.header">
          <div class="col-2 opacity-80" (click)="filterListByStatus(item.status)">
            <span
              [class]="'btn  position-relative w-100 mt-4 ' + checkStatus(item.status, item.isNonStandard, item.isSpecial).color">
              {{checkStatus(item.status).status}}
              <br>
              <p class="font-size-20">
                {{item.loanValue | currency:"BRL"}}
              </p>
              <span class="font-size-16">
                {{ item.quantities }} propostas <br>
              </span>
            </span>
          </div>
        </ng-container>
      </div>
    </div>

    <div *ngIf="showListProposals" class="card card-custom animation">
      <div class="card-header ps-0">
        <div class="card-title w-100 d-flex justify-content-between">
          <h4 class="card-label">
            {{checkStatus(itemStatusByCard).status}}
          </h4>
          <h4 (click)="closeProposalList()" class="btn me-2 btn-sm btn-danger">
            Fechar
          </h4>
        </div>
      </div>
      <div class="card-body">
        <ng-container>
          <table mat-table matSort [dataSource]="dataSource"
            class="mat-elevation-z1 table table-striped table-hover my-1 mb-0">

            <ng-container matColumnDef="proposalDate">
              <th mat-header-cell mat-sort-header *matHeaderCellDef> Data </th>
              <td mat-cell *matCellDef="let element"> {{element.dateRegister | date : "dd/MM/yyyy HH:mm"}} </td>
            </ng-container>

            <ng-container matColumnDef="netCapitalAmountLoan">
              <th mat-header-cell *matHeaderCellDef> Empréstimo </th>
              <td mat-cell *matCellDef="let element"> {{element.loanValue | currency:"BRL"}} </td>
            </ng-container>

            <ng-container matColumnDef="iProposalInterestMonth">
              <th mat-header-cell *matHeaderCellDef> Taxa Juros </th>
              <td mat-cell *matCellDef="let element"> {{element.installmentInterest}} % </td>
            </ng-container>

            <ng-container matColumnDef="installmentQuantityLoan">
              <th mat-header-cell *matHeaderCellDef> Parcelas </th>
              <td mat-cell *matCellDef="let element"> {{element.installmentQuantity}}x </td>
            </ng-container>

            <ng-container matColumnDef="installmentValue">
              <th mat-header-cell *matHeaderCellDef> Valor Parcela </th>
              <td mat-cell *matCellDef="let element"> {{element.installmentValue | currency:"BRL"}} </td>
            </ng-container>

            <ng-container matColumnDef="fvTotalAmountLoanValue">
              <th mat-header-cell *matHeaderCellDef> Valor Total </th>
              <td mat-cell *matCellDef="let element"> {{element.totalValue | currency:"BRL"}} </td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef class="text-center"> Ações </th>
              <td mat-cell *matCellDef="let element" class="text-center">
                <mat-icon class="mx-4" (click)="viewDetails(element.uuid)">visibility</mat-icon>
                <!-- <mat-icon [routerLink]="[element.uuid, 'edit']" >edit</mat-icon>
                <mat-icon class="mx-4" (click)="getByuuid(element.uuid)">delete</mat-icon> -->
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          </table>
        </ng-container>
      </div>
    </div>
  </div>

  <form [formGroup]="resourceForm" [ngClass]="isView ? 'disabled':''">
    <div class="card g-12 ">
      <div class="card-header pe-0">
        <h3 class="card-title">Informações sobre o Colaborador</h3>
        <div class="d-flex flex-column justify-content-end">
          <button (click)="updateEmployBrassPress()" *ngIf="isEdit" type="submit"
            class="btn btn-success btn-lg float-right mt-3">
            Atualizar dados
          </button>
          <p class='required-text text-danger'>Os campos com * são obrigatórios</p>
        </div>
      </div>

      <!-- <div style="text-align:center">
        <button mat-raised-button color="primary" [class.spinner]="loading" [disabled]="loading" (click)="save()"></button>
      </div> -->

      <div class="card-body">
        <div class="row">

          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'CPF *'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              (change)="getByDocument($event)" [formName]="'identityGov'" [isNumber]="true" [isCPF]="true"
              [placeholder]="'Digite seu CPF'">
            </app-ui-c-input>
          </div>

          <div class="col-12 col-md-6">
            <app-ui-c-input [name]="'Nome *'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              [formName]="'fullName'" [type]="'text'" [placeholder]="'Digite seu nome'">
            </app-ui-c-input>
          </div>

          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'Celular'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              [formName]="'dddCellPhone'" [isPhone]="true" [placeholder]="'(99) 99999-9999'">
            </app-ui-c-input>
          </div>

          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'Nascimento *'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              [formName]="'birthDate'" [minlength]="'10'" [maxlength]="'10'" [isDate]="true"
              [placeholder]="'XX/XX/XXXX'">
            </app-ui-c-input>
          </div>

          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'RG'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              [formName]="'identity'" [minlength]="'4'" [placeholder]="'XXXXXXXX'">
            </app-ui-c-input>
          </div>

          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'Orgão Emissor'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              [formName]="'ufIdentity'" [placeholder]="'XXXXXXXXX'">
            </app-ui-c-input>
          </div>

          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'Data de emissão da identidade'" [form]="resourceForm"
              [errorMessages]="errorMessagesEmployees" [formName]="'identityIssueDate'" [isDate]="true"
              [placeholder]="'XX/XX/XXXX'">
            </app-ui-c-input>
          </div>

          <div class="col-12 col-md-3">
            <ng-container>
              <label class="mt-3 mb-0 form-label fs-6 fw-bolder text-dark">Sexo</label>
              <select formControlName="sex" name="sex" data-control="sex" data-hide-search="true"
                class="form-select form-select-solid form-select-lg fw-bold w-100">
                <option value="M">Masculino </option>
                <option value="F">Feminino </option>
                <option value="O">Outro </option>
              </select>
            </ng-container>
          </div>


          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'E-mail'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              [formName]="'email'" [placeholder]="'E-mail'">
            </app-ui-c-input>
          </div>

          <div class="col-12 col-md-6">
            <app-ui-c-input [name]="'Nome da mãe'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              [formName]="'motherName'" [placeholder]="'Nome da mãe'">
            </app-ui-c-input>
          </div>

          <div class="col-12 col-md-6">
            <app-ui-c-input [name]="'Nome do Pai'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              [formName]="'fatherName'" [type]="'text'" [placeholder]="'Nome do Pai'">
            </app-ui-c-input>
          </div>

          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'Nº Dependentes'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              [formName]="'dependentNumber'" [type]="'number'" [placeholder]="'Nº Dependentes'">
            </app-ui-c-input>
          </div>
        </div>

      </div>
    </div>

    <div class="card g-12 my-2">
      <div class="card-header">
        <h3 class="card-title">Contatos e Endereço RESIDENCIAL do Colaborador</h3>
      </div>

      <div class="card-body">
        <div class="row">

          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'CEP *'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              (change)="onChangePostal($event)" [formName]="'zipCodeResidence'" [isCEP]="true" [placeholder]="'CEP'">
            </app-ui-c-input>
          </div>

          <div class="col-12 col-md-6">
            <app-ui-c-input [name]="'Logradouro *'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              [formName]="'residentialStreet'" [placeholder]="'Logradouro'">
            </app-ui-c-input>
          </div>

          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'Número *'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              [formName]="'residenceNumber'" [isNumber]="true" [placeholder]="'Número'">
            </app-ui-c-input>
          </div>

          <div class="col-12 col-md-6">
            <app-ui-c-input [name]="'Complemento'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              [formName]="'residenceComplement'" [placeholder]="'Complemento'">
            </app-ui-c-input>
          </div>

          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'Bairro *'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              [formName]="'residentialNeighborhood'" [placeholder]="'Bairro'">
            </app-ui-c-input>
          </div>

          <div class="col-12 col-md-3">
            <ng-container>
              <label class="mt-3 mb-0 form-label fs-6 fw-bolder text-dark">Estado *</label>
              <select formControlName="ufResidence" name="ufresidence" data-control="ufresidence"
                data-hide-search="true" class="form-select form-select-solid form-select-lg fw-bold w-100">
                <option *ngFor="let state of listUf"> {{state.name}} </option>
              </select>
            </ng-container>
          </div>

          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'Cidade *'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              [formName]="'homecity'" [placeholder]="'Cidade'">
            </app-ui-c-input>
          </div>

          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'Fone Residencial'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              [formName]="'homePhone'" [isPhone]="true" [placeholder]="'Fone Residencial'">
            </app-ui-c-input>
          </div>

          <div class="col-12 col-md-3">
            <ng-container>
              <label class="mt-3 mb-0 form-label fs-6 fw-bolder text-dark">Estado Civil *</label>
              <select formControlName="maritalStatus" name="maritalStatus" data-control="maritalStatus"
                data-hide-search="true" class="form-select form-select-solid form-select-lg fw-bold w-100">
                <option value="0"> Solteiro </option>
                <option value="1"> Casado </option>
                <option value="2"> Divorciado </option>
                <option value="3"> União Estável </option>
                <option value="4"> Viúvo </option>
              </select>
            </ng-container>
          </div>


          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'Nacionalidade *'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              [formName]="'nationality'" [placeholder]="'Nacionalidade'">
            </app-ui-c-input>
          </div>

          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'Naturalidade'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              [formName]="'naturalNess'" [placeholder]="'Naturalidade'">
            </app-ui-c-input>
          </div>
        </div>

      </div>
    </div>
    <div class="card g-12">
      <div class="card-header">
        <h3 class="card-title">Contatos e Endereço TRABALHO do Colaborador</h3>
      </div>

      <div class="card-body">
        <div class="row">
          <div class="col-12  col-md-3">
            <app-ui-c-input [name]="'CEP'" [isCEP]="true" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              (change)="onChangePostalComercial($event)" [formName]="'zipCodeWork'" [placeholder]="'CEP'">
            </app-ui-c-input>
          </div>

          <div class="col-12  col-md-6">
            <app-ui-c-input [name]="'Logradouro'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              [formName]="'streetWork'" [placeholder]="'Logradouro'">
            </app-ui-c-input>
          </div>

          <div class="col-12  col-md-3">
            <app-ui-c-input [name]="'Número'" [isNumber]="true" [form]="resourceForm"
              [errorMessages]="errorMessagesEmployees" [formName]="'workNumber'" [placeholder]="'Número'">
            </app-ui-c-input>
          </div>

          <div class=" col-12  col-md-3">
            <app-ui-c-input [name]="'Complemento'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              [formName]="'complementWork'" [placeholder]="'Complemento'">
            </app-ui-c-input>
          </div>

          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'Bairro'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              [formName]="'neighborhoodWork'" [placeholder]="'Bairro'">
            </app-ui-c-input>
          </div>

          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'Cidade'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              [formName]="'cityWork'" [placeholder]="'Cidade'">
            </app-ui-c-input>
          </div>

          <div class="col-12 col-md-3">
            <label class="mt-3 mb-0 form-label fs-6 fw-bolder text-dark">Estado</label>
            <select formControlName="ufWork" name="ufwork" data-control="ufwork" data-hide-search="true"
              class="form-select form-select-solid form-select-lg fw-bold w-100">
              <option *ngFor="let state of listUf"> {{state.name}} </option>
            </select>
          </div>
          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'Celular'" [isPhone]="true" [form]="resourceForm"
              [errorMessages]="errorMessagesEmployees" [formName]="'dddWork'" [placeholder]="'Celular'">
            </app-ui-c-input>
          </div>
          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'Fone Trabalho'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              [isPhone]="true" [formName]="'phoneWork'" [placeholder]="'Fone Trabalho'">
            </app-ui-c-input>
          </div>
          <div class="col-12 col-md-6">
            <app-ui-c-input [name]="'E-mail Trabalho'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              [formName]="'emailWork'" [placeholder]="'E-mail Trabalho'">
            </app-ui-c-input>
          </div>
        </div>
      </div>
    </div>

    <div class="card g-12 my-2">
      <div class="card-header">
        <h3 class="card-title">Dados Ocupação Colaborador</h3>
      </div>

      <div class="card-body">
        <div class="row">

          <div class="col-12 col-md-3">
            <!--  <app-ui-c-input [name]="'Empresa'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              [formName]="'companyName'" [placeholder]="'Empresa'">
            </app-ui-c-input> -->

            <label class="mt-3 mb-0 form-label fs-6 fw-bolder text-dark">Empresa</label>
            <select (change)="selectCompany($event)" formControlName="uuidCompany" name="companyName"
              data-control="uuidCompany" data-hide-search="true"
              class="form-select form-select-solid form-select-lg fw-bold w-100">
              <option *ngFor="let company of companyList" [value]="company?.uuid"> {{company.company}} </option>
            </select>

          </div>
          <div class="col-12 col-md-2">
            <app-ui-c-input [name]="'Setor'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              [formName]="'sector'" [placeholder]="'Setor'">
            </app-ui-c-input>
          </div>
          <div class="col-12 col-md-2">
            <app-ui-c-input [name]="'Ocupação'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              [formName]="'ocuppation'" [placeholder]="'Ocupação'">
            </app-ui-c-input>
          </div>
          <div class="col-12 col-md-2">
            <app-ui-c-input [name]="'Matrícula'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              [formName]="'registration'" [placeholder]="'Matrícula'">
            </app-ui-c-input>
          </div>
          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'Data Admissão'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              [formName]="'administrationDate'" [isDate]="true" [placeholder]="'Data Admissão'">
            </app-ui-c-input>
          </div>
          <div class="col-12 col-md-3" *ngIf="!userService?.isAttendant()">
            <app-ui-c-input [name]="'Salário *'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              [isNumber]="true" [isMoney]="true" [formName]="'salary'" [placeholder]="'Salário'">
            </app-ui-c-input>
          </div>
          <div class="col-12  col-md-4">
            <app-ui-c-input [name]="'Gestor Responsável *'" [form]="resourceForm"
              [errorMessages]="errorMessagesEmployees" [formName]="'managerResponsible'"
              [placeholder]="'Gestor Responsável'">
            </app-ui-c-input>
          </div>
          <div class="col-12 col-md-5">
            <app-ui-c-input [name]="'Email Gestor *'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              [formName]="'responsibleManagerEmail'" [placeholder]="'Email Gestor'">
            </app-ui-c-input>
          </div>
          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'Celular Gestor *'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              [formName]="'cellPhoneManagerResponsible'" [isPhone]="true" [placeholder]="'Celular Gestor'">
            </app-ui-c-input>
          </div>
          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'CPF Gestor *'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              [formName]="'responsibleIdentityGov'" [isCPF]="true" [isNumber]="true" [placeholder]="'CPF Gestor'">
            </app-ui-c-input>
          </div>

        </div>

      </div>


    </div>
    <div class="card g-12 my-2" *ngIf="!userService?.isAttendant()">
      <div class="card-header">
        <h3 class="card-title">Descontos no Contracheque/Holerite Colaborador</h3>
      </div>

      <div class="card-body">
        <div class="row d-flex">

          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'Pensão Alimentícia *'" [form]="resourceForm"
              [errorMessages]="errorMessagesEmployees" [isNumber]="true" [isMoney]="true" [formName]="'alimony'"
              [placeholder]="'Pensão alimentícia'">
            </app-ui-c-input>
          </div>
          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'Cobranças Judiciais'" [form]="resourceForm"
              [errorMessages]="errorMessagesEmployees" [isNumber]="true" [isMoney]="true" [formName]="'courtCharges'"
              [placeholder]="'Cobranças Judiciais'">
            </app-ui-c-input>
          </div>
          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'INSS *'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              [isMoney]="true" [isNumber]="true" [formName]="'inss'" [placeholder]="'INSS'">
            </app-ui-c-input>
          </div>
          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'Imposto de Renda *'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              [isMoney]="true" [isNumber]="true" [formName]="'incomeTax'" [placeholder]="'Imposto de Renda'">
            </app-ui-c-input>
          </div>
          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'Sindicato *'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              [isMoney]="true" [isNumber]="true" [formName]="'unionsdues'" [placeholder]="'Sindicato'">
            </app-ui-c-input>
          </div>

          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'Plano de Saúde *'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              [isMoney]="true" [isNumber]="true" [formName]="'healthPlan'" [placeholder]="'Plano de Saúde'">
            </app-ui-c-input>
          </div>

          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'Plano Odontológico *'" [form]="resourceForm"
              [errorMessages]="errorMessagesEmployees" [isMoney]="true" [isNumber]="true" [formName]="'dentalPlan'"
              [placeholder]="'Plano Odontológico'">
            </app-ui-c-input>
          </div>

          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'Seguro de Vida *'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              [isMoney]="true" [isNumber]="true" [formName]="'lifeInsurance'" [placeholder]="'Seguro de Vida'">
            </app-ui-c-input>
          </div>
          <!-- </div> -->
          <!--  <div class="row my-3"> -->

          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'Outros Emprest. BrasPress *'" [form]="resourceForm"
              [errorMessages]="errorMessagesEmployees" [isMoney]="true" [isNumber]="true" [formName]="'otherDiscounts'"
              [placeholder]="'Outros Emprest. BrasPress'">
            </app-ui-c-input>
          </div>

          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'Valor da parcela'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              [isMoney]="true" [isNumber]="true" [formName]="'installmentAmount'" [placeholder]="'Valor da parcela'">
            </app-ui-c-input>
          </div>

          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'Parcela Multa'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              [isMoney]="true" [isNumber]="true" [formName]="'fineInstallment'" [placeholder]="'Parcela Multa'">
            </app-ui-c-input>
          </div>

          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'Parcela Colisao'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              [isMoney]="true" [isNumber]="true" [formName]="'collisionInstallment'" [placeholder]="'Parcela Colisao'">
            </app-ui-c-input>
          </div>

          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'Parcela Responsabilidade'" [form]="resourceForm"
              [errorMessages]="errorMessagesEmployees" [isMoney]="true" [isNumber]="true"
              [formName]="'responsibilityPortion'" [placeholder]="'Parcela Responsabilidade'">
            </app-ui-c-input>
          </div>

          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'Parcela Produtos'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              [isMoney]="true" [isNumber]="true" [formName]="'installmentProducts'" [placeholder]="'Parcela Produtos'">
            </app-ui-c-input>
          </div>

          <div class="col-12 col-md-3">
            <app-ui-c-input [name]="'Parcela Outros'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              [isMoney]="true" [isNumber]="true" [formName]="'otherInstallment'" [placeholder]="'Parcela Outros'">
            </app-ui-c-input>
          </div>

        </div>
      </div>
    </div>

    <div class="card g-12 my-2" id="accountData">
      <div class="card-header">
        <h3 class="card-title">Dados Bancários</h3>
      </div>

      <div class="card-body">
        <div class="row">

          <div class="col-12  col-md-3">
            <app-ui-c-input [name]="'Número Banco'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              [formName]="'bankNumber'" [placeholder]="'Número Banco'">
            </app-ui-c-input>
          </div>
          <div class="col-12  col-md-3">
            <app-ui-c-input [name]="'Nome Banco'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              [formName]="'bankName'" [placeholder]="'Nome Banco'">
            </app-ui-c-input>
          </div>
          <div class="col-12  col-md-3">
            <app-ui-c-input [name]="'Agência'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              [isAgency]="true" [formName]="'agency'" [placeholder]="'Agência'">
            </app-ui-c-input>
          </div>
          <div class="col-12  col-md-3">
            <app-ui-c-input [name]="'Número Conta'" [form]="resourceForm" [errorMessages]="errorMessagesEmployees"
              [isAccount]="true" [formName]="'bankAccount'" [placeholder]="'Número Conta'">
            </app-ui-c-input>
          </div>
          <div class="col-12  col-md-3">
            <ng-container>
              <label class="mt-3 mb-0 form-label fs-6 fw-bolder text-dark">Tipo de Conta</label>
              <select formControlName="bankAccountType" name="bankAccountType" data-control="bankAccountType"
                data-hide-search="true" class="form-select form-select-solid form-select-lg fw-bold w-100">
                <!-- <option value="CP">Conta Poupança </option>
                <option value="CC">Conta Corrente </option> -->
                <option value="0">Conta Pagamento </option>
              </select>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="row form-button">
      <div class="col-12 col-md-4 col-sm-6">
        <button (click)="save()" [disabled]="resourceForm.invalid" *ngIf="!isEdit" type="submit"
          class="btn btn-primary btn-lg float-right mt-3">
          Salvar
        </button>
        <button [disabled]="resourceForm.invalid" (click)="update()" *ngIf="!isView && isEdit" type="submit"
          class="btn btn-primary btn-lg float-right mt-3">
          Salvar
        </button>
      </div>
    </div>
  </form>
</div>

<!-- [disabled]="resourceForm.invalid -->