<div id="proposal-installments-details">
    <section class="card mb-4 shadow-sm">
        <div class="card-header border-0 d-flex align-items-center justify-content-start">
            <span [inlineSVG]="'./assets/media/icons/duotune/abstract/abs029.svg'"
                class="svg-icon svg-icon-2tx svg-icon-gray-800 me-4"></span>
            <h3 class="fw-bolder card-title mt-3">Detalhes das Parcelas</h3>
        </div>

        <div class="card-body border-top p-9">
            <div class="d-flex">
                <h5 class="col-3 px-10">
                    Nº de Parcelas:
                </h5>
                <h5 class="col-3 px-10">
                    Valor da Parcela:
                </h5>
                <h5 class="col-3 px-10">
                    Valor Total da Proposta (M):
                </h5>
                <h5 class="col-3 px-10">
                    Total de Juros:
                </h5>
            </div>

            <div class="d-flex">
                <div class="col-3 px-10">
                    {{ proposal?.loan?.installmentQuantityLoan }}x
                </div>
                <div class="col-3 px-10">
                    {{ proposal?.loan?.pinStallmentValue | currency:"BRL" }}
                </div>
                <div class="col-3 px-10">
                    {{ proposal?.loan?.fvTotalAmountLoanValue | currency:"BRL" }}
                </div>
                <div class="col-3 px-10">
                    {{ proposal?.loan?.totalInterest | currency:"BRL" }}
                </div>
            </div>

            <div class="d-flex mt-10">
                <h5 class="col-3 px-10">
                    Valor Solicitado:
                </h5>

                <h5 class="col-3 px-10">
                    Taxa de Juros:
                </h5>

                <h5 class="col-3 px-10">
                    Tributos (IOF):
                </h5>

                <h5 class="col-3 px-10">
                    TAC:
                </h5>
            </div>

            <div class="d-flex">
                <div class="col-3 px-10">
                    {{ proposal?.loan?.amountProposal | currency:"BRL" }}
                </div>

                <div class="col-3 px-10">
                    {{ proposal?.loan?.iProposalInterestMonth }}%
                </div>

                <div class="col-3 px-10">
                    {{ proposal?.loan?.iof | currency:"BRL" }}
                </div>

                <div class="col-3 px-10">
                    {{ proposal?.loan?.taxAdministration | currency:"BRL" }}
                </div>
            </div>
        </div>
    </section>
</div>