import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {TableData} from "src/app/core/scfi/services/services-table/table.dto";
import { Router} from "@angular/router";
import {
  TypeCustomerDataDto
} from "src/app/core/scfi/services/services-customer-data/type-customer-data.dto";
import {TypeCustomerDataService} from "src/app/core/scfi/services/services-customer-data/type-customer-data.service";
import {AbstractScfi} from "../../abstract-scfi";
import {CurrencyPipe, DatePipe} from "@angular/common";
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss']
})
export class DocumentListComponent extends AbstractScfi implements OnInit {
  tableData: TableData;
  dataList: TypeCustomerDataDto[];

  constructor(
      protected currencyPipe: CurrencyPipe,
      private router: Router,
      private dataService: TypeCustomerDataService,
      protected datePipe: DatePipe,
      private spinner: NgxSpinnerService,
      private cdr: ChangeDetectorRef,
  ) {
    super(currencyPipe, datePipe);
  }

  ngOnInit() {
    this.findDataList();
  }

  public findDataList() {
    this.spinner.show();
    this.dataService.findDataList().subscribe({
      next: (dataListData: TypeCustomerDataDto[]) => {
        this.dataList = dataListData;
        this.tableData = this.generateTableData();
        this.spinner.hide();
        this.cdr.detectChanges();
      },
      error: (error) => {
        this.showErrorModal('Erro ao buscar dados!');
        this.spinner.hide();
      }
    });
  }

  translateTypeCustomer(name: string): string {
    switch (name) {
      case 'Pessoa física':
        return 'PF';
      case 'Pessoa jurídica':
        return 'PJ';
      default:
        return '-';
    }
  }

  generateTableData(): TableData {
    return {
      header: [ 'Dado', 'Tipo de Cliente', 'Requer Anexo', 'Ações'],
      rows: this.dataList.map(row => {
        return {
          fields: [
            row.name,
            this.translateTypeCustomer(row.typeCustomer.name),
            this.translateAttachmentRequired(row.hasAttachment)

          ],
          actions: [
            {icon: './assets/media/icons/duotune/general/gen055.svg', onClick: () => this.redirectToNewOrEditData(row.uuid)},
            {icon: './assets/media/icons/duotune/general/gen027.svg', onClick: () => this.deleteData(row.uuid, row.uuidWhiteLabel)}
          ]
        }
      }),
    }
  }


  public async deleteData(uuid: string, uuidWhiteLabel: string)  {
    const confirmed = await this.showConfirmationModal('Tem certeza que deseja excluir este dado?')

    const deleteToData = {
      uuid: uuid,
      uuidWhiteLabel: uuidWhiteLabel
    }
    if (confirmed) {
      this.spinner.show();
      this.dataService.deleteData(deleteToData).subscribe({
        next: () => {
          this.findDataList();
          this.showSuccessModal('Dado excluído com sucesso!');
          this.spinner.hide();
        },
        error: (error) => {
          this.showErrorModal('Erro ao excluir dado!');
          this.spinner.hide();
        }
      });
    }
  }
  redirectToNewOrEditData(uuid?:string) {
    if (uuid) {
      this.router.navigate(['scfi/documents/edit-document', uuid]);
    } else {
      this.router.navigate(['scfi/documents/new-document']);
    }
  }

  translateAttachmentRequired(attachmentRequired: boolean): string {
    switch (attachmentRequired) {
      case true:
        return 'Sim';
      case false:
        return 'Não';
      default:
        return attachmentRequired;
    }
  }

}
