import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { ProposalApprovalDetailsComponent } from './proposal-approval-details.component';

@NgModule({
    imports: [
        CommonModule,
        InlineSVGModule
    ],
    declarations: [ProposalApprovalDetailsComponent],
    exports: [ProposalApprovalDetailsComponent],
})
export class ProposalApprovalDetailsModule { }
