<app-page-header [page-title]="creationPageTitle()" button-text="Voltar" button-link="/admin/billing-invoices"
  button-class="btn-danger">
</app-page-header>

<div class="container-fluid  mt-4 p-0">

  <div class="row p-0" *ngIf="!isEdit">
    <div class="mt-0 mb-5 col-4">
      <app-select-companies (emitCompany)="emitCompany($event)"></app-select-companies>
    </div>
    <div class="mt-0 mb-5 col-8">
      <app-date-picker [textButton]="'Preparar'" [disabled]="uuidCompnay?.length == 0" [showDate]="false"
        (emitDate)="emitDate($event)"></app-date-picker>
    </div>
  </div>
  <div class="mt-4 content d-flex flex-column flex-column-fluid">
    <div class="card card-shadown" *ngIf="isPreparedInvoice">
      <div class="card-body">
        <div class="d-flex flex-column flex-xl-row">
          <div class="flex-lg-row-fluid">
            <div class="mb-0">
              <div class="fw-bolder fs-1 text-gray-800 mb-8">{{invoiceHeader?.invoiceName}} - {{companyName}}</div>
              <div class="row g-5 mb-11">
                <div class="col-sm-3">
                  <div class="fw-bold fs-3 text-gray-600 mb-1">Vencimento:</div>
                  <div class="fw-bolder fs-4 text-gray-800">{{invoiceHeader?.dueDate | date : "dd/MM/yyyy" }}</div>
                </div>

                <div class="col-sm-3">
                  <div class="fw-bold fs-3 text-gray-600 mb-1">Data de geração:</div>
                  <div class="fw-bolder fs-4 text-gray-800">{{invoiceHeader?.generationDate | date : "dd/MM/yyyy" }}
                  </div>
                </div>

                <div class="col-sm-3">
                  <div class="fw-bold fs-3 text-gray-600 mb-1">Total:</div>
                  <div class="fw-bolder fs-4 text-gray-800"> {{invoiceHeader?.totalValue | currency:"BRL"}} </div>
                </div>

                <div class="col-sm-3">
                  <div class="fw-bold fs-3 text-gray-600 mb-1">Total de contratos:</div>
                  <div class="fw-bolder fs-4 text-gray-800"> {{invoiceHeader?.contractsQuantity}} </div>
                </div>

                <div class="col-sm-3">
                  <div class="fw-bold fs-3 text-gray-600 mb-1">Status:</div>
                  <div class="fw-bolder fs-4 text-gray-800 d-flex align-items-center flex-wrap">
                    <span
                      [class]="'badge fs-4 badge-'+prepareColorStatus(invoiceHeader?.status)+ ' me-2 d-flex align-items-center'">{{prepareNameStatus(invoiceHeader?.status)}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex flex-stack flex-wrap mb-lg-15 button-action">
              <div></div>
              <div class="my-2">
                <a *ngIf="!showDetails" class="btn me-2 btn-sm btn-primary" (click)="showDetailInvoice()">
                  Ver Detalhes
                </a>
                <a *ngIf="showDetails" class="btn me-2 btn-sm btn-danger" (click)="showDetailInvoice()">
                  Fechar detalhes
                </a>
                <a class="btn btn-sm btn-success me-2" *ngIf="isLiquidation() && invoiceHeader.status == 'APPROVED'"
                  (click)="depositedInvoice()">Dar Baixa </a>
                <a class="btn btn-sm btn-secondary me-2" *ngIf="isLiquidation() && invoiceHeader.status == 'PREPARED' "
                  (click)="saveInvoice()">Cobrar fatura</a>

                <a class="btn btn-sm btn-success me-2" *ngIf="isAudit() && invoiceHeader.status == 'GENERATED'"
                  (click)="approvedInvoice()">Aprovar</a>
                <a (click)="deleteInvoice()" class="btn me-2 btn-sm btn-danger"
                  *ngIf="isLiquidation() && invoiceHeader.status == 'PREPARED'">
                  Deletar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card card-shadown" *ngIf="!isPreparedInvoice">
      <div class="card-body d-flex  align-items-center justify-content-center">
        <div class="d-flex  align-items-center">
          <div class="mb-0 text-center fs-3">
            Selecione uma empresa e um mês para gerar uma nova fatura!
          </div>
        </div>
      </div>
    </div>

    <br><br>

    <!-- Tabela para listagem de parcelas da invoice -->
    <div class="content d-flex flex-column flex-column-fluid" *ngIf="showDetails">
      <h2 class="fw-bolder fs-3 text-gray-800 mb-8">Detalhes</h2>
      <div class="card">
        <div class="card-body card-shadown">
          <div class="row mt-4">
            <div class="search col-md-4 mt-0 mb-5">
              <label class="form-label fw-bolder text-dark fs-6 mb-0">Pesquisar</label>
              <input class="form-control bg-white mt-1" placeholder="Nome, documento, status…"
                (keyup)="filterData($event)" autocomplete="off" />
            </div>
          </div>
          <div class="row">
            <table mat-table [dataSource]="dataSource" class=" mb-0 table table-striped table-hover">

              <ng-container matColumnDef="registration">
                <th mat-header-cell *matHeaderCellDef> Matricula </th>
                <td mat-cell *matCellDef="let element"> {{element.registration}} </td>
              </ng-container>

              <ng-container matColumnDef="fullName">
                <th mat-header-cell *matHeaderCellDef> Nome </th>
                <td mat-cell *matCellDef="let element"> {{element.fullname}} </td>
              </ng-container>

              <ng-container matColumnDef="dateInstallment">
                <th mat-header-cell *matHeaderCellDef> Vencimento </th>
                <td mat-cell *matCellDef="let element"> {{element.dateinstallment | date : "dd/MM/yyyy" }} </td>
              </ng-container>

              <ng-container matColumnDef="numberInstallment">
                <th mat-header-cell *matHeaderCellDef> Parcela </th>
                <td mat-cell *matCellDef="let element"> {{element.numberinstallment}}° </td>
              </ng-container>

              <ng-container matColumnDef="installmentValue">
                <th mat-header-cell *matHeaderCellDef> PMT </th>
                <td mat-cell *matCellDef="let element"> {{truncate.truncate(element.installmentvalue,2) }} </td>
              </ng-container>

              <ng-container matColumnDef="insuranceValue">
                <th mat-header-cell *matHeaderCellDef> Seguro </th>
                <td mat-cell *matCellDef="let element"> {{ element.partner === "MONEY_PLUS" ? [ 0 | currency:"BRL"] :
                  [element.insurancevalue | currency:"BRL"]}} </td>
              </ng-container>

              <ng-container matColumnDef="valueDaysIof">
                <th mat-header-cell *matHeaderCellDef> IOF </th>
                <td mat-cell *matCellDef="let element"> {{ element.partner === "MONEY_PLUS" ? [ 0 | currency:"BRL"] :
                  [element.valuedaysiof | currency:"BRL"]}} </td>
              </ng-container>

              <ng-container matColumnDef="totalValue">
                <th mat-header-cell *matHeaderCellDef> Total Folha </th>
                <td mat-cell *matCellDef="let element">
                  {{element.partner === "MONEY_PLUS" ? [truncate.truncate(element.installmentvalue,2)
                  ]:[truncate.truncate(element.totalValue,2) ]}}
                  <!--  {{element.installmentvalue | currency:"BRL"}}  -->

                </td>
              </ng-container>

              <ng-container matColumnDef="installmentStatus">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let element">
                  <span [class]="'p-2 rounded-pill ' + checkStatus(element.installmentstatus).color">{{
                    checkStatus(element.installmentstatus).installmentStatus }} </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="text-center"> Ações </th>
                <td mat-cell *matCellDef="let element" class="text-center">
                  <mat-icon (click)="goToContract(element.uuidcontract)">visibility</mat-icon>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;">
              </tr>
            </table>
            <mat-paginator #paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 20]">
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>