import { NgModule } from '@angular/core';
import { SimulationInstallmentsDetailsComponent } from './simulation-installments-details.component';

@NgModule({
    imports: [
    ],
    declarations: [
        SimulationInstallmentsDetailsComponent
    ],
    exports: [
        SimulationInstallmentsDetailsComponent
    ],
})
export class SimulationInstallmentsDetailsModule { }