import { Routes, RouterModule } from '@angular/router';
import { KitListComponent } from './kit-list/kit-list.component';
import { KitFormComponent } from './kit-form/kit-form.component';

const routes: Routes = [
  {
    path: "kit-list",
    component: KitListComponent
  },
  {
    path: "new-kit",
    component: KitFormComponent
  },
  {
    path: "edit-kit/:uuidKit",
    component: KitFormComponent
  },
];

export const KitsRoutes = RouterModule.forChild(routes);
