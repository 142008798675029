import {Injectable} from "@angular/core";

@Injectable()
export class Base64Service {

    convertFileToBase64(file: File): Promise<string> {
        return new Promise(resolve => {
            const reader = new FileReader();
            // Read file content on file loaded event
            reader.onload = function(event: any) {
                resolve(event.target.result);
            };
            // Convert data to base64
            reader.readAsDataURL(file);
        });
    }

  b64toBlob(base64: string) {
    let block = base64.split(";");
    let contentType = block[0].split(":")[1];
    let b64Data = block[1].split(",")[1];

    contentType = contentType || '';
    let sliceSize = 512;

    let byteCharacters = atob(b64Data);
    let byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      let byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    let blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }
}
