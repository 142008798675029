<!-- BEGIN: COMPONENT HEADER -->
<app-page-header
  page-title="Lista de Faturas Geradas"
  button-text="+ Nova Fatura"
  button-link="/admin/billing-invoices/new"
  button-class="btn-success ml-10"
  [noForm]="isLiquidation()"
  [isBack]="false"
></app-page-header>
<!-- END: COMPONENT HEADER -->

<div class="container-fluid p-3">
  <div class="row d-flex align-items-center justify-content-between">
    <div class="row p-0">
      <!-- BEGIN: COMPONENT SELECT COMPANIES -->
      <div class="mt-0 mb-5 col-4" *ngIf="!isLiquidation()">
        <app-select-companies
          (emitCompany)="emitCompany($event)"
        ></app-select-companies>
      </div>
      <!-- END: COMPONENT SELECT COMPANIES -->

      <!-- BEGIN: COMPONENT DATE PICKER -->
      <div class="mt-0 mb-5 col-8">
        <app-date-picker [defaultDate]='intervalDays' (emitDate)="emitDate($event)"></app-date-picker>
      </div>
      <!-- END: COMPONENT DATE PICKER -->
    </div>

    <!-- BEGIN: COMPONENT CARDS INFOS -->
    <app-cards-infos [cardInfo]="cardInfo"></app-cards-infos>
    <!-- END: COMPONENT CARDS INFOS -->

    <div class="row d-flex align-items-center justify-content-between">
      <!-- BEGIN: SEARCH FILTER -->
      <div class="search col-md-4 mt-0 mb-5">
        <label class="form-label fw-bolder text-dark fs-6 mb-0"
          >Pesquisar</label
        >
        <input
          class="form-control bg-white mt-1"
          placeholder="Nome, documento, status…"
          (keyup)="filterData($event)"
          autocomplete="off"
        />
      </div>
      <!-- END: SEARCH FILTER -->

      <!-- BEGIN: TABLE -->
      <table
        mat-table
        matSort
        [dataSource]="dataSource"
        class="mat-elevation-z1 table table-striped table-hover mb-0"
      >
        <ng-container matColumnDef="id">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>ID Contrato</th>
          <td mat-cell *matCellDef="let element">{{ element.id }}</td>
        </ng-container>

        <ng-container matColumnDef="companie">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Empresa</th>
          <td mat-cell *matCellDef="let element">{{ element.companyName }}</td>
        </ng-container>

        <ng-container matColumnDef="contract">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Contrato</th>
          <td mat-cell *matCellDef="let element">{{ element.invoicename }}</td>
        </ng-container>

        <ng-container matColumnDef="invoice">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>#INVOICE</th>
          <td mat-cell *matCellDef="let element">
            {{ element.contractsquantity }}
          </td>
        </ng-container>

        <ng-container matColumnDef="dateRegister">
          <th mat-header-cell *matHeaderCellDef>Criação</th>
          <td mat-cell *matCellDef="let element">
            {{ element.generationdate | date : "dd/MM/yyyy" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="dueDate">
          <th mat-header-cell *matHeaderCellDef>Vencimento</th>
          <td mat-cell *matCellDef="let element">
            {{ element.duedate | date : "dd/MM/yyyy" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="totalValue">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Capital</th>
          <td mat-cell *matCellDef="let element">
            {{ element.totalvalue | currency : "BRL" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="invoiceStatus">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element">
            <div>
              <span
                [class]="
                  'badge rounded-pill text-center w-100 justify-content-center ' +
                  prepareColorStatus(element.status)
                "
                >{{ prepareNameStatus(element?.status) }}</span
              >
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="sendDate">
          <th mat-header-cell *matHeaderCellDef>Envio</th>
          <td mat-cell *matCellDef="let element">
            {{ element.invoiceSentDate}}
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef class="text-center">Ações</th>
          <td mat-cell *matCellDef="let element" class="text-center">
            <mat-icon class="mx-4" (click)="viewDetails(element)"
              >visibility</mat-icon
            >
            <mat-icon  (click)="download(element.uuid)">download</mat-icon>
          </td>
          
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <!-- END: TABLE -->

      <!-- <h4 class="mat-table-message text-center mt-0 pb-15 font-weight-bold" *ngIf="isEmpty">Nenhum registro
        encontrado</h4> -->
        <mat-paginator class="mat-elevation-z1"  #paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 20]">
        </mat-paginator>
    </div>
  </div>
</div>
