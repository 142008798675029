
<div class="text-center token-modal mt-5">
    <p>No seu App <strong></strong>, clique no menu <br />
        <strong>Token > Ler Token</strong> e escaneie o QrCode acima <br />
        ou digite o token enviado por SMS ou gerado no App.</p> <br />

    <div class="form-group box-token">
        <label for="input-liq-token"><strong>Token de Autorização:</strong></label>
        <input id="input-liq-token" placeholder="Digite o token" class="form-control">
    </div>

    <div class="form-group box-code">
        <label for="input-liq-cod-externo">
            <strong>Código de Identificação (Externo)</strong>
        </label>
        <div class="input-group mb-1">
            <div class="input-group-prepend">
                <div class="input-group-text">
                    <input type="checkbox" name="input-check-liq-cod-externo" id="input-check-liq-cod-externo" aria-label="Checkbox for following text input">
                </div>
            </div>
            <input type="text" id="input-liq-cod-externo" placeholder="Digite o código externo" class="form-control" aria-label="Text input with checkbox"  disabled="disabled">
        </div>
    </div>

<div class="d-flex justify-content-between mt-xxl-5">
    <button type="button" class="btn btn-danger"  (click)="closeModal()" >Cancelar</button>

    <button type="button" class="btn btn-primary" >Confirmar</button>
</div>

</div>
