<div id="simulation-form-component">

    <!--Título-->
    <h1 class="page-title mb-10"> Nova Simulação </h1>

    <!--Formulário Dados Gerais-->
    <section class="card mb-4">
        <div class="d-flex align-items-center justify-content-between">
            <div class="card-header border-0 d-flex align-items-center">
                <span [inlineSVG]="'./assets/media/icons/duotune/communication/com006.svg'"
                    class="svg-icon svg-icon-2tx svg-icon-gray-800 me-4"></span>
                <h3 class="fw-bolder card-title mt-3"> Dados para Simulação </h3>
            </div>
            <div class="toggle-container">
                <p class="toggle-label"> PF </p>
                <mat-slide-toggle class="mx-6" color="primary" [checked]="isPjClient"
                    (change)="changeClientType($event)">
                </mat-slide-toggle>
                <p class="toggle-label"> PJ </p>
            </div>
        </div>
        <div class="card-body border-top p-9" *ngIf="isPjClient; else isPfClient">
            <form>
                <div class="d-flex">
                    <div class="col-3 px-10">
                        <app-ui-c-input [form]="formSimulation" [errorMessages]="errorMessagesSimulation"
                            [formName]="'document'" [name]="'CNPJ:'" [isCNPJ]="true" [placeholder]="'Digite o CNPJ'"
                            (change)="findCustomerByDocument($event)"></app-ui-c-input>
                    </div>
                    <div class="col-3 px-10">
                        <app-ui-c-input [form]="formSimulation" [errorMessages]="errorMessagesSimulation"
                            [formName]="'name'" [name]="'Razão Social:'" [type]="'text'"
                            [placeholder]="'Digite a razão social'"></app-ui-c-input>
                    </div>
                    <div class="col-3 px-10">
                        <app-ui-c-input [form]="formSimulation" [errorMessages]="errorMessagesSimulation"
                            [formName]="'phone'" [name]="'Celular:'" [isPhone]="true"
                            [placeholder]="'Digite o celular'"></app-ui-c-input>
                    </div>
                    <div class="col-3 px-10">
                        <app-ui-c-input [form]="formSimulation" [errorMessages]="errorMessagesSimulation"
                            [formName]="'email'" [name]="'E-mail:'" [type]="'text'"
                            [placeholder]="'Digite o e-mail'"></app-ui-c-input>
                    </div>
                </div>
            </form>
        </div>
    </section>
    <ng-template #isPfClient>
        <div class="card-body border-top p-9">
            <form>
                <div class="d-flex">
                    <div class="col-3 px-10">
                        <app-ui-c-input [form]="formSimulation" [errorMessages]="errorMessagesSimulation"
                            [formName]="'document'" [name]="'CPF:'" [isCPF]="true" [placeholder]="'Digite o CPF'"
                            (change)="findCustomerByDocument($event)"></app-ui-c-input>
                    </div>
                    <div class="col-3 px-10">
                        <app-ui-c-input [form]="formSimulation" [errorMessages]="errorMessagesSimulation"
                            [formName]="'name'" [name]="'Nome:'" [type]="'text'"
                            [placeholder]="'Digite o nome do cliente'"></app-ui-c-input>
                    </div>
                    <div class="col-3 px-10">
                        <app-ui-c-input [form]="formSimulation" [errorMessages]="errorMessagesSimulation"
                            [formName]="'phone'" [name]="'Celular:'" [isPhone]="true"
                            [placeholder]="'Digite o celular'"></app-ui-c-input>
                    </div>
                    <div class="col-3 px-10">
                        <app-ui-c-input [form]="formSimulation" [errorMessages]="errorMessagesSimulation"
                            [formName]="'email'" [name]="'E-mail:'" [type]="'text'"
                            [placeholder]="'Digite o e-mail'"></app-ui-c-input>
                    </div>
                </div>
            </form>
        </div>
    </ng-template>

    <!--Formulário Dados do Empréstimo-->
    <section class="card mb-4">
        <div class="card-header border-0 d-flex align-items-center justify-content-start">
            <span [inlineSVG]="'./assets/media/icons/duotune/finance/fin010.svg'"
                class="svg-icon svg-icon-2tx svg-icon-gray-800 me-4"></span>
            <h3 class="fw-bolder card-title mt-3"> Simulação </h3>
        </div>
        <div class="card-body border-top p-9">
            <form [formGroup]="formSimulation">
                <div class="d-flex">
                    <div class="col-3 px-10">
                        <label class="form-label fs-6 fw-bolder text-dark mt-3"> Tipo de Produto: </label>
                        <select class="form-select form-select-solid form-select-lg text-dark"
                            formControlName="productType" (change)="findProductList($event)">
                            <option *ngFor="let productType of productTypeList" [value]="productType.uuid">{{
                                productType.name }}</option>
                        </select>
                    </div>
                    <div class="col-3 px-10">
                        <label class="form-label fs-6 fw-bolder text-dark mt-3"> Produto: </label>
                        <select class="form-select form-select-solid form-select-lg text-dark" formControlName="product" (ngModelChange)="findProductByUuid($event)">
                            <option *ngFor="let product of productsList" [value]="product.uuid">{{ product.name }}
                            </option>
                        </select>
                    </div>
                    <div class="col-3 px-10">
                        <app-ui-c-input [form]="formSimulation" [errorMessages]="errorMessagesSimulation"
                            [formName]="'minValue'" [name]="'Valor Mínimo:'" [isMoney]="true"
                            [block]="true"></app-ui-c-input>
                    </div>
                    <div class="col-3 px-10">
                        <app-ui-c-input [form]="formSimulation" [errorMessages]="errorMessagesSimulation"
                            [formName]="'maxValue'" [name]="'Valor Máximo:'" [isMoney]="true"
                            [block]="true"></app-ui-c-input>
                    </div>
                </div>
                <div class="d-flex mt-10">
                    <div class="col-3 px-10">
                        <app-ui-c-input [form]="formSimulation" [errorMessages]="errorMessagesSimulation"
                            [formName]="'typeFee'" [name]="'Tarifa:'"
                            [block]="true"></app-ui-c-input>
                    </div>
                    <div class="col-3 px-10">
                        <app-ui-c-input [form]="formSimulation" [errorMessages]="errorMessagesSimulation"
                            [formName]="'valueTac'" [name]="'TAC:'" [isMoney]="isMoneyTac" [isPercent]="isPercentTac"
                            [placeholder]="'Digite a TAC'"></app-ui-c-input>
                    </div>
                    <div class="col-3 px-10">
                        <app-ui-c-input [form]="formSimulation" [errorMessages]="errorMessagesSimulation"
                            [formName]="'interestRate'" [name]="'Taxa de Juros:'" [isPercent]="true"
                            [placeholder]="'Digite a taxa de juros'"></app-ui-c-input>
                    </div>
                    <div class="col-3 px-10">
                        <label class="form-label fs-6 fw-bolder text-dark mt-3"> Quantidade de Parcelas: </label>
                        <select class="form-select form-select-solid form-select-lg text-dark"
                            formControlName="quantityInstallments">
                            <option *ngFor="let installment of quantityInstallments" [value]="installment">{{
                                installment }}</option>
                        </select>
                    </div>
                </div>
                <div class="d-flex mt-10">
                    <div class="col-3 px-10">
                        <app-ui-c-input [form]="formSimulation" [errorMessages]="errorMessagesSimulation"
                            [formName]="'loanAmount'" [name]="'Valor Desejado:'" [isMoney]="true"
                            [placeholder]="'Digite o valor solicitado'"></app-ui-c-input>
                    </div>

                    <div class="col-3 px-10">
                        <app-ui-c-input [form]="formSimulation" [errorMessages]="errorMessagesSimulation"
                            [formName]="'gracePeriod'" [name]="'Período de Carência:'"
                            [placeholder]="'Digite o perído de carência (meses)'"></app-ui-c-input>
                    </div>

                    <div class="col-3 px-10">
                        <label class="form-check form-check-inline form-check-solid mt-15">
                            <input class="form-check-input" name="isIofIncluded" type="checkbox"
                                formControlName="isIofIncluded" />
                            <span class="form-label fs-6 fw-bolder text-dark">IOF Incluso</span>
                        </label>
                    </div>
                </div>
            </form>
        </div>
    </section>

    <!--Botão Salvar-->
    <div class="d-flex justify-content-end my-8">
        <button type="button" class="btn btn-primary btn-default"
        (click)="simulate()" [disabled]="formSimulation.invalid">
            Simular
        </button>
    </div>

    <ng-container *ngIf="hasSimulation">

        <section class="card mb-12">
            <div class="card-body d-flex justify-content-center border-top p-9">
                <div class="d-flex flex-column align-items-center">
                    <strong class="installmentsStrong">
                        {{ sfiCalculatorResponseType.parametros_entrada.num_periodos + '
                        x ' +
                        formatValueToMoney(sfiCalculatorResponseType.parcelas_price[0].prestacao) }}
                    </strong>
                    <strong class="totalAmountStrong"> {{
                        formatValueToMoney(sfiCalculatorResponseType.parametros_saida.valor_total_financiado) }}
                    </strong>
                </div>
            </div>
        </section>

        <mat-accordion>
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title class="fw-bolder card-title">
                        Detalhes da Simulação
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <simulation-installments-details
                    [sfiCalculatorResponseType]="sfiCalculatorResponseType"></simulation-installments-details>
            </mat-expansion-panel>
        </mat-accordion>

        <div class="d-flex justify-content-end my-8" id="bottom">
            <button type="button" class="btn btn-success btn-send-simulation" (click)="pickSimulation()">
                Enviar Simulado
            </button>
        </div>
    </ng-container>
</div>