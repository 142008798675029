
import { UserInfoService } from 'src/app/core/_base/_auth/user-info.service';
import { TablesimulatorComponent } from './../tablesimulator/tablesimulator.component';
import { Component, ViewChild, ElementRef, ChangeDetectorRef, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

import { ProposalServices } from '../services-apis/proposals-services';
import { SpeNOpenAccountProxy } from 'src/app/core/proxy/spe/spe-n-open-account.proxy';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from 'src/app/shared/services-ultil/storage/storage.service';
import { take } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { Platform } from '@angular/cdk/platform';
import { ProposalStatusEnum } from '../enums/proposal-status-enum';
import { MoneyPlusProposalStatusEnum } from '../enums/mp-proposal-status-enum';
import { EmployeeServices } from '../../../admin/employees/services-apis/employees-services';
import { FormControl, FormGroup } from '@angular/forms';
import { Employee } from '../../../admin/employees/services-apis/employees-model';

@Component({
  selector: 'app-proposals-forms',
  templateUrl: './proposals-forms.component.html',
  styleUrls: ['./proposals-forms.component.scss']
})

export class ProposalsFormsComponent implements OnInit {
  hide = true;
  cap: number = 0;

  @ViewChild(TablesimulatorComponent, { static: false })
  tabela!: TablesimulatorComponent;
  dataSourceSimulador: any[] = [];
  public columnsToDisplayInstallment = ['dateInstallment', 'installmentsNumber', 'interestInstallment', 'amortInstallment', 'installmentValue', 'amountDue', 'daysIofIndex', 'insuranceValue', 'total'];
  @ViewChild("emprestimo", { static: true }) emprestimo!: ElementRef;

  public errorMessagesProposal: any;
  public resourceForm: any;
  public resource: any;

  public canApproveProposal: boolean = true;

  public uuidProposal: string;
  public isView: any = false;
  public loading: boolean;
  public listUf: { name: string; value: string; }[];
  public insurance: boolean = true;
  public isManegment: boolean = false;
  public isAudit: boolean = false;

  public status: string = "";
  public uuidLogedUser: string | undefined;

  public installmentsDetails: any = {};
  proposal: any;
  userProfile: any;
  location = {
    coords: {
      "latitude": 0,
      "longitude": 0
    }
  };
  price: any;
  textAreaValue: string = "";
  historyApprovation: any;

  public formCustomer: FormGroup;
  public errorMessagesEmployees: any = [];

  constructor(protected proposalService: ProposalServices,
    private cdr: ChangeDetectorRef,
    private speNOpenAccountProxy: SpeNOpenAccountProxy,
    protected activeRouter: ActivatedRoute,
    private storageService: StorageService,
    private spinner: NgxSpinnerService,
    private userInfoService: UserInfoService,
    private router: Router,
    private platform: Platform,
    protected employeeServices: EmployeeServices,

  ) { }

  async ngOnInit() {
    this.listUf = this.speNOpenAccountProxy.getListUf()
    this.isView = this.storageService.getData("isView");
    this.getGeolocation();

    this.activeRouter.paramMap.pipe(take(1))
      .subscribe((res: any) => {
        this.uuidProposal = res.params['id'];
        if (this.uuidProposal) {

          this.spinner.show();
          this.proposalService.getProposal(this.uuidProposal).subscribe(proposal => {
            this.proposal = proposal;
            this.cdr.detectChanges()
            this.dataSourceSimulador = proposal.installments;
            this.sumValuesTableInstallment();
            this.price = proposal.price;
            this.proposalService.sycForm(proposal);
            this.setInsurance(true);
            this.installmentsDetails = proposal.loan;
            this.spinner.hide();

            this.createEmployeeUpdateDataDisconts();

            if (this.proposal?.proposalPartner?.partner === "MONEY_PLUS") {
              this.columnsToDisplayInstallment = ['dateInstallment', 'installmentsNumber', 'interestInstallment', 'amortInstallment', 'installmentValue', 'amountDue'];
            }

            this.proposalService.getHistoryApprovation(proposal.uuid)
              .subscribe(result => {
                this.historyApprovation = result;
                this.cdr.detectChanges();
                this.canApproveProposal = true;
                for (let h of this.historyApprovation) {
                  if (h.status == "MANAGER_REPROVED" || h.status == "AUDIT_REPROVED" || h.status == 'EXPIRED') {
                    this.canApproveProposal = false;
                    this.cdr.detectChanges();
                  }
                }
              })
          })
        }
      });

    this.isAudit = this.userInfoService.isUserInRole('bo-consigned-audit');
    if (!this.isAudit) {
      this.isManegment = true;
    }

    /* this.isAudit =  */
    this.userInfoService.getInfoProfile().then(
      res => {
        this.uuidLogedUser = res?.id;
        this.userProfile = res;
        return res;
      }
    );

    this.buildResourceForm();
  }

  createEmployeeUpdateDataDisconts() {
    this.spinner.show();
    this.employeeServices.getEmployeesBraspress(this.proposal.customer.identityGov).subscribe(employee => {
      if (employee.code == '404') {
        this.spinner.hide();
        Swal.fire({
          title: 'Atenção!',
          text: employee.description,
          icon: 'warning',
          confirmButtonText: "OK"
        })
      } else {
        this.formCustomer = this.employeeServices.createFormEmployee();
        this.formCustomer.addControl('installmentLiquid', new FormControl(''));
        this.employeeServices.sycFormBrasspress(employee)
        this.cdr.detectChanges()
        this.formCustomer.controls.installmentLiquid.setValue(this.calcDisconutsLiquid());
        this.spinner.hide();
      }
    }, error => {
      this.spinner.hide();
    })
  }

  calcDisconutsLiquid() {
    let dataCustomer = this.formCustomer.getRawValue();
    const {
      alimony,
      courtCharges,
      inss,
      incomeTax,
      unionsdues,
      healthPlan,
      dentalPlan,
      lifeInsurance,
      otherDiscounts,
      installmentAmount,
      fineInstallment,
      collisionInstallment,
      responsibilityPortion,
      installmentProducts,
      otherInstallment,
      salary
    } = dataCustomer;

    let liquidSalary = (
      this.converterToNumber(salary) -
      this.converterToNumber(alimony) -
      this.converterToNumber(courtCharges) -
      this.converterToNumber(inss) -
      this.converterToNumber(incomeTax) -
      this.converterToNumber(unionsdues) -
      this.converterToNumber(healthPlan) -
      this.converterToNumber(dentalPlan) -
      this.converterToNumber(lifeInsurance)
    );

    const thirtyPercentOfSalary = (liquidSalary * 30) / 100;
    return (
      thirtyPercentOfSalary -
      this.converterToNumber(installmentAmount) -
      this.converterToNumber(fineInstallment) -
      this.converterToNumber(collisionInstallment) -
      this.converterToNumber(responsibilityPortion) -
      this.converterToNumber(installmentProducts) -
      this.converterToNumber(otherInstallment)
    );
  }

  converterToNumber(element: any) {
    if (!!element) return Number.parseFloat(element)
    return 0;
  }

  aproveProposal(isApprovation?: boolean) {
    if (this.isManager() || true) {
      alert('aaaaaa')
      if (isApprovation) {
        Swal.fire({
          title: "Atenção!",
          text: "Tem certeza que o colaborador será demitido nos próximos 30 dias?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar"
        }).then((result) => {
          if (result.isConfirmed) {
            this.status = "MANAGER_REPROVED";
            this.aproveProposalSend();
          }
        });
      } else {
        Swal.fire({
          title: "Atenção!",
          text: "Tem certeza que o colaborador NÃO será demitido nos próximos 30 dias?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar"
        }).then((result) => {
          if (result.isConfirmed) {
            this.status = "MANAGER_APPROVED";
            this.aproveProposalSend();
          }
        });

      }
    } else {
      this.aproveProposalSend();
    }
  }

  aproveProposalSend() {
    this.spinner.show();
    if (this.uuidLogedUser) {
      this.proposalService.aproveProposal(
        this.uuidProposal,
        this.uuidLogedUser,
        this.status,
        this.userProfile?.username,
        this.userProfile?.email,
        this.location.coords.latitude.toString(),
        this.location.coords.longitude.toString(),
        navigator.platform,
        this.userProfile.email,
        this.textAreaValue,
      )
        .pipe(take(1)).subscribe(res => {
          if (res) {
            this.spinner.hide();
            Swal.fire({
              title: "Sucesso!",
              text: "Status da proposta alterado com sucesso!",
              icon: "success",
              cancelButtonText: "Fechar",
              backdrop: false
            }).then(() => {
              this.router.navigate(['operations/proposals'])
            })
          } else {
            this.spinner.hide();
            Swal.fire({
              title: "Atenção!",
              text: "Erro ao alterado o status da prospota!",
              icon: "warning",
              cancelButtonText: "Fechar",
            })
          }
        }, error => {
          this.spinner.hide();
          Swal.fire({
            title: "Atenção!",
            text: "Proposta já aprovada!",
            icon: "warning",
            cancelButtonText: "Fechar",
          })
        })
    } else {
      this.spinner.hide();
      Swal.fire({
        title: "Atenção!",
        text: "Erro ao alterado o status da prospota!",
        icon: "warning",
        cancelButtonText: "Fechar",
      })
    }
  }

  ngAfterViewInit(): void {

  }

  private setInsurance(insurance: boolean) {
    this.insurance = insurance;
  }

  protected buildResourceForm() {
    this.errorMessagesProposal = this.proposalService.getFormErrors();
    this.resourceForm = this.proposalService.createFormProposal();

  }


  protected creationPageTitle(): string {
    return "Detalhes da Proposta";
  }

  protected editionPageTitle(): string {
    const companyName = this.resource.id || "";
    return "Editando Proposta: " + companyName;
  }

  async getGeolocation() {
    window.navigator.geolocation.getCurrentPosition(
      (location: any) => {
        this.location.coords.latitude = location.coords.latitude;
        this.location.coords.longitude = location.coords.longitude;
      }, error => {
        Swal.fire({
          title: "Atenção!",
          text: "Por favor, habilite sua localização para realizar essa operação!",
          icon: 'warning',
        });
        this.location.coords.latitude = 0;
        this.location.coords.longitude = 0;
      })
  }

  preprarePaper(status: string) {
    if (status.includes('MANAGER')) {
      return 'Gestor';
    }

    if (status.includes('AUDIT')) {
      return 'Auditor';
    }

    if (status.includes('REQUEST') || status.includes('SIGNEDCCB')) {
      return 'Cliente';
    }

    if (status.includes('SPECIAL_FINANCE_APROVED'))
      return 'Financeiro'

    if (status.includes('MASTER'))
      return 'Master'

    if (status.includes('CONTRACT_WORK_SIGNATURE'))
      return 'Auditor'

    let arrayStatusMoney = ['PAID', 'APPROVED', 'CANCELED', 'FINISHED', 'RELEASED_FOR_PAYMENT', 'PAID', 'CEDED', 'PAYMENT_PENDING']
    if (arrayStatusMoney.indexOf(status) > -1)
      return 'Money Plus'
  }

  prepareStatus(status: ProposalStatusEnum) {
    switch (status) {
      case ProposalStatusEnum.AUDIT_ANALYSIS: return 'Análise de auditoria'
      case ProposalStatusEnum.REQUESTED: return 'Solicitado'
      case ProposalStatusEnum.AUDIT_APPROVED: return 'Aprovado auditoria'
      case ProposalStatusEnum.AUDIT_REPROVED: return 'Reprovado auditoria'
      case ProposalStatusEnum.MANAGER_IN_ANALYSIS: return 'Aguardando Aprovação - Gestor'
      case ProposalStatusEnum.MANAGER_APPROVED: return 'Aprovado Gestor'
      case ProposalStatusEnum.MANAGER_REPROVED: return 'Reprovado Gestor'
      case ProposalStatusEnum.CONTRACT_CUSTOMER_SIGNATURE: return 'Assinado'
      case ProposalStatusEnum.CONTRACT_WORK_SIGNATURE: return 'Aprovado'
      case ProposalStatusEnum.SIGNEDCCB: return 'Assinado - Aguardando deposito'
      case ProposalStatusEnum.PAID: return 'Depositado'
      case ProposalStatusEnum.APPROVED: return 'Proposta foi aprovada pela área de análise de crédito'
      case ProposalStatusEnum.CANCELED: return 'Proposta foi cancelada pela área de análise de crédito'
      case ProposalStatusEnum.FINISHED: return 'Proposta foi finalizada'
      case ProposalStatusEnum.RELEASED_FOR_PAYMENT: return 'Proposta foi liberada pela área de formalização para pagamento'
      case ProposalStatusEnum.PAID: return 'Proposta foi paga pela área financeira'
      case ProposalStatusEnum.CEDED: return 'Proposta foi cedida ao fundo'
      case ProposalStatusEnum.PAYMENT_PENDING: return 'Inconsistência   em   dados   bancários'
      default:
        return 'Análise';
    }
  }

  sumValuesTableInstallment() {
    let interestInstallment: number = 0;
    let amortInstallment: number = 0;
    let installmentValue: number = 0;
    let valueDaysIof: number = 0;
    let insuranceValue: number = 0;
    let total: number = 0;

    this.proposal.installments.map((el: any) => {
      interestInstallment += Number(el.interestInstallment);
      amortInstallment += Number(el.amortInstallment);
      installmentValue += Number(el.installmentValue);
      valueDaysIof += Number(el.valueDaysIof);
      insuranceValue += Number(el.insuranceValue);
    });

    total += installmentValue;
    total += valueDaysIof;
    total += insuranceValue;

    this.dataSourceSimulador = [...this.dataSourceSimulador, {
      interestInstallment,
      amortInstallment,
      installmentValue,
      valueDaysIof,
      insuranceValue,
      total,
    }]
  }

  sumElemets(installmentValue: string, daysIofIndex: string, insuranceValue: string) {
    return Number(installmentValue) + Number(daysIofIndex) + Number(insuranceValue);
  }

  isLiquidation() {
    return this.userInfoService.isLiquidation();
  }

  isMaster() {
    return this.userInfoService.isFinancial();
  }

  isManager() {
    return this.userInfoService.isManager();
  }
}



