import { Routes, RouterModule } from '@angular/router';
import { ContractListComponent } from './contract-list/contract-list.component';
import { ContractFormComponent } from './contract-form/contract-form.component';

const routes: Routes = [
  {
    path: "contract-list",
    component: ContractListComponent
  },
  {
    path: "contract-form",
    component: ContractFormComponent
  },
];

export const ContractsRoutes = RouterModule.forChild(routes);
