import { StatementDto } from "./funding.dto";

export const STATEMENT_MOCK: StatementDto[] =
    [
        {
            uuid: "1",
            dateRegister: "2024-02-01T02:50:13.000Z",
            description: "SALDO",
            reference: "SALDO",
            credit: 0,
            debit: 0,
            balance: 1000
        },
        {
            uuid: "2",
            dateRegister: "2024-01-31T13:50:11.000Z",
            description: "Transferência",
            reference: "Crédito em Conta",
            credit: 1000,
            debit: 0,
            balance: 0
        },
        {
            uuid: "3",
            dateRegister: "2024-01-30T14:41:10.000Z",
            description: "SALDO",
            reference: "SALDO",
            credit: 0,
            debit: 0,
            balance: 0
        }
    ]