import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { UiCInputModule } from '../ui-c-input/ui-c-input.module';
import { InstallmentsTableComponent } from './installments-table.component';
import { MatTableModule } from '@angular/material/table';
import { UiCTableModule } from '../ui-c-table/ui-c-table.module';

@NgModule({
    imports: [
        CommonModule,
        InlineSVGModule,
        UiCInputModule,
        MatTableModule,
        UiCTableModule
    ],
    declarations: [InstallmentsTableComponent],
    exports: [InstallmentsTableComponent],
})
export class InstallmentsTableModule { }