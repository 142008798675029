<div id="kit-list-component">

    <!--Título-->
    <h1 class="page-title mb-10"> Lista de Kits </h1>

    <!--Botão-->
    <div id="add-kit-btn-container">
        <button type="button" class="btn btn-success px-10 mb-4" id="add-kit-btn" (click)="redirectToNewOrEditKit()">
            Criar Kit
        </button>
    </div>

    <!--Tabela-->
    <ng-container *ngIf="tableKit">
        <ui-c-table [tableData]="tableKit"></ui-c-table>
    </ng-container>

</div>
