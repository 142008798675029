import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/_base/_auth/api.service';
import { SpeViewNScfiProxy } from 'src/app/core/proxy/view/spe-view-n-scfi.proxy';
import { Observable } from 'rxjs';
import { CepDetailsDto } from './cep-details.dto';

@Injectable({
    providedIn: 'root'
})
export class CepService {

    constructor(
        protected api: ApiService,
        private speViewNScfiProxy: SpeViewNScfiProxy,
    ) { }

    public getAddressByCep(cep: string): Observable<CepDetailsDto> {
        return this.speViewNScfiProxy.getAddressByCep(cep);
    }
}