<div id="funding-list-component">
  <h1 class="page-title mb-4">Fundos</h1>

  <div class="d-flex justify-content-end mb-4">
    <button type="button" class="btn btn-success" (click)="goToCreateOrEditFunding()">
      Criar Fundo
    </button>
  </div>

  <ng-container *ngIf="tableData">
    <ui-c-table [tableData]="tableData"></ui-c-table>
  </ng-container>
</div>