import { Routes, RouterModule } from '@angular/router';
import { FeeListComponent } from './fee-list/fee-list.component';
import { FeeFormComponent } from './fee-form/fee-form.component';

const routes: Routes = [
  {
    path: "fees-list",
    component: FeeListComponent
  },
  {
    path: "create",
    component: FeeFormComponent
  },
  {
    path: "edit/:uuid",
    component: FeeFormComponent
  },
];

export const FeesRoutes = RouterModule.forChild(routes);
