import {NgModule} from '@angular/core';
import { AdminModule } from './admin/admin.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { FgtsContractsLoansModule } from './fgts/fgts-contracts/fgts-contracts-loans.module';
import { OperationsModule } from './operations/operations.module';

@NgModule({
  declarations: [],
  imports: [
    AdminModule,
    DashboardModule,
    FgtsContractsLoansModule,
    OperationsModule
    
  ],
})
export class ConsignedModule { }