import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductsRoutes } from './products.routing';
import { TypeProductFormComponent } from './product-type-form/product-type-form.component';
import { ProductTypeListComponent } from './product-type-list/product-type-list.component';
import { ProductFormComponent } from './product-form/product-form.component';
import { UiCTableModule } from "src/app/shared/components/ui-c-table/ui-c-table.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UiCInputModule } from "src/app/shared/components/ui-c-input/ui-c-input.module";
import { InlineSVGModule } from "ng-inline-svg-2";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatTableModule } from "@angular/material/table";
import { MatRadioModule } from "@angular/material/radio";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";

@NgModule({
    imports: [
        CommonModule,
        ProductsRoutes,
        UiCTableModule,
        FormsModule,
        UiCInputModule,
        InlineSVGModule,
        MatCheckboxModule,
        MatExpansionModule,
        MatTableModule,
        ReactiveFormsModule,
        MatRadioModule,
        MatOptionModule,
        MatSelectModule
    ],
  declarations: [TypeProductFormComponent, ProductTypeListComponent, ProductFormComponent]
})
export class ProductsModule { }
