import { ContractsService } from './../services-apis/contracts-service';
import { ChangeDetectorRef, Component, ElementRef, Injector, ViewChild } from '@angular/core';
import { Validators } from "@angular/forms";
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { take } from 'rxjs';
import { SpeNOpenAccountProxy } from 'src/app/core/proxy/spe/spe-n-open-account.proxy';
import { UserInfoService } from 'src/app/core/_base/_auth/user-info.service';
import { StorageService } from 'src/app/shared/services-ultil/storage/storage.service';
import Swal from 'sweetalert2';

import { ProposalServices } from '../../proposals/services-apis/proposals-services';
import { TablesimulatorComponent } from '../../proposals/tablesimulator/tablesimulator.component';
import { TruncateService } from 'src/app/shared/services-ultil/truncate-service/truncate.service';

/**
 * @title Basic use of `<table mat-table>`
 */


@Component({
  selector: 'app-contracts-loans-forms',
  templateUrl: './contracts-loans-forms.component.html',
  styleUrls: ['./contracts-loans-forms.component.scss']
})
export class ContractsLoansFormsComponent {

  public goBackToInvoice = false;
  public uuidInvoice = null
  public uuidCompany = null;
  public urlInvoice = '';

  hide = true;
  cap: number = 0;
  dataSourceSimulador: any[] = [];

  @ViewChild(TablesimulatorComponent, { static: false })
  tabela!: TablesimulatorComponent;
  @ViewChild("emprestimo", { static: true }) emprestimo!: ElementRef;

  public errorMessagesProposal: any;
  public resourceForm: any;
  public resource: any;

  public uuidProposal: string;
  public isView: any = false;
  public loading: boolean;
  public listUf: { name: string; value: string; }[];
  public insurance: boolean = true;
  public isManegment: boolean = false;
  public isAudit: boolean = false;

  public status: string = "";
  public uuidLogedUser: string | undefined;
  public proposalPartner: string;

  public installmentsDetails: any = {};

  public columnsToDisplayInstallment = ['dateInstallment', 'installmentsNumber', 'interestInstallment', 'amortInstallment', 'installmentValue', 'amountDue', 'daysIofIndex', 'insuranceValue', 'total', 'installmentStatus'];
  proposal: any;
  historyApprovation: any;
  email: string = "";

  constructor(protected proposalService: ProposalServices,
    private cdr: ChangeDetectorRef,
    private speNOpenAccountProxy: SpeNOpenAccountProxy,
    protected activeRouter: ActivatedRoute,
    private storageService: StorageService,
    private spinner: NgxSpinnerService,
    private userInfoService: UserInfoService,
    private contractService: ContractsService,
    protected truncateService:TruncateService
  ) {
    if (this.activeRouter.snapshot.queryParams['goBackToInvoice']) {
      this.goBackToInvoice = true;
      this.uuidInvoice = this.activeRouter.snapshot.queryParams['uuidInvoice'];
      this.uuidCompany = this.activeRouter.snapshot.queryParams['uuidCompany'];
      this.urlInvoice = `/admin/billing-invoices/${this.uuidInvoice}/company/${this.uuidCompany}/edit`;
    }
  }


  ngOnInit(): void {
    this.listUf = this.speNOpenAccountProxy.getListUf()
    this.isView = this.storageService.getData("isView");

    this.activeRouter.paramMap.pipe(take(1))
      .subscribe((res: any) => {
        // console.log(res)
        this.uuidProposal = res.params['id'];
        if (this.uuidProposal) {
          this.spinner.show();
          this.contractService.getContractUiid(this.uuidProposal).subscribe(proposal => {
            this.proposal = proposal;
            this.proposalService.sycForm(proposal);
            this.setInsurance(true);
            this.dataSourceSimulador = proposal.installments;

            if (this.proposal?.proposalPartner?.partner === "MONEY_PLUS") {
              this.columnsToDisplayInstallment = ['dateInstallment', 'installmentsNumber', 'interestInstallment', 'amortInstallment', 'installmentValue', 'amountDue', 'installmentStatus'];
            }


            this.sumValuesTableInstallment();
            this.installmentsDetails = proposal.loan;
            this.spinner.hide();

            this.proposalService.getHistoryApprovation(proposal.uuidProposal)
              .subscribe(result => {
                this.historyApprovation = result;
                let isContractSendBraspress: boolean = false;
                let isPaid: boolean = false;

                this.historyApprovation.map((el: any) => {
                  if (el.status === "CONTRACT_SEND") isContractSendBraspress = true;
                  if (el.status === "PAID") isPaid = true;
                });

                if (isPaid && !isContractSendBraspress) {
                  Swal.fire({
                    title: "Atenção!",
                    text: "Contrato não enviado para Braspress",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Reenviar contrato",
                    cancelButtonText: "Fechar"
                  }).then((result) => {
                    if (result.isConfirmed) {
                      this.contractService.retrySendContract(this.uuidProposal)
                        .subscribe((res:any) => {
                          if (res.status) {
                            Swal.fire({
                              title: "Sucesso!",
                              text: "Contrato enviado com sucesso!",
                              icon: "success",
                              /* showCancelButton: true, */
                              confirmButtonColor: "#3085d6",
                              /* cancelButtonText: "Fechar" */
                            })
                          } else {
                            Swal.fire({
                              title: "Atenção!",
                              text: "Contrato não enviado! Tente novamente mais tarde!",
                              icon: "warning",
                              /* showCancelButton: true,    */
                              /* cancelButtonText: "Fechar" */
                            })
                          }
                        })
                    }
                  });

                }

                this.cdr.detectChanges();
              })
          })
        }
      });

    this.isAudit = this.userInfoService.isUserInRole('bo-consigned-audit');
    if (!this.isAudit) {
      this.isManegment = true;
    }
    /* this.isAudit =  */
    this.userInfoService.getInfoProfile().then(
      (res: any) => {
        this.uuidLogedUser = res?.id;
        this.email = res.email;
        return res;
      }
    )

    this.buildResourceForm();
  }


  isAttendant() {
    return this.userInfoService.isAttendant()
  }

  aproveProposal() {
    this.spinner.show();
    if (this.uuidLogedUser) {
      this.proposalService.aproveProposal(this.uuidProposal, this.uuidLogedUser, this.status, '', '', '', '', '')
        .pipe(take(1)).subscribe(res => {
          if (res) {
            this.spinner.hide();
            Swal.fire({
              title: "Sucesso!",
              text: "Status da proposta alterado com sucesso!",
              icon: "success",
              cancelButtonText: "Fechar",
            })
          } else {
            this.spinner.hide();
            Swal.fire({
              title: "Atenção!",
              text: "Erro ao alterado o status da prospota!",
              icon: "warning",
              cancelButtonText: "Fechar",
            })
          }
        })
    } else {
      this.spinner.hide();
      Swal.fire({
        title: "Atenção!",
        text: "Erro ao alterado o status da prospota!",
        icon: "warning",
        cancelButtonText: "Fechar",
      })
    }
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

  }

  private setInsurance(insurance: boolean) {
    this.insurance = insurance;
  }

  protected buildResourceForm() {
    this.errorMessagesProposal = this.proposalService.getFormErrors();
    this.resourceForm = this.proposalService.createFormProposal();
    /* console.log(this.resourceForm) */
  }



  calcular() {
    if (this.resourceForm) {
      const parcelas = [12, 18, 24, 30, 36, 42, 48];

      const C: number = parseFloat('12');
      for (var parc in parcelas) {

        //alert(parcelas[parc]);
        this.createTableOptionPai(0.0475, parcelas[parc], C, 425.00, "TAC 393,00 i=4,75 a.m");

      }
    }
  }
  createTableOptionPai(i: number, n: number, c: number, seg: number, detail: string) {

    let parc: number = (c / ((1 - ((1 + i) ** (n * -1))) / i));
    // alert(n + "x PAI" + "R$" + parc.toPrecision(5));
    this.dataSourceSimulador.push({ prazo: n.toString() + "X", valorparcela: "R$ " + parc.toPrecision(5).toString(), valoremprestimo: "R$ " + c.toPrecision(5).toString(), seguro: "R$ " + seg.toPrecision(5).toString(), detalhes: detail });

  }
  protected creationPageTitle(): string {
    return "Detalhes da Proposta";
  }

  protected editionPageTitle(): string {
    const companyName = this.resource.id || "";
    return "Editando Proposta: " + companyName;
  }

  emitInstallmentNumber(event: any) {
  }

  checkStatus(status: any) {
    switch (status) {
      case "INSTALLMENT_ON_DAY":
        return {
          installmentStatus: "Em Aberto",
          color: "text-bg-warning text_color_black"
        }

      case "INSTALLMENT_PAID":
        return {
          installmentStatus: "Paga",
          color: "text-bg-success text_color_black"
        }

      case "INSTALLMENT_LATE":
        return {
          installmentStatus: "Atrasada",
          color: "text-bg-danger text_color_white"
        }

      case "INSTALLMENT_AWAY":
        return {
          installmentStatus: "Afastado",
          color: "text-bg-success text_color_black"
        }

    }
  }


  paidContract() {
    this.contractService.paidContracg(this.uuidProposal, this.email).subscribe(res => {
      Swal.fire({
        title: "Sucesso!",
        text: "Ação realizado com sucesso!",
        icon: "success",
        cancelButtonText: "Fechar",
      }).then(() => {
        document.location.reload();
      })

    })
  }

  sumElemets(installmentValue: string, daysIofIndex: string, insuranceValue: string) {
    return Number(installmentValue) + Number(daysIofIndex) + Number(insuranceValue);
  }

  sumValuesTableInstallment() {
    let interestInstallment: number = 0;
    let amortInstallment: number = 0;
    let installmentValue: number = 0;
    let valueDaysIof: number = 0;
    let insuranceValue: number = 0;
    let total: number = 0;

    this.proposal.installments.map((el: any) => {
      interestInstallment += Number(el.interestInstallment);
      amortInstallment += Number(el.amortInstallment);
      installmentValue += Number(el.installmentValue);
      valueDaysIof += Number(el.valueDaysIof);
      insuranceValue += Number(el.insuranceValue);
    });

    total += installmentValue;
    total += valueDaysIof;
    total += insuranceValue;

    this.dataSourceSimulador = [...this.dataSourceSimulador, {
      interestInstallment,
      amortInstallment,
      installmentValue,
      valueDaysIof,
      insuranceValue,
      total,
    }]
  }


  preprarePaper(status: string) {
    if (status.includes('MANAGER')) {
      return 'Gestor';
    }

    if (status.includes('AUDIT')) {
      return 'Auditor';
    }

    if (status.includes('REQUEST') || status.includes('SIGNEDCCB')) {
      return 'Cliente';
    }

    if (status.includes('SPECIAL_FINANCE_APROVED'))
      return 'Financeiro'

    if (status.includes('MASTER'))
      return 'Master'

    if (status.includes('CONTRACT_WORK_SIGNATURE'))
      return 'Auditor'

    let arrayStatusMoney = ['PAID', 'APPROVED', 'CANCELED', 'FINISHED', 'RELEASED_FOR_PAYMENT', 'PAID', 'CEDED', 'PAYMENT_PENDING']
    if (arrayStatusMoney.indexOf(status) > -1)
      return 'Money Plus'
  }

  prepareStatus(status: string, description?: string) {
    /*  console.log(status) */

    if (!!description) return description;

    switch (status) {
      case 'AUDIT_ANALYSIS': return 'Análise de auditoria'
      case 'REQUESTED': return 'Solicitado'
      case 'AUDIT_APPROVED': return 'Aprovado auditoria'
      case 'AUDIT_REPROVED': return 'Reprovado auditoria'
      case 'MANAGER_IN_ANALYSIS': return 'Aguardando Aprovação - Gestor'
      case 'MANAGER_APPROVED': return 'Aprovado Gestor'
      case 'MANAGER_REPROVED': return 'Reprovado Gestor'
      case 'CONTRACT_CUSTOMER_SIGNATURE': return 'Assinado'
      case 'CONTRACT_WORK_SIGNATURE': return 'Aprovado'
      case 'SIGNEDCCB': return 'Assinado - Aguardando deposito'
      case "CONTRACT_SEND": return "Enviado para Braspress"
      /* case 'PAID': return 'Depositado' */
      case 'APPROVED': return 'Proposta foi aprovada pela área de análise de crédito'
      case 'CANCELED': return 'Proposta foi cancelada pela área de análise de crédito'
      case 'FINISHED': return 'Proposta foi finalizada'
      case 'RELEASED_FOR_PAYMENT': return 'Proposta foi liberada pela área de formalização para pagamento'
      case 'PAID': return 'Proposta foi paga pela área financeira'
      case 'CEDED': return 'Proposta foi cedida ao fundo'
      case 'PAYMENT_PENDING': return 'Inconsistência   em   dados   bancários'

      /* Status Braspress */
      case 'CONTRACT_SEND': return 'Contrato enviado'
      case 'CONTRACT_DUPLICATE': return 'Contrato duplicado'
      case 'DOCUMENT_NOT_FOUND': return 'Documento não encontrato'
      case 'RETROACTIVE_DATE': return 'Data retroativa'
      case 'OUT_OF_SCOPE': return 'Fora da escopo'
      case 'INSTALLMENT_DIFFERENT_CONTRACT': return 'Parcelas divergentes com o contrato'
      case 'COLLABORATOR_NOT_FOUNT': return 'Colaborador não encontrado'
      default:
        return 'Análise';
    }
  }
 

}
