import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ProposalModel} from 'src/app/core/scfi/services/services-proposal/proposal.dto';
import {ProposalService} from 'src/app/core/scfi/services/services-proposal/proposal.service';
import {TableData} from 'src/app/core/scfi/services/services-table/table.dto';
import {AbstractScfi} from '../../abstract-scfi';
import {CurrencyPipe, DatePipe} from '@angular/common';
import {MaskService} from 'src/app/shared/services-ultil/masker-service/masker.service';
import {Router} from '@angular/router';
import { ProposalStatusRoleEnum } from 'src/app/core/scfi/services/services-proposal/enum/proposal-status-role.enum';
import {KeycloakService} from "keycloak-angular";
import { UserInfoService } from 'src/app/core/_base/_auth/user-info.service';
import { RoleTypeEnum } from 'src/app/core/scfi/services/services-proposal/enum/role-type.enum';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-proposal-list',
    templateUrl: './proposal-list.component.html',
    styleUrls: ['./proposal-list.component.scss']
})
export class ProposalListComponent extends AbstractScfi implements OnInit {
    public tableData: TableData;
    proposalList: ProposalModel[];
    public status: ProposalStatusRoleEnum;
    public userRole: string[];

    constructor(
        private proposalService: ProposalService,
        private keycloakService: KeycloakService,
        private userInfoService: UserInfoService,
        protected currencyPipe: CurrencyPipe,
        protected datePipe: DatePipe,
        private maskService: MaskService,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private spinner: NgxSpinnerService
    ) {
        super(currencyPipe, datePipe);
    }

    ngOnInit() {
        this.findProposalList();
    }


    getProposalStatusAllowedByRole(role: RoleTypeEnum): ProposalStatusRoleEnum | undefined {

        switch (role) {
            case  RoleTypeEnum.GUARANTOR :
                return ProposalStatusRoleEnum.GUARANTOR;

            case RoleTypeEnum.TREASURY:
                return ProposalStatusRoleEnum.TREASURY;

            case RoleTypeEnum.COMMITTEE:
                return ProposalStatusRoleEnum.COMMITTE
            default:
                return undefined;
        }

    }

    public findProposalList() {

        const userRole = this.userInfoService.getRoleOperation();

        if (!userRole) {
            this.showErrorModal('Não foi possível buscar as permissões do seu usuário');
            return;
        }

        const proposalStatus = this.getProposalStatusAllowedByRole(userRole);

        if (!proposalStatus) {
            this.showErrorModal('Não foi possível acessar as propostas');
            return;
        }

        this.spinner.show();
        this.proposalService.findProposalByStatus(proposalStatus).subscribe({
            next: (proposalList: ProposalModel[]) => {
                this.spinner.hide();
                this.proposalList = proposalList;
                this.tableData = this.generateTableData();
                this.cdr.detectChanges();
            },
            error: (error) => {
                this.spinner.hide();
                this.showErrorModal('Erro ao buscar propostas!');
            }
        });
    }

    public generateTableData(): TableData {

        const ICON_ACTION = './assets/media/icons/duotune/general/gen063.svg';
        return {
            header: ['Valor Total', 'Status', 'Tipo da Tac', 'Valor da Tac', 'Data Solicitação', 'ações'],
            rows: this.proposalList.map(row => {
                const formattedValue = row.details ? this.formatValueToMoney(row.details.value) : '- - -';
                const formattedTac = row.details ? row.details.typeTac : '- - -'
                const formattedDateRegister = this.formatDatetime(row.dateRegister.toString());
                const formattedTacValue = row.details ? this.formatValueToMoney(row.details.valueTac) : '---';

                return {
                    fields: [
                        formattedValue,
                        row.status,
                        formattedTac,
                        formattedTacValue,
                        formattedDateRegister,
                    ],
                    actions: [
                        {
                            icon: ICON_ACTION,
                            onClick: () => this.router.navigate(['scfi/proposals/proposal-form', row.uuid])
                        }
                    ]
                }
            }),
        }
    }
}
