import { Routes, RouterModule } from '@angular/router';
import { AcceptProposalComponent } from './accept-proposal.component';

const routes: Routes = [
    {
        path: "accept-proposal",
        component: AcceptProposalComponent
    },
];

export const AcceptProposalRoutes = RouterModule.forChild(routes);
