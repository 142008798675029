<!-- <app-bread-crumb [items]="[{text: 'Propostas'}]"></app-bread-crumb> -->

<app-page-header page-title="Lista de Propostas" button-text="+ Nova Proposta" button-link="/operations/proposals/new"
  button-class="btn-success ml-10" [noForm]="true"></app-page-header>

<div class="container-fluid p-3">
  <div class="row">

    <div class="row p-0">
      <div class="col-md-12 mt-0 mb-5">
        <app-date-picker (emitDate)="emitDate($event)"></app-date-picker>
      </div>
    </div>

    <div class=" row p-0">
      <ng-container *ngFor="let item of cardsByFilter">
        <div class="col-2 opacity-80" (click)="filterListByStatus(item.status)">
          <span [class]="'btn position-relative w-100 mt-4 ' + checkStatus(item.status).color">
            {{checkStatus(item.status).status}}
            <br>
            <p class="font-size-20">
              {{item.totalAmount | currency:"BRL"}}
            </p>            
            <span class="font-size-16">
              {{ item.quantities }} propostas
            </span>            
          </span>
        </div>
      </ng-container>
    </div>

    <div class="row mt-4">
      <div class="search col-md-4 mt-0 mb-5">
        <label class="form-label fw-bolder text-dark fs-6 mb-0">Pesquisar</label>
        <input class="form-control bg-white mt-1" placeholder="Nome, documento, status…" (keyup)="filterData($event)"
          autocomplete="off" />
      </div>
    </div>


    <table mat-table matSort [dataSource]="dataSource"
      class="mat-elevation-z1 table table-striped table-hover my-1 mb-0">
      <ng-container matColumnDef="proposalDate">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Data </th>
        <td mat-cell *matCellDef="let element"> {{element.dateRegister | date : "dd/MM/yyyy HH:mm"}} </td>
      </ng-container>

      <ng-container matColumnDef="proposalName">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Nome. Pro </th>
        <td mat-cell *matCellDef="let element"> {{element.proposalName}} </td>
      </ng-container>

      <ng-container matColumnDef="fullName">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Colaborador </th>
        <td mat-cell *matCellDef="let element"> {{element.employee}} </td>
      </ng-container>

      <ng-container matColumnDef="identityGov">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Documento </th>
        <td mat-cell *matCellDef="let element"> {{maskerService.formatDocumentCPF(element.document)}} </td>
      </ng-container>

      <ng-container matColumnDef="companyName">
        <th mat-header-cell *matHeaderCellDef> Empresa </th>
        <td mat-cell *matCellDef="let element"> {{element.companyname}} </td>
      </ng-container>

      <ng-container matColumnDef="salary">
        <th mat-header-cell *matHeaderCellDef> Salário </th>
        <td mat-cell *matCellDef="let element"> {{element.salary | currency:"BRL"}} </td>
      </ng-container>

      <ng-container matColumnDef="netCapitalAmountLoan">
        <th mat-header-cell *matHeaderCellDef> Empréstimo </th>
        <td mat-cell *matCellDef="let element"> {{element.loanValue | currency:"BRL"}} </td>
      </ng-container>

      <ng-container matColumnDef="iProposalInterestMonth">
        <th mat-header-cell *matHeaderCellDef> Taxa Juros </th>
        <td mat-cell *matCellDef="let element"> {{element.installmentInterest}} % </td>
      </ng-container>

      <ng-container matColumnDef="installmentQuantityLoan">
        <th mat-header-cell *matHeaderCellDef> Parcelas </th>
        <td mat-cell *matCellDef="let element"> {{element.installmentQuantity}}x </td>
      </ng-container>

      <ng-container matColumnDef="installmentValue">
        <th mat-header-cell *matHeaderCellDef> Valor Parcela </th>
        <td mat-cell *matCellDef="let element"> {{truncate.truncate(element.installmentValue, 2)}} </td>
      </ng-container>

      <ng-container matColumnDef="fvTotalAmountLoanValue">
        <th mat-header-cell *matHeaderCellDef> Valor Total </th>
        <td mat-cell *matCellDef="let element"> {{element.totalValue | currency:"BRL"}} </td>
      </ng-container>

      <ng-container matColumnDef="statusProposal">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element">
          <span
            [class]="'p-2 rounded-pill ' + checkStatus(element.status, element.isNonStandard, element.isSpecial).color">{{
            checkStatus(element.status, element.isNonStandard, element.isSpecial).status
            }} </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="text-center"> Ações </th>
        <td mat-cell *matCellDef="let element" class="text-center">
          <mat-icon class="mx-4" (click)="viewDetails(element.uuid)">visibility</mat-icon>
          <!-- <mat-icon [routerLink]="[element.uuid, 'edit']" >edit</mat-icon>
          <mat-icon class="mx-4" (click)="getByuuid(element.uuid)">delete</mat-icon> -->
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
    <mat-paginator class="mat-elevation-z1" #paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 20, 50, 100]">
    </mat-paginator>
  </div>
</div>
  <!-- [routerLink]="[element.uuid, 'edit'] -->