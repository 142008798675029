<div id="installments-table">
    <section class="card mb-4 shadow-sm">
        <div class="card-header border-0 d-flex align-items-center justify-content-start">
            <span [inlineSVG]="'./assets/media/icons/duotune/abstract/abs012.svg'"
                class="svg-icon svg-icon-2tx svg-icon-gray-800 me-4"></span>
            <h3 class="fw-bolder card-title mt-3">Parcelas</h3>
        </div>

        <div class="card-body border-top p-9 installments-table">
            <ng-container *ngIf="tableData">
                <ui-c-table [tableData]="tableData"></ui-c-table>
            </ng-container>
        </div>
    </section>
</div>