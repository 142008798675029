<ng-container *ngIf="goBackToInvoice">
  <app-page-header [page-title]="createPageTitle()" button-text="Voltar" [button-link]="urlInvoice"
    button-class="btn-danger">
  </app-page-header>
</ng-container>
<ng-container *ngIf="!goBackToInvoice">
  <app-page-header [page-title]="createPageTitle()" button-text="Voltar" button-link="/consigned/fgts"
    button-class="btn-danger">
  </app-page-header>
</ng-container>

<div class="container-fluid px-0 mt-4 contract">
  <form [formGroup]="resourceForm" class="">

    <div class="card g-12 disabled" *ngIf="target?.status === 'PRE_CANCELED' || !!cancelation">
      <div class="card-header">
        <h3 class="card-title">Solicitação de Cancelamento</h3>
      </div>  
      <div class="card-body">
        <div class="col-12">
          <div class="row">
            <p><span class="fw-bold">Status: </span> {{mountStatus()}}</p>
            <hr>
            <p><span class="fw-bold">Data da solicitação: </span> {{cancelation?.dateRegister | date: "dd/MM/yyyy"}}</p>
            <hr>
            <div class="mb-3">
              <span class="fw-bold">Motivo do cancelamento: </span> 
              <textarea class="form-control" rows="1" name="cancelationReason" (input)="onInputChange($event)"></textarea>
            </div>
          </div>
          <button *ngIf="target?.status === 'PRE_CANCELED'" class="btn btn-danger float-right" (click)="postConfirmProposalCancelation()"
          >Confirmar Cancelamento</button>
        </div>
      </div>
    </div>

    <div class="card g-12 disabled mt-4" *ngIf="target?.status === 'MONEY_CANCELLATION_REQUESTED' && !!cancelation">
      <div class="card-header">
        <h3 class="card-title">Dados de Pagamento do Cancelamento</h3>
      </div>  
      <div class="card-body">
        <div class="col-12">
          <div class="row">
            <p><span class="fw-bold">Valor: </span> {{cancelation.billetValue | currency:"BRL"}}</p>

            <hr>
            <p><span class="fw-bold">Código de barras: </span> 
              <copy-component content="{{cancelation?.digitLineNumber}}"></copy-component>
            </p>
            <hr>

            <p><span class="fw-bold">Pix Copia e Cola: </span> 
              <copy-component content="{{cancelation?.pixCopyPaste}}"></copy-component>
            </p>
            <hr>

            <p><span class="fw-bold">QrCode: </span> 
              <ngx-qrcode 
                [elementType]="qrCodeElType"
                value="{{cancelation?.pixCopyPaste}}"
                cssClass="qrCode"
              >
              </ngx-qrcode>
            </p>

            
          </div>

        </div>
      </div>
    </div>

    <div class="card g-12 disabled mt-4">
      <div class="card-header">
        <h3 class="card-title">Dados {{contractType === "contract" ? 'do Contrato' : 'da Proposta'}}</h3>
      </div>
      <div class="card-body">
        <div class="card  col-12">
          <div class="row">
            <div class="col-12 col-md-6">
              <p><span class="fw-bold">Nome: </span> {{target?.fullName }}</p>
              <hr>
              <p><span class="fw-bold">Documento: </span> {{maskerService.formatDocumentPerson(target?.cpf)}}</p>
              <hr>
              <p><span class="fw-bold">Primeira parcela: </span> {{target?.startDate | date: "dd/MM/yyyy"}}</p>
              <hr>
              <p><span class="fw-bold">Última parcela: </span> {{target?.endDate | date: "dd/MM/yyyy"}}</p>
              <hr>
            </div>
            <div class="col-12 col-md-6">
              <p><span class="fw-bold">Valor: </span> {{target?.releasedAmount | currency:"BRL"}}</p>
              <hr>
              <p><span class="fw-bold">Parcelamento: </span> {{target?.period}}</p>
              <hr>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Detalhamento dos juros -->
    <div class="card g-12 disabled mt-4">
      <div class="card-header">
        <h3 class="card-title">Detalhes das Parcelas</h3>
      </div>
      <div class="card-body">
        <div class="card  col-12">
          <div class="row">
            <div class="col-12 col-md-6">
              <p><span class="fw-bold">Quantidade: </span> {{target?.period | currency:"BRL"}}</p>
              <hr>
              <p><span class="fw-bold">(M) Valor total da proposta: </span> {{target?.operationAmount |
                currency:"BRL"}}</p>
              <hr>
              <p><span class="fw-bold">Juros totais: </span> {{target?.interestAmount | currency:"BRL"}}</p>
              <hr>
              <p><span class="fw-bold">Valor Solicitado: </span> {{target?.releasedAmount | currency:"BRL"}}</p>
              <hr>
            </div>
            <div class="col-12 col-md-6">
              <p><span class="fw-bold">Taxa de juros: </span> {{target?.annualInterestRate}}% a.a</p>
              <hr>
              <p><span class="fw-bold">Tributos(IOF): </span>
                {{sumElementus(target?.additionalIofAmount,target?.iofAmount) | currency:"BRL"}}
              </p>
              <hr>
              <p><span class="fw-bold">TAC: </span> {{target?.tacAmount | currency:"BRL"}}</p>
              <hr>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="card g-12 mt-4">
    <div class="card-header">
      <h3 class="card-title">Parcelas</h3>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <table mat-table matSort [dataSource]="dataSourceSimulador" multiTemplateDataRows
            class="table-installment mat-elevation-z1 table table-striped table-hover mb-0">

            <ng-container matColumnDef="repaymentDate">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Data de vencimento
              </th>
              <td mat-cell *matCellDef="let element"> {{element.repaymentDate | date: "dd/MM/yyyy"}}</td>
            </ng-container>

            <ng-container matColumnDef="interestAmount">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                (J) Juros*
              </th>
              <td mat-cell *matCellDef="let element"> {{element.interestAmount | currency:"BRL"}} </td>
            </ng-container>

            <ng-container matColumnDef="iofAmount">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                IOF
              </th>
              <td mat-cell *matCellDef="let element"> {{truncateService.truncate(sumElementus(element.iofAmount,
                element.additionalIofAmount),2)}} </td>
            </ng-container>

            <ng-container matColumnDef="releasedAmount">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                (P) Parcela
              </th>
              <td mat-cell *matCellDef="let element"> {{truncateService.truncate(element.releasedAmount,2)}} </td>
            </ng-container>

            <ng-container matColumnDef="repaymentAmount">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                (P) Parcela com Juros
              </th>
              <td mat-cell *matCellDef="let element"> {{truncateService.truncate(element.repaymentAmount,2)}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplayInstallment"></tr>
            <tr class="center" mat-row *matRowDef="let row; columns: columnsToDisplayInstallment;  let i = index"></tr>
          </table>
        </div>
      </div>
    </div>
  </div>
    <div class="card g-12 mt-4">
      <div class="card-header">
        <h3 class="card-title">Histórico</h3>
      </div>

      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <table mat-table matSort [dataSource]="eventsList" class="table mat-elevation-z1 table table-striped table-hover mb-0">

              <ng-container matColumnDef="device">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Device
                </th>
                <td mat-cell *matCellDef="let element"> {{element.device}} </td>
              </ng-container>
            
              <ng-container matColumnDef="status">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Evento
                </th>
                <td mat-cell *matCellDef="let element"> {{element.status}}</td>
              </ng-container>

              <ng-container matColumnDef="description">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Descrição
                </th>
                <td mat-cell *matCellDef="let element"> {{element.description}}</td>
              </ng-container>

              <ng-container matColumnDef="dateRegister">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Data do evento
                </th>
                <td mat-cell *matCellDef="let element"> {{element.dateRegister | date: "dd/MM/yyyy"}} </td>
              </ng-container>
            
              <tr mat-header-row *matHeaderRowDef="columnsToDisplayEvents"></tr>
              <tr class="center" mat-row *matRowDef="let row; columns: columnsToDisplayEvents;  let i = index">
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>