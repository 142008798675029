import { take } from 'rxjs';
import { ContractsService } from '../services-apis/contracts-service';

import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MaskService } from 'src/app/shared/services-ultil/masker-service/masker.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTableDataSource } from '@angular/material/table';
import { UserInfoService } from 'src/app/core/_base/_auth/user-info.service';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';


import { jsPDF } from 'jspdf';
import { SelectionModel } from '@angular/cdk/collections';
import Swal from 'sweetalert2';
import { CurrencyPipe } from '@angular/common';
import { Router } from '@angular/router';
import { TruncateService } from 'src/app/shared/services-ultil/truncate-service/truncate.service';
import { FgtsService } from '../services-apis/fgts.service';

@Component({
  selector: 'app-fgts-contracts-list',
  templateUrl: './fgts-contracts-list.component.html',
  styleUrls: ['./fgts-contracts-list.component.scss']
})
export class FgtsContractsListComponent implements OnInit {
  displayedColumns = [
    'fullname', 'identityGov', 'netCapitalAmountLoan', 'installmentQuantityLoan', 'fvTotalAmountLoanValue', 'action'];
  dataSource: any;
  selection = new SelectionModel<any>(true, []);
  matSort: any;
  startDate: string;
  endDate: string;
  arrayCards: any[] = [];

  email: any = "";
  defaultDate = 90;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  listProposalsFgts: any;

  constructor(
    private contractService: ContractsService,
    public maskerService: MaskService,
    private spinner: NgxSpinnerService,
    private cdr: ChangeDetectorRef,
    private userInfoService: UserInfoService,
    private currenty: CurrencyPipe,
    protected truncate: TruncateService,
    private router: Router,
    private fgtsService: FgtsService
    // private storageSerice: StorageService,
  ) {
  }



  async ngOnInit(): Promise<void> {
    this.spinner.show()
    const currencydate = new Date();
    this.startDate = moment(new Date().setFullYear(currencydate.getFullYear() - 1)).format('YYYY-MM-DD');
    this.endDate = moment(currencydate).format('YYYY-MM-DD');


    this.fgtsService.getProposalsList(this.startDate, this.endDate)
      .subscribe(proposalsList => {
        this.listProposalsFgts = proposalsList;
        this.sumTotalProposals();
        let auxArrayProposals = [...proposalsList.proposals, ...proposalsList.contracts];
        this.dataSource = new MatTableDataSource(auxArrayProposals);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.matSort;
        this.cdr.detectChanges();
      })

    this.spinner.hide()
  }


  goToDetailsProposal(proposal: any) {
    this.router.navigate([`consigned/fgts/${proposal.uuid}/${proposal.typeDocument}/edit`], { state: { proposal } })
  }

  sumTotalProposals() {

    let totalAllValue = 0;
    let obj = {
      type: "all",
      quant: this.listProposalsFgts.contracts.length + this.listProposalsFgts.proposals.length
    };

    this.arrayCards.unshift(obj);

    Object.keys(this.listProposalsFgts).map((el: any) => {
      let totalValue = 0;
      let obj = {
        type: el,
        quant: this.listProposalsFgts[el].length
      }
      this.listProposalsFgts[el].map((prop: any) => {
        totalValue += Number(prop.releasedAmount);
      });

      totalAllValue += totalValue;

      this.arrayCards.push({ ...obj, totalValue: totalValue });
    })

    this.arrayCards[0].totalValue = totalAllValue;
  }


  filterData($event: any) {
    this.dataSource.filter = $event.target.value;
  }

  emitDate(event: any) {
    this.dataSource = new MatTableDataSource([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.matSort;
    this.cdr.detectChanges();

  }

  checkStatusColor(status: string) {
    if (status == "contracts") return "text-bg-secondary text_color_black";
    if (status == "proposals") return "text-bg-success text_color_white";
    if (status == "all") return "text-bg-primary text_color_white";
    return "text-bg-warning";
  }

  checkStatusText(status: string) {
    if (status == "contracts") return "Contratos";
    if (status == "proposals") return "Propostas";
    if (status == "all") return "Todos";
  }

  getContractTermsCCB(uuidContract: any) {
    this.spinner.show();
    this.fgtsService.getProposalByUuid(uuidContract)
      .subscribe(res => {
        this.fgtsService.getCCB(res.proposalCode)
          .pipe(
            take(1)
          )
          .subscribe((result: any) => {
            this.spinner.hide();
            let element = document.createElement('a');
            element.setAttribute('href', result.base64);
            element.setAttribute('download', `${uuidContract}.pdf`);

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);

          })
      }, err => {
        this.spinner.hide();
      })
  }

  exportAsPDF(contract: any, uuidContract: string) {
    let doc = new jsPDF();
    doc.html(contract, {
      callback: function (doc) {
        doc.save(`${uuidContract}.pdf`);
      },
      x: 15,
      y: 15,
      width: 170,
      windowWidth: 650
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    let qtDisabled: number = this.dataSource.data.filter((el: any) => {
      return el.statusProposal == "PAID"
    }).length;

    const numRows = this.dataSource.data.length;
    return numSelected + qtDisabled === numRows;
    return 1 == 1;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach((row: any) => {
        if (row.statusProposal != "PAID") {
          this.selection.select(row)
        }
      });
  }

  depositContracts() {
    /* this.spinner.show(); */
    let arrayId: string[] = [];
    let valueContract: number = 0;
    this.selection.selected.filter(el => {
      arrayId.push(el.uuid);
      valueContract += Number(el.totalValue);
    });

    Swal.fire({
      title: `<strong>Atenção!</strong>`,
      icon: 'warning',
      html: `<p>Tem certeza que deseja liquidar ${arrayId.length} contrato(s) no valor total de
      <b>${this.currenty.transform(valueContract.toFixed(2), 'BRL')} ?</b>
       </p>`,
      showCancelButton: true,
      focusConfirm: true,
      confirmButtonText: 'Liquidar',
      cancelButtonText: 'Cancelar',
    }).then(res => {
      if (res.isConfirmed) {
        this.contractService.paymantContracts(arrayId, this.email)
          .subscribe((result:any) => {
            if (result.succes) {
              Swal.fire({
                icon: 'success',
                title: "Sucesso!",
                text: "Contratos pagos!"
              }).then(res => {
                if (res.isConfirmed) {
                  this.spinner.hide();
                  window.location.reload()
                }
              })
            }
          }, (err:any) => {
            this.spinner.hide();
          });
      }
    })


  }

  getPaginatorIntl(paginator: MatPaginatorIntl) {
    paginator.itemsPerPageLabel = 'Items por página';
    paginator.lastPageLabel = 'Página Anterior';
    paginator.nextPageLabel = 'Próxima Página';
    paginator.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return `0 de ${length}`;
      }

      length = Math.max(length, 0);

      const startIndex = page * pageSize;

      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

      return `${startIndex + 1} - ${endIndex} de ${length}`;
    };
  }

  applayFilter(card: any) {
    if (card.type === "all") {
      this.dataSource = new MatTableDataSource(this.listProposalsFgts.proposals.concat(this.listProposalsFgts.contracts));
      return;
    }
    this.dataSource = new MatTableDataSource(this.listProposalsFgts[card.type]);
  }

  async getHistoryEvents(){}

  widthStyle<T>(list: T[]): string {
    return `calc((100% / ${list.length}) - 1%)`;
  }

}
