import {AcceptProposalDto, ConfDataAccept} from "./accept-proposal.dto";

export const ACCEPT_DATA_MOCK: AcceptProposalDto[] = [{
    dueDate: new Date(),
    totalFees: 119.16,
    totalAmortization: 499.98,
    totalToPay: 619.14,
    quantityInstallments: 6,
    installmentAmount: 103.19,
},
    {
        dueDate: new Date(),
        totalFees: 119.16,
        totalAmortization: 499.98,
        totalToPay: 619.14,
        quantityInstallments: 6,
        installmentAmount: 103.19,
    },
    {
        dueDate: new Date(),
        totalFees: 119.16,
        totalAmortization: 499.98,
        totalToPay: 619.14,
        quantityInstallments: 6,
        installmentAmount: 103.19,
    },
    {
        dueDate: new Date(),
        totalFees: 119.16,
        totalAmortization: 499.98,
        totalToPay: 619.14,
        quantityInstallments: 6,
        installmentAmount: 103.19,
    },
    {
        dueDate: new Date(),
        totalFees: 119.16,
        totalAmortization: 499.98,
        totalToPay: 619.14,
        quantityInstallments: 6,
        installmentAmount: 103.19,
    },
    {
        dueDate: new Date(),
        totalFees: 119.16,
        totalAmortization: 499.98,
        totalToPay: 619.14,
        quantityInstallments: 6,
        installmentAmount: 103.19,
    },
];

export const CONF_ACCEPT: ConfDataAccept = {
    name: 'Luiz Eduardo',
    document: '23421223432',
    email: 'Gambeti.kg@gmail.com',
    phone: '11967521944'
}


