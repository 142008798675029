import { Component, OnInit } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { AbstractScfi } from "../../abstract-scfi";
import { CurrencyPipe, DatePipe } from "@angular/common";
import { CustomerPfDto, CustomerPjDto } from "src/app/core/scfi/services/services-customer/customer.dto";
import { MaskService } from "src/app/shared/services-ultil/masker-service/masker.service";
import { ContractService } from "src/app/core/scfi/services/services-contract/contract.service";
import { TableData } from "src/app/core/scfi/services/services-table/table.dto";
import { ContractTable } from "src/app/core/scfi/services/services-contract/contract.dto";
import { Router } from "@angular/router";

@Component({
  selector: 'app-contract-list',
  templateUrl: './contract-list.component.html',
  styleUrls: ['./contract-list.component.scss']
})
export class ContractListComponent extends AbstractScfi implements OnInit {
  public infoCustomer: CustomerPfDto|CustomerPjDto;
  public formCustomer: FormGroup;
  public tableData: TableData;
  public isPjClient: boolean;
  public contractList: ContractTable[];

  constructor(
      private router: Router,
      private contractService: ContractService,
      private maskService: MaskService,
      protected currencyPipe: CurrencyPipe,
      protected datePipe: DatePipe
  ) {
    super(currencyPipe, datePipe);
  }

  ngOnInit() {
    //this.findCustomerBysUuid();
    this.findContractList();
  }

  buildFormCustomer() {
    this.formCustomer = this.contractService.createFormCustomer();
    this.formCustomer.patchValue({
        typeAccount: this.infoCustomer.typeAccount,
        document: this.isPjClient ? this.maskService.formatDocumentCNPJ((this.infoCustomer.document).toString()) : this.maskService.formatDocumentCPF((this.infoCustomer.document).toString()),
        birthday: this.datePipe.transform(this.infoCustomer.birthday, 'dd/MM/YYYY'),
        email: this.infoCustomer.email,
        phone: this.maskService.formatPhone((this.infoCustomer.phone).toString()),
        cep: this.maskService.formatCep((this.infoCustomer.cep).toString()),
        city: this.infoCustomer.city,
        state: this.infoCustomer.state,
        street: this.infoCustomer.street,
        number: this.infoCustomer.number,
        complement: this.infoCustomer.complement,
        neighborhood: this.infoCustomer.neighborhood,
        bankNumber: this.infoCustomer.bankNumber,
        nameNumber: this.infoCustomer.nameNumber,
        agency: this.maskService.formatAgencyNumber((this.infoCustomer.agency).toString()),
        numberAccount: this.maskService.formatAccountNumber((this.infoCustomer.numberAccount).toString()),
        score: this.infoCustomer.score,
    });
  }

  // public findCustomerBysUuid() {
  //   this.contractService.findCustomerByUuid().subscribe({
  //     next: (customer) => {
  //         this.infoCustomer = customer;
  //         if (customer.typeAccount === AccountTypeEnum.PJ) {
  //           this.isPjClient = true;
  //         } else {
  //           this.isPjClient = false;
  //         }
  //         },
  //     error: (error) => {
  //       this.showErrorModal('Erro ao buscar cliente!');
  //     }
  //   });
  //   return this.buildFormCustomer();
  // }

  public findContractList() {
    this.contractService.findContractList().subscribe({
      next: (contractListData: ContractTable[]) => {
        this.contractList = contractListData;
        this.tableData = this.generateTableData();
      },
      error: (error) => {
        this.showErrorModal('Erro ao buscar contratos!');
      }
    });
  }

  public generateTableData(): TableData {
    return {
      header: ['Produto', 'Valor', 'Parcelas', 'Taxa Juros', 'Total Juros', 'Data da Solicitação', 'Status', 'Ações'],
      rows: this.contractList.map(row => {
        return {
          fields: [
              row.name,
              this.formatValueToMoney(row.value),
              row.installments.toString(),
              this.formatValueToPercent(row.fees),
              this.formatValueToMoney(row.totalFees),
              this.formatDatetime(row.createdDate.toString()),
              row.status
          ],
          actions: [
            { icon: './assets/media/icons/duotune/general/gen063.svg', onClick: () => this.router.navigate(['scfi/contracts/contract-form']) }
          ]
        }
      }),
    }
  }

}
