import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bills-charge-forms',
  templateUrl: './bills-charge-forms.component.html',
  styleUrls: ['./bills-charge-forms.component.scss']
})
export class BillsChargeFormsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
