<div id="simulation-table-details" class="p-9">
    <table class="text-center mat-elevation-z1 table table-striped table-hover mb-0">
        <thead class="p-3">
            <tr>
                <th scope="col">
                    Juros a.m
                </th>
                <th scope="col">
                    TAC
                </th>
                <th scope="col">
                    IOF Adicional
                </th>
                <th scope="col">
                    IOF Diário
                </th>
                <th scope="col">
                    Quantidade de Parcelas
                </th>
                <th scope="col" class="text-bg-warning">
                    Carência (meses)
                </th>
                <th scope="col">
                    Data da Simulação
                </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>
                    {{ formatValueToPercent(simulationDetails.parametros_entrada.taxa_juros_nominal) }}
                </td>
                <td>
                    {{ formatTacValue(simulationDetails.parametros_entrada.valor_tac, simulationDetails.parametros_entrada.tipo_calculo_tac) }}
                <td>
                    {{ formatValueToPercent(simulationDetails.parametros_entrada.aliquota_iof_adicional) }}
                </td>
                <td>
                    {{ formatValueToPercent(simulationDetails.parametros_entrada.aliquota_iof_diario) }}
                </td>
                <td>
                    {{ simulationDetails.parametros_entrada.num_periodos }}
                </td>
                <td class="text-bg-warning">
                    {{ simulationDetails.parametros_entrada.periodo_carencia }}
                </td>
                <td>
                    {{ formatDate(simulationDetails.parametros_entrada.data_emprestimo.toString()) }}
                </td>
            </tr>
        </tbody>
    </table>
</div>