import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaxesRoutes } from './taxes.routing';
import { TaxFormComponent } from './tax-form/tax-form.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { UiCInputModule } from 'src/app/shared/components/ui-c-input/ui-c-input.module';

@NgModule({
  imports: [
    CommonModule,
    TaxesRoutes,
    InlineSVGModule,
    UiCInputModule
  ],
  declarations: [TaxFormComponent]
})
export class TaxesModule { }
