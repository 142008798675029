import { InvestimentFundsFormsComponent } from './investiment-funds-forms/investiment-funds-forms.component';
import { InvestimentFundsListComponent } from './investiment-funds-list/investiment-funds-list.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', component: InvestimentFundsListComponent, },
  { path: 'new', component: InvestimentFundsFormsComponent, },
  { path: ':id/edit', component: InvestimentFundsFormsComponent, },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InvestimentFundsRoutingModule { }
