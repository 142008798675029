<div id="document-form-component">

    <!--Título-->
    <h1 class="page-title mb-10">
        {{ !isEditMode ? 'Cadastro de Dados' : 'Alteração de Dado' }}
    </h1>

    <!--Botão Voltar-->
    <div id="back-to-last-page-btn-container">
        <button type="button" class="btn btn-warning btn-default px-10 mb-4" id="back-to-last-page-btn" (click)="backToLastPage()">
            <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr074.svg'" class="svg-icon svg-icon-1 me-4"></span>
            <span class="pt-2"> Voltar </span>
        </button>
    </div>

    <!--Toggle-->
    <div class="toggle-container mb-8">
        <p class="toggle-label"> PF </p>
        <mat-slide-toggle class="mx-6"
                          color="primary"
                          [checked]="isPjClient"
                          (change)="changeClientType($event)">
        </mat-slide-toggle>
        <p class="toggle-label"> PJ </p>
    </div>

    <!--Formulário Informações Gerais-->
    <section class="card mb-4 shadow-sm">
        <div class="card-header border-0 d-flex align-items-center justify-content-start">
            <span [inlineSVG]="'./assets/media/icons/duotune/general/gen045.svg'"
                  class="svg-icon svg-icon-2tx svg-icon-gray-800 me-4"></span>
            <h3 class="fw-bolder card-title mt-3"> Informações Gerais </h3>
        </div>

        <div class="card-body border-top p-9">
            <form>
                <div class="d-flex">
                    <div class="col-3 px-10">
                        <app-ui-c-input [form]="formData" [errorMessages]="errorMessagesData" [formName]="'name'" [name]="'Nome:'"
                                        [type]="'text'" [placeholder]="'Digite o nome do dado'"></app-ui-c-input>
                    </div>
                    <div class="col-3 px-10" [formGroup]="formData">
                        <label class="form-check form-check-inline form-check-solid mt-15">
                            <input class="form-check-input" name="hasAttachment" type="checkbox" formControlName="hasAttachment" />
                            <span class="form-label fs-6 fw-bolder text-dark"> Necessita anexo </span>
                        </label>
                    </div>
                </div>
            </form>
        </div>
    </section>

    <!--Botão Salvar-->
    <div class="d-flex justify-content-end my-8">
        <button type="button" class="btn btn-primary btn-default" [disabled]="formData.invalid" (click)="saveData()">
            Salvar
        </button>
    </div>

</div>
