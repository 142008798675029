<div id="tax-form-component">
  <h1 class="page-title mb-4">Impostos</h1>

  <section class="card mb-4 shadow-sm">
    <div class="card-header border-0 d-flex align-items-center justify-content-start">
      <span [inlineSVG]="'./assets/media/icons/duotune/general/gen045.svg'"
        class="svg-icon svg-icon-2tx svg-icon-gray-800 me-4"></span>
      <h3 class="fw-bolder card-title mt-3">Informações Gerais</h3>
    </div>

    <div class="card-body border-top p-9">
      <form>
        <div class="d-flex">
          <div class="col-3 px-10">
            <app-ui-c-input [form]="formTax" [errorMessages]="errorMessagesConfigurationTax" [formName]="'pfIofIndex'" [name]="'IOF Adicional PF:'" [isPercent]="true"></app-ui-c-input>
          </div>
          <div class="col-3 px-10">
            <app-ui-c-input [form]="formTax" [errorMessages]="errorMessagesConfigurationTax" [formName]="'pfIofDailyIndex'" [name]="'IOF Diário PF:'" [isPercent]="true"></app-ui-c-input>
          </div>
          <div class="col-3 px-10">
            <app-ui-c-input [form]="formTax" [errorMessages]="errorMessagesConfigurationTax" [formName]="'pfIofMaxDays'" [name]="'Máximo de Dias PF:'"></app-ui-c-input>
          </div>
        </div>

        <div class="d-flex mt-10">
          <div class="col-3 px-10">
            <app-ui-c-input [form]="formTax" [errorMessages]="errorMessagesConfigurationTax" [formName]="'pjIofIndex'" [name]="'IOF Adicional PJ:'" [isPercent]="true"></app-ui-c-input>
          </div>
          <div class="col-3 px-10">
            <app-ui-c-input [form]="formTax" [errorMessages]="errorMessagesConfigurationTax" [formName]="'pjIofDailyIndex'" [name]="'IOF Diário PJ:'" [isPercent]="true"></app-ui-c-input>
          </div>
          <div class="col-3 px-10">
            <app-ui-c-input [form]="formTax" [errorMessages]="errorMessagesConfigurationTax" [formName]="'pjIofMaxDays'" [name]="'Máximo de Dias PJ:'"></app-ui-c-input>
          </div>
        </div>

      </form>
    </div>
  </section>

  <div class="d-flex justify-content-end">
    <button type="button" class="btn btn-primary" [disabled]="formTax.invalid" (click)="saveTax()">
      Salvar
    </button>
  </div>
</div>
