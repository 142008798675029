import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'billing-invoices',
    loadChildren: () =>
      import('./billing-invoices/billing-invoices-routing.module').then((m) => m.BillingInvoicesRoutingModule),
  },
  {
    path: "insurance",
    loadChildren: () => import('./insurance/insurance.module').then(m => m.InsuranceModule)
  },
  {
    path: 'calculation-engine',
    loadChildren: () =>
      import('./calculation-engine/calculation-engine.module').then((m) => m.CalculationEngineModule),
  },
  {
    path: 'companies',
    loadChildren: () =>
      import('./companies/companies-routing.module').then((m) => m.CompaniesRoutingModule),
  },
  {
    path: 'default',
    loadChildren: () =>
      import('./default/default-routing.module').then((m) => m.DefaultRoutingModule),
  },
  {
    path: 'employees',
    loadChildren: () =>
      import('./employees/employees.module').then((m) => m.EmployeesModule),
  },
  {
    path: 'investiment',
    loadChildren: () =>
      import('./investiment-funds/investiment-funds.module').then((m) => m.InvestimentFundsModule),
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('./reports/reports.module').then((m) => m.ReportsModule),
  },
  {
    path: 'settle-loans',
    loadChildren: () =>
      import('./settle-loans/settle-loans.module').then((m) => m.SettleLoansModule),
  },
  {
    path: 'taxes-interest',
    loadChildren: () =>
      import('./taxes-interest/taxes-interest.module').then((m) => m.TaxesInterestModule),
  },
  {
    path: 'users',
    loadChildren: () =>
      import('./users/users.module').then((m) => m.UsersModule),
  },
];

export const AdminRoutes = RouterModule.forChild(routes);
