<div id="simulation-table-finance" class="p-9">
    <table class="text-center mat-elevation-z1 table table-striped table-hover mb-0">
        <thead class="p-3">
            <tr>
                <th scope="col" class="text-bg-success">
                    Valor Solicitado
                </th>
                <th scope="col">
                    Total Juros
                </th>
                <th scope="col">
                    Total IOF
                </th>
                <th scope="col" class="text-bg-success">
                    Valor Total Financiado
                </th>
                <th scope="col">
                    Taxa Efetiva Anual
                </th>
                <th scope="col">
                    CET Anual
                </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="text-bg-success">
                    {{ formatValueToMoney(simulationFinance.parametros_entrada.valor_emprestimo) }}
                </td>
                <td>
                    {{ formatValueToMoney(simulationFinance.parametros_saida.total_juros) }}
                <td>
                    {{ formatValueToMoney(simulationFinance.parametros_saida.iof_total) }}
                </td>
                <td class="text-bg-success">
                    {{ formatValueToMoney(simulationFinance.parametros_saida.valor_total_financiado) }}
                </td>
                <td>
                    {{ formatValueToPercent(simulationFinance.parametros_saida.taxa_efetiva_anual) }}
                </td>
                <td>
                    {{ formatValueToPercent(simulationFinance.parametros_saida.cet_anual) }}
                </td>
            </tr>
        </tbody>
    </table>
</div>