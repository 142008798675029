import { SelectionModel } from '@angular/cdk/collections';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { firstValueFrom, pipe, Subscription, take } from 'rxjs';
import { UserInfoService } from 'src/app/core/_base/_auth/user-info.service';
import Swal from 'sweetalert2';

import { CompanyServices } from '../../companies/services-apis/companies.services';
import { InvoiceService } from '../services-api/invoice-service';

import { TruncateService } from 'src/app/shared/services-ultil/truncate-service/truncate.service';


@Component({
  selector: 'app-billing-invoices-settle-forms',
  templateUrl: './billing-invoices-settle-forms.component.html',
  styleUrls: ['./billing-invoices-settle-forms.component.scss']
})
export class BillingInvoicesSettleFormsComponent implements OnInit {


  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  resourceForm: any;
  formBuilder: any;
  resource: string;
  showDetails: boolean = false;

  displayedColumns: string[] = [
    'registration',
    'fullName',
    'dateInstallment',
    'numberInstallment',
    'installmentValue',
    'insuranceValue',
    'valueDaysIof',
    'totalValue',
    'installmentStatus',
    'action'
  ];

  dataSource = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);

  emailUser: string = '';
  invoiceHeader: any;
  isPreparedInvoice: boolean = false;
  uuidInvoice: any;
  isEdit: boolean = false;
  uuidCompnay: any = '';
  companyName: string = '';

  cameFromInvoice: boolean;

  private unsubscribeInvoice: Subscription;
  private unsubscribeCompany: Subscription;
  private unsubscribeRouter: Subscription;


  constructor(
    protected companyServices: CompanyServices,
    private spinner: NgxSpinnerService,
    private cdr: ChangeDetectorRef,
    private invoiceService: InvoiceService,
    private user: UserInfoService,
    private activeRouter: ActivatedRoute,
    private datePipe: DatePipe,
    private router: Router,
    private company: CompanyServices,
    private currecyPipe: CurrencyPipe,
    protected truncate:TruncateService
  ) {
  }

  async ngOnInit() {

    await this.user.getInfoProfile()
      .then((result: any) => {        
        this.emailUser = result.email;
      });

    this.spinner.show();
    let obsData = this.activeRouter.paramMap.pipe(take(1));
    await firstValueFrom(obsData).then(async (res: ParamMap) => {
      this.uuidInvoice = res.get('id');
      let company: any = res.get('company')
      if (res.get('company') != null) {
        let observaleCompany = this.company.getUuidCompany(company).pipe(take(1));
        await firstValueFrom(observaleCompany).then((res) => {
          this.companyName = res.name;
          this.uuidCompnay = res.uuid;
          this.cdr.detectChanges();
        });
      };

      if (this.uuidInvoice != null) {
        let observaleInvoice = this.invoiceService.findInvoice(this.uuidInvoice);
        await firstValueFrom(observaleInvoice).then((res: ParamMap) => {
          this.spinner.hide()
          this.isEdit = true;
          this.invoiceHeader = res;
          this.isPreparedInvoice = true;
          this.cdr.detectChanges();
        });
      };
    });
    this.spinner.hide();

  }

  goToContract(uuidContract: string) {
    this.router.navigate([`/operations/contracts-loans/${uuidContract}/edit`], { queryParams: { goBackToInvoice: true, uuidInvoice: this.uuidInvoice, uuidCompany: this.uuidCompnay } })
  }

  ngOnDestroy() {
  }


  protected creationPageTitle(): string {
    if (this.isEdit) {
      return this.companyName;
    }
    return "Nova Fatura";
  }

  protected editionPageTitle(): string {
    return ''
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  logSelection() {
    /* this.selection.selected.forEach(s => console.log(s.name)); */
  }

  showDetailInvoice() {
    this.spinner.show();
    this.invoiceService.findInvoiceDetail(this.invoiceHeader.uuid)
      .pipe(take(1)).subscribe(result => {
        this.spinner.hide();
        this.showDetails = !this.showDetails;
        this.cdr.detectChanges();              
        for (let r of result) {
          r.totalValue = (Number(r.valuedaysiof) + Number(r.insurancevalue) + Number(r.installmentvalue))
        }

        this.dataSource = new MatTableDataSource<any>(result);


        this.dataSource.paginator = this.paginator;
        this.cdr.detectChanges();
      })
  }

  emitDate(event: any) {
    this.spinner.show();
    let date = event.startDate.substr(0, 7);
    this.invoiceService.generetedHeaderInvoice(date, this.uuidCompnay, this.emailUser)
      .pipe(take(1)).subscribe(result => {
        this.spinner.hide();
        if (result.message != undefined) {
          Swal.fire({
            title: `<strong>Atenção!</strong>`,
            icon: 'warning',
            text: result.message
          });
        } else {
          this.company.getCompany(this.uuidCompnay)
            .subscribe(company => {
              this.companyName = company.name;
              this.cdr.detectChanges();
            })
          this.isPreparedInvoice = true;
          this.invoiceHeader = result;
          this.cdr.detectChanges();
        }
      }, error => {
        this.spinner.hide();
      })
  }

  isAudit(): any {
    return this.user.isAudit()
  }

  isLiquidation() {
    return this.user.isLiquidation()
  }

  prepareNameStatus(status: string) {
    switch (status) {
      case 'PREPARED':
        return 'Preparado'

      case 'GENERATED':
        return 'Gerado'

      case 'APPROVED':
        return 'Aprovada'

      case 'PAID':
        return 'Paga'
    }
  }

  prepareColorStatus(status: string) {
    switch (status) {
      case 'PREPARED':
        return 'primary'

      case 'GENERATED':
        return 'warning'

      case 'APPROVED':
        return 'secondary'

      case 'PAID':
        return 'success'
    }
  }

  checkStatus(status: any) {
    switch (status) {
      case "INSTALLMENT_ON_DAY":
        return {
          installmentStatus: "Em Aberto",
          color: "text-bg-warning text_color_white"
        }

      case "INSTALLMENT_PAID":
        return {
          installmentStatus: "Paga",
          color: "text-bg-success text_color_white"
        }

      case "INSTALLMENT_LATE":
        return {
          installmentStatus: "Atrasada",
          color: "text-bg-danger text_color_white"
        }

      default:
        return {
          installmentStatus: "Em Aberto",
          color: "text-bg-warning text_color_white"
        }
    }
  }

  approvedInvoice() {

    Swal.fire({
      title: `<strong>Atenção!</strong>`,
      icon: 'warning',
      html: `
      <p>Por favor, verifique os dados abaixo e confirme a aprovação?</p> <br>
      <p>
        Fatura: <b>${this.invoiceHeader.invoiceName}</b>  <br>
        Vencimento: <b>${this.datePipe.transform(this.invoiceHeader?.dueDate, 'dd/MM/YYYY')}</b> <br>
        N° de contratos: <b>${this.invoiceHeader.contractsQuantity}</b> <br>
        Valor da fatura: <b> ${this.currecyPipe.transform(Number(this.invoiceHeader.totalValue).toFixed(2), "BRL")}</b> <br>

      </p>
      `,
      showCancelButton: true,
      focusConfirm: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
    }).then(res => {
      if (res.isConfirmed) {
        this.spinner.show();
        this.invoiceService.approveInvoice(this.invoiceHeader.uuid, this.emailUser)
          .pipe(take(1)).subscribe(result => {
            this.spinner.hide();
            if (result.sucess) {
              this.alert('Sucesso!', 'Fatura aprovada com sucesso!', 'success', 'OK');
              this.router.navigate(['/admin/billing-invoices']);
              this.cdr.detectChanges();
            }
          }, error => {
            this.spinner.hide();
          })
      }
    })
  };

  saveInvoice() {
    Swal.fire({
      title: `<strong>Atenção!</strong>`,
      icon: 'warning',
      html: `
      <p>Tem certeza que deseja cobrar fatura? </p> <br>
      <p>
        Fatura: <b>${this.invoiceHeader.invoiceName}</b>  <br>
        Vencimento: <b>${this.datePipe.transform(this.invoiceHeader?.dueDate, 'dd/MM/YYYY')}</b> <br>
        N° de contratos: <b>${this.invoiceHeader.contractsQuantity}</b> <br>
        Valor da fatura: <b> R$ ${this.currecyPipe.transform(Number(this.invoiceHeader.totalValue).toFixed(2), "BRL")}</b> <br>

      </p>
      `,
      showCancelButton: true,
      focusConfirm: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
    }).then(res => {
      if (res.isConfirmed) {
        this.invoiceService.generetedInvoice(this.invoiceHeader.uuid, this.emailUser)
          .pipe(take(1)).subscribe(result => {
            this.spinner.hide();
            if (result.sucess) {
              this.alert('Sucesso!', 'Cobrança de fatura enviada com sucesso!', 'success', 'OK');
              this.router.navigate(['/admin/billing-invoices']);
              this.cdr.detectChanges();
            }
          }, error => {
            this.spinner.hide();
          })
      }
    })

  };

  deleteInvoice() {
    Swal.fire({
      title: `<strong>Atenção!</strong>`,
      icon: 'warning',
      html: `
      <p>Tem certeza que deseja deletar fatura? </p> <br>
      <p>
        Fatura: <b>${this.invoiceHeader.invoiceName}</b>  <br>
        Vencimento: <b>${this.datePipe.transform(this.invoiceHeader?.dueDate, 'dd/MM/YYYY')}</b> <br>
        N° de contratos: <b>${this.invoiceHeader.contractsQuantity}</b> <br>
        Valor da fatura: <b> R$ ${this.currecyPipe.transform(Number(this.invoiceHeader.totalValue).toFixed(2), "BRL")}</b> <br>

      </p>
      `,
      showCancelButton: true,
      focusConfirm: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
    }).then(res => {
      if (res.isConfirmed) {
        this.spinner.show();
        this.invoiceService.deleteInvoice(this.invoiceHeader.uuid)
          .pipe(take(1)).subscribe((result:any)=> {
            this.spinner.hide();
            if (result.sucess) {
              this.alert('Sucesso!', 'Fatura deletada com sucesso!', 'success', 'OK');
              this.router.navigate(['/admin/billing-invoices']);
              this.cdr.detectChanges();
            }
          }, (error:any) => {
            this.spinner.hide();
          })
      }
    })
  }

  depositedInvoice() {
    Swal.fire({
      title: `<strong>Atenção!</strong>`,
      icon: 'warning',
      html: `
      <p>Tem certeza que deseja dar baixa nessa fatura? </p> <br>
      <p>
        Fatura: <b>${this.invoiceHeader.invoiceName}</b>  <br>
        Vencimento: <b>${this.datePipe.transform(this.invoiceHeader?.dueDate, 'dd/MM/YYYY')}</b> <br>
        N° de contratos: <b>${this.invoiceHeader.contractsQuantity}</b> <br>
        Valor da fatura: <b> R$ ${this.currecyPipe.transform(Number(this.invoiceHeader.totalValue).toFixed(2), "BRL")}</b> <br>

      </p>
      `,
      showCancelButton: true,
      focusConfirm: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
    }).then(res => {
      if (res.isConfirmed) {
        this.spinner.show();
        this.invoiceService.depositedInvoice(this.invoiceHeader.uuid, this.emailUser)
          .pipe(take(1)).subscribe(result => {
            this.spinner.hide();
            if (result.sucess) {
              this.alert('Sucesso!', 'Fatura baixada com sucesso!', 'success', 'OK');
              this.router.navigate(['/admin/billing-invoices']);
              this.cdr.detectChanges();
            }
          }, error => {
            this.spinner.hide();
          })
      }
    })
  }

  alert(title: any, text: any, icon: any, confirmButtonText: any) {
    return Swal.fire({
      title,
      text,
      icon,
      confirmButtonText
    })
  }

  emitCompany(uuidCompany: string) {

    this.uuidCompnay = uuidCompany
  }

  filterData($event: any) {
    this.dataSource.filter = $event.target.value;
  }

}
