<mat-accordion>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title class="fw-bolder card-title">
                Extrato
            </mat-panel-title>
        </mat-expansion-panel-header>
        <table mat-table [dataSource]="statement" class="table">
            <ng-container matColumnDef="dateRegister">
                <mat-header-cell *matHeaderCellDef class="fs-6 fw-bolder text-dark text-center">Data</mat-header-cell>
                <mat-cell *matCellDef="let element" class="font-family-semibold">
                    {{ element.dateRegister | date: 'dd/MM/yyyy HH:mm:ss'}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="description">
                <mat-header-cell *matHeaderCellDef class="fs-6 fw-bolder text-dark">Descrição</mat-header-cell>
                <mat-cell *matCellDef="let element" class="font-family-semibold">{{ element.description }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="reference">
                <mat-header-cell *matHeaderCellDef class="fs-6 fw-bolder text-dark">Referência</mat-header-cell>
                <mat-cell *matCellDef="let element" class="font-family-semibold">{{ element.reference }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="credit">
                <mat-header-cell *matHeaderCellDef class="fs-6 fw-bolder text-dark">Crédito</mat-header-cell>
                <mat-cell *matCellDef="let element" class="text-success">
                    {{ element.credit ? (element.credit | currency: 'BRL') : null }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="debit">
                <mat-header-cell *matHeaderCellDef class="fs-6 fw-bolder text-dark">Débito</mat-header-cell>
                <mat-cell *matCellDef="let element" class="text-danger">
                    {{ element.debit ? (element.debit | currency: 'BRL') : null }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="balance">
                <mat-header-cell *matHeaderCellDef class="fs-6 fw-bolder text-dark">Saldo</mat-header-cell>
                <mat-cell *matCellDef="let element" class="text-success">
                    {{ element.balance | currency: 'BRL' }}
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </table>
    </mat-expansion-panel>
</mat-accordion>