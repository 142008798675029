import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { UiCInputModule } from '../ui-c-input/ui-c-input.module';
import {ApprovalProposal} from "./approval-proposal.component";
import {FormsModule} from "@angular/forms";

@NgModule({
    imports: [
        CommonModule,
        InlineSVGModule,
        UiCInputModule,
        FormsModule
    ],
  declarations: [ApprovalProposal],
  exports: [ApprovalProposal],
})
export class ApprovalProposalModule { }
