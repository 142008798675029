<div class="app-navbar-item" [ngClass]="itemClass">
  <!--begin::Menu wrapper-->
  <div class="cursor-pointer symbol" [ngClass]="userAvatarClass" data-kt-menu-trigger="{default: 'click'}"
    data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
    <div class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2">
      <span class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Olá, </span>
      <span class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline ms-3 mr-3">{{name}}</span>
    </div>
  </div>
  <app-user-inner data-kt-menu='true'> </app-user-inner>


</div>

<!-- <ng-container *ngIf="appHeaderDefaulMenuDisplay">
  <div class="app-navbar-item d-lg-none ms-2 me-n3" title="Show header menu">
    <div class="btn btn-icon btn-active-color-primary w-35px h-35px" id="kt_app_header_menu_toggle">
      <span [inlineSVG]="'./assets/media/icons/duotune/text/txt001.svg'" class="svg-icon"
        [ngClass]="btnIconClass"></span>
    </div>
  </div>
</ng-container> -->