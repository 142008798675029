import { Component, Input, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.scss'],
  animations: [
    trigger('flipState', [
      state('active', style({
        transform: 'rotateY(180deg)'
      })),
      state('inactive', style({
        transform: 'rotateY(0)'
      })),
      transition('active => inactive', animate('600ms ease-out')),
      transition('inactive => active', animate('600ms ease-in'))
    ])
  ]
})
export class DashboardCardComponent implements OnInit {

  @Input() public cardColor: string;
  @Input() public title: string;
  @Input() public props: Array<{ key: string, value: string | number | undefined, type?: string | undefined }>;
  @Input() public customerList: Array<{ name: string, document: string, uuid: string, id: number | string, registration: string, sector: string }> | undefined;

  front: boolean = true;
  back: boolean = false;
  flip: string = 'inactive';

  constructor() { }

  ngOnInit() {
   
  }

  frontBack() {    
    this.flip = (this.flip == 'inactive') ? 'active' : 'inactive';
    this.front = !this.front;
    this.back = !this.back;
  }
}
