<form [formGroup]="form" class="mt-3">
  <label class="form-label fs-6 fw-bolder text-dark">{{name}}</label>
  
    <div class="input-group">
     <!--  <div *ngIf="isMoney" class="input-group-prepend">     
        <span class="input-group-text money">R$</span>
      </div> -->
      <input 
      *ngIf="hasMasker && !isMoney && !isPercent"
        class="form-control text-dark"     
        [type]="type" 
        [formControlName]="formName" 
        [min]="minlength"
        [max]="maxlength"       
        [placeholder]="placeholder"
        [attr.disabled]="block ? true : null"
        (change)="changeMoney()" 
        (keyup)="checkValue($event)"
        [brmasker]="changeMasker()"
        [ngClass]="{'is-invalid': (form?.get(formName)?.invalid),
        'is-valid': (form?.get(formName)?.valid && form?.get(formName)?.dirty)}" />

        <input 
        *ngIf="hasMasker && isMoney"
        class="form-control form-control-lg form-control-solid text-dark"     
        [type]="type" 
        [formControlName]="formName"         
        [placeholder]="placeholder"
        [attr.disabled]="block ? true : null"
        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left', allowZero: true }"
        currencyMask
        [ngClass]="{'is-invalid': (form?.get(formName)?.invalid),
        'is-valid': (form?.get(formName)?.valid && form?.get(formName)?.dirty)}" />


        <input 
        *ngIf="hasMasker && isPercent"
        class="form-control form-control-lg form-control-solid text-dark"     
        [type]="type" 
        [formControlName]="formName"         
        [placeholder]="placeholder"
        [attr.disabled]="block ? true : null"
        [options]="{ prefix: '', suffix: ' %', thousands: '.', decimal: ',', precision: '6', align: 'left', allowZero: true }"
        currencyMask
        [ngClass]="{'is-invalid': (form?.get(formName)?.invalid),
        'is-valid': (form?.get(formName)?.valid && form?.get(formName)?.dirty)}" />
        
    


    <ng-container *ngIf="!hasMasker">
      <!-- <span class="icn-holder" slot="start" *ngIf="isMoney"> R$ </span> -->
      <input     
      class="form-control form-control-lg form-control-solid text-dark"     
      [type]="type" 
      [formControlName]="formName" 
      [min]="minlength"
      [max]="maxlength"
      [placeholder]="placeholder"
      [attr.disabled]="block ? true : null"
      [ngClass]="{'is-invalid': (form?.get(formName)?.invalid),
    'is-valid': (form?.get(formName)?.valid && form?.get(formName)?.dirty)}" />
    
    </ng-container>
    
  </div>
    
    <ng-container  *ngIf="errorMessages && errorMessages[formName]" >
      <ng-container *ngFor="let error of errorMessages[formName]">
        <div class="error-message font-family-semibold invalid text-center mt-2 font-family-semibold"
        *ngIf="form?.get(formName)?.hasError(error?.type) && (form?.get(formName)?.dirty || form?.get(formName)?.touched)">
        {{ error?.message}}
      </div>
    </ng-container>
  </ng-container>
</form>
