import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-bucket',
  templateUrl: './bucket.component.html',
  styleUrls: ['./bucket.component.scss']
})
export class BucketComponent implements OnInit {

  @Input() public bucket: { potencialValue: number, nowValue: number, nowIndex: number } | undefined;

  public potencialHeight = `height: 0% !important;`;

  private cdr: ChangeDetectorRef
  constructor() {
  }

  ngOnInit() {
    this.potencialHeight = `height: ${this.bucket?.nowIndex}% !important;`;
  }

}
