import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CurrencyPipe, DatePipe, Location } from '@angular/common';
import { AbstractScfi } from '../../abstract-scfi';
import { ProposalService } from 'src/app/core/scfi/services/services-proposal/proposal.service';
import { FormGroup } from '@angular/forms';
import { CompanyDto, ErrorMessagesProposalDto, PersonDto, PriceDto, ProposalDto } from 'src/app/core/scfi/services/services-proposal/proposal.dto';
import { MaskService } from 'src/app/shared/services-ultil/masker-service/masker.service';
import * as moment from 'moment';

@Component({
  selector: 'app-proposal-form',
  templateUrl: './proposal-form.component.html',
  styleUrls: ['./proposal-form.component.scss']
})
export class ProposalFormComponent extends AbstractScfi implements OnInit {
  public errorMessagesProposal: ErrorMessagesProposalDto;
  public formProposal: FormGroup;
  public proposal: ProposalDto;
  public price: PriceDto;
  public isPF: boolean;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private proposalService: ProposalService,
    protected currencyPipe: CurrencyPipe,
    protected datePipe: DatePipe,
    private maskService: MaskService,
  ) {
    super(currencyPipe, datePipe);
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const uuid = params['uuid'];

      if (uuid) {
        this.buildFormProposal();
        this.findProposalByUuid(uuid);
      } else {
        this.buildFormProposal();
      }
    });
  }

  public buildFormProposal() {
    this.errorMessagesProposal = this.proposalService.getErrorMessages();
    this.formProposal = this.proposalService.createFormProposal();
  }

  public backToLastPage(): void {
    this.location.back();
  }

  public findProposalByUuid(uuid: string) {
    this.proposalService.findProposalByUuid(uuid).subscribe({
      next: (proposal: ProposalDto) => {
        this.proposal = proposal;
        this.price = proposal.price;

        this.isPF = proposal.requester.document.length === 11 ? true : false;

        if (this.isPF) {
          const requester = proposal.requester as PersonDto;

          this.formProposal.patchValue({
            document: this.maskService.formatDocumentCPF(requester.document),
            name: requester.name,
            motherName: requester.motherName,
            fatherName: requester.fatherName,
            birthDate: moment(requester.birthDate).format("DD/MM/YYYY"),
            phone: this.maskService.formatPhone(requester.phone),
            email: requester.email
          });
        }
        else {
          const requester = proposal.requester as CompanyDto;

          this.formProposal.patchValue({
            document: this.maskService.formatDocumentCNPJ(requester.document),
            name: requester.name,
            stateRegistration: requester.stateRegistration,
            openingDate: moment(requester.openingDate).format("DD/MM/YYYY"),
            phone: this.maskService.formatPhone(requester.phone),
            email: requester.email
          });
        }
      },
      error: (error) => {
        this.showErrorModal('Erro ao buscar proposta!');
      }
    });
  }
}
