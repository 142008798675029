<div class=" container-fluid mt-0 p-2 bg-dark bg-info bg-opacity-1 border rounded-3">
  <nav class="mb-3 mt-3">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/">Home</a>
      </li>
      <li *ngFor="let item of items" class="breadcrumb-item" [class.active]="isTheLastItem(item)">
        <span class="text-warning" *ngIf="isTheLastItem(item)">
          {{item.text}}
        </span>

        <a *ngIf="!isTheLastItem(item)" [routerLink]="item.link">
          {{item.text}}
        </a>
      </li>
    </ol>
  </nav>
</div>
