export enum ProposalStatusRoleEnum {
    REJECTED = 'REPROVADA',
    SIMULATION = 'SIMULACAO',
    BACKOFFICE = 'BACKOFFICE',
    GUARANTOR= "AVALISTA",
    TREASURY = "TESOURARIA",
    COMMITTE = "COMITE",
    CONTRACT_SIGNING = "ASSINATURA_CONTRATO",
    SIGNED_CONTRACT = 'CONTRATO_ASSINADO'
}
