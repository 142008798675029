import { FundingDto } from "./funding.dto";

export const FUNDING_MOCK: FundingDto =
{
    uuid: "1",
    name: "XPTO-1",
    negotiatedRate: 0.7,
    capital: 300000,
    isExternal: true,
    integrationData: {
        identification: "1",
        manager: "Gestora 1"
    }
}