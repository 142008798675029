import { DashboardCardComponent } from './components/dashboard-card/dashboard-card.component';
import { BucketComponent } from './components/bucket/bucket.component';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { ModalsModule, WidgetsModule } from 'src/app/_metronic/partials';
import { UiCSelectCompanieModule } from 'src/app/shared/components/select-companies/select-companies.module';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { MaskService } from 'src/app/shared/services-ultil/masker-service/masker.service';

@NgModule({
  declarations: [DashboardComponent, BucketComponent, DashboardCardComponent],
  imports: [
    CommonModule,
    UiCSelectCompanieModule,
    RouterModule.forChild([
      {
        path: '',
        component: DashboardComponent,
      },
    ]),
    WidgetsModule,
    ModalsModule,
    InlineSVGModule

  ],
  providers: [
    MaskService
  ]
})
export class DashboardModule { }
