import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

export const pagesRouting: Routes = [
    {
        path: 'scfi',
        loadChildren: () =>
            import('./scfi/scfi.routing').then((m) => m.routesScfi),
    },
    {
        path: 'consigned',
        loadChildren: () =>
            import('./consigned/consigned.routing').then((m) => m.consignedRouting),
    },

];

@NgModule({
    imports: [RouterModule.forChild(pagesRouting)],
    exports: [RouterModule],
})
export class PagesRoutingModule { }
