import { TypeCustomerDto } from 'src/app/core/scfi/services/services-customer/type-customer.dto';
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { AbstractScfi } from "../../abstract-scfi";
import { ActivatedRoute, Router } from "@angular/router";
import { ProductsService } from "src/app/core/scfi/services/services-product/products.service";
import { CurrencyPipe, DatePipe, Location } from "@angular/common";
import { FormGroup } from "@angular/forms";
import {
  ConfigurationDto, CreateDetailDto,
  CreateFeeDto, CreateKitDto,
  CreateProductDto,
  ErrorMessagesProductDto,
  ProductDto
} from "src/app/core/scfi/services/services-product/products.dto";
import { ConfigurationFeeDto } from "src/app/core/scfi/services/services-fees/fees.dto";
import { FeesService } from "src/app/core/scfi/services/services-fees/fees.service";
import { NgxSpinnerService } from "ngx-spinner";
import { TypeCustomerEnum } from "src/app/core/scfi/services/services-product/type-customer.enum";
import { environment } from "src/environments/environment";
import { ConfigurationKitDto } from "src/app/core/scfi/services/services-kits/kits.dto";
import { KitsService } from "src/app/core/scfi/services/services-kits/kits.service";
import { ConfigurationTaxDto } from 'src/app/core/scfi/services/services-tax/tax.dto';
import { TaxService } from 'src/app/core/scfi/services/services-tax/tax.service';
import { CustomerService } from 'src/app/core/scfi/services/services-customer/customer.service';

@Component({
  selector: 'app-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.scss']
})
export class ProductFormComponent extends AbstractScfi implements OnInit {
  public formProduct: FormGroup;
  public errorMessagesProduct: ErrorMessagesProductDto;
  public isEditMode: boolean;
  public nameType: string = '';
  public nameProduct: string = '';
  public panelOpenState: boolean = false;
  public feeList: ConfigurationFeeDto[];
  public kitList: ConfigurationKitDto[];
  public currentTax: ConfigurationTaxDto;
  public uuidTypeProduct: string;
  public configProduct: ConfigurationDto[];
  public selectedFeeUuidPf: string;
  public selectedFeeUuidPj: string;
  public selectedKitUuidPf: string;
  public selectedKitUuidPj: string;
  public configPF: {isPF: boolean, uuidTypeCustomer: string};
  public configPJ: {isPJ: boolean, uuidTypeCustomer: string};

  constructor(
    private location: Location,
    private activateRoute: ActivatedRoute,
    private productsService: ProductsService,
    protected currencyPipe: CurrencyPipe,
    protected datePipe: DatePipe,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private feesService: FeesService,
    private kitsService: KitsService,
    private taxService: TaxService,
    private customerService: CustomerService,
    private spinner: NgxSpinnerService,
  ) {
    super(currencyPipe, datePipe);
  }
  ngOnInit() {
    this.activateRoute.params.subscribe(params => {
      const uuidProduct = params['uuidProduct']
      this.uuidTypeProduct = params['uuidType']
      this.isEditMode = !!uuidProduct;

      this.buildFormProduct();
      this.findFeeList();
      this.findKitList();
      this.findCurrentTax();
      this.getTypeCustomer();
      if (uuidProduct) {
        return this.findProductByUuid(uuidProduct)
      };
    });
  }

  public backToLastPage(): void {
    this.location.back();
  }

  public buildFormProduct() {
    this.errorMessagesProduct = this.productsService.getErrorMessagesProduct();
    this.formProduct = this.productsService.createFormProduct();
  }

  public findProductByUuid(uuid: string) {
    this.spinner.show();
    this.productsService.getProductByUuid(uuid).subscribe({
      next: (product: ProductDto) => {
        this.nameType = product.typeProduct.name;
        this.nameProduct = product.name;
        this.configProduct = product.configuration;

        this.updateFormProduct(product);
        this.spinner.hide();
        this.cdr.detectChanges();
      },
      error: () => {
        this.spinner.hide();
        this.showErrorModal('Erro ao buscar produto!');
      }
    });
  }

  private updateFormProduct(product: ProductDto) {
    const configurationPF = product.configuration.find(config => config.typeCustomer.type === TypeCustomerEnum.PF);
    const configurationPJ = product.configuration.find(config => config.typeCustomer.type === TypeCustomerEnum.PJ);

    this.formProduct.patchValue({
      name: product.name,
      isPF: !!configurationPF,
      isPJ: !!configurationPJ,
      minInstallmentPF: configurationPF?.detail.minInstallment,
      maxInstallmentPF: configurationPF?.detail.maxInstallment,
      minValuePF: configurationPF?.detail.minValue,
      maxValuePF: configurationPF?.detail.maxValue,
      feeNamePF: configurationPF?.fee.productConfigurationFee.uuid,
      typeTacPF: configurationPF?.fee.productConfigurationFee.typeTac,
      minValueTacPF: configurationPF?.fee.productConfigurationFee.minValueTac,
      valueTacPF: configurationPF?.fee.productConfigurationFee.valueTac,
      minInterestRatePF: configurationPF?.fee.productConfigurationFee.minInterestRate,
      interestRatePF: configurationPF?.fee.productConfigurationFee.interestRate,
      kitNamePF: configurationPF?.kit.name,
      minInstallmentPJ: configurationPJ?.detail.minInstallment,
      maxInstallmentPJ: configurationPJ?.detail.maxInstallment,
      minValuePJ: configurationPJ?.detail.minValue,
      maxValuePJ: configurationPJ?.detail.maxValue,
      feeNamePJ: configurationPJ?.fee.productConfigurationFee.uuid,
      typeTacPJ: configurationPJ?.fee.productConfigurationFee.typeTac,
      minValueTacPJ: configurationPJ?.fee.productConfigurationFee.minValueTac,
      valueTacPJ: configurationPJ?.fee.productConfigurationFee.valueTac,
      minInterestRatePJ: configurationPJ?.fee.productConfigurationFee.minInterestRate,
      interestRatePJ: configurationPJ?.fee.productConfigurationFee.interestRate,
      kitNamePJ: configurationPJ?.kit.name,
      pfIofIndex: configurationPF?.tax.pfIofIndex,
      pfIofDailyIndex: configurationPF?.tax.pfIofDailyIndex,
      pfIofMaxDays: configurationPF?.tax.pfIofMaxDays,
      pjIofIndex: configurationPJ?.tax.pjIofIndex,
      pjIofDailyIndex: configurationPJ?.tax.pjIofDailyIndex,
      pjIofMaxDays: configurationPJ?.tax.pjIofMaxDays,
    });
  }

  public getTypeCustomer() {
    const productForm = this.formProduct.getRawValue();
    this.customerService.findTypeCustomer().subscribe({
      next: (typeCustomerList: TypeCustomerDto[]) => {
        this.configPF = {
          isPF: productForm.isPF,
          uuidTypeCustomer: typeCustomerList.find(type => type.name === 'Pessoa física')?.uuid ?? ''
        };
        this.configPJ = {
          isPJ: productForm.isPJ,
          uuidTypeCustomer: typeCustomerList.find(type => type.name === 'Pessoa jurídica')?.uuid ?? ''
        };
        this.cdr.detectChanges();
      },
      error: () => {
        this.showErrorModal('Erro ao buscar tarifas!');
      }
    });
  }

  public async saveProduct() {
    const confirmationMessage = this.isEditMode
      ? 'Tem certeza que deseja alterar este produto?'
      : 'Tem certeza que deseja criar este produto?';

    const confirmed = await this.showConfirmationModal(confirmationMessage);

    if (confirmed) {
      const fees: CreateFeeDto[] = [];
      const details: CreateDetailDto[] = [];
      const kit: CreateKitDto[] = [];

      if (this.configPF) {
        fees.push({
          uuidConfigurationFee: this.selectedFeeUuidPf,
          uuidTypeCustomer: this.configPF.uuidTypeCustomer,
        });
        details.push({
          uuidTypeCustomer: this.configPF.uuidTypeCustomer,
          maxInstallment: parseInt(this.formProduct.value.maxInstallmentPF),
          minInstallment: parseInt(this.formProduct.value.minInstallmentPF),
          maxValue: parseInt(this.formProduct.value.minValuePF),
          minValue: parseInt(this.formProduct.value.maxValuePF),
        });
        kit.push({
          uuidConfigurationKit: this.selectedKitUuidPf,
          uuidTypeCustomer: this.configPF.uuidTypeCustomer,
        })
      }

      if (this.configPJ) {
        fees.push({
          uuidConfigurationFee: this.selectedFeeUuidPj,
          uuidTypeCustomer: this.configPJ.uuidTypeCustomer,
        });
        details.push({
          uuidTypeCustomer: this.configPJ.uuidTypeCustomer,
          maxInstallment: parseInt(this.formProduct.value.maxInstallmentPJ),
          minInstallment: parseInt(this.formProduct.value.minInstallmentPJ),
          maxValue: parseInt(this.formProduct.value.minValuePJ),
          minValue: parseInt(this.formProduct.value.maxValuePJ),
        });
        kit.push({
          uuidConfigurationKit: this.selectedKitUuidPj,
          uuidTypeCustomer: this.configPJ.uuidTypeCustomer,
        })
      }

      const dataForm: CreateProductDto = {
        uuidTypeProduct: this.uuidTypeProduct,
        uuidWhiteLabel: environment.wlp,
        name: this.formProduct.value.name,
        fees: fees,
        details: details,
        kit: kit,
      };

      if (this.isEditMode) {
        this.updateProduct(dataForm);
      } else {
        this.createProduct(dataForm);
      }
    }
  }

  public createProduct(product: CreateProductDto) {
    this.productsService.createProduct(product).subscribe({
      next: async () => {
        await this.showSuccessModal('Tem certeza que deseja criar este produto?');
        this.router.navigate(['/scfi/products/new', this.uuidTypeProduct]);
      },
      error: () => {
        this.showErrorModal('Erro ao criar o produto!');
      }
    });
  }

  public updateProduct(product: CreateProductDto) {
    this.productsService.updateProduct(product).subscribe({
      next: async () => {
        await this.showSuccessModal('Tem certeza que deseja alterar este produto?');
        this.router.navigate(['/scfi/products/new', this.uuidTypeProduct]);
      },
      error: () => {
        this.showErrorModal('Erro ao alterar o produto!');
      }
    });
  }

  public findFeeList() {
    this.feesService.findFeeList().subscribe({
      next: (feeList: ConfigurationFeeDto[]) => {
        this.feeList = feeList;
        this.cdr.detectChanges();
      },
      error: () => {
        this.showErrorModal('Erro ao buscar tarifas!');
      }
    });
  }

  public findKitList() {
    this.kitsService.findKitsList().subscribe({
      next: (kitList: ConfigurationKitDto[]) => {
        this.kitList = kitList;
        this.cdr.detectChanges();
      },
      error: () => {
        this.showErrorModal('Erro ao buscar kits!');
      }
    });
  }

  public findCurrentTax() {
    this.taxService.findTaxes().subscribe({
      next: (currentTax: ConfigurationTaxDto[]) => {
        this.currentTax = currentTax[0];
        this.formProduct.patchValue({
          pfIofIndex: this.currentTax.pfIofIndex,
          pfIofDailyIndex: this.currentTax.pfIofDailyIndex,
          pfIofMaxDays: this.currentTax.pfIofMaxDays,
          pjIofIndex: this.currentTax.pjIofIndex,
          pjIofDailyIndex: this.currentTax.pjIofDailyIndex,
          pjIofMaxDays: this.currentTax.pjIofMaxDays,
        })
        this.cdr.detectChanges();
      },
      error: () => {
        this.showErrorModal('Erro ao buscar impostos!');
      }
    });
  }

  public onFeeChangePF(uuid: string) {
    const selectedFee = this.feeList.find(fee => fee.uuid === uuid);

    if (selectedFee) {
      this.selectedFeeUuidPf = selectedFee.uuid;
      this.formProduct.patchValue({
        typeTacPF: selectedFee.typeTac === 'PERCENT' ? 'Percentual' : selectedFee.typeTac === 'FIXED' ? 'Valor Fixo' : '',
        minValueTacPF: selectedFee.minValueTac,
        valueTacPF: selectedFee.valueTac,
        minInterestRatePF: selectedFee.minInterestRate,
        interestRatePF: selectedFee.interestRate,
      });
    }
  }

  public onFeeChangePJ(uuid: string) {
    const selectedFee = this.feeList.find(fee => fee.uuid === uuid);

    if (selectedFee) {
      this.selectedFeeUuidPj = selectedFee.uuid;
      this.formProduct.patchValue({
        typeTacPJ: selectedFee.typeTac === 'PERCENT' ? 'Percentual' : selectedFee.typeTac === 'FIXED' ? 'Valor Fixo' : '',
        minValueTacPJ: selectedFee.minValueTac,
        valueTacPJ: selectedFee.valueTac,
        minInterestRatePJ: selectedFee.minInterestRate,
        interestRatePJ: selectedFee.interestRate,
      });
    }
  }

  public onKitChangePF(uuid: string) {
    const selectedKit = this.kitList.find(kit => kit.uuid === uuid);
    this.selectedKitUuidPf = selectedKit?.uuid ?? '';
  }

  public onKitChangePJ(uuid: string) {
    const selectedKit = this.kitList.find(kit => kit.uuid === uuid);
    this.selectedKitUuidPj = selectedKit?.uuid ?? '';
  }

}
