import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SpeViewNBackofficeConsignedProxy } from 'src/app/core/proxy/view/spe-view-n-backoffice-consigned.proxy';
import { CardInfoComponent } from './cards-infos.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [CardInfoComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    MatTableModule,
    NgbDropdownModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
  ],
  providers: [SpeViewNBackofficeConsignedProxy],
  exports: [CardInfoComponent],
})
export class UiCCardinfoModule {}
