import {Injectable} from "@angular/core";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "../../../_base/_auth/api.service";
import {SpeNScfiProxy} from "../../../proxy/spe/spe-n-scfi.proxy";
import {SpeViewNScfiProxy} from "../../../proxy/view/spe-view-n-scfi.proxy";
import {Observable} from "rxjs";
import {AcceptProposalDto, AccountProposalModel, ConfDataAccept, GenerateAccountTokenValidationRequestBodyDtoType, ValidateAccountCodeDtoRequestType, ValidateAccountCodeResponseWithData} from "./accept-proposal.dto";

@Injectable({
    providedIn: 'root'
})
export class AcceptProposalService {
    formAccept: FormGroup;

    constructor(
        protected api: ApiService,
        private formBuilder: FormBuilder,
        private speNScfiProxy: SpeNScfiProxy,
        private speViewNScfiProxy: SpeViewNScfiProxy
    ) {
    }

    public createFormAccept(): FormGroup {
        this.formAccept = this.formBuilder.group({
            name: new FormControl('', Validators.compose([
                Validators.required
            ])),
            document: new FormControl('', Validators.compose([
                Validators.required
            ])),
            phone: new FormControl('', Validators.compose([
                Validators.required
            ])),
            email: new FormControl('', Validators.compose([
                Validators.required
            ])),
        })
        return this.formAccept;
    }

    public findAcceptDetails(): Observable<AcceptProposalDto[]> {
        return this.speViewNScfiProxy.findAcceptDetails();
    }

    public findAcceptData(): Observable<ConfDataAccept>{
        return this.speViewNScfiProxy.findAcceptData();
    }

    public generateAuthToken(data: GenerateAccountTokenValidationRequestBodyDtoType): Observable<void>{
        return this.speNScfiProxy.generateAuthToken(data);
    }

    public validateAuthToken(data: ValidateAccountCodeDtoRequestType): Observable<ValidateAccountCodeResponseWithData<AccountProposalModel>>{
        return this.speNScfiProxy.validateAuthToken(data);
    }


}
