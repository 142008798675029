import { TablesimulatorComponent } from './../simulations/tablesimulator/tablesimulator.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptService } from 'src/app/core/_base/_auth/intercept/intercept.service';
import { UiCDatePickerModule } from 'src/app/shared/components/date-picker/date-picker.module';
import { MaskerModule } from 'src/app/shared/services-ultil/masker-service/masker-module.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';

import { ContractsLoansRoutingModule } from './contracts-loans-routing.module';
import { ContractsLoansListComponent } from './contracts-loans-list/contracts-loans-list.component';
import { ContractsLoansFormsComponent } from './contracts-loans-forms/contracts-loans-forms.component';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table'; 
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SpeNBackofficeConsignedProxy } from 'src/app/core/proxy/spe/spe-n-backoffice-consigned';
import { UiCInputModule } from 'src/app/shared/components/ui-c-input/ui-c-input.module';
import { ProposalServices } from '../proposals/services-apis/proposals-services';
import { TableFinanceContractDetailModule } from 'src/app/shared/components/table-details-contract/table-finance-contract.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TruncatePipe } from 'src/app/shared/pipes/truncate.pipe';
import { TruncateService } from 'src/app/shared/services-ultil/truncate-service/truncate.service';




@NgModule({
  declarations: [
    ContractsLoansListComponent,
    ContractsLoansFormsComponent,
    //TablesimulatorComponent
    TruncatePipe
  ],
  imports: [
    CommonModule,
    ContractsLoansRoutingModule,
    SharedModule,
    MatIconModule,
    MatPaginatorModule,
    MatTableModule,
    MatFormFieldModule,
    UiCInputModule,
    MaskerModule,
    UiCDatePickerModule,
    FormsModule,
    TableFinanceContractDetailModule,
    MatCheckboxModule
  ],
  providers: [
    // MaskService,
    InterceptService,
    SpeNBackofficeConsignedProxy,
    ProposalServices,
    CurrencyPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
    TruncateService
  ]
})
export class ContractsLoansModule { }
