import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiCTableComponent } from './ui-c-table.component';
import { BrMaskerModule } from "br-mask";
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { InlineSVGModule } from 'ng-inline-svg-2';

@NgModule({
  imports: [
    CommonModule,
    BrMaskerModule,
    CurrencyMaskModule,
    InlineSVGModule
  ],
  declarations: [UiCTableComponent],
  exports: [UiCTableComponent],
})
export class UiCTableModule { }
