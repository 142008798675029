<div id="simulation-installments-details" class="p-9">
    <div class="d-flex">
        <h5 class="col-3 px-10">
            Valor Solicitado:
        </h5>
        <h5 class="col-3 px-10">
            Quantidade de Parcelas
        </h5>
        <h5 class="col-3 px-10">
            Valor da Parcela:
        </h5>
        <h5 class="col-3 px-10">
            Valor Total Financiado:
        </h5>
    </div>

    <div class="d-flex">
        <div class="col-3 px-10">
            {{ formatValueToMoney(simulationDetails.parametros_entrada.valor_emprestimo) }}
        </div>
        <div class="col-3 px-10">
            {{ simulationDetails.parametros_entrada.num_periodos }}
        </div>
        <div class="col-3 px-10">
            {{ formatValueToMoney(simulationDetails.parcelas_price[0].prestacao) }}
        </div>
        <div class="col-3 px-10">
            {{ formatValueToMoney(simulationDetails.parametros_saida.valor_total_financiado) }}
        </div>
    </div>

    <div class="d-flex mt-10">
        <h5 class="col-3 px-10">
            Taxa de Juros:
        </h5>

        <h5 class="col-3 px-10">
            Valor Total de Juros:
        </h5>

        <h5 class="col-3 px-10">
            Taxa Efetiva Anual:
        </h5>

        <h5 class="col-3 px-10">
            CET Anual:
        </h5>
    </div>

    <div class="d-flex">
        <div class="col-3 px-10">
            {{ formatValueToPercent(simulationDetails.parametros_entrada.taxa_juros_nominal) }}
        </div>

        <div class="col-3 px-10">
            {{ formatValueToMoney(simulationDetails.parametros_saida.total_juros) }}
        </div>

        <div class="col-3 px-10">
            {{ formatValueToPercent(simulationDetails.parametros_saida.taxa_efetiva_anual) }}
        </div>

        <div class="col-3 px-10">
            {{ formatValueToPercent(simulationDetails.parametros_saida.cet_anual) }}
        </div>
    </div>

    <div class="d-flex mt-10">
        <h5 class="col-3 px-10">
            Valor TAC:
        </h5>

        <h5 class="col-3 px-10">
            Alíquota IOF Diário:
        </h5>

        <h5 class="col-3 px-10">
            Alíquota IOF Adicional:
        </h5>

        <h5 class="col-3 px-10">
            Valor Total de IOF:
        </h5>
    </div>

    <div class="d-flex">
        <div class="col-3 px-10">
            {{ formatTacValue(simulationDetails.parametros_entrada.valor_tac,
            simulationDetails.parametros_entrada.tipo_calculo_tac) }}
        </div>

        <div class="col-3 px-10">
            {{ formatValueToPercent(simulationDetails.parametros_entrada.aliquota_iof_diario) }}
        </div>

        <div class="col-3 px-10">
            {{ formatValueToPercent(simulationDetails.parametros_entrada.aliquota_iof_adicional) }}
        </div>

        <div class="col-3 px-10">
            {{ formatValueToMoney(simulationDetails.parametros_saida.iof_total) }}
        </div>
    </div>

    <div class="d-flex mt-10">
        <h5 class="col-3 px-10">
            Período de Carência (em meses):
        </h5>

        <h5 class="col-3 px-10">
            IOF Incluso no Financiamento:
        </h5>
    </div>

    <div class="d-flex">
        <div class="col-3 px-10">
            {{ simulationDetails.parametros_entrada.periodo_carencia }}
        </div>

        <div class="col-3 px-10">
            {{ isIOFIncluded(simulationDetails.parametros_entrada.incluir_iof_no_financiamento) }}
        </div>
    </div>
</div>