import { CurrencyPipe, DatePipe } from "@angular/common";
import { ProposalStatusEnum } from "src/app/core/scfi/services/services-proposal/enum/proposal-status.enum";
import { RoleTypeEnum } from "src/app/core/scfi/services/services-proposal/enum/role-type.enum";
import Swal from "sweetalert2";

export abstract class AbstractScfi {
    constructor(
        protected currencyPipe: CurrencyPipe,
        protected datePipe: DatePipe
    ) { }

    public showConfirmationModal(text: string): Promise<boolean> {
        return Swal.fire({
            title: "Atenção",
            text: text,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "var(--kt-primary)",
            cancelButtonColor: "var(--kt-danger)",
            confirmButtonText: "Confirmar",
            cancelButtonText: "Cancelar",
            reverseButtons: true
        }).then((result) => {
            return result.isConfirmed;
        });
    }

    public showSuccessModal(text: string): Promise<boolean> {
        return Swal.fire({
            title: "Sucesso!",
            text: text,
            icon: "success",
            confirmButtonColor: "var(--kt-primary)",
        }).then((result) => {
            return result.isConfirmed;
        });
    }

    public showErrorModal(text: string): Promise<boolean> {
        return Swal.fire({
            title: "Erro!",
            text: text,
            icon: "error",
            confirmButtonColor: "var(--kt-primary)",
        }).then((result) => {
            return result.isConfirmed;
        });
    }

    public formatValueToPercent(value: number): string {
        return `${value}%`;
    }

    public formatValueToMoney(value: number): string {
        const formattedValue: string | null = this.currencyPipe.transform(value, 'BRL', 'symbol', '1.2-2');
        return formattedValue ?? '';
    }

    public formatDatetime(datetime: string): string {
        const formattedDate = this.datePipe.transform(datetime, 'dd/MM/yyyy HH:mm:ss', 'America/Sao_Paulo');
        return formattedDate || '';
    }

    public formatDate(date: string): string {
        const dateFormatted = new Date(date);
        const day = String(dateFormatted.getDate()).padStart(2, '0');
        const month = String(dateFormatted.getMonth() + 1).padStart(2, '0');
        const year = String(dateFormatted.getFullYear());
        return `${day}/${month}/${year}`;
    }

    public translateProposalStatus(proposalStatus: ProposalStatusEnum) {
        switch (proposalStatus) {
            case ProposalStatusEnum.APPROVED:
                return 'Aprovado';
            case ProposalStatusEnum.CANCELED:
                return 'Cancelado';
            case ProposalStatusEnum.CCB_SIGNED:
                return 'CCB Assinada';
            case ProposalStatusEnum.FINISHED:
                return 'Finalizado';
            case ProposalStatusEnum.IN_ANALYSIS:
                return 'Em Análise';
            case ProposalStatusEnum.PAID:
                return 'Pago';
            case ProposalStatusEnum.REQUESTED:
                return 'Solicitado';
            default:
                return proposalStatus;
        }
    }

    public translateRoleType(roleType: RoleTypeEnum) {
        switch (roleType) {
            case RoleTypeEnum.CLIENT:
                return 'Cliente';
            case RoleTypeEnum.COMMITTEE:
                return 'Comitê';
            default:
                return roleType;
        }
    }
}
