import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TableData } from "src/app/core/scfi/services/services-table/table.dto";
import { Router } from "@angular/router";
import { KitsService } from "src/app/core/scfi/services/services-kits/kits.service";
import { AbstractScfi } from "../../abstract-scfi";
import { CurrencyPipe, DatePipe } from "@angular/common";
import { NgxSpinnerService } from 'ngx-spinner';
import {ConfigurationKitDto, DeleteKitDto, UpdateKitDto} from 'src/app/core/scfi/services/services-kits/kits.dto';
import { environment } from "../../../../../environments/environment";

@Component({
  selector: 'app-kit-list',
  templateUrl: './kit-list.component.html',
  styleUrls: ['./kit-list.component.scss']
})
export class KitListComponent extends AbstractScfi implements OnInit {
  kits: ConfigurationKitDto[];
  tableKit: TableData;

  constructor(
      private router: Router,
      private kitsService: KitsService,
      protected currencyPipe: CurrencyPipe,
      protected datePipe: DatePipe,
      private spinner: NgxSpinnerService,
      private cdr: ChangeDetectorRef
  ) {
  super(currencyPipe, datePipe)
  }

  ngOnInit() {
    this.findKitsList();
  }

  public findKitsList() {
    this.spinner.show()
    this.kitsService.findKitsList().subscribe({
      next: (kitsList: ConfigurationKitDto[]) => {
        this.kits = kitsList;
        this.tableKit = this.generateTableData();
        this.cdr.detectChanges();
        this.spinner.hide();
      },
      error: (error) => {
        this.spinner.hide();
        this.showErrorModal('Erro ao buscar os kits!');
      }
    });
  }

  public generateTableData(): TableData {
    return {
      header: ['Kit', 'Ações'],
      rows: this.kits.map(row => {
        return {
          fields: [
            row.name,
          ],
          actions: [
            { icon: './assets/media/icons/duotune/general/gen055.svg', onClick: () => this.redirectToNewOrEditKit(row.uuid) },
            { icon: './assets/media/icons/duotune/general/gen027.svg', onClick: () => this.deleteKit(row.uuid)
            }
          ]
        }
      }),
    }
  }

  redirectToNewOrEditKit (uuidKit?: string) {
    if (uuidKit) {
      this.router.navigate(['scfi/kits/edit-kit', uuidKit]);
    }
    else {
      this.router.navigate(['scfi/kits/new-kit']);
    }
  }

  public async deleteKit(uuid: string)  {
    const confirmed = await this.showConfirmationModal('Tem certeza que deseja excluir este kit?')

    const deleteToData = {
      uuid: uuid,
      uuidWhiteLabel: environment.wlp,
    }

    if (confirmed) {
      this.spinner.show();
      this.kitsService.deleteKit(deleteToData).subscribe({
        next: () => {
          this.findKitsList();
          this.showSuccessModal('Kit excluído com sucesso!');
          this.spinner.hide();
        },
        error: (error) => {
          this.showErrorModal('Erro ao excluir kit!');
          this.spinner.hide();
        }
      });
    }
  }

}
