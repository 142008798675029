<div class="row card-info d-flex justify-content-center w-100">
  <div *ngFor="let card of cardInfo" class="col-3">
    <div [class]="'card card-custom m-5 col-3 ' + checkCards(card.titleCard).color">
      <div class="card-body d-flex flex-column">
        <div>
          <p [class]="'title-card font-size-h3 ' + checkCards(card.titleCard).colorText">
            <strong>
              {{card.titleCard}}
            </strong>
          </p>
        </div>
        <div *ngIf="card.qtd == false">
          <p
            [class]="'text-color text-dark-75 font-weight-bolder font-size-h3 ' + checkCards(card.titleCard).colorText">
            <strong>
              {{card.value == null ? 0: card.value | currency : "BRL"}}
            </strong>
          </p>
        </div>
        <div *ngIf="card.qtd == true">
          <p
            [class]="'text-color text-dark-75 font-weight-bolder font-size-h3 ' + checkCards(card.titleCard).colorText">
            <strong >
              {{card.value}}
            </strong>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
