<div class="ui-c-table-container card-body row col-12 mx-0">
    <ng-container *ngIf="tableData.title != null">
        <div class="card-header">
            <h3 class="card-title"> {{ tableData.title }} </h3>
        </div>
    </ng-container>

    <table class="mat-elevation-z1 table table-striped table-hover my-1 mb-0">

        <thead class="p-3">
            <tr>
                <th scope="col text-center" *ngFor="let column of tableData.header">
                    {{ column }}
                </th>
            </tr>
        </thead>

        <tbody>
            <tr class="py-3" *ngFor="let row of tableData.rows">
                <td *ngFor="let column of row.fields">
                    {{ column }}
                </td>
                <td>
                    <button type="button" class="btn btn-sm btn-icon btn-active-color-primary" *ngFor="let action of row.actions" (click)="action.onClick()">
                        <span [inlineSVG]="action.icon" class="svg-icon svg-icon-1 svg-icon-gray-800 me-4"></span>
                    </button>
                </td>
            </tr>

            <tr *ngIf="tableData.rows.length === 0">
                <td colspan="2" class="text-center">Não há registros nessa tabela</td>
            </tr>
        </tbody>

    </table>
</div>
