import { Company } from './companies.model';
import { SpeNBackofficeConsignedProxy } from 'src/app/core/proxy/spe/spe-n-backoffice-consigned';
import { SpeViewNBackofficeConsignedProxy } from 'src/app/core/proxy/view/spe-view-n-backoffice-consigned.proxy';
import { SpeNOpenAccountProxy } from 'src/app/core/proxy/spe/spe-n-open-account.proxy';
import { CompaniesTableModel } from './companies-table.model';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Injectable, Injector } from '@angular/core';

import { FormControl, Validators } from '@angular/forms';
import { ApiService } from 'src/app/core/_base/_auth/api.service';


@Injectable({
  providedIn: 'root'
})
export class CompanyServices {

  formCompany: FormGroup;

  constructor(
    protected injector: Injector,
    private formBuilder: FormBuilder, protected api: ApiService,
    private speNOpenAccountProxy: SpeNOpenAccountProxy,
    private speViewNBackofficeConsignedProxy: SpeViewNBackofficeConsignedProxy,
    private speNBackofficeConsignedProxy: SpeNBackofficeConsignedProxy
  ) { }


  getCompaniesList(startdate: string, endDate: string): Observable<CompaniesTableModel[]> {
    return this.speViewNBackofficeConsignedProxy.getCompaniesList(startdate, endDate);
  }

  createFormCompany(): FormGroup {
    this.formCompany = this.formBuilder.group({
      name: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(100),
      ])),
      consignedPartner: new FormControl('', Validators.compose([
      ])),
      uuidConsignedPartner: new FormControl('', Validators.compose([
      ])),
      documentGov: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(18),
        Validators.maxLength(18),
      ])),
      documentStates: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(100),
      ])),
      documentCity: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(100),
      ])),
      nameContact: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(100),
      ])),
      documentContact: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(18),
      ])),
      phoneContact: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(100),
      ])),
      emailContact: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(100),
        Validators.email
      ])),
      nameResponsibleAssign: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(100)
      ])),
      emailResponsibleAssign: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(100),
        Validators.email
      ])),
      phoneResponsibleAssing: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(100),
      ])),
      cpfResponsibleAssign: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(100),
      ])),
      rgResponsibleAssign: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(100)
      ])),
      emailCompany: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(100),
        Validators.email
      ])),
      phone: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(100),
      ])),
      zipcode: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(9),
        Validators.maxLength(9),
      ])),
      address: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      addressNumber: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(100),
      ])),
      complement: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      city: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(100),
      ])),
      states: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(100),
      ])),
      district: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100),
      ])),
      country: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(100),
      ])),
      companiesStatus: "",
      uuid: "",
      id: ""
    });

    return this.formCompany;
  }

  createFormCompanySimulation(): FormGroup {
    this.formCompany = this.formBuilder.group({
      name: new FormControl('', Validators.compose([
      ])),
      documentGov: new FormControl('', Validators.compose([

      ])),
      documentStates: new FormControl('', Validators.compose([

      ])),
      documentCity: new FormControl('', Validators.compose([

      ])),
      nameContact: new FormControl('', Validators.compose([

      ])),
      documentContact: new FormControl('', Validators.compose([

      ])),
      phoneContact: new FormControl('', Validators.compose([

      ])),
      emailContact: new FormControl('', Validators.compose([
      ])),
      nameResponsibleAssign: new FormControl('', Validators.compose([

      ])),
      emailResponsibleAssign: new FormControl('', Validators.compose([

      ])),
      phoneResponsibleAssing: new FormControl('', Validators.compose([

      ])),
      cpfResponsibleAssign: new FormControl('', Validators.compose([

      ])),
      rgResponsibleAssign: new FormControl('', Validators.compose([

      ])),
      emailCompany: new FormControl('', Validators.compose([

      ])),
      phone: new FormControl('', Validators.compose([

      ])),
      zipcode: new FormControl('', Validators.compose([

      ])),
      address: new FormControl('', Validators.compose([
      ])),
      addressNumber: new FormControl('', Validators.compose([

      ])),
      complement: "",
      city: new FormControl('', Validators.compose([

      ])),
      states: new FormControl('', Validators.compose([

      ])),
      district: new FormControl('', Validators.compose([

      ])),
      country: new FormControl('', Validators.compose([

      ])),
      companiesStatus: "",
      uuid: "",
      id: ""
    });

    return this.formCompany;
  }


  getFormsErrors() {
    return {
      name: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        },
        {
          type: 'minlength',
          message: 'O nome precisar ter no mínimo 2 caracteres',
        }
      ],
      documentGov: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        },
        {
          type: 'minlength',
          message: 'O documento precisar ter 18 caracteres',
        },
        {
          type: 'maxlength',
          message: 'O documento precisar ter 18 caracteres',
        }
      ],
      documentStates: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        }
      ],
      documentCity: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        }
      ],
      nameContact: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        }
      ],
      phoneContact: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        }
      ],
      emailContact: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        },
        {
          type: 'minlength',
          message: 'O email deve conter no mínimo 4 caracteres'
        },
        {
          type: 'email',
          message: 'Digite um email válido'
        }
      ],
      emailResponsibleAssign: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        },
        {
          type: 'minlength',
          message: 'O email deve conter no mínimo 4 caracteres'
        },
        {
          type: 'email',
          message: 'Digite um email válido'
        }
      ],
      cpfResponsibleAssign: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        },
        {
          type: 'minlength',
          message: 'CPF inválido'
        },
        {
          type: 'maxlength',
          message: 'CPF inválido'
        }
      ],
      phoneResponsibleAssing: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        },
        {
          type: 'minlength',
          message: 'CPF inválido'
        },
        {
          type: 'maxlength',
          message: 'CPF inválido'
        }
      ],
      emailCompany: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        },
        {
          type: 'minlength',
          message: 'O email deve conter no mínimo 4 caracteres'
        },
        {
          type: 'email',
          message: 'Digite um email válido'
        }
      ],
      phone: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        }
      ],
      address: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        }
      ],
      addressNumber: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        }
      ],
      city: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        },
        {
          type: 'minlength',
          message: 'Campo precisa ter no mínimo 2 caracteres'
        }
      ],
      states: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        }
      ],
      district: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        }
      ],
      country: [
        {
          type: 'required',
          message: 'Campo obrigatório'
        },
        {
          type: 'minlength',
          message: 'Campo precisa ter no mínimo 2 caracteres'
        }
      ],

    }
  }

  getCompany(uuid: string): Observable<any> {
    return this.speViewNBackofficeConsignedProxy.getAllAtributesCompany(uuid);
  }

  public updateAddressCompany(form: any, address: any): void {
    form.patchValue({
      address: address.logradouro,
      district: address.bairro,
      complement: address.complemmento,
      states: address.uf,
      city: address.localidade,
      country: 'br'
    });
  }

  public sycForm(company: any) {
    this.formCompany.patchValue({
      documentContact: company.contact.documentCcontact,
      emailContact: company.contact.emailContact,
      phoneContact: company.contact.phoneContact,
      nameContact: company.contact.nameContact,

      emailResponsibleAssign: company.responsible.emailResponsibleAssign,
      cpfResponsibleAssign: company.responsible.cpfResponsibleAssign,
      nameResponsibleAssign: company.responsible.nameResponsibleAssign,
      rgResponsibleAssign: company.responsible.rgResponsibleAssign,
      phoneResponsibleAssing: company.responsible.phoneResponsibleAssing,

      name: company.name,
      phone: company.phone,
      states: company.address.states,
      ddd: company.phone,
      district: company.address.district,
      documentStates: company.documentStates,
      emailCompany: company.emailCompany,
      documentCity: company.documentCity,
      zipcode: company.address.zipcode,
      documentGov: company.documentGov,
      city: company.address.city,
      companiesStatus: company.companiesStatus,
      complement: company.address.addressNumber,
      country: company.address.addressNumber,
      addressNumber: company.address.addressNumber,
      address: company.address.address,

      uuid: company.uuid,
      id: company.id,
      consignedPartner: company.consignedPartner,
      uuidConsignedPartner: company.uuidConsignedPartner
    });
  }

  editCompany() {
    let company = this.prepareCompany(this.formCompany);
    return this.speNBackofficeConsignedProxy.editCompany(company);
  }

  createCompany(): Observable<any> {
    let company = this.prepareCompany(this.formCompany);
    return this.speNBackofficeConsignedProxy.createCompany(company);
  }

  private prepareCompany(formCompany: FormGroup): Company {
    let values = formCompany.getRawValue();
    return {
      "uuid": values.uuid ? values.uuid : "",
      "id": values.id ? values.id : "",
      "consignedPartner": values.consignedPartner ? values.consignedPartner : "URBANO_BANK",
      "uuidConsignedPartner": values.uuidConsignedPartner ? values.uuidConsignedPartner : "",

      "name": values.name,
      "companiesStatus": values.companiesStatus,
      "documentGov": values.documentGov,
      "documentStates": values.documentStates,
      "documentCity": values.documentCity,
      "emailCompany": values.emailCompany,
      "phone": values.phone,
      "address": {
        "address": values.address,
        "addressNumber": values.addressNumber,
        "complement": values.complement,
        "city": values.city,
        "states": values.states?.toUpperCase(),
        "district": values.district,
        "zipcode": values.zipcode,
        "country": values.country
      },
      "contact": {
        "nameContact": values.nameContact,
        "phoneContact": values.phoneContact,
        "emailContact": values.emailContact,
        "documentCcontact": values.documentContact
      },
      "responsible": {
        "nameResponsibleAssign": values.nameResponsibleAssign,
        "cpfResponsibleAssign": values.cpfResponsibleAssign,
        "emailResponsibleAssign": values.emailResponsibleAssign,
        "phoneResponsibleAssing": values.phoneResponsibleAssing,
        "rgResponsibleAssign": values.rgResponsibleAssign,
      }
    }
  }

  getListCompanySelectEmployee(): Observable<any> {
    return this.speViewNBackofficeConsignedProxy.getListCompanySelectEmployee();
  }

  getUuidCompany(uuid: string): Observable<any> {
    return this.speViewNBackofficeConsignedProxy.getUuidCompany(uuid);
  }

  public enumTypeToArray(enumType: any): any {
    return Object.keys(enumType)
  }

  public findByUuidCompanyUrbanActives(uuid: string): Observable<any> {
    return this.speViewNBackofficeConsignedProxy.findByUuidCompanyUrbanActives(uuid);
  }

  public getCompanyByCNPJ(cnpj: string) {    
    return this.speViewNBackofficeConsignedProxy.getCompanyByCNPJ(cnpj);
  }

}
