<ng-container *ngIf="appFooterContainerCSSClass">
  <!--begin::Footer container-->
  <div class="app-container" [ngClass]="appFooterContainerCSSClass">
    <ng-container *ngTemplateOutlet="footerView"></ng-container>
  </div>
  <!--end::Footer container-->
</ng-container>

<ng-container *ngIf="!appFooterContainerCSSClass">
  <ng-container *ngTemplateOutlet="footerView"></ng-container>
</ng-container>

<ng-template #footerView>
  <!--begin::Copyright-->
  <div class="text-dark order-3 order-md-1">
    <span class="text-muted fw-semibold me-1">{{currentDateStr}}&copy;</span>
    <a class="text-gray-800 text-hover-primary">Urbano SCFI</a>
  </div>
  <!--end::Copyright-->

  <!--begin::Menu-->
  <!-- <ul class="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
    <li class="menu-item"><a href="https://keenthemes.com/" target="_blank" class="menu-link px-2">About</a></li>

    <li class="menu-item"><a href="https://devs.keenthemes.com/" target="_blank" class="menu-link px-2">Support</a></li>

    <li class="menu-item"><a href="https://themeforest.net/item/metronic-responsive-admin-dashboard-template/4021469"
        target="_blank" class="menu-link px-2">Purchase</a></li>
  </ul> -->
  <!--end::Menu-->

</ng-template>
