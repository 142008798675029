export enum ProposalStatusEnum {
    AUDIT_ANALYSIS = 'AUDIT_ANALYSIS',
    REQUESTED = 'REQUESTED',
    AUDIT_APPROVED = 'AUDIT_APPROVED',
    AUDIT_REPROVED = 'AUDIT_REPROVED',
    MANAGER_IN_ANALYSIS = 'MANAGER_IN_ANALYSIS',
    MANAGER_APPROVED = 'MANAGER_APPROVED',
    MANAGER_REPROVED = 'MANAGER_REPROVED',
    CONTRACT_CUSTOMER_SIGNATURE = 'CONTRACT_CUSTOMER_SIGNATURE',
    CONTRACT_WORK_SIGNATURE = 'CONTRACT_WORK_SIGNATURE',
    SIGNEDCCB = 'SIGNEDCCB',
    APPROVED = 'APPROVED',
    CANCELED = 'CANCELED',
    FINISHED = 'FINISHED',
    RELEASED_FOR_PAYMENT = 'RELEASED_FOR_PAYMENT',
    PAID = 'PAID',
    CEDED = 'CEDED',
    PAYMENT_PENDING = 'PAYMENT_PENDING',
};
