<div class="table-finance">
  <div class="card-header">
    <h3 class="card-title">Detalhes Financeiros do Contrato</h3>
  </div>

  <div class="card-body">
    <div class="row">
      <div class="col-12">
        <table class="table-finance-table text-center mat-elevation-z1 table table-striped table-hover mb-0">
          <thead class="p-3">
            <tr>
              <th class="text-bg-success text-center text_color_black" scope="col text-center">Solicitado </th>
              <th class="" scope="col text-center">+TAC</th>
              <th class="text-bg-warning text-center text_color_black" scope="col text-center">+ Caren.</th>
              <th class="text-bg-success text-center text_color_black" scope="col text-center">(P) Total Solicitado</th>
              <th class="" scope="col text-center">IOF Princ.</th>
              <th class="" scope="col text-center">+IOF Princ.</th>
              <th class="" scope="col text-center">Parcela</th>
              <th class="" scope="col text-center">(n) Qtd. Parcelas</th>
              <th class="" scope="col text-center">Total IOF</th>
              <th class="" scope="col text-center">Total Juros</th>
              <th class="" scope="col text-center">Total Seguro</th>
              <th class="" scope="col text-center">CET</th>
              <th class="" scope="col text-center">CET %</th>
            </tr>
          </thead>
          <tbody class="disabled">
            <tr>
              <td class="text-bg-success text-center text_color_black">
                {{proposal?.loan?.amountProposal | currency:"BRL"}}
              </td>
              <td>
                {{proposal?.priceDetails?.requestedPlusTac | currency:"BRL"}}
              </td>
              <td class="text-bg-warning text-center text_color_black">
                {{proposal?.priceDetails?.plusLack | currency:"BRL"}}
              </td>

              <td class="text-bg-success text-center text_color_black">
                {{proposal?.loan?.loan | currency:"BRL"}}
              </td>
              <td>
                {{proposal?.priceDetails?.mainIofValue | currency:"BRL"}}
              </td>
              <td>
                {{proposal?.priceDetails?.plusIof | currency:"BRL"}}
              </td>
              <td>
                {{proposal?.loan.pinStallmentValue | currency:"BRL" }}
              </td>
              <td>
                {{proposal?.loan.installmentQuantityLoan}}X
              </td>
              <td>
                {{proposal?.priceDetails.totalIof | currency:"BRL" }}
              </td>
              <td>
                {{proposal?.loan.totalInterest | currency:"BRL" }}
              </td>
              <td>
                {{proposal?.priceDetails?.totalInsurance | currency:"BRL"}}
              </td>
              <td>
                {{proposal?.priceDetails?.cet | currency:"BRL"}}
              </td>
              <td>
                {{proposal?.priceDetails?.cetIndex}}%
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
