import { Injectable } from '@angular/core';
import { ApiService } from '../../_base/_auth/api.service';
import { Observable, of } from 'rxjs';
import { ConfigurationTaxDto } from '../../scfi/services/services-tax/tax.dto';
import { ConfigurationFeeDto } from '../../scfi/services/services-fees/fees.dto';
import { ConfigurationKitDto, CreateKitDto } from "../../scfi/services/services-kits/kits.dto";
import {FundingDto, StatementDto} from '../../scfi/services/services-funding/funding.dto';
import { FUNDING_LIST_MOCK } from '../../scfi/services/services-funding/funding-list.mock';
import { TypeCustomerDataDto} from "../../scfi/services/services-customer-data/type-customer-data.dto";
import {MenuDto} from "../../scfi/services/services-dto/menu.dto";
import { FUNDING_MOCK } from '../../scfi/services/services-funding/funding.mock';
import { STATEMENT_MOCK } from '../../scfi/services/services-funding/statement.mock';
import { ProductDto } from "../../scfi/services/services-product/products.dto";
import { TypeProductDto } from "../../scfi/services/services-product/type-product.dto";
import {
    ApprovalHistoryDto,
    ProposalDto, ProposalModel,
} from '../../scfi/services/services-proposal/proposal.dto';
import { APPROVAL_HISTORY_MOCK, PROPOSAL_MOCK } from '../../scfi/services/services-proposal/proposal.mock';
import {
    CustomerPfDto,
    CustomerPjDto,
    CustomerDto,
    CustomerContactDto
} from "../../scfi/services/services-customer/customer.dto";
import { ContractTable } from "../../scfi/services/services-contract/contract.dto";
import { CONTRACT_LIST_MOCK } from "../../scfi/services/services-contract/contract.mock";
import { TypeCustomerDto } from "../../scfi/services/services-customer/type-customer.dto";
import {AcceptProposalDto, ConfDataAccept} from "../../scfi/services/services-accept/accept-proposal.dto";
import {ACCEPT_DATA_MOCK, CONF_ACCEPT} from "../../scfi/services/services-accept/accept-proposal.mock";
import {ProposalStatusRoleEnum} from "../../scfi/services/services-proposal/enum/proposal-status-role.enum";
import { AddressTypeDto } from '../../scfi/services/services-common/address-type.dto';
import { CepDetailsDto } from '../../scfi/services/services-cep/cep-details.dto';

const SPE_VIEW_N_SCFI = '/spe-view-n-scfi';

@Injectable(
    { providedIn: 'root' }
)
export class SpeViewNScfiProxy {

    constructor(
        private api: ApiService
    ) { }

    findTaxes(): Observable<ConfigurationTaxDto[]> {
        const uri = `${SPE_VIEW_N_SCFI}/v1/tax`;
        return this.api.get(uri);
    }

    findTaxByUuid(uuid: string): Observable<ConfigurationTaxDto> {
        const uri = `${SPE_VIEW_N_SCFI}/v1/tax/${uuid}`;
        return this.api.get(uri);
    }

    findFeeList(): Observable<ConfigurationFeeDto[]> {
        const uri = `${SPE_VIEW_N_SCFI}/v1/fee`;
        return this.api.get(uri);
    }

    findDataList(): Observable<TypeCustomerDataDto[]>{
        const uri = `${SPE_VIEW_N_SCFI}/v1/type-customer-data`;
        return this.api.get(uri);
    }

    findAcceptDetails(): Observable<AcceptProposalDto[]>{
        return of(ACCEPT_DATA_MOCK);
    }

    findDataByUuid(uuid:string): Observable<TypeCustomerDataDto> {
        const uri = `${SPE_VIEW_N_SCFI}/v1/type-customer-data/${uuid}`
        return this.api.get(uri);
    }

    findFeeByUuid(uuid: string): Observable<ConfigurationFeeDto> {
        const uri = `${SPE_VIEW_N_SCFI}/v1/fee/${uuid}`;
        return this.api.get(uri);
    }

    findKitsList(): Observable<ConfigurationKitDto[]> {
        const uri = `${SPE_VIEW_N_SCFI}/v1/kit`;
        return this.api.get(uri);
    }

    findKitByUuid(uuid: string): Observable<CreateKitDto> {
        return of();
    }

    findByUuidWithDocs(uuid: string): Observable<ConfigurationKitDto> {
        const uri = `${SPE_VIEW_N_SCFI}/v1/kit/kit-docs/${uuid}`;
        return this.api.get(uri);
    }

    findFundingList(): Observable<FundingDto[]> {
        return of(FUNDING_LIST_MOCK);
    }

    getMenuSide(type: string, role: string) :Observable<MenuDto[]> {
        const uri = `${SPE_VIEW_N_SCFI}/v1/menu/type/${type}/role/${role}`;
        return this.api.get(uri);
    }

    findFundingByUuid(uuid: string): Observable<FundingDto> {
        return of(FUNDING_MOCK);
    }

    getFundingStatement(uuid: string): Observable<StatementDto[]> {
        return of(STATEMENT_MOCK);
    }

    findProductTypeList(uuidWhitelabel: string): Observable<TypeProductDto[]> {
        const uri = `${SPE_VIEW_N_SCFI}/v1/type-product/white-label/${uuidWhitelabel}`;
        return this.api.get(uri);
    }

    findProductTypeWithProductConfiguredList(uuidWhitelabel: string): Observable<TypeProductDto[]> {
        const uri = `${SPE_VIEW_N_SCFI}/v1/type-product/white-label/${uuidWhitelabel}?isConfigured=true`;
        return this.api.get(uri);
    }

    findTypeProductByUuid(uuid: string): Observable<TypeProductDto> {
        const url = `${SPE_VIEW_N_SCFI}/v1/type-product/${uuid}`;
        return this.api.get(url);
    }

    findProductList(uuidWhitelabel: string, uuidProductType: string): Observable<ProductDto[]> {
        const uri = `${SPE_VIEW_N_SCFI}/v1/product/white-label/${uuidWhitelabel}/type-product/${uuidProductType}`;
        return this.api.get(uri);
    }

    findFullRegisterProductList(uuidWhitelabel: string, uuidProductType: string): Observable<ProductDto[]> {
        const uri = `${SPE_VIEW_N_SCFI}/v1/product/white-label/${uuidWhitelabel}/type-product/${uuidProductType}?isFullCreated=true`;
        return this.api.get(uri);
    }

    findProductByUuid(uuid: string): Observable<ProductDto> {
        return of();
    }

    getProductByUuid(uuid: string): Observable<ProductDto> {
        const uri = `${SPE_VIEW_N_SCFI}/v1/product/${uuid}`;
        return this.api.get(uri);
    }


    findProductDetailsByUuid(uuidProduct: string): Observable<ProductDto> {
        const uri = `${SPE_VIEW_N_SCFI}/v1/product/details/${uuidProduct}`;
        return this.api.get(uri);
    }

    findProposalsList(): Observable<ProposalDto[]>{
        return of(PROPOSAL_MOCK);
    }

    findProposalByUuid(uuid: string): Observable<ProposalDto> {
        return of(PROPOSAL_MOCK[0]);
    }

    findProposalByStatus(status: ProposalStatusRoleEnum): Observable<ProposalModel[]>{
        const uri = `${SPE_VIEW_N_SCFI}/v1/proposal/status/${status}`;
        return this.api.get(uri);
    }

    findApprovalHistory(uuid: string): Observable<ApprovalHistoryDto[]> {
        return of(APPROVAL_HISTORY_MOCK);
    }

    findCustomerList(): Observable<CustomerDto[]> {
        const uri = `${SPE_VIEW_N_SCFI}/v1/customer`;
        return this.api.get(uri);
    }

    findCustomerByUuid(uuid: string): Observable<CustomerDto> {
        const uri = `${SPE_VIEW_N_SCFI}/v1/customer/${uuid}`;
        return this.api.get(uri);
    }

    findCustomerByDocument(document: string): Observable<CustomerDto> {
        const documentSanitized = document.replace(/\D/g,"")
        const uri = `${SPE_VIEW_N_SCFI}/v1/customer/document/${documentSanitized}`;
        return this.api.get(uri);
    }

    findTypeCustomer(): Observable<TypeCustomerDto[]> {
        const uri = `${SPE_VIEW_N_SCFI}/v1/type-customer`;
        return this.api.get(uri);
    }

    findContactCustomerByUuid(uuidCustomer: string): Observable<CustomerContactDto> {
        const uri = `${SPE_VIEW_N_SCFI}/v1/customer/contact/${uuidCustomer}`;
        return this.api.get(uri);
    }

    findContractList(): Observable<ContractTable[]> {
        return of(CONTRACT_LIST_MOCK);
    }

    findAcceptData(): Observable<ConfDataAccept>{
        return of(CONF_ACCEPT);
    }

    getAddressByCep(cep: string): Observable<CepDetailsDto> {
        const uri = `${SPE_VIEW_N_SCFI}/v1/cep/${cep}`;
        return this.api.get(uri);
    }
    
    findAddressType(): Observable<AddressTypeDto[]> {
        const uri = `${SPE_VIEW_N_SCFI}/v1/type-address`;
        return this.api.get(uri);
    }
}
